@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.status {
  background: #fff;
  border-radius: 10px;

  .status__empty {
    padding: 0 45px 16px 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty__img {
      width: 140px;
      height: 140px;
    }
    .empty__title {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: #222;
    }
    .empty__desc {
      padding-top: 4px;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      text-align: center;
      color: #666;
    }
  }
  .status__result {
    padding: 12px;

    .result__title {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      color: #222;
    }
    .result__total {
      padding-top: 8px;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: #222;

      span {
        font-family: PingFang SC;
        font-weight: 500;
        color: #ff4747;
      }
    }
    .result__prize {
      margin-top: 16px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff0de;
      border-radius: 10px;

      .prize__title {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        color: #8c4206;
      }
      .prize__img {
        margin-top: 12px;
        width: 74px;
        height: 74px;
        border: 1px solid #ffe9d0;
        border-radius: 8px;
        box-sizing: border-box;
      }
      .prize__desc {
        padding-top: 8px;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        color: #222;
      }
    }

    .tips {
      padding: 16px 0 0 0;
      font-family: "苹方-简";
      font-size: 11px;
      font-weight: normal;
      line-height: 11px;
      color: #222;
      text-align: center;
    }
  }
}
