@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.activity {
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #f5f5f5;
  padding: 12px 0 12px 0;
  .top {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    .brand {
      display: flex;
      // padding-top: 5px;
      .img {
        border-radius: 8px;
        border: 0.5px solid rgba(34, 34, 34, 0.05);
        width: 40px;
        height: 40px;
        background: #ffffff;
        border-radius: 8px;
        box-sizing: border-box;
        flex-shrink: 0;
        object-fit: contain;
      }
      .title {
        padding-left: 6px;
        .top_name {
          font-size: 14px;
          // font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .bottom_name {
          font-size: 12px;
          font-family:
            PingFangSC-Regular,
            PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
          padding-top: 6px;
          max-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .time {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      position: relative;
      .notice {
        font-size: 12px;
        width: 42px;
        height: 26px;
        background: rgba(255, 240, 224, 0.8);
        border-radius: 4px;
        opacity: 0.8;
        border: 1px solid #ff6600;
        margin-right: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 102, 0, 1);
        font-weight: 500;
        box-sizing: border-box;
        margin-top: 7px;
      }
      .btn {
        width: 64px;
        height: 26px;
        background: linear-gradient(270deg, #ff7346 0%, #ff2b2b 100%);
        border-radius: 4px;
        font-size: 12px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 7px;
        .icon {
          width: 10px;
          height: 10px;
          padding-left: 3px;
        }
      }
      .btn_pre {
        width: 64px;
        height: 26px;
        background: rgba(255, 102, 0, 1);
        border-radius: 4px;
        font-size: 12px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 7px;
      }
      .countdown {
        padding-top: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #666666;
        position: absolute;
        bottom: -27px;
        right: -4px;
        .pad_4 {
          padding: 0 4px;
          white-space: nowrap;
        }
        .color_block {
          height: 18px;
          background: #f1f2f5;
          border-radius: 5px;
          font-size: 10px;
          font-family:
            PingFangSC-Semibold,
            PingFang SC;
          font-weight: 600;
          color: #222222;
          text-align: center;
          line-height: 18px;
          padding: 0 3px;
        }
      }
      .process {
        font-family: PingFang SC;
        font-size: 10px;
        font-weight: normal;
        color: #666666;
        position: absolute;
        bottom: -27px;
        right: 0px;
      }
    }
  }
  .discount_label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: hidden;
    padding: 0 12px 0 12px;
    height: 23px;
    width: 250px;
    .discount_label_item {
      width: 42px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid rgb(255 102 0 / 80%);
      color: rgb(255 102 0 / 80%);
      text-align: center;
      line-height: 15px;
      margin-right: 3px;
      margin-bottom: 3px;
    }

    &.intr_pos {
      padding: 9px 0 0 12px;
      position: relative;
      margin-top: -6px;
      // height: auto;

      & > * {
        flex-shrink: 0;
      }
    }
  }
  .coupon {
    padding-top: 12px;
    display: flex;
    .label {
      height: 16px;
      border-radius: 4px;
      opacity: 0.8;
      border: 1px solid #ff6600;
      font-size: 10px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #ff6600;
      line-height: 16px;
      padding: 0 3px;
      text-align: center;
      margin-right: 4px;
    }
  }
  .empty {
    height: 24px;
  }
  .product {
    padding-top: 6px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    .item_block {
      margin-right: 6px;
      .item_price {
        padding: 0 !important;
        color: #ffffff;
      }
      .price {
        display: flex;
        width: calc(100% - 76px);
        line-height: 22px;
        width: 100px;
        .price_text {
          color: #ff302d;
          display: flex;
          height: 19px;
          align-items: flex-end;
          transform: scale(0.9);
          white-space: nowrap;
          flex-shrink: 0;
        }
        .sec_kill {
          font-size: 10px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #ff302d;
          padding-top: 4px;
          padding-right: 2px;
          flex-shrink: 0;
        }
        .price_real {
          font-size: 14px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #ff302d;
          display: flex;
          align-items: flex-end;
          .price_big {
            padding-left: 2px;
            font-size: 16px;
            line-height: 19px;
          }
        }
        .price_old {
          font-size: 12px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #a5a5a5;
          padding-left: 3px;
          text-decoration: line-through;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-top: 4px;
          white-space: nowrap;
        }
      }
      .item {
        width: 100px;
        height: 100px;
        border-radius: 4px;
        flex-shrink: 0;
        padding: 3px;
        box-sizing: border-box;
        position: relative;
        border: solid #eeee 0.5px;
        overflow: hidden;
        .live {
          width: fit-content;
          height: 18px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 9px;
          color: #ffffff;
          padding: 0 0 0 3px;
          display: flex;
          align-items: center;

          .barTotal {
            width: 14px;
            height: 14px;
            display: inline-block !important;
            position: relative;
            border-radius: 50%;
            background-color: rgba(255, 48, 45, 1);
          }
          .barTotal span {
            position: absolute;
            width: 15%;
            bottom: 3px;
            border-radius: 3px;
          }
          .bar1 {
            left: 2px;
            height: 60%;
            background-color: #ffff;
          }
          .bar2 {
            left: 5.5px;
            height: 45%;
            background-color: #ffff;
          }
          .bar3 {
            left: 9px;
            height: 90%;
            background-color: #ffff;
          }
          .a1 {
            -webkit-animation: ani1 1s infinite alternate both;
            -moz-animation: ani1 1s infinite alternate both;
            animation: ani1 1s infinite alternate both;
          }
          .a2 {
            -webkit-animation: ani2 1s infinite alternate both;
            -moz-animation: ani2 1s infinite alternate both;
            animation: ani2 1s infinite alternate both;
          }
          .a3 {
            -webkit-animation: ani3 1s infinite alternate both;
            -moz-animation: ani3 1s infinite alternate both;
            animation: ani3 1s infinite alternate both;
          }
          @-webkit-keyframes ani1 {
            0% {
              height: 30%;
            }
            25% {
              height: 50%;
            }
            50% {
              height: 60%;
            }
            75% {
              height: 30%;
            }
            100% {
              height: 60%;
            }
          }
          @-moz-keyframes ani1 {
            0% {
              height: 30%;
            }
            25% {
              height: 50%;
            }
            50% {
              height: 60%;
            }
            75% {
              height: 30%;
            }
            100% {
              height: 60%;
            }
          }
          @keyframes ani1 {
            0% {
              height: 30%;
            }
            25% {
              height: 50%;
            }
            50% {
              height: 60%;
            }
            75% {
              height: 30%;
            }
            100% {
              height: 60%;
            }
          }
          @-webkit-keyframes ani2 {
            0% {
              height: 55%;
            }
            25% {
              height: 35%;
            }
            50% {
              height: 46%;
            }
            75% {
              height: 27%;
            }
            100% {
              height: 11%;
            }
          }
          @-moz-keyframes ani2 {
            0% {
              height: 55%;
            }
            25% {
              height: 35%;
            }
            50% {
              height: 46%;
            }
            75% {
              height: 27%;
            }
            100% {
              height: 11%;
            }
          }
          @keyframes ani2 {
            0% {
              height: 55%;
            }
            25% {
              height: 35%;
            }
            50% {
              height: 46%;
            }
            75% {
              height: 27%;
            }
            100% {
              height: 11%;
            }
          }
          @-webkit-keyframes ani3 {
            0% {
              height: 56%;
            }
            25% {
              height: 60%;
            }
            50% {
              height: 45%;
            }
            75% {
              height: 25%;
            }
            100% {
              height: 60%;
            }
          }
          @-moz-keyframes ani3 {
            0% {
              height: 56%;
            }
            25% {
              height: 60%;
            }
            50% {
              height: 45%;
            }
            75% {
              height: 25%;
            }
            100% {
              height: 60%;
            }
          }
          @keyframes ani3 {
            0% {
              height: 56%;
            }
            25% {
              height: 60%;
            }
            50% {
              height: 45%;
            }
            75% {
              height: 25%;
            }
            100% {
              height: 60%;
            }
          }
          .text {
            padding-left: 0px;
            transform: scale(0.9);
            white-space: nowrap;
            padding-right: 3px;
          }
        }
        .liveDesc {
          width: fit-content;
          height: 14px;
          line-height: 14px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 0px 8px 0px 8px;
          position: absolute;
          bottom: 0;
          left: 0;
          color: #ffffff;
          padding: 0 3px;
          .desc {
            font-size: 12px;
            transform: scale(0.9);
          }
        }
        .tag {
          width: fit-content;
          height: 17px;
          background: rgba(255, 102, 0, 0.8);
          border-radius: 8px 0px 8px 0px;
          .text {
            padding-left: 6px;
            padding-right: 6px;
            transform: scale(0.8);
          }
        }
        .img_play {
          position: absolute;
          bottom: 6px;
          left: 6px;
          width: 18px;
          height: 18px;
        }
        .save_money_content {
          position: absolute;
          bottom: 0;
          background-color: rgba(251, 73, 59, 1);
          border-bottom-left-radius: 8px;
          border-top-right-radius: 8px;
          display: flex;
          max-width: 100%;
          height: 14px;
          align-items: center;
          .save_money {
            background: rgba(255, 224, 234, 0.9);
            font-size: 10px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #fb493b;
            border-bottom-left-radius: 8px;
            border-top-right-radius: 8px;
            padding: 0px 6px;
            white-space: nowrap;
          }
          .add {
            color: #ffffff;
            padding: 0 3px;
            line-height: 18.5px;
            flex-shrink: 1;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }
        }
        .sell_out {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          .sell_out_circle {
            width: 79px;
            height: 79px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            text-align: center;
            font-size: 18px;
            font-family:
              PingFangSC-Semibold,
              PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 79px;
          }
        }
        .quantity {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
          border-bottom-left-radius: 8px;
          border-top-right-radius: 8px;
          display: flex;
          max-width: 100%;
          color: #ffff;
          padding: 0 6px;
        }
      }
      .story {
        font-size: 12px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #666666;
        padding-top: 6px;
        line-height: 16px;
        display: flex;
        justify-content: center;
        max-width: 100px;
        .story_text {
          height: 18px;
          background: #fff0ef;
          border-radius: 6px;
          color: rgba(203, 54, 49, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 6px;
          .story_text_div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80px;
            text-align: center;
          }
        }
      }
      .price {
        font-size: 12px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #666666;
        padding-top: 6px;
        line-height: 16px;
        .text {
          // transform: scale(0.9);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100px;
          margin-left: -5px;
          width: 94px;
          margin-left: 10px;
          margin-top: 3px;
          font-weight: 500;
          color: #222222;
        }
      }
      .price_sec {
        font-size: 10px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #ff302d;
        padding-top: 3px;
        line-height: 14px;
        display: flex;
        .text {
          transform: scale(0.9);
        }
        .price_big {
          transform: scale(1.1);
          display: inline-block;
          margin: 0 2px 0 3px;
        }
      }
    }
    .item_block:first-child {
      margin-left: 12px;
    }
  }
  .product::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.more {
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    width: 73px;
    height: 20px;
    .text {
      font-size: 12px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #a5a5a5;
      padding-right: 3px;
    }
  }
}
