@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  flex-shrink: 0;
  width: 102px;
  box-sizing: border-box;
  margin-left: 8px;
  padding: 6px;
  padding-top: 14px;
  font-size: 12px;
  border-radius: 8px;
  text-align: center;
  background: linear-gradient(351deg, #ffffff 0%, #ffeae4 90%, #ffeae4 90%), #feebee;
  &:first-child {
    margin-left: 12px;
  }
  .title {
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    color: #222222;
  }
  .cont {
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: 14px;
    font-weight: 500;
    color: #ff4747;
    line-height: 35px;
    .price {
      padding-right: 2px;
      font-size: 36px;
      font-weight: bold;
      font-family: DIN-Medium;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    margin-bottom: 5px;
    color: #ffffff;
    font-weight: 500;
    box-sizing: border-box;
    color: #ff4747;
    border: 1px solid #ff4747;
    border-radius: 4px;
    background: rgba(255, 71, 71, 0.08);
    &.primary {
      border-width: 0;
      background: #ff4747;
      color: #ffffff;
    }
  }
  .desc {
    color: #666666;
    .count {
      color: #ff4747;
    }
  }
}

.wrap.disable {
  .title {
    color: #dbdbdb;
  }
  .cont {
    color: #dbdbdb;
  }
  .btn {
    background: #dbdbdb;
    color: #ffffff;
  }
  .desc {
    color: #dbdbdb;
    .count {
      color: #dbdbdb;
    }
  }
}

.wrap.smallCard {
  background: url(https://akim-oss.aikucun.com/e1822db470e60d090affd0956d743cb0e7cdf113_1706593903000_95.png)
    center center no-repeat;
  background-size: 100% 100%;
}

.cornerLabel {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  padding: 0 8px;
  transform: translateY(-50%);
  border: 0.5px solid #ffffff;
  border-radius: 6px 1px 6px 0px;
  background: #ffdfc5;
  color: #8c4206;
  font-size: 10px;
  &.translateY0 {
    transform: translateY(0);
  }
}

.LongWrap {
  position: relative;
  height: 90px;
  margin-bottom: 8px;
  background: url(https://akim-oss.aikucun.com/fb644351560d8296fe6da332236b1f8d61b2828a_1706523752098_3.png)
    left top no-repeat;
  background-size: 100%;
  .main {
    display: flex;
  }
  .longLeft {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 90px;
    padding-top: 3px;
    color: #666666;
    font-size: 11px;
    .price {
      display: flex;
      align-items: baseline;
      font-size: 30px;
      font-weight: 600;
      font-family: DIN-Medium;
      color: #ff1010;
      line-height: 30px;
      &::before {
        content: "¥";
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .longRight {
    flex: 1;
    padding: 10px;
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #ff1010;
    }
    .desc {
      width: 161px;
      white-space: noWrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-bottom: 7.5px;
      border-bottom: 0.5px dashed rgba(255, 16, 16, 0.2);
      font-size: 12px;
      color: #a5a5a5;
    }
    .extra {
      padding-top: 5px;
      color: #a5a5a5;
      .hl {
        color: #ff4747;
      }
    }
  }
  .btn {
    position: absolute;
    right: 12px;
    top: 32px;
    padding: 5px 14px;
    line-height: 16px;
    border: 1px solid #ff4747;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    color: #ff4747;
    background: rgba(255, 71, 71, 0.08);
    &.primary {
      background: #ff4747;
      color: #ffffff;
      border-color: #ff4747;
    }
  }
  &.disable {
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      width: 80px;
      height: 80px;
      right: -4.5px;
      bottom: -19.5px;
      background: url(https://akim-oss.aikucun.com/fe2ef495a1152561572949784c16bf23abb28057_1706534162831_75.png)
        left top no-repeat;
      background-size: 80px auto;
    }
    &.hasUsed::after {
      background-image: url(https://akim-oss.aikucun.com/827bfc458708f0b442009c9c9836f7e4b65557fb_1706534163057_54.png);
    }
    .longLeft {
      color: #dbdbdb;
      .price {
        color: #dbdbdb;
      }
    }
    .longRight {
      color: #dbdbdb;
      .title {
        color: #dbdbdb;
      }
      .desc {
        color: #dbdbdb;
        border-bottom-color: #dbdbdb;
      }
      .extra {
        color: #dbdbdb;
        .hl {
          color: #dbdbdb;
        }
      }
    }
  }
}
