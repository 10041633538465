@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #cd34cf;

  .scroll_view {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #cd34cf;
    box-sizing: border-box;
    overflow: hidden scroll;

    .scroll__content {
      width: 100vw;
      background-color: #cd34cf;
      background-image: url("https://akim-oss.aikucun.com/mshop/5e868cdf3d610ed0336c5939981e063edf079d9d_1732616245552_76.png");
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 100%;

      .page__header {
        padding: 16px 0 22px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .activity_status {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .activity-status__left {
            .status {
              position: relative;
              left: -12px;
              padding: 0 12px;
              height: 26px;
              line-height: 26px;
              background: #ffe5c8;
              border-radius: 0 24px 24px 0;
              font-family: PingFang SC;
              font-size: 12px;
              font-weight: 500;
              color: #333;
            }
          }

          .activity-status__right {
            padding-right: 12px;

            .right__count-down {
              display: flex;
              align-items: center;
              font-family: PingFang SC;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              color: #fff;

              .block {
                margin: 0 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 18px;
                height: 18px;
                background: #ffe5c8;
                border-radius: 4px;
                font-family: "DIN-Medium";
                font-size: 12px;
                font-weight: bold;
                line-height: 16px;
                color: #222;
              }
            }
          }
        }

        .activity-name-wrap {
          padding: 36px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;

          .logo-list {
            position: relative;
            flex-shrink: 0;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;

            .item {
              position: relative;
              width: 30px;
              height: 30px;
              background: #fff;
              border: 1px solid #fff;
              object-fit: contain;
              border-radius: 4px;
              box-sizing: border-box;
            }
          }

          .activity_name {
            flex-shrink: 0;
            max-width: 270px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
            font-family: PingFang SC;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            color: #ffe5c8;
          }
        }

        .activity_desc {
          padding-top: 4px;
          font-family: "苹方-简";
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          color: #fff;
        }

        .activity_time {
          margin-top: 8px;
          padding: 0 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 24px;
          background: #ffe5c8;
          border-radius: 12px;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 500;
          color: #222;
        }
      }

      .page__body {
        margin: 0 12px;

        .body__content {
          background: #fff;
          border-radius: 10px;

          .body__tab {
            padding: 12px 12px 8px 12px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .tab__item {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: PingFang SC;
              font-size: 14px;
              font-weight: normal;
              line-height: 20px;
              color: #666;
            }

            .tab__active {
              position: relative;
              font-weight: bold;

              &::after {
                content: "";
                position: absolute;
                bottom: -4px;
                left: 0;
                right: 0;
                margin: auto;
                width: 50%;
                height: 2px;
                border-radius: 2px;
                background: #ff4747;
              }
            }
          }

          .body__stimulation {
            padding: 12px;

            .stimulation__title {
              margin-bottom: 10px;
              text-align: center;
              font-family: PingFang SC;
              font-size: 18px;
              font-weight: 500;
              line-height: 24px;
              color: #222;
            }

            .pass {
              color: #ff4747;
            }
          }
        }

        .body__product {
          margin: 20px 0 12px 0;

          .product__title {
            padding-bottom: 12px;
            text-align: center;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            color: #fff;

            &::before {
              position: relative;
              right: 8px;
              content: "";
              display: inline-block;
              width: 10px;
              height: 10px;
              background-image: url("https://akim-oss.aikucun.com/mshop/9db496dd5add8a9f0b21dfb28590ffe3ea57cb1e_1715583756445_8.png");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }

            &::after {
              position: relative;
              left: 8px;
              content: "";
              display: inline-block;
              width: 10px;
              height: 10px;
              // background-image: url("https://akim-oss.aikucun.com/mshop/9db496dd5add8a9f0b21dfb28590ffe3ea57cb1e_1714933584561_90.png");
              background-image: url("https://akim-oss.aikucun.com/mshop/9db496dd5add8a9f0b21dfb28590ffe3ea57cb1e_1715583756445_8.png");
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  .page__footer {
    flex-shrink: 0;
    padding: 6px 12px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    width: 100vw;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;

    .footer__btn {
      margin-bottom: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      background: #ff4747;
      border-radius: 6px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #fff;

      .icon {
        margin-right: 4px;
        width: 20px;
        height: 20px;
      }
    }

    .disabled {
      opacity: 0.5;
    }
  }

  .text-ellipsis1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}
