@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .item {
    position: absolute;
    width: 100%;
  }
}
