@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.exchangeBox {
  position: relative;
  z-index: 10;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  background: #FFF4E6;


  .icon__notice {
    flex-shrink: 0;
    width: 14px;
    height: 14px;
  }

  .content {
    flex-grow: 1;
    padding: 0 4px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    font-family: '苹方-简';
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #ff4747;

    &::after {
      content: "";
      position: relative;
      top: 2px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-image: url(https://akim-oss.aikucun.com/mshop/e1fa4795d11da15cdbb80a572ebc830ec77a8ba8_1736315182625_38.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .icon__close {
    flex-shrink: 0;
    align-self: flex-end;
    width: 12px;
    height: 12px;
  }
}