@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.image-search {
  position: relative;
  background: #222222;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .fiexd-top {
    position: fixed;
    top: 12px;
    left: 0;
    width: 100%;
    z-index: 100;
  }
}
