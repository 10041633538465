@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  padding-top: 44px;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  .header {
    position: fixed;
    top: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    width: 375px;
    height: 44px;
    box-sizing: border-box;
    background-color: white;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(34, 34, 34, 0.08);
      transform: scaleY(0.5);
    }
    .back {
      width: 32px;
      height: 32px;
    }
    .tip {
      display: flex;
      align-items: center;
      margin-left: 24px;
      padding: 0px 4px 0px 12px;
      box-sizing: border-box;
      height: 32px;
      font-size: 14px;
      line-height: 32px;
      color: #222222;
      border-radius: 8px;
      background: linear-gradient(90deg, rgba(255, 71, 71, 0.08) 2%, rgba(255, 71, 71, 0) 98%);
      span {
        font-weight: 500;
        color: #ff4747;
      }
      img {
        margin-left: 8px;
        width: 13px;
        height: 13px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    .poster-item {
      position: relative;
      margin-bottom: 6px;
      width: 175px;
      height: 175px;
      border-radius: 6px;
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 200%;
        height: 200%;
        border: 1px solid rgba(34, 34, 34, 0.08);
        transform-origin: left top;
        transform: scale(0.5);
      }
      img {
        position: relative;
        z-index: 10;
        border-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
:global(.adm-image-viewer-image-wrapper) {
  img {
    width: 80%;
  }
}
