@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.sort-list {
  position: fixed;
  top: 44px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 16px;
  width: 375px;
  height: 44px;
  box-sizing: border-box;
  background-color: white;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(34, 34, 34, 0.08);
    transform: scaleY(0.5);
  }
  .sort-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: #666666;

    .filterIcon {
      margin-left: 4px;
    }

    &:last-child {
      height: 14px;
      padding-left: 16px;
      border-left: 1px solid rgba(34, 34, 34, 0.16);
    }

    &.active {
      color: @themeColor;
      font-weight: 500;
      .filterIcon {
        path {
          fill: @themeColor;
        }
      }
    }
  }
}
