@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  width: 362px;
  margin: 0 auto;
  padding: 12px 0;
  border-radius: 12px;
  color: #222222;
  border: 1px solid #ffffff;
  background: linear-gradient(180deg, #fff0df 0%, #ffffff 9%);

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0 12px;

    .headerLeft {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;

      .headerLeftTitle {
        width: 98px;
        height: 20px;
        background: url('https://akim-oss.aikucun.com/54c3d6281f4c8024b25a9321d206f4409148ded4_1741346645164_97.png') no-repeat;
        background-size: contain;
        background-position: center;
      }
    }

    .headerRight {
      display: flex;
      align-items: center;

      &>img {
        width: 24px;
        height: 24px;
      }

      &>span {
        margin-left: 4px;
      }
    }

    // .headerRight {
    //   display: flex;
    //   align-items: center;
    //   color: #666666;

    //   .switch {
    //     margin-left: 8px;
    //   }
    // }
  }

  .main {
    margin-bottom: 12px;
    overflow-x: scroll;

    .list {
      display: flex;
      flex-wrap: nowrap;

      &::after {
        content: "";
        padding-right: 10px;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;

    .bottomLeft {
      display: flex;
      align-items: center;
      font-size: 12px;

      &>img {
        width: 23px;
        height: 23px;
        margin-right: 4px;
      }
    }

    .noticeMessages {
      display: flex;
      color: #666666;

      .remind {
        margin-right: 8px;
      }
    }
  }
}