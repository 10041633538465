@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.productParamtersC {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  .content {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    .name {
      font-size: 12px;
      color: #666;
      font-weight: 400;
      width: 100px;
    }
    .desc {
      width: 100%;
      font-size: 12px;
      color: #333;
      font-weight: 400;
      margin-left: 15px;
    }
  }
  .line {
    height: 1px;
    background-color: #eee;
    margin-top: 20px;
  }
}
