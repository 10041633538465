@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.follow-wechat {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border-radius: 10px;
  // margin-top: 8px;
  background-color: #ffffff;
  .follow-wechat__icon {
    width: 20px;
    height: 20px;
  }
  .follow-wechat__text {
    font-size: 12px;
    line-height: 16px;
    margin-left: 4px;
    color: #222222;
  }
  .follow-wechat__btn {
    margin-left: auto;
    padding: 4px 8px;
    border-radius: 4px;
    background: #ff6600;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #ffffff;
  }
}
.popup {
  display: flex;
  flex-direction: column;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  .header {
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #333;
    font-size: 15px;
    font-weight: bold;
  }
  .imgWrapper {
    width: 351px;
    margin: 0 auto;
    position: relative;
    min-height: 100px;

    .bg {
      width: 351px;
    }
  }
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    text-align: center;
    color: #222;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }
  .content {
    text-align: center;
    color: #666;
    font-size: 15px;
    padding: 12px 0 24px 0;
  }
  .button {
    width: 250px;
    height: 44px;
    color: white;
    background-color: @themeColor;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 44px;
    border-radius: @smallBorderRadius;
  }
}
