@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  border-radius: 10px 10px 0 0;
  // background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 100%);
  padding-top: 4px;

  .activitySelectorList {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    height: 60px;
    padding-right: 10px;
    box-sizing: border-box;

    .activitySelector {
      flex-shrink: 0;
      // height: 100px;
      margin-left: 10px;
      margin-bottom: 4px;
      height: 48px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px 14px;
      box-sizing: border-box;
      position: relative;

      .category {
        text-align: center;
        min-width: 58px;
        font-size: 14px;
        height: 20px;
        line-height: 20px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .tagImg {
          width: 18px;
          height: 18px;
        }
      }

      .highlight {
        font-size: 12px;
        line-height: 16px;
        display: flex;

        .highlightPair {
          display: flex;
          .tag {
            white-space: nowrap;
          }
          .gapLine {
            margin: 0 4px;
          }
        }
      }

      &.selected {
        background-color: @themeColor;
        border: 1px solid @themeColor;
        color: white;

        .highlight {
          color: white;
          .gapLine {
            color: rgba(255, 255, 255, 0.16);
          }
        }

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          bottom: -4px;
          width: 10px;
          height: 4px;
          background: url(https://akim-oss.aikucun.com/mshop/11d4129a66925b18e7163b4c95c6c32dbe77df99_1701241538460_70.png)
            no-repeat;
          background-size: 100% 100%;
        }
      }

      &.unselected {
        background-color: white;
        border: 0.02rem solid rgba(34, 34, 34, 0.16);
        color: #222222;

        .highlight {
          color: #666666;
          .gapLine {
            color: #eeeeee;
          }
        }
      }
    }
  }
}
