@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.card {
  display: flex;
  flex: 1;
  padding: 10px 12px;
  margin-bottom: 8px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;

  .card__left {
    display: flex;
    margin-right: 14px;

    .left__image {
      align-self: center;
      display: flex;
      width: 24px;
      height: 24px;
    }
  }

  .card__right {
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
    overflow: hidden;

    .right__top {
      display: flex;
      flex-direction: row;
      overflow: hidden;

      .top__image {
        display: flex;
        width: 60px;
        height: 60px;
        border-radius: 4px;
        align-self: center;
        opacity: 1;
        box-sizing: border-box;
        border: 1px solid rgba(34, 34, 34, 0.08);
        margin-bottom: 8px;
        overflow: hidden;
        position: relative;

        .image__i {
          width: 60px;
          height: 60px;
        }

        .image__status {
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          height: 16px;
          background-color: #fff1f1;
          justify-content: center;
          align-items: center;
          align-content: center;

          .status__title {
            display: flex;
            align-self: center;
            justify-content: center;
            font-family: PingFang SC;
            font-size: 10px;
            font-weight: 500;
            line-height: 14px;
            color: #ff4747;
          }
        }
      }

      .top__content {
        display: flex;
        flex: 1;
        margin-left: 8px;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        overflow: hidden;

        .content__title {
          display: flex;
          margin-bottom: 4px;
          overflow: hidden;
          width: 100%;

          .title__left {
            /* 或者使用 display: block;，取决于你的需求 */
            display: inline-block;
            /* 不换行 */
            white-space: nowrap;
            /* 隐藏超出的内容 */
            overflow: hidden;
            /* 添加省略号 */
            text-overflow: ellipsis;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #222222;
            flex-shrink: 1;
          }

          .title__right {
            display: flex;
            /* 隐藏超出的内容 */
            overflow: hidden;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1px 4px;
            background: #ffd4b6;
            border-radius: 2px;
            margin-left: 6px;

            .right__title {
              font-family: PingFang SC;
              font-size: 10px;
              font-weight: 500;
              line-height: 14px;
              display: flex;
              align-items: center;
              color: #8c4206;
            }

            flex-shrink: 0;
          }
        }

        .content__desc {
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;
          color: #666666;
        }

        .address__desc {
          margin-top: 4px;
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;
          color: #666666;
        }
      }
    }

    .right__bottom {
      display: flex;
      flex: 1;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 12px 4px 7px;
      background: #fff2e8;
      justify-content: space-between;

      .bottom__title {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #8c4206;
      }

      .bottom__image {
        width: 5px;
        height: 10px;
      }
    }

    .right__delete {
      position: absolute;
      top: 2px;
      right: 0px;
      height: 16px;
      display: 16px;
    }

    .bottom__tag {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      margin-top: 6px;
      overflow-x: auto;
      white-space: nowrap;
      overflow: scroll;

      .tag__travel {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px 4px;
        background: #ffd4b6;
        border-radius: 2px;
        margin-right: 4px;

        .tag__title {
          font-family: PingFang SC;
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
          display: -webkit-box;
          align-items: center;
          color: #8c4206;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .tag__category {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px 4px;
        background: #ff474714;
        border-radius: 2px;
        margin-right: 4px;

        .tag__title {
          font-family: PingFang SC;
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
          display: flex;
          align-items: center;
          color: #ff4747;
        }
      }
    }
  }
}

.card__transparent {
  opacity: 0.6;
}
