@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  padding: 8px 10px;
  background-image: url("https://akim-oss.aikucun.com/80f87c8847da6c872ce2fc9fec0a98a73ebc578d_1706753422411_59.png");
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-color: #f1f2f5;
}
.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  padding-bottom: 10px;
  display: flex;
  .explain {
    margin-left: auto;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    height: 16px;
    letter-spacing: 0px;
    color: #cccccc;
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
.select_data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .normal {
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0px;
    border-radius: 4px;
    border: 0.5px solid #cccccc;
    margin-right: 8px;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 24px;
  }
  .needIcon {
    width: 70px;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .active {
    background: #ff4747;
    color: #ffffff;
    border: none;
    position: relative;
  }
  .active::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -9px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ff4747 transparent;
    left: 50%;
    transform: translateX(-50%);
  }
  .active::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff1f1 transparent;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}
.select_tip {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0px;
  padding: 4px 8px;
  border: 0.5px solid #ff4747;
  margin: 8px 0px 8px 0px;
  border-radius: 3px;
  color: #ff4747;
  max-width: fit-content;
  text-align: center;
  background: #fff1f1;
}
.explainPopup {
  padding: 16px;
  position: relative;
  padding-bottom: calc(12px + constant(safe-area-inset-bottom));
  padding-bottom: calc(12px + env(safe-area-inset-bottom));
  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    padding-bottom: 26px;
    display: flex;
    justify-content: center;
    position: relative;
    img {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
  .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #222222;
    padding-bottom: 4px;
  }
  .text {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #666666;
    padding-bottom: 20px;
  }
  .bottom_tip {
    span {
      color: #ff4747;
    }
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: #a5a5a5;
  }
}
