@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  height: 81px;
  border-radius: 10px;
  opacity: 1;
  background: linear-gradient(283deg, #222222 -1%, #35312b 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px 12px;
  box-sizing: border-box;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  color: #ffc673;
}

.subtitle {
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0;
  color: #ffffff;
  margin-top: 6px;
}

.button {
  width: 74px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  border-radius: 4px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0;
  color: #222222;
  opacity: 1;
  background: linear-gradient(292deg, #ffc673 0%, #fde4c2 100%);
  border: none;
  cursor: pointer;
}
