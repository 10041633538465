@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
// .container {
//   width: 100%;
//   position: relative;

// }

.waterfallConatainer {
  min-height: 100vh;
  margin: 0 auto;
}
