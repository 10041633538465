@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.table {
  background: #fff;
  box-sizing: border-box;
  overflow: hidden;


  .table__title {
    display: flex;
    align-items: center;
    background: #FFE7D2;
    border: 1px solid #FFD7B5;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-family: '苹方-简';
    font-size: 12px;
    font-weight: normal;
    color: #8C4206;

    .title__item {
      flex-grow: 1;
      flex-shrink: 0;
      padding: 8px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      &:nth-child(1) {
        padding: 8px 0;
        width: 60px;
        border-right: 1px solid #FFD7B5;
      }

      &:nth-child(2) {
        width: 85px;
        border-right: 1px solid #FFD7B5;
      }

      &:nth-child(3) {
        width: 85px;
        border-right: 1px solid #FFD7B5;
      }

      &:nth-child(4) {
        width: 95px;
      }
    }
  }

  .table__body {
    max-height: 456px;
    overflow-y: auto;

    .body__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #FFD7B5;
      border-top: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .list__item {
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        height: 38px;
        border-bottom: 1px solid #FFD7B5;
        box-sizing: border-box;
        text-align: center;

        div {
          padding: 0 4px;
          display: flex;
          // justify-content: center;
          justify-content: flex-start;
          align-items: center;
          height: inherit;
          box-sizing: border-box;

          .item__gift_image {
            width: 30px;
            height: 30px;
            border: 1px solid #FFE9D0;
            border-radius: 4px;
          }
        }

        &>div:nth-child(1) {
          padding: 0;
          display: inline-block;
          width: 60px;
          line-height: 38px;
          border-right: 1px solid #FFD7B5;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &>div:nth-child(2) {
          width: 85px;
          border-right: 1px solid #FFD7B5;
        }

        &>div:nth-child(3) {
          width: 85px;
          border-right: 1px solid #FFD7B5;
        }

        &>div:nth-child(4) {
          width: 95px;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .me__item {
        color: #FF4747;
      }

      .distributor__item {
        background: rgba(255, 231, 210, 0.4);
      }
    }
  }
}