@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    transform: translateY(-100px);
    position: relative;

    .close {
      position: absolute;
      left: 50%;
      bottom: -56px;
      width: 36px;
      height: 36px;
      transform: translateX(-50%);
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 103px;

    &>img {
      width: 100%;
      height: 100%;
    }
  }
}