@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.show_images {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;

  .go_back {
    position: fixed;
    top: 25px;
    left: 18px;
    z-index: 999;
    width: 25px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }

  .img_container {
    margin-top: 26px;
    margin-left: 15px;
    margin-right: 15px;
  }
}
