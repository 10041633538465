@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  /* 背景色/cg4_#000000_0.7黑色蒙层 */
  background: rgba(0, 0, 0, 0.7);
  /* 背景色/cg1_#F1F2F5_页面背景 */
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 3333;
  flex-direction: column;

  .textView {
    /* 圆角8 */
    border-radius: 8px;
    opacity: 1;
    padding: 12px 16px 12px 16px;
    background: rgba(34, 34, 34, 0.9);

  }

  .text {
    /* 次要内容_常规 */
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0px;
    /* 背景色/cg3_#FFFFFF_卡片 */
    color: #FFFFFF;
  }

  .tips {
    /* 次要内容_常规 */
    margin-top: 8px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0px;
    /* 背景色/cg3_#FFFFFF_卡片 */
    color: #FFFFFF;
  }

}