@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 20px;
    border: 0.5px solid #e5e5e5;
    display: flex;
    position: relative;
    z-index: 10;

    .leftImageBox {
      // width: 44px;
      // height: 32px;
      padding: 6px 12px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .image {
        width: 20px;
        height: 20px;
      }
    }

    .leftImageBox:first-child::after {
      content: "";
      width: 1px;
      height: 18px;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      right: 0;
    }
  }

  .right {
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    border: 0.5px solid #e5e5e5;
    border-radius: 20px;

    .rightImageBox {
      // width: 20px;
      // height: 20px;
      position: relative;
      padding: 6px 12px 6px 12px;

      .badge {
        position: absolute;
        top: -2px;
        right: -4px;
        width: 18px;
        height: 18px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #ffffff;
        background-color: #ff302d;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        transition: all 0.1s;
      }

      .badgeSticky {
        color: #ffffff;
        background-color: #ff6600;
      }

      &:first-child {
        padding-right: 10px;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 7px;
          width: 0.5px;
          height: 18px;
          background: rgba(0, 0, 0, 0.2);
        }
      }

      &:last-child {
        padding-left: 10px;
      }
    }
  }

  .right2 {
    background: rgba(255, 255, 255, 0.6);
    border: 0.5px solid #e5e5e5;
    display: flex;
    border-radius: 20px;

    .rightImageBox {
      position: relative;
      padding: 6px 12px 6px 12px;

      .badge {
        position: absolute;
        top: -2px;
        right: -4px;
        width: 18px;
        height: 18px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #ffffff;
        background-color: #ff302d;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        transition: all 0.1s;
      }

      .badgeSticky {
        color: #ffffff;
        background-color: #ff6600;
      }
    }
  }

  .center {
    flex: 1;
  }
}

.imageIcon {
  width: 20px;
  height: 20px;
  display: block;
}
