@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  width: 351px;
  height: 90px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 12px;
  padding: 4px 14px;
  box-sizing: border-box;
  background: left top no-repeat;
  background-size: 351px auto;
  font-size: 12px;

  .header {
    display: inline-flex;
    align-items: center;
    height: 25px;
    padding-right: 20px;
    line-height: 30px;
    color: #ffffff;
    background: url(https://akim-oss.aikucun.com/86f7e437faa5a7fce15d1ddcb9eaeaea377667b8_1730881848616_59.png)
      right center no-repeat;
    background-size: 16px;

    .hl {
      color: #ffe144;
      font-weight: bold;
      font-size: 17px;
      font-family: "DIN-Medium";
    }

    .coin {
      width: 14px;
      height: 14px;
      margin-left: 4px;
    }
  }

  .main {
    position: relative;
    display: flex;
    align-items: center;
    height: 34px;
    box-sizing: border-box;
    padding: 6px 6px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.4);
    color: #ffffff;

    .label {
      width: 66px;
      height: 22px;
    }

    .pd8 {
      padding: 0 4px;
    }

    .hl {
      color: #ffe144;
      font-weight: bold;
      font-size: 17px;
    }

    .aside-btn {
      position: absolute;
      right: 6px;
      top: 6px;
      width: 84px;
      height: 22px;
    }
  }
}

.wrap2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-bottom: -8px;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 10px;

  .main {
    display: flex;
    align-items: center;
    font-weight: 500;

    .label {
      width: 66px;
      height: 24px;
      margin-right: 6px;
    }

    .hl {
      position: relative;
      margin-left: 3px;
      color: #ff4747;
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      font-family: "DIN-Medium";
    }
  }

  .aside {
    padding-right: 17px;
    color: #666666;
    background: url(https://akim-oss.aikucun.com/3c363836cf4e16666669a25da280a1865c2d2874_1730965327831_1.png)
      right center no-repeat;
    background-size: 12px;
  }
}

.noGrouthValue {
  padding-left: 3px;

  .qaIcon {
    color: #a5a5a5;
    font-size: 16px;
    font-weight: bold;

    &::after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      margin: 3px 0 0 2px;
      background: url(https://akim-oss.aikucun.com/356a192b7913b04c54574d18c28d46e6395428ab_1735020320825_22.png)
        center center no-repeat;
      background-size: contain;
    }
  }

  .tip {
    position: absolute;
    z-index: 10;
    margin: 8px 0 0 -40px;
    width: 218px;
    padding: 8px;
    background: rgba(34, 34, 34, 0.9);
    border-radius: 6px;
    color: #ffffff;
    font-size: 12px;
    text-align: justify;

    &::before {
      content: "";
      position: absolute;
      margin: -13px 0 0 50px;
      width: 12px;
      height: 6px;
      background: url(https://akim-oss.aikucun.com/da4b9237bacccdf19c0760cab7aec4a8359010b0_1735022152348_58.png)
        center center no-repeat;
      background-size: contain;
    }
  }
}
