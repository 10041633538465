@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  min-height: 100vh;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.stepOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
}

.currentPhoneLabel {
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
}

.currentPhone {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.changeBtn {
  width: 100%;
  height: 44px;
  background: #ff4d4f;
  color: #fff;
  border: none;
  border-radius: 22px;
  font-size: 16px;
  cursor: pointer;
  outline: none;

  &:active {
    opacity: 0.9;
  }
}

.dialogContent {
  padding: 10px;

  h3 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
  }
}

.agreementText {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: center;

  p {
    margin-bottom: 8px;
  }
}

.headPictureContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.headPicture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #eee;
}

.dialogButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    flex: 1;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.cancelBtn {
  background: #f5f5f5;
  color: #666;
  border: none;

  &:active {
    opacity: 0.9;
  }
}

.confirmBtn {
  background: #ff4d4f;
  color: #fff;
  border: none;

  &:active {
    opacity: 0.9;
  }
}

.stepTwo, .stepThree {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.verifyTitle, .inputTitle {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.inputGroup {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.phoneInputGroup {
  width: 100%;
  margin-bottom: 20px;
}

.verifyInput, .phoneInput {
  width: 100%;
  height: 44px;
  background: #f5f5f5;
  border: none;
  border-radius: 6px;
  padding: 0 120px 0 15px;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  outline: none;

  &::placeholder {
    color: #999;
  }
}

.phoneInput {
  padding: 0 15px;
}

.sendCodeBtn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #ff4d4f;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  
  &:disabled {
    color: #999;
  }
}

.nextBtn, .confirmBtn {
  width: 100%;
  height: 44px;
  background: #ff758c;
  color: #fff;
  border: none;
  border-radius: 22px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  outline: none;

  &:active {
    opacity: 0.9;
  }
}

.confirmBtn {
  background: #ff4d4f;
}