@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 54px;
  margin-right: 12px;
  font-size: 12px;
  &:first-child {
    margin-left: 12px;
  }
  &:last-child {
    margin-right: 0;
  }
  .card {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 54px;
    height: 67px;
    padding-bottom: 2px;
    box-sizing: border-box;
    margin-bottom: 6px;
    font-size: 10px;
    color: #222222;
    font-weight: 600;
    background: url(https://akim-oss.aikucun.com/ac3478d69a3c81fa62e60f5c3696165a4e5e6ac4_1723084941893_44.png)
      center center no-repeat;
    background-size: 100%;
  }
}
