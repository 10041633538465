@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
// 修改 antd组件库图片预览的样式
.adm-image-viewer-footer {
  bottom: 50% !important;
}
.adm-mask {
  // background-color: #000000 !important;
}
