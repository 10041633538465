@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.overlay {
  .content {
    width: 375px;
    height: 290px;
    margin-top: 40%;
    padding-top: 140px;
    background: url(https://akim-oss.aikucun.com/0c725f1347b1b5254ebf91e362846aafa6b73ecf_1740196443463_60.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;

    .dialog {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 16px;
        font-weight: 500;
      }

      .label {
        margin-top: 12px;
        font-size: 14px;
        color: #666;
      }

      .dialogFooter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 27px;

        &>div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 124px;
          height: 44px;
          font-size: 16px;
          box-sizing: border-box;
          border-radius: 6px;
        }

        &>div:first-child {
          border: 2px solid #FF4747;
          color: #FF4747;
        }

        &>div:last-child {
          background: #FF4747;
          color: #fff;
          margin-left: 12px;
        }
      }
    }
  }
}