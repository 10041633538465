@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.shopOrderCard {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 12px 0px 16px;
  border-radius: 10px;
  background: #ffffff;
  &::before {
    content: "";
    position: absolute;
    right: 0px;
    top: 40px;
    width: 15px;
    height: 60px;
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0.2) 100%);
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 40px;
    width: 15px;
    height: 60px;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0.2) 100%);
    z-index: 1;
  }
  .orderTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px 8px 12px;
    .title {
      font-size: 14px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      line-height: 20px;
      color: #222222;
    }
    .more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      color: #666666;
      .arrow {
        margin-left: 4px;
        width: 12px;
        height: 12px;
        background: url("https://akim-oss.aikucun.com/mshop/35b362ad53d641a08e9d5c54f336832077907f4d_1711614550393_56.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .orderStatusList {
    position: relative;
    width: 100%;
    padding-top: 6px;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    .orderStatusItem {
      display: inline-block;
      margin-right: 30px;
      text-align: center;
      &:first-child {
        margin-left: 18px;
      }
      .imgItem {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .count {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          top: -5px;
          left: calc(100% - 10px);
          z-index: 10;
          min-width: 14px;
          padding: 0 4px;
          height: 14px;
          font-size: 10px;
          color: #fff;
          background: #ff1010;
          border-radius: 14px;
        }
        img {
          display: inline-flex;
          margin-bottom: 6px;
          height: 28px;
          width: 28px;
        }
      }
      .statusText {
        font-size: 12px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        color: #222222;
        line-height: 16px;
      }
    }
  }
}
