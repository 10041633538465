@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.footer {
  position: fixed;
  bottom: 0;
  z-index: 10;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  width: 375px;
  background-color: white;
  &_top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    .check-box {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 44px;
      color: #222222;
      img {
        margin-right: 6px;
        width: 24px;
        height: 24px;
      }
    }
    span {
      font-size: 14px;
      line-height: 44px;
      color: #666666;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(34, 34, 34, 0.08);
      transform: scaleY(0.5);
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(34, 34, 34, 0.08);
      transform: scaleY(0.5);
    }
    .skeleton {
      width: 90px;
      height: 22px;
      border-radius: 2px;
      background: #f1f2f5;
    }
  }
  &_bottom {
    display: flex;
    width: 100%;
    height: 56px;
    .btn_share {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 62px;
      img {
        width: 22px;
        height: 22px;
      }
    }
    .btn_poster {
      flex: 1;
      font-size: 16px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 56px;
      text-align: center;
      background: #ff4747;
    }
  }
}
