@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.userinfo {
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
  .userinfo-left {
    display: flex;
    align-items: center;
    .userinfo-left-avatar-box {
      position: relative;

      .role-switch {
        position: absolute;
        bottom: -4px;
        left: -3px;
        background-color: #fff;
        border-radius: 4px;
        // border: 1px solid rgba(255, 71, 71, 0.5);
        display: flex;
        align-items: center;
        padding: 2px 3px;
        margin-top: 4px;
        width: max-content;
        .role-switch__icon {
          width: 10px;
          height: 10px;
          margin-right: 2px;
          vertical-align: bottom;
        }
        .role-switch__text {
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
          color: #222222;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: -0.5px;
          width: 200%;
          height: 200%;
          transform-origin: left top;
          transform: scale(0.5);
          border: 1px solid rgba(255, 71, 71, 0.5);
          border-radius: 8px;
          box-sizing: border-box;
        }
      }
      .userinfo-left__avatar {
        width: 48px;
        height: 48px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        vertical-align: bottom;
      }
    }

    .userinfo-left__name {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-left: 12px;
      color: #222222;
      display: flex;
      flex-direction: column;
      p {
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 22px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-size: 16px;
        color: #222;
        line-height: 22px;
        font-weight: 600;
      }
      .label_wrap {
        flex-shrink: 0;
        margin-top: 4px;
        height: 16px;
        .label {
          height: inherit;
        }
      }

      .bind-phone {
        width: fit-content;
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;
        color: #ffffff;
        padding: 2px 4px;
        color: #666666;
        border: 1px solid #a5a5a5;
        border-radius: 8px;
      }

      .user-id {
        padding-top: 4px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: normal;
        line-height: 14px;
        color: #222222;

        .btn__copy {
          position: relative;
          margin-left: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 16px;
          border-radius: 4px;
          // border: 1px solid #A5A5A5;
          font-size: 10px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #666;
          text-align: center;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: -0.5px;
            width: 200%;
            height: 200%;
            transform-origin: left top;
            transform: scale(0.5);
            border: 1px solid #a5a5a5;
            border-radius: 8px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .userinfo-right {
    display: flex;
    margin-left: auto;
    .userinfo-right__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:last-child {
        margin-left: 16px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-bottom: 4px;
      }
      span {
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        color: #222222;
      }
    }
  }
  .guide_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    .guide {
      width: 179px;
      height: auto;
      position: absolute;
      top: 0px;
      right: 8px;
    }
  }
}
