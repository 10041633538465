@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.shopSchoolCard {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0 12px;
  background-color: #fff;
  border-radius: 8px;

  .schoolTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    .titleLeft {
      font-size: 14px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      line-height: 20px;
      color: #222222;
    }

    .titleRight {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      color: #666666;
      .arrow {
        margin-left: 4px;
        width: 12px;
        height: 12px;
        background: url("https://akim-oss.aikucun.com/mshop/35b362ad53d641a08e9d5c54f336832077907f4d_1711614550393_56.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .swiperRoot {
    height: 104px;
    padding-bottom: 20px;
    border-radius: 6px;
    .swiperItem {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100px;
        border-radius: 6px;
      }
      :global(.adm-page-indicator-dot) {
        width: 6px;
        height: 6px;
      }
    }
  }
}
