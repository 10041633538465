@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.high-commission {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  background: #f1f2f5;
  padding-top: 11px;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 132px;
    background: linear-gradient(180deg, #fcd5bf 0%, transparent 62%);
    background: -moz-linear-gradient(180deg, #fcd5bf 0%, transparent 62%);
    background: -webkit-linear-gradien(180deg, #fcd5bf 0%, transparent 62%);
    background: -o-linear-gradient(180deg, #fcd5bf 0%, transparent 62%);
    background: -ms-linear-gradient(180deg, #fcd5bf 0%, transparent 62%);
  }

  .list {
    display: flex;
    flex-direction: column;
    width: 171px;
    float: left;

    &:first-child {
      margin-right: 9px;
    }
  }
}

.go_back {
  position: fixed;
  top: 13px;
  left: 12px;
  z-index: 1000;
  width: 30px;
}
.high-commission-contain {
  padding-top: 24px;
  background: url("../assets/hcommission_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top;
}

.water-fall-box {
  overflow: hidden;
  z-index: 1;
  position: relative;
}

.cut-info-box {
  padding-top: 4px;
  position: relative;

  .btn-box {
    position: absolute;
    bottom: 9px;
    right: 0px;
  }
}

body {
  background: #f1f2f5;
}

.no-icon-box {
  margin-top: -10vh;

  .box-icon {
    width: 183px;

    img {
      width: 100%;
    }
  }

  .icon-text {
    text-align: center;
    color: #666;
    font-size: 14px;
  }
}

.emty-map {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 18px;
  height: calc(100vh - 24px);
}

.loadingMore {
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;

  img {
    width: 21px;
    margin-top: 3px;
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingEnd {
  display: flex;
  align-items: center;
  justify-content: center;

  .end_word {
    color: #ccc;
  }
}
