@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  padding-top: 32px;
  width: 100vw;
  height: 100vh;
  overflow: hidden scroll;
  background: #f1f2f5;
  position: relative;
  box-sizing: border-box;

  .loading {
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }
  .loadingFixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: #fff;
    .header_bg {
      background: #f1f2f5;
    }

    .heade_nav {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      padding: 6px 0 6px 6px;
      box-sizing: border-box;
      background: #ffffff;
      display: flex;
      justify-content: flex-end;
      padding-right: 8px;

      .nav_title {
        display: flex;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.7);
        box-sizing: border-box;
        border: 0.5px solid #e5e5e5;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        color: #222222;
        padding-left: 8px;
        padding-right: 8px;
        align-items: center;
        height: 32px;
      }
    }

    .header_tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      .tabs_item {
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #666;
        margin-left: 20px;
        margin-right: 20px;
      }
      .tabs_item--active {
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #222;
        margin-left: 20px;
        margin-right: 20px;
        &::before {
          content: "";
          position: absolute;
          bottom: -7px;
          left: 0;
          right: 0;
          margin: auto;
          width: 32px;
          height: 2px;
          background: #ff4747;
        }
      }
    }
  }

  .body {
    padding: 0 12px;
    padding-top: 16px;
    overflow: hidden;

    .card {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;
      .card_item {
        display: flex;
        padding: 10px;
        margin-bottom: 10px;
        height: 50px;
        border-radius: 10px;
        text-align: center;
      }
    }

    .red_tips {
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #666;
      padding-bottom: 10px;
      padding-top: 5px;
    }

    .coupon_tips {
      font-family: "苹方-简";
      font-size: 10px;
      font-weight: normal;
      line-height: 14px;
      color: #a5a5a5;
      padding-bottom: 10px;
    }

    .empty {
      position: absolute;
      top: 220px;
      left: 94px;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      color: #666666;

      .empty_icon {
        width: 184px;
        height: 184px;
      }
    }
  }
  .body_t {
    padding-top: 60px;
  }
  .bottom {
    position: fixed;
    z-index: 100;
    bottom: 0;
    height: 56px;
    display: flex;
    flex-direction: row;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    left: 0px;
    right: 0px;
    justify-content: space-between;
    background-color: #fff;

    .bottom_btn {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-right: 12px;
      margin-top: 6px;
      margin-left: 12px;
      height: 44px;
      justify-content: center;
      align-items: center;
      background-color: #ff4747;
      border-radius: 6px;
      .btn_title {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        color: #fff;
      }
    }
  }
}
