@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.search-container-active {
  animation: height 2s ease;
}
.search-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 88%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background: #f1f2f5;
  border-radius: 10px 10px 0 0;
  touch-action: none;
  will-change: height;
  overflow-y: hidden;
  // box-sizing: border-box;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 8px;
    width: 40px;
    height: 4px;
    background: #ccc;
    border-radius: 16px;
    transform: translateX(-50%);
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    z-index: 100;
    img {
      width: 18px;
      height: 18px;
      filter: grayscale(100%) brightness(40%);
    }
  }
  .product-container {
    height: calc(100% - 100px);
    overflow-y: scroll;
  }

  .header-wrap {
    padding: 20px 10px;
  }

  .loadingMore {
    // margin-bottom: 8px;
    // margin-top: 2px;
    // transform: rotate(360deg);
    // -webkit-transition: -webkit-transform 1s linear;
    // transition: transform 1s linear;
    // -webkit-animation: spin 1s linear 1s 5 alternate;
    // animation: spin 1s linear infinite;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      min-height: 600px;
    }
  }
}
