@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.category {
  margin-bottom: 8px;
  padding: 15px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 8px;

  .category__name {
    padding-right: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }
  .category__btn {
    flex-shrink: 0;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    background: #ff4747;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #fff;
  }

  .category__btn_cancel {
    background: #fff;
    border: 1px solid #ff4747;
    color: #ff4747;
  }
}
