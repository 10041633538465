@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.list {
  background: #f1f2f5;
  box-sizing: border-box;
  width: 100vw;

  .loading {
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }

  .loadingFixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .page__body {
    padding: 10px;
    flex: 1;
    padding-bottom: calc(56px + env(safe-area-inset-bottom));

    .list__header {
      display: flex;
      padding-bottom: 6px;
      padding-top: 6px;

      .header__image {
        display: flex;
        width: 8px;
        height: 20px;
      }

      .header__title {
        display: flex;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        margin-top: 2px;
        font-weight: 500;
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        text-align: left;
        color: #222;
        padding-left: 8px;
      }
    }

    .empty {
      position: absolute;
      top: 212px;
      left: 0;
      right: 0;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      color: #666666;

      .emptyIcon {
        width: 184px;
        height: 184px;
      }
    }

    .footer {
      display: flex;
      padding-top: 22px;
      padding-bottom: 65px;
      justify-content: center;

      .footer__text {
        display: flex;
        flex-direction: row;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        color: #222222;
      }
    }
  }
}
