@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.shopCustomerTrack {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 13px 15px 5px 15px;
  border-radius: 8px;
  background: #fff;
  .titleHeader {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
    font-family:
      PingFangSC-Regular,
      PingFang SC;
    .titleLeft {
      font-size: 14px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      line-height: 20px;
      color: #222222;
    }
    .titleRight {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      color: #666666;
      .arrow {
        margin-left: 4px;
        width: 12px;
        height: 12px;
        background: url("https://akim-oss.aikucun.com/mshop/35b362ad53d641a08e9d5c54f336832077907f4d_1711614550393_56.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .customerItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    &:last-child {
      margin-bottom: 18px;
    }
    img {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid rgba(34, 34, 34, 0.08);
    }
    .itemRight {
      flex: 1;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .itemRightTop {
        max-width: 240px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #222222;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .itemRightBottom {
        display: flex;
        margin-top: 4px;
        font-size: 12px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: normal;
        color: #a5a5a5;
        span {
          color: #ff4747;
        }
      }
    }
  }
  .customerEmpty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 17px 0 9px;
    p {
      color: #666;
      font-size: 14px;
      line-height: 18px;
    }
    .homeBtn {
      margin-top: 12px;
      padding: 6px 20px;
      color: #666;
      font-size: 15px;
      font-weight: 500;
      border-radius: 12px;
      border: 0.5px solid #ccc;
    }
  }
}
