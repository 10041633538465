@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.myPage {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: auto;
  width: 375px;
  height: calc(100vh - 46px - env(safe-area-inset-bottom));
  -webkit-overflow-scrolling: touch;
  background: #f1f2f5;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 176px;
    background: url("https://akim-oss.aikucun.com/mshop/80f87c8847da6c872ce2fc9fec0a98a73ebc578d_1711612709664_52.png")
      no-repeat;
    background-size: 100% 100%;
  }
}
