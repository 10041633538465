@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN-Bold";
  src: url("../../../../assets/fonts/DIN-Bold.otf"); /* 注意路径 */
}
@font-face {
  font-family: "DIN-Medium";
  src: url("../../../../assets/fonts/DIN-Medium.otf"); /* 注意路径 */
}
@font-face {
  font-family: "DIN-Regular";
  src: url("../../../../assets/fonts/DIN-Regular.otf"); /* 注意路径 */
}
.header {
  width: 100%;
  height: 56px;
  padding: 16px 0;
  box-sizing: border-box;
  position: relative;
  .text {
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #222222;
  }
  .cart {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 17px;
    right: 20px;
  }
  .close {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 18px;
    right: 16px;
  }
}
.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  position: relative;
}
.tab {
  font-family: "苹方-简";
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
  color: #666666;
  width: 50%;
}
.tab:first-child {
  padding-left: 110px;
  box-sizing: border-box;
}
.tab:nth-child(2) {
  padding-left: 50px;
  box-sizing: border-box;
}
.active {
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  color: #222222;
}
.line {
  width: 32px;
  height: 3px;
  background-color: #ff4747;
  position: absolute;
  bottom: 3.5px;
  left: 0;
  transition: all linear 0.2s;
}
.content {
  padding: 8px 10px 0 10px;
  background-color: #f1f2f5;
}
.content_height {
  height: 410px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.content_all_height {
  height: 410px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
.cartNum {
  width: 18px;
  height: 18px;
  background: #ff302d;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 9px;
  right: 10px;
}
.bottom {
  font-family: DIN-Bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #a5a5a5;
  text-align: center;
  padding: 4px 0 9px 0;
}
