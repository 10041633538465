@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.sort {
  width: 100%;
  height: 44px;
  background-color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 77px;
  box-sizing: border-box;
  position: sticky;
  top: 84px;
  z-index: 100;
  // border-bottom: 0.5px solid rgba(34, 34, 34, 0.08);
  .filter {
    width: 76px;
    height: 43px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .sortDiv {
    display: flex;
    overflow-x: auto;
    flex-shrink: 0;
    width: 305px;
    .active {
      background-color: rgba(255, 71, 71, 0.08);
      color: #ff4747;
    }
  }
  .sortDiv::-webkit-scrollbar {
    display: none;
  }
  .sortItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    border-radius: 4px;
    background: #f5f5f5;
    color: #666666;
    margin-left: 8px;
    flex-shrink: 0;
    padding: 6px 16px;
    box-sizing: border-box;
    .price {
      width: 10px;
      height: 10px;
      margin-left: 4px;
    }
  }
}
