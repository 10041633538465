@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  position: relative;
  width: 100vw;
  // height: 100vh;
  padding-top: 140px;
  background-color: #F1F2F5;
  opacity: 1;
  /* 背景色/cg1_#F1F2F5_页面背景 */
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
  z-index: 1;

  .errorImg {
    width: 140px;
    height: 140px;
  }

  .errorText {
    /* 次要内容_常规 */
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0px;
    /* 文字色/ct2_#666666_次要文字色 */
    color: #666666;
  }

}