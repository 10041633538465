@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.brand {
  width: 351px;
  border-radius: 10px;
  opacity: 1;
  background: #ffffff;
  margin-left: 12px;
  // position: absolute;
  // bottom: -77px; //待调整
  box-sizing: border-box;
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-direction: column;

  .brand_top {
    display: flex;
    width: 351px;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;

    .img {
      width: 44px;
      height: 44px;
      border-radius: 6px;
      border: 1px solid rgba(255, 71, 71, 0.3);
      margin-right: 8px;
      flex-shrink: 0;
      box-sizing: border-box;

      :global(.adm-image-img) {
        object-fit: contain !important;
      }
    }

    .img_bottom {
      display: flex;
      align-items: center;

      .img_auth {
        width: 83px;
        height: 15px;
        margin-right: 6px;
      }

      .evaluate {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 16px;

        .shopAuthorize {
          margin-right: 4px;
          position: relative;
          width: 63px;
          height: auto;
        }

        .shopScore {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: rgba(255, 71, 71, 0.08);
          padding: 0px 8px;
          border-radius: 15px;

          .score {
            font-family: PingFang SC;
            padding-left: 6px;
            color: #222222;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    .brand_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 44px;

      .shop_name {
        font-size: 14px;
        color: #222222;
        line-height: 20px;
        font-weight: 500;
        display: flex;

        .title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 200px;
        }
      }
    }

    .subscript {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;
      width: 48px;
      color: #222222;
      border-radius: 4px;
      height: 26px;
      border: 1px solid #dbdbdb;
    }

    .unSubscript {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;
      background-color: #ff4747;
      width: 48px;
      color: #ffffff;
      border-radius: 4px;
      height: 26px;
    }

    .iconPlus {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

    .subscriptFont {
      font-size: 12px;
      font-weight: 500;
      line-height: 26px;
    }

    .hideButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      width: 26px;
      height: 26px;
      border-radius: 4px;
      background: rgba(255, 71, 71, 0.08);
      border: 0.5px solid rgba(255, 71, 71, 0.16);

      .hideButtonIcon {
        width: 16px;
        height: 16px;
      }
    }

    .marginLeft6 {
      margin-left: 6px !important;
    }
  }

  .brand_subTitle {
    border-top: 1px dashed rgba(255, 71, 71, 0.16);
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0px;
    color: #222222;
    box-sizing: border-box;
    width: 335px;
    margin-left: 8px;
  }
}

:global(.dialog_hide) {
  :global(.adm-center-popup-wrap) {
    width: 300px !important;
  }

  :global(.adm-auto-center-content) {
    color: #666666;
    text-align: center;
  }

  :global(.adm-dialog-button) {
    color: #666 !important;
  }

  :global(.adm-dialog-button-bold) {
    color: #ff4747 !important;
  }
}
