@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  padding-top: 32px;
  width: 100vw;
  height: 100vh;
  overflow: hidden scroll;
  background: #f1f2f5;
  position: relative;
  box-sizing: border-box;

  .loading {
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }
  .loadingFixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    .header_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      background-color: #fff;
      .top_left {
        display: flex;
        flex: 1;
        align-items: center;
        padding-left: 12px;
        justify-content: center;
        .l_text {
          font-family: "苹方-简";
          font-size: 12px;
          color: #222;
        }
        .l_image {
          padding-left: 1px;
          width: 14px;
          height: 14px;
        }
      }

      .top_right {
        display: flex;
        align-items: center;
        margin-right: 16px;
        .r_search {
          width: 212px;
          height: 32px;
          caret-color: #ff4747; /* 可以替换为你想要的颜色 */
        }
        .r_text {
          padding-left: 16px;
          font-family: "苹方-简";
          font-size: 15px;
          line-height: 22px;
          color: #ff4747;
        }
      }
    }

    .header_line {
      background-color: #f1f2f5;
      height: 0.5px;
      display: flex;
    }
    .header_tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      background: #fff;
      .tabs_item {
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #666;
        margin-left: 20px;
        margin-right: 20px;
      }
      .tabs_item--active {
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #222;
        margin-left: 20px;
        margin-right: 20px;
        &::before {
          content: "";
          position: absolute;
          bottom: -7px;
          left: 0;
          right: 0;
          margin: auto;
          width: 32px;
          height: 2px;
          background: #ff4747;
        }
      }
    }
  }

  .body {
    padding: 0 12px;
    padding-top: 60px;
    overflow: hidden;

    .card {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;
      .card_item {
        display: flex;
        padding: 10px;
        margin-bottom: 10px;
        background-color: aquamarine;
        height: 50px;
        border-radius: 10px;
        text-align: center;
      }
    }

    .red_tips {
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #666;
      padding-bottom: 10px;
      padding-top: 5px;
    }

    .empty {
      position: absolute;
      top: 160px;
      left: 94px;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      color: #666666;

      .empty_icon {
        width: 184px;
        height: 184px;
      }
    }
  }
}
