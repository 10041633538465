@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.banner {
  position: relative;
  .item {
    width: 100%;
    height: 100%;
  }
  .customIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    .banner_dot {
      width: 6px;
      height: 3px;
      background: #ff302d;
      border-radius: 9px;
      margin-right: 3px;
    }
  }
}
.banner3 {
  position: relative;
  .item {
    width: 100%;
    height: 100%;
    :global(.adm-swiper) {
      height: 100%;
    }
  }
  .customIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 8px;
    position: relative;
    .banner_dot {
      width: 6px;
      height: 3px;
      background: #ff302d;
      border-radius: 9px;
      margin-right: 3px;
    }
  }
}
