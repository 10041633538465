@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.rank_item {
  position: relative;
  margin-bottom: 10px;
  padding: 10px 0;
  display: flex;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;

  .item__rank {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #222;

    .rank__icon {
      width: 30px;
      height: 30px;
    }
  }

  .item__info {
    flex-grow: 1;
    display: flex;

    .info__avatar {
      width: 52px;
      height: 52px;
      object-fit: contain;
      border-radius: 50%;
    }

    .info__right {
      padding: 4px 10px 2px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .right__code {
        display: flex;
        align-items: center;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #222;

        .finalist {
          margin-left: 4px;
          width: 64px;
          height: 22px;
        }
      }

      .right__gmv {
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #222;
      }
    }
  }

  .myself {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 64px;
    height: 22px;
  }
}
