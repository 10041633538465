@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.component {
  padding: 0 15px;

  .component__tit {
    padding: 15px 0 10px 0;
    font-family: "苹方-简";
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    color: #fff;
  }

  .component__con {
    display: flex;
    flex-wrap: wrap;

    .con__item {
      width: 168px;
      height: 80px;
      margin: 0 7px 8px 0;
      border-radius: 8px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
