@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.star_com {
  display: flex;
  height: 10px;
  column-gap: 3px;
  .star_item {
    width: 10px;
  }
}
