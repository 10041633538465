@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.shop_list {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 26%);
  background-image: url("https://akim-oss.aikucun.com/mshop/b471722a3fb1cb25a7f3be10fcdbb563dce212e2_1700708166581_25.png");
  background-position: top left;
  background-size: 100vw 104px;
  background-repeat: no-repeat;

  .bg_img {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100vw;
    height: 104px;
    background: #fff linear-gradient(180deg, #ffffff 0%, #f1f2f5 26%);
    background-image: url("https://akim-oss.aikucun.com/mshop/b471722a3fb1cb25a7f3be10fcdbb563dce212e2_1700708166581_25.png");
    background-position: top left;
    background-size: 100vw 104px;
    background-repeat: no-repeat;
  }

  .go_back {
    position: fixed;
    top: 25px;
    left: 18px;
    z-index: 999;
    width: 25px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }

  .list {
    padding: 100px 0 20px 0;
    background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 23%);

    .item {
      margin: 10px;
    }
  }

  .shop_header {
    position: fixed;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 64px;
    line-height: 64px;

    .header_icon_left {
      width: 13px;
      height: 21px;
    }
    .header_title {
      padding: 0 12px;
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
    }
    .header_icon_right {
      width: 13px;
      height: 21px;
    }
  }

  .tabs {
    position: fixed;
    top: 64px;
    z-index: 100;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 40px;
    box-sizing: border-box;
    // background: linear-gradient(180deg, #ffffff 0%, #f6f7f9 100%);

    .back {
      padding-left: 12px;
      padding-right: 12px;
      width: 24px;
      height: 24px;
    }
    .btn {
      position: absolute;
      right: 0;
      width: 56px;
      height: 40px;
      z-index: 2;
      border-top-right-radius: 12px;
    }
    .tadDiv {
      // background: linear-gradient(94deg, #ffeeee 0%, #e6ecff 95%);
      width: 100%;
      height: 40px;
      z-index: 2;

      .tabLine {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        position: relative;
        overflow-y: hidden;
        overflow-x: auto;
        flex-wrap: nowrap;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background: #fff;

        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }

        .tabTile {
          display: flex;
          flex-direction: row;
          padding-right: 32px;
        }
      }

      .tab {
        white-space: nowrap;
        flex-shrink: 0;
        font-size: 15px;
        color: #222222;
        margin-right: 20px;
        position: relative;
        .text {
          position: relative;
          text-align: center;
          z-index: 2;
        }
      }
      .tab:first-child {
        margin-left: 16px;
      }
      .curTab {
        font-size: 16px;
        font-weight: 500;
      }
      .selectedLineContainer {
        height: 3px;
        position: absolute;
        bottom: 2px;
        transition:
          margin 0.3s,
          width 0.3s;
        .selectedLine {
          background: #ff4747;
          height: 100%;
          width: 75%;
          margin: 0 auto;
        }
      }
    }
  }

  .tabModal {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: calc(100vh - 44px);
    top: 40px;
    .content {
      background-color: #fff;
      padding: 10px 6px 5px 16px;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      display: flex;
      flex-wrap: wrap;
      .category {
        width: 80px;
        height: 33px;
        border-radius: 10px;
        background: #ffffff;
        color: #666666;
        border: 0.5px solid #cccccc;
        margin-bottom: 9px;
        margin-right: 8px;
        display: flex;
        padding: 0 4px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
        }
      }
      .category_selected {
        border-color: #ff302d;
        color: #ff302d;
      }
    }
  }

  .loading_box {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    margin: auto;
    // width: 100px;
    // height: 100px;
    // background: rgba(0, 0, 0, 0.3);
    // border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;

    .loading_icon {
      animation: rotate 5s linear infinite;
      -webkit-animation: rotate 5s linear infinite;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0);
      }

      25% {
        transform: rotate(90deg);
      }

      50% {
        transform: rotate(180deg);
      }

      75% {
        transform: rotate(270deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
