@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.poster-unify-toast {
  z-index: 19999 !important;
  border-radius: 12px;
}

.poster_swipe_wrapper {
  touch-action: none;
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/
  user-select: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15vh;

  .poster__mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    opacity: 0.7;
  }

  .swipe-content {
    z-index: 1;
  }

  .swiper-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .first-property {
      height: 30px;
      font-size: 12px;
      color: white;
      font-weight: 400;
    }

    .swiper-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .swiper-slide {
      text-align: center;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;

      .poster__img {
        display: block;
        object-fit: contain;
        position: relative;
        width: 315px;
        height: 315px;
        border-radius: 18px;
        background-color: #fff;
      }
    }

    .swiper-slide {
      transform: scale(0.88);
      transition: 0.4s ease-in-out;
      opacity: 0.8;

      &.swiper-slide-active {
        transform: scale(1);
        transition: 0.4s ease-in-out;
        opacity: 1;
      }
    }
  }
}

.prop-num {
  margin: 10px auto 0 auto;
  text-align: center;
  font-size: 14px;
  font-family:
    PingFangSC-Regular,
    PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.check-detail {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.check-detail .gesture {
  margin-top: 10px;
  width: 175px;
  height: 101px;
  opacity: 1;
}

.check-detail-btn {
  width: 180px;
  height: 36px;
  background: linear-gradient(270deg, #ff7346 0%, #ff2b2b 100%);
  border-radius: 14px;
  font-size: 14px;
  font-family:
    PingFangSC-Medium,
    PingFang SC;
  font-weight: 500;
  color: #fff4f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sell-out {
  position: absolute;
  left: 50%;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 4;
  border-radius: 18px;
  width: 315px;
  height: 315px;
  transform: translateX(-50%);
}

.sell-out .inline-block {
  display: inline-block;
}

.sell-out .act-flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.sell-out .txt {
  background: rgba(0, 0, 0, 0.5);
  width: 96px;
  height: 96px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
}