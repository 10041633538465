@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.videoPlayC {
  position: relative;
  z-index: 1;
  * {
    box-sizing: border-box;
  }
  .video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .pbc {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .playBtnC {
      width: 92px;
      height: 38px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .playBtn {
        width: 20px;
        height: 20px;
      }
      .time {
        font-size: 15px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 8px;
      }
    }
    .muted {
      width: 28px;
      height: 22px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      position: absolute;
      left: 12px;
      bottom: 10px;
      background: rgba(0, 0, 0, 0.3);
      .mutedImg {
        width: 18px;
        height: 18px;
      }
    }
  }
}
