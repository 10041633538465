@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wechat-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 10px;
  padding: 0 12px;
  border-radius: 4px;
  height: 32px;
  background: #fff4e6;
  span {
    font-size: 12px;
    color: #222222;
  }
  .action {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #ff4747;
    img {
      margin-left: 4px;
      width: 12px;
      height: 12px;
    }
  }
}
