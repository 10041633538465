@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.after-pay {
  font-size: 14px;
  display: flex;
}

@brand-color: #ff4747;

.orderSuccess {
  padding: 30px 12px 0;
  font-family: "PingFang SC", sans-serif;
}

.successHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 22px;

  .successText {
    font-size: 23px;
    font-weight: 500;
    line-height: 28px;
    color: @brand-color;
    display: flex;
    align-items: center;

    .successIcon {
      width: 30px;
      height: 30px;
      margin-right: 1px;
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  gap: 16px; // 控制按钮之间的间距
  margin: 20px 0 0;

  .btn {
    flex: 1;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    box-sizing: border-box;

    &.btnHome {
      border: 1px solid @brand-color;
      background: transparent;
      color: @brand-color;
    }

    &.btnOrder {
      background: @brand-color;
      color: #ffffff;
      border: none;
    }
  }
}

.reminder {
  padding: 12px 0 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #a5a5a5;
  text-align: left;
  margin-bottom: 20px;
}
