@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.video-player-container {
  position: relative;
  width: 100vw;
  height: -webkit-calc(100vh - 45px);
  height: -moz-calc(100vh - 45px);
  height: calc(100vh - 45px);
  overflow-y: hidden;
  overflow-x: hidden;
  background: rgba(0, 0, 0);
  .videoLoading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    img {
      width: 36px;
      height: 36px;
      animation: loading 1s linear infinite;
      @keyframes loading {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  .mask1 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 108px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    z-index: 10;
  }
  .mask2 {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 36.95%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 71%);
    z-index: 10;
  }
  .animation-wrapper {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 121px;
    z-index: 50;
  }
  .video {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    overflow: hidden;
    width: 100vw;
    object-fit: cover;
    height: -webkit-calc(100vh - 50px);
    height: -moz-calc(100vh - 50px);
    height: calc(100vh - 50px);
    background: rgba(0, 0, 0);
    z-index: 1;
  }

  .playBtn {
    width: 76px;
    height: 76px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
  }
  .progress-bar {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #fff;
  }
  .prev-video {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 52px;
    width: 92px;
    height: 32px;
    border-radius: 0px 24px 24px 0px;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: "苹方-简";
    .icon {
      margin-left: 4px;
      width: 12px;
      height: 12px;
    }
  }
}
.fade-in {
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes fadeInOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fixed-elements2 {
  position: absolute;
  bottom: calc(20px + env(safe-area-inset-bottom));
  width: 100%;
  z-index: 101;
}
.fixed-elements {
  position: absolute;
  bottom: calc(0px + env(safe-area-inset-bottom));
  width: 100%;
  z-index: 1001;
  left: 0;
}
.float-elements {
  position: absolute;
  bottom: 20px;
  left: 12px;
  .desc-elements {
    color: #fff;
    width: 297px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
  }
  .ellipsisWrapper {
    position: relative;
    color: #fff;
    font-family: PingFang SC;
    width: 297px;
    font-size: 16px;
    line-height: 22px;
    .ellipsis {
      width: 265px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* 控制显示的行数 */
      -webkit-box-orient: vertical;
      max-height: 60px; /* 控制最大高度，对应两行文字的高度 */
      white-space: normal; /* 在声明了display: -webkit-box之后，将white-space设置为normal */
    }
    .normal {
      height: auto;
      padding-bottom: 20px;
    }
    .btn {
      z-index: 100;
      position: absolute;
      font-size: 16px;
      font-weight: 500;
      right: 0;
      bottom: 0px;
    }
  }

  .product-list {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 300px;
    margin: 11px 0;
    position: relative;
    .more-item {
      width: 90px;
      height: 90px;
    }
    .product-item {
      margin-right: 8px;
    }
  }
  // .product-shadow:after {
  //   content: '';
  //   width: 30px;
  //   height: 100%;
  //   position: absolute;
  //   right: -17px;
  //   background: linear-gradient(
  //     90deg,
  //     rgba(0, 0, 0, 0) 0%,
  //     rgba(0, 0, 0, 0.7) 70%
  //   );
  //   z-index: 100;
  // }
}
.ecommerce-elements {
  position: absolute;
  bottom: 20px;
  right: 11px;
  display: flex;
  flex-direction: column;
  // width: 60px;
  z-index: 100;
  align-items: center;
  /* 其他样式属性（根据需要自行调整） */
  /* 电商元素样式 */
  .next-wrapper {
    position: relative;
    right: 0px;
    margin-bottom: 24px;
  }
  // .other-wrapper {
  //   width: 60px;
  // }
  .next-video {
    width: 92px;
    height: 32px;
    border-radius: 24px 0px 0px 24px;
    background: rgba(0, 0, 0, 0.5);
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0px;
    opacity: 1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin-left: 4px;
      width: 12px;
      height: 12px;
    }
  }
  .header-wrapper {
    margin-left: 16px;
    border: 2px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    background: #fff;
  }
  .header-element {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    // filter: blur(1px);
    top: -1px;
    left: -1px;
    position: absolute;
  }
  .like-elements {
    margin-left: 16px;
    margin-top: 30px;
    margin-bottom: 10px;
    .like-icon {
      width: 46px;
      height: 46px;
    }
    .like-num {
      text-align: center;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      margin-top: -5px;
    }
  }
  .share-element {
    margin-left: 16px;
    margin-bottom: 10px;
    .share-icon {
      width: 46px;
      height: 46px;
    }
    .share-num {
      text-align: center;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      margin-top: -5px;
    }
    /* 样式 */
  }
  .hidden {
    opacity: 0;
  }
  .product-card-element {
    margin-left: 16px;
    // margin-bottom: 10px;
    .product-icon {
      width: 46px;
      height: 46px;
    }
    .product-num {
      text-align: center;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      margin-top: -5px;
    }
  }
}
.mySlider {
  --fill-color: #fff;
  width: 352px;
  border-radius: 4px;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  z-index: 100;
  left: 0px;
  :global(.adm-slider-track) {
    border-radius: 4px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.3);
  }
  :global(.adm-slider-thumb) {
    opacity: 1;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 6px 0px rgba(6, 34, 83, 0.2);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
  :global(.adm-slider-thumb-container) {
    width: 22px;
    top: auto;
    height: 29px;
    padding: 10px 200px;
  }
  .slider-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    background-image: url(https://akim-oss.aikucun.com/3429b55825baeea1105b0c9aa9f1ff5fb9ea8a15_1700720402001_49.png);
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.timeWrapper {
  position: absolute;
  bottom: calc(70px + env(safe-area-inset-bottom));
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  @font-face {
    font-family: "DIN-Bold";
    src: url("../../../../assets/fonts/DIN-Bold.otf"); /* 注意路径 */
  }
  .time1 {
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 0px;
    color: #ffffff;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
    padding-right: 10px;
  }
  .time2 {
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0.5;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  }
}
:global(.mask_class) {
  width: unset;
}

.share-mask-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  .share-guide {
    z-index: 1000;
    position: absolute;
    right: 12px;
    width: 207px;
    height: 48px;
    top: 6px;
    opacity: 1;
  }
}
