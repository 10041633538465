@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.save_money_content {
  position: absolute;
  bottom: 0;
  background-color: rgba(251, 73, 59, 1);
  border-top-right-radius: 8px;
  display: flex;
  max-width: 100%;
  line-height: 14px;

  .save_money {
    background: rgba(255, 224, 234, 0.9);
    font-size: 12px;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    font-weight: 500;
    color: #fb493b;
    display: flex;
    align-items: center;
    border-top-right-radius: 8px;
    padding: 2px 6px;
    white-space: nowrap;
  }

  .add {
    color: #ffffff;
    font-size: 12px;
    padding: 2px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
