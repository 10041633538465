@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  padding: 12px 10px;
  background-image: url("https://akim-oss.aikucun.com/80f87c8847da6c872ce2fc9fec0a98a73ebc578d_1706753422411_59.png");
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-color: #f1f2f5;
  .header {
    display: flex;
    .header__avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }
    .header__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 8px;
      .info__name {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #222222;
      }
      .info__identity {
        width: 45px;
        height: 17px;
      }
    }
  }
  .data-list {
    margin-top: 20px;
    .list__item {
      margin-bottom: 24px;
      .item__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222222;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        height: 20px;
        .title__prefix {
          width: 8px;
          height: 8px;
          margin: 0 4px;
        }
      }
      .item__content {
        font-family: "苹方-简";
        font-size: 14px;
        line-height: 20px;
        color: #222222;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 10px;
      }
    }
  }
}
