@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.qaContainer {
    width: 375px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;
    box-sizing: border-box;
    background: #f1f2f5;

    .topNavigation {
        position: relative;
        height: 56px;
        background-color: white;
    }

    .productC {
        background: #fff;
        padding: 6px 12px 12px 12px;
    }

    .list {
        flex: 1;
        overflow: hidden scroll;
        position: relative;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);

        .item {
            margin-top: 8px;
            margin-left: 12px;
            margin-right: 12px;
        }

        .bottom {
            height: 10px;
        }

        .empty {
            position: absolute;
            top: 130px;
            left: 0;
            right: 0;
            font-family: "苹方-简";
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0px;
            color: #666666;

            .empty_icon {
                width: 184px;
                height: 184px;
            }
        }
    }

}