@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.filter-list {
  display: flex;
  justify-content: space-around;
  position: relative;
  z-index: 40;
  border-bottom: 0.5px solid #f1f2f5;
  padding: 12px 0;
  .filter-box {
    display: flex;

    .filter-name {
      font-size: 14px;
      color: #666;
      line-height: 20px;
      &.active {
        color: #ff302d;
        font-weight: 500;
      }
    }

    .filter-switchs {
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .arr {
        background-image: url("../../assets/g_arr.png");
        width: 6px;
        height: 4px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 1px;
      }

      .arr-top {
        .arr();
      }

      .arr-bottom {
        .arr();
        background-image: url("../../assets/g_arrd.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        &.active {
          background-image: url("../../assets/r_arrd.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .active {
        background-image: url("../../assets/r_arr.png");
        width: 6px;
        height: 4px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .filter-icon {
      margin-left: 3px;
    }

    &.active {
      .filter-name {
        color: @themeColor;
        font-weight: 500;
      }
      .filter-icon {
        path {
          fill: @themeColor;
        }
      }
    }
  }

  .filter-toast {
    position: absolute;
    left: 0;
    top: 20px;
    background-color: #fff;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1;

    .filter-btn {
      height: 30px;
      padding-left: 20px;
      line-height: 30px;

      &.active {
        color: #ff302d;
      }
    }
  }
}
