@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px 6px 6px;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #fff;
}

.leftActions {
  display: flex;
  align-items: center;
  border: 1px solid #E5E5E5;
  border-radius: 18px;
}

.rightActions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  border: 1px solid #E5E5E5;
}

.actionItem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 6px 12px;
}

.rightActions .actionItem {
  padding: 0;
}

.divider {
  width: 1px;
  height: 18px;
  background: rgba(0, 0, 0, 0.2);
}

.icon {
  width: 20px;
  height: 20px;
}

.badge {
  :global(.adm-badge-content) {
    position: absolute;
    top: -2px;
    right: -2px;
    font-size: 10px;
    transform: translate(50%, -50%);
    padding: 0 4px;
    min-width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 8px;
    background-color: #ff4d4f;
    color: #fff;
    text-align: center;
  }
}