@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.pad12 {
  padding: 12px;
  position: relative;
  height: auto;
  width: 100vw;

  .marb30 {
    margin-bottom: 30px;
  }

  .line {
    height: 8px;
  }

  .noBounce {
    overscroll-behavior-y: none; // 完全禁用滚动反弹
    -webkit-overflow-scrolling: touch; // 确保 iOS 上的平滑滚动
    // background-color: #bd1d1d;
    width: calc(100vw - 24px);
  }

  .lineview {
    height: 20px;
    width: 100vw,
  }


  .lineview1 {
    height: 80px;
    width: 100vw,
  }


  .bottomTextshow {
    text-align: center;
    /* 辅助内容_常规 */
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    margin-left: -24px;
    // width: calc(100vw - 12px);
    line-height: 16px;
    letter-spacing: normal;
    color: #A5A5A5;

  }

  .mainItem {
    height: auto;
    width: calc(100vw - 24px);
    position: relative;
    z-index: 19;

    .itemBg {
      width: 298px;
      height: 207px;
      position: absolute;
      border-radius: 4px;
      z-index: 11;

      // background-color: #FFFFFF;
    }

    .listItem {
      position: relative;
      // z-index: 10;
      top: 0px;
      opacity: 1;
      height: 225px;
      opacity: 1;
      border-radius: 10px;
      padding: 12px;
      border-style: solid;
      border-width: 1px;
      border-color: rgba(255, 71, 71, 0.12);
      background-color: #FFFFFF;

      background-repeat: no-repeat;
      background-size: cover;

      .childtop {
        justify-content: space-between;
        align-items: center;
        position: relative;
        flex-direction: row;
        display: flex;

        .childtopleft {
          justify-content: center;
          align-items: center;
          flex-direction: row;
          display: flex;

          .listItemTitle {
            font-family: PingFang SC;
            font-size: 14px;
            margin-left: 6px;
            margin-right: 6px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0px;
            color: #222222;
          }

          .childImg {
            width: 12px;
            height: 20px;

          }
        }

        .seeRank {
          width: 64px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          opacity: 1;
          background: #FF4747;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0px;
          color: #FFFFFF;
        }

      }

      .childDetailList {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-top: 12px;

        .itemsize {
          width: 104px;
          // width: calc(100% - 48px)/3;
          height: 104px;
          z-index: 20;
        }

        .imgView {
          // width: 104px;
          height: 104px;
          position: relative;
          border-radius: 4px;
          overflow: hidden;

          .proImg {
            // width: 104px;
            height: 104px;
            position: absolute;
            z-index: 10;
          }

          .proSoftImg {
            position: absolute;
            width: 25px;
            left: 2px;
            height: 30px;
            z-index: 20;
          }

          .proMoney {
            position: absolute;
            bottom: 0px;
            z-index: 20;
            width: 62px;
            height: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            opacity: 1;
            background: #FFD4B6;
            font-size: 10px;
            font-weight: bold;
            line-height: 14px;
            letter-spacing: 0px;
            color: #8C4206;
            font-weight: 500;
            font-size: 10px;
            font-family: DIN;
            font-weight: 700;
            font-size: 10px;

          }
        }

        .titleProduct {
          margin-top: 4px;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;
          color: #222222;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

        }

        .priceView {
          display: flex;
          flex-direction: row;
          margin-top: 4px;
          align-items: baseline;

          .priceLeft {
            font-family: PingFang SC;
            font-size: 10px;
            font-weight: 500;
            line-height: 14px;
            text-align: justify;
            letter-spacing: 0px;
            color: #FF4747;

          }

          .priceCenter {
            font-family: DIN;
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            letter-spacing: 0px;
            color: #FF4747;

          }

          .priceRight {
            font-family: DIN;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0px;
            text-decoration: line-through;
            color: #A5A5A5;

          }
        }

        .hotview {
          flex-direction: row;
          display: flex;
          margin-top: 5px;
          align-items: flex-end;
          /* 圆角2 */
          border-radius: 2px;
          box-sizing: border-box;
          border: 0.5px solid rgba(255, 71, 71, 0.2);

          .hottext {
            font-family: PingFang SC;
            font-size: 10px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0px;
            color: #FF4747;

          }

          .hotimg {
            width: 14px;
            height: 14px;
            margin-top: -2px;
          }

        }

        .price_btn {
          margin-top: 3px;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          width: 104px;
          // background-color: #8C4206;

          .price {
            display: flex;
            width: 104px;
            line-height: 22px;
            align-items: flex-end;

            .price_red_yuan {
              font-family: DIN;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              text-align: justify;
              /* 浏览器可能不支持 */
              letter-spacing: 0px;
              /* 品牌色/#FF4747_AKC红 */
              /* 样式描述：爱库存品牌色 */
              color: #FF4747;
            }

            .price_text {
              color: #ff302d;
              display: flex;
              height: 19px;
              align-items: flex-end;
              transform: scale(0.9);
              flex-shrink: 0;
            }

            .sec_kill {
              font-size: 10px;
              font-family:
                PingFangSC-Medium,
                PingFang SC;
              font-weight: 500;
              color: #ff302d;
              padding-top: 4px;
              padding-right: 2px;
              flex-shrink: 0;
            }

            .price_real {
              font-size: 14px;
              font-family:
                PingFangSC-Medium,
                PingFang SC;
              font-weight: 500;
              color: #ff302d;
              display: flex;
              align-items: flex-end;
              flex-direction: row;

              .price_big {
                font-family: DIN;
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
                letter-spacing: 0px;
                /* 品牌色/#FF4747_AKC红 */
                /* 样式描述：爱库存品牌色 */
                color: #FF4747;
              }
            }

            .price_old {
              margin-left: 5px;
              font-family: DIN;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              padding-bottom: 2px;
              letter-spacing: 0px;
              text-decoration: line-through;
              /* 文字色/ct3_#A5A5A5_描述文字色 */
              color: #A5A5A5;

            }
          }

          .btn,
          .btn_save {
            width: 73px;
            height: 22px;
            background: linear-gradient(270deg, #ff7346 0%, #ff2b2b 100%);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            .btn_img {
              width: 16px;
              height: 16px;
            }

            .btn_font {
              font-size: 12px;
              font-family:
                PingFangSC-Medium,
                PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
          }

          .btn_pre {
            width: 73px;
            height: 22px;
            background: rgba(255, 102, 0, 1);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            .btn_img {
              width: 16px;
              height: 16px;
            }

            .btn_font {
              font-size: 12px;
              font-family:
                PingFangSC-Medium,
                PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
          }

          .btn_save {
            background: linear-gradient(270deg, #fd7921 0%, #ff6600 100%);
          }

          .btn_buy {
            width: 68px;
            height: 22px;
          }

          .buy_btn {
            width: 68px;
            position: relative;

            .btn_img {
              width: 68px;
              height: 22px;
            }

            .buy_text {
              font-size: 12px;
              font-family:
                PingFangSC-Medium,
                PingFang SC;
              font-weight: 500;
              color: #ffffff;
              position: absolute;
              top: 10%;
              left: 20%;
              white-space: nowrap;
            }
          }
        }
      }

    }
  }

}