@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.express-card {
  margin: 8px 10px;
  border-radius: 10px;
  background: #ffffff;
  &__header {
    padding: 8px 12px;
    font-size: 10px;
    color: #ff6600;
    border-radius: 10px 10px 0 0;
    background: #fff4e6;
  }
  &__content {
    padding: 10px 14px 10px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .company {
        font-size: 20px;
        color: #222222;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
      }
      .order {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #666666;
        .copy {
          position: relative;
          margin-left: 4px;
          width: 28px;
          height: 16px;
          font-size: 10px;
          color: #666666;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: -1px;
            width: 200%;
            height: 200%;
            transform-origin: left top;
            transform: scale(0.5);
            border: 1px solid #cccccc;
            border-radius: 4px;
            box-sizing: border-box;
          }
        }
      }
    }
    .right {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 18px;
      font-size: 12px;
      color: #222222;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 2px;
        width: 1px;
        height: 44px;
        transform-origin: left top;
        transform: scaleX(0.5);
        background: rgba(34, 34, 34, 0.16);
      }
      a {
        display: block;
        width: 32px;
        height: 32px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__bottom {
    padding: 8px 12px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      transform-origin: left top;
      transform: scaleY(0.5);
      background: rgba(0, 10, 71, 0.05);
    }
    .product-img {
      img {
        margin-right: 6px;
        width: 30px;
        height: 30px;
        border-radius: 4px;
      }
    }
    .product-count {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #666666;
      span {
        color: #ff4747;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
      }
      img {
        margin-left: 4px;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.product-popup {
  &__header {
    padding: 12px;
    position: relative;
    border-bottom: 1px solid rgba(34, 34, 34, 0.08);
    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      color: #222222;
    }
    .desc {
      margin-top: 4px;
      font-size: 14px;
      color: #666666;
    }
    .close {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__content {
    padding: 12px;
    min-height: 120px;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    .product-item {
      margin-bottom: 12px;
      display: flex;
      .product-img {
        margin-right: 10px;
        width: 80px;
        height: 80px;
        border-radius: 6px;
      }
      .product-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .product-name {
          font-size: 14px;
          color: #222;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          line-height: 18px;
          .line2;
        }
        .product-desc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          color: #666;
          .size {
            max-width: 200px;
            .line2;
          }
        }
      }
    }
  }
}

.line2 {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
