@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 355px;
  height: 140px;
  border-radius: 10px;
  background: #FFFFFF;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 12px;
}

.icon {
  width: 72px;
  height: 72px;
}

.text {
  font-family: "苹方-简", PingFangSC-Regular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  color: #666666;
}