@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN-Medium";
  src: url("../../assets/fonts/DIN-Medium.otf");
  /* 注意路径 */
}

.tradeSnapshotDetailC {
  width: 375px;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  background: #f1f2f5;
  padding-bottom: calc(env(safe-area-inset-bottom));

  .top {
    height: 375px;
    width: 375px;
    position: relative;

    .customIndicator {
      border-radius: 4px;
      opacity: 1;
      background: rgba(0, 0, 0, 0.3);
      font-family: DIN-Medium;
      font-weight: 500;
      font-size: 10px;
      vertical-align: middle;
      text-align: center;
      letter-spacing: 0px;
      color: #ffffff;
      position: absolute;
      padding: 3px 8px;
      right: 12px;
      bottom: 12px;
    }
  }

  .desc {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 12px;

    .title {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      color: #222222;
    }

    .exp {
      background-color: #f7f8f9;
      border-radius: 8px;
      padding: 12px;
      margin-top: 8px;

      .txt {
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #222222;
      }

      .pdc {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;

        .pd {
          font-family: "苹方-简";
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #ff4747;
        }

        .arrow {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .labelC {
    margin-top: 8px;
  }
}
