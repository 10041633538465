@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  padding-top: 32px;
  width: 100vw;
  height: 100vh;
  overflow: hidden scroll;
  background: #f1f2f5;
  position: relative;
  box-sizing: border-box;

  .loading {
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }
  .loadingFixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .page__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    .header__select {
      padding: 0 12px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100vw;
      height: 32px;
      background: #f1f2f5;
      box-sizing: border-box;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;

      .select__right {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        text-align: right;
        color: #666666;
        .icon__arrow {
          margin-left: 2px;
          width: 10px;
          height: 10px;
        }
      }
    }
    .header__tips {
      padding: 0 12px;
      display: flex;
      align-items: center;
      height: 36px;
      background: #f1f2f5;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #666;
    }
  }
  .page__body {
    padding: 0 12px;
    overflow: hidden;

    .card {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;

      .card__header {
        display: flex;
        padding: 10px 12px 0 12px;
        border-radius: 10px;

        flex-direction: column;
        background-color: #fff;
        margin-bottom: 8px;

        .header__title {
          display: flex;
          flex: 1;
          margin-bottom: 6px;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          color: #222222;
        }
      }
    }

    .empty {
      position: absolute;
      top: 112px;
      left: 94px;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      color: #666666;

      .emptyIcon {
        width: 184px;
        height: 184px;
      }
    }
  }
}
