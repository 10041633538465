@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 20px;
  height: 50px;
  font-size: 14px;
  font-family:
    PingFangSC-Regular,
    PingFang SC;
  color: #666666;
  border-radius: 10px;
  background-color: #fff;
}
