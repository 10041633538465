@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
/* 去掉滚动条样式 */
::-webkit-scrollbar {
  display: none; /* Chrome、Safari 和 Opera */
}

/* 可选：调整滚动条轨道的样式 */
::-webkit-scrollbar-track {
  background-color: transparent; /* 轨道背景色 */
}

/* 可选：调整滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
  background-color: transparent; /* 滑块背景色 */
}
.promote-container {
  background-color: #fff;
  height: -webkit-calc(100vh - 50px);
  height: -moz-calc(100vh - 50px);
  height: calc(100vh - 50px);
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
.ocupy-area {
  height: 44px;
}
.tab-list {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  .icon-area {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    display: flex;
  }
  .search-icon {
    height: 24px;
    width: 24px;
  }
  .cart-area {
    align-items: center;
    display: flex;
    position: relative;
    .cart-icon {
      height: 24px;
      width: 24px;
      margin-left: 16px;
    }
    .cart-num {
      position: absolute;
      top: -5px;
      right: -8px;
      background-color: #ff302d;
      border-radius: 50%;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .item {
    font-size: 18px;
    color: #666;
    margin: 0 16px;
    &.active {
      color: #222;
      font-weight: bold;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 32px;
        background-color: #ff4747;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.tab-list2 {
  background-color: transparent;
  .item {
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 0px 1px 2px rgba(0, 19, 50, 0.16);
    &.active {
      color: #fff;
      font-weight: bold;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 32px;
        background-color: #fff;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
