@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.shopCustomerData {
  position: relative;
  padding: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 12px;
  background-color: #fff;
  .titleHeader {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #222;
    .timeTab {
      display: flex;
      margin-left: 16px;
      .tabItem {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        width: 40px;
        height: 22px;
        border-radius: 4px;
        font-size: 12px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        text-align: center;
        background: #f1f2f5;
        &.active {
          font-size: 12px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          line-height: 22px;
          color: #ffffff;
          background: #ff4747;
        }
      }
    }
  }
  .stasticContent {
    display: flex;
    margin-top: 16px;
    margin-bottom: 12px;
    .stasticItem {
      width: 25%;
      .stasticItemTitle {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
        .icon {
          margin-left: 2px;
          height: 10px;
          width: 10px;
          background: url("https://akim-oss.aikucun.com/mshop/890a6158d5b75701e98735b397939cfa0caeec28_1711619597670_29.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
      .stasticItemCount {
        margin-top: 8px;
        font-size: 18px;
        line-height: 18px;
        font-family: DIN-Bold;
        font-weight: bold;
        color: #222222;
      }
    }
  }
  .moduleContent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 16px;
    font-size: 12px;
    color: #222;
    border-radius: 8px;
    background: #f7f8f9;
    .moduleItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 26px;
      img {
        width: 32px;
        height: 28px;
      }
      .moduleItemName {
        margin-top: 8px;
        line-height: 12px;
      }
    }
  }
}
