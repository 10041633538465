@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #F1F2F5;
  position: relative;

  .noBottomBounce {
    overscroll-behavior-y: contain; // 或者使用其他样式来控制弹起效果
  }

  .bgImgapp {
    width: 100vw;
    height: 240px;
    position: relative;
  }

  .bgImg {
    width: 100vw;
    height: 216px;
    position: relative;
  }

  .loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }

  .moreIcon {
    width: 12px;
    height: 12px;
  }

  .rankallview {
    z-index: 2000;
    margin-top: -212px;

    align-items: center;
    width: 76px;
    height: 26px;

    position: absolute;
    right: 0px;
    padding-right: 2px;

    justify-content: flex-end;
    background-color: #FFE5C8;
    flex-direction: row;

    display: flex;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

  }

  .apprankallview {
    z-index: 2000;
    margin-top: -190px;

    align-items: center;
    width: 76px;
    height: 26px;

    position: absolute;
    right: 0px;
    padding-right: 2px;

    justify-content: flex-end;
    background-color: #FFE5C8;
    flex-direction: row;

    display: flex;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

  }

  .allRank {

    // text-align: center;

    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    color: #222222;

    opacity: 1;
    flex-direction: row;

  }

  .main {
    width: 100vw;
    height: 100vh;
    margin-top: -170px;
    position: relative;

    .mainHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;

      .mainTitle {
        font-family: PingFang SC;
        font-size: 28px;
        font-weight: 600;
        line-height: 38px;
        margin-left: 12px;
        margin-right: 12px;
        text-align: center;
        letter-spacing: 0.04em;
        font-variation-settings: "opsz" auto;
        background: linear-gradient(180deg, #FFFFFF 6%, #FFE7C9 89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      .img {
        width: 19px;
        height: 32px;

      }

    }

    .bottomTextshow {
      margin-top: 20px;
      text-align: center;
      /* 辅助内容_常规 */
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: normal;
      color: #A5A5A5;

    }

    .hastopnobottom {
      width: 100vw;
      align-items: center;
      justify-content: center;

    }

    .mainCenterView {
      margin-top: 14px;
      display: flex;
      width: 100vw;
      align-items: center;
      justify-content: center;

      .mainCenter {
        // align-items: center;
        // justify-content: center;
        // background-color: #FFE5C8;
        width: 224px;
        // text-align: center;
        height: 24px;
        // display: flex;
        // border-radius: 12px;
        opacity: 1;
        flex-direction: row;


        .imglabke {
          width: 224px;
          height: 24px;

        }

        .hotRankLeft {
          background-image: url(https://akim-oss.aikucun.com/2cf8ce21aea8dd69b63715ecd334c8639fdadce3_1742200894256_64.png);
          background-size: 100% 100%;
          width: 58px;
          height: 20px;
          font-family: Alimama FangYuanTi VF;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          line-height: 20px;
          padding-left: 6px;
          letter-spacing: 0px;
          font-variation-settings: "opsz" auto;
          color: #F2DEC9;

        }

        .hotRankRight {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0px;
          color: #8C4206;
          margin-left: 2px;
          margin-right: 4px;
          margin-top: -2px;
        }
      }

    }

    .tips {
      margin-top: 12px;
      font-family: PingFang SC;
      font-size: 10px;
      font-weight: normal;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0px;
      color: #FFE5C8;
    }


    .mainList {
      width: 100%;
      height: 100vh;
      opacity: 1;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      // justify-content: center;
      // align-items: center;
      margin-top: 20px;
      background: #F1F2F5;
      display: flex;
      flex-direction: column;

      .pCard {
        margin-top: 12px;
        margin-left: 12px;
        margin-right: 12px;
      }

      .moreRank {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        justify-content: center;

        .moreRankText {
          font-family: PingFang SC;
          font-size: 16px;
          margin-left: 8px;
          margin-right: 8px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0px;
          color: #222222;

        }

        .moreRankImg {
          width: 10px;
          height: 9px;
        }

      }

      .pad12 {
        padding: 12px;

        .line {
          height: 8px;
        }

        .mainItem {
          // background-color: #8C4206;
          // background-image: url(https://akim-oss.aikucun.com/ccbcdf9e51e14c89ffd9bc0f2384751ff9fcde40_1740725434108_42.png);
          height: auto;
          // height: 249;
          position: relative;
          // background-repeat: no-repeat;
          // background-size: cover;
          // background-position: center;
          // width: calc(100% - 24px);

          .itemBg {
            width: 298px;
            height: 207px;
          }

          .listItem {
            // position: relative;
            z-index: 10;
            top: 0px;
            opacity: 1;
            height: 230px;
            // width: 351px;
            // min-height: 351px;
            // width: calc(100% - 24px);
            // border-radius: 10px;
            opacity: 1;
            // background-color: #b98989;
            padding: 12px;
            // border-style: solid;
            // border-width: 2px;
            // border-color: rgba(255, 71, 71, 0.12);
            // background-image: url('https://akim-oss.aikucun.com/ccbcdf9e51e14c89ffd9bc0f2384751ff9fcde40_1740725434108_42.png/298x207');
            background-image: url(https://akim-oss.aikucun.com/ee6a888a814818064eca11cb10f0b4e2d9f190cd_1740970528698_21.png);
            background-repeat: no-repeat;
            background-size: cover;
            // background-image: url(https://akim-oss.aikucun.com/ccbcdf9e51e14c89ffd9bc0f2384751ff9fcde40_1740725434108_42.png);

            .childtop {
              justify-content: space-between;
              align-items: center;
              // background-color: #F2DEC9;
              // margin-top: 12px;
              // margin-bottom: 12px;
              position: relative;
              flex-direction: row;
              display: flex;

              .childtopleft {
                justify-content: center;
                align-items: center;
                flex-direction: row;
                display: flex;

                .listItemTitle {
                  font-family: PingFang SC;
                  font-size: 14px;
                  margin-left: 6px;
                  margin-right: 6px;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: 0px;
                  color: #222222;
                }

                .childImg {
                  width: 12px;
                  height: 20px;

                }
              }

              .seeRank {
                width: 64px;
                height: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                opacity: 1;
                background: #FF4747;
                font-family: PingFang SC;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0px;
                color: #FFFFFF;
              }

            }

            .childDetailList {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              position: relative;
              margin-top: 12px;

              .itemsize {
                width: 104px;
                // width: calc(100% - 48px)/3;
                height: 104px;

              }

              .imgView {
                // width: 104px;
                height: 104px;
                position: relative;

                .proImg {
                  // width: 104px;
                  height: 104px;
                  position: absolute;
                  z-index: 10;
                }

                .proSoftImg {
                  position: absolute;
                  width: 25px;
                  left: 2px;
                  height: 30px;
                  z-index: 20;
                }

                .proMoney {
                  position: absolute;
                  bottom: 0px;
                  z-index: 20;
                  width: 62px;
                  height: 16px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 2px;
                  opacity: 1;
                  background: #FFD4B6;
                  font-size: 10px;
                  font-weight: bold;
                  line-height: 14px;
                  letter-spacing: 0px;
                  color: #8C4206;
                  font-weight: 500;
                  font-size: 10px;
                  font-family: DIN;
                  font-weight: 700;
                  font-size: 10px;

                }
              }

              .titleProduct {
                margin-top: 4px;
                font-family: PingFang SC;
                font-size: 12px;
                font-weight: normal;
                line-height: 16px;
                letter-spacing: 0px;
                color: #222222;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

              }

              .priceView {
                display: flex;
                flex-direction: row;
                margin-top: 4px;
                align-items: baseline;

                .priceLeft {
                  font-family: PingFang SC;
                  font-size: 10px;
                  font-weight: 500;
                  line-height: 14px;
                  text-align: justify;
                  letter-spacing: 0px;
                  color: #FF4747;

                }

                .priceCenter {
                  font-family: DIN;
                  font-size: 16px;
                  font-weight: bold;
                  line-height: 22px;
                  letter-spacing: 0px;
                  color: #FF4747;

                }

                .priceRight {
                  font-family: DIN;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16px;
                  letter-spacing: 0px;
                  text-decoration: line-through;
                  color: #A5A5A5;

                }
              }

              .hotview {
                flex-direction: row;
                display: flex;
                margin-top: 5px;
                align-items: center;

                .hottext {
                  font-family: PingFang SC;
                  font-size: 10px;
                  font-weight: 500;
                  line-height: 14px;
                  letter-spacing: 0px;
                  color: #FF4747;

                }

                .hotimg {
                  width: 14px;
                  height: 14px;
                }

              }

            }
          }
        }

      }

      .tsss {
        position: absolute;
        padding-top: 6px;
        padding-left: 6px;
        z-index: 200;
        pointer-events: auto;
        width: 100vw;
      }

      .toSeeAllViewNoBottom {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 999;
      }

      .toSeeAllNoBottom {
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;


        /* 辅助内容_中黑 */
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        letter-spacing: normal;
        /* 文字色/ct1_#222222_主要文字色 */
        /* 样式描述：用于标题，主要信息 */
        color: #222222;

        // width: 88px;
        height: 26px;
        /* 自动布局 */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 8px;
        /* 圆角4 */
        border-radius: 4px;
        opacity: 1;
        box-sizing: border-box;
        /* 分割线色/#222222 0.16a_分割线_hard */
        border: 1px solid rgba(34, 34, 34, 0.16);

      }

      .toSeeAllView {
        display: flex;
        margin-top: -20px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 999;

        .toSeeAll {
          align-items: center;
          justify-content: center;
          margin-bottom: 100px;

          // width: 88px;
          height: 26px;
          /* 自动布局 */
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 5px 8px;
          /* 圆角4 */
          border-radius: 4px;
          opacity: 1;
          box-sizing: border-box;
          /* 分割线色/#222222 0.16a_分割线_hard */
          border: 1px solid rgba(34, 34, 34, 0.16);


          /* 辅助内容_中黑 */
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: center;
          letter-spacing: normal;
          /* 文字色/ct1_#222222_主要文字色 */
          /* 样式描述：用于标题，主要信息 */
          color: #222222;

        }

      }



    }

  }

  .bottomBtnText {
    color: #FFFFFF;
    border-radius: 6px;
    opacity: 1;
    background: #FF4747;
    /* 品牌色/#FF4747_AKC红 */
    font-size: 16px;
    font-weight: 500;
    height: 44px;
    margin-top: 6px;
    margin-left: 12px;
    margin-bottom: 10px;
    margin-right: 12px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(255, 71, 71, 0.3);

    &:active {
      transform: scale(0.98);
      background: #FF4747;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background: url('https://akim-oss.aikucun.com/7716474716fc755c73519708fdb2f0ae293b5564_1742010830638_25.png') no-repeat;
      background-size: contain;
      background-position: center;
    }
  }


  .bottomBtnTextMoney {
    color: #FFFFFF;
    border-radius: 6px;
    opacity: 1;
    background: #FF4747;
    /* 品牌色/#FF4747_AKC红 */
    font-size: 16px;
    font-weight: 500;
    height: 44px;
    margin-top: 6px;
    margin-left: 12px;
    margin-bottom: 10px;
    margin-right: 12px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(255, 71, 71, 0.3);

    &:active {
      transform: scale(0.98);
      background: #FF4747;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background: url(https://akim-oss.aikucun.com/eeaaa563822b7c0c875f3c49e65c48e966db9716_1742009858312_9.png) no-repeat;
      background-size: contain;
      background-position: center;
    }
  }


  .bottomBtn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: #FFFFFF;
    display: flex;
    /* 自动布局 */
    flex-direction: row;
    // align-items: center;
    z-index: 999;
    /* 底部安全区域 */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out;
  }

}