@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN";
  src: url("../../../../assets/fonts/DIN-Bold.otf");
  /* 注意路径 */
}

.my-equity-wrap {
  width: 100%;
  height: 137px;

  .equity-card {
    display: flex;
    justify-content: space-between;

    // 左侧：我的权益
    .equity-section {
      width: 241px;
      height: 137px;
      padding: 8px 4px 4px 4px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #ffffff;

      .section-title {
        font-size: 14px;
        font-weight: 500;
        color: #222222;
        margin: 0 0 7px 4px;
      }

      .equity-content {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
        padding: 0 10px;
        box-sizing: border-box;

        .equity-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;

          .equity-label {
            font-size: 12px;
            color: #666;
            margin-bottom: 4px;
            line-height: 16px;
          }

          .equity-value {
            font-family: DIN;
            font-size: 18px;
            font-weight: bold;
            color: #222;
            line-height: 16px;
          }

          .label-packet {
            font-size: 12px;
            color: #222;
            line-height: 16px;
            font-weight: 700;
          }

          .label-coupon {
            font-size: 12px;
            color: #222;
            line-height: 16px;
            font-weight: 500;
          }
        }
      }

      .red-packet-banner {
        display: flex;
        height: 52px;
        justify-content: space-between;
        align-items: center;
        background-color: #fff5f5;
        border-radius: 6px;
        padding: 8px;
        box-sizing: border-box;

        .red-packet-left {
          display: flex;
          align-items: center;

          .red-packet-icon {
            width: 36px;
            height: 36px;
            margin-right: 6px;
            border-radius: 50%;
          }

          .red-packet-info {
            max-width: 120px;

            .red-packet-value {
              font-size: 14px;
              font-weight: 500;
              color: #222222;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            .red-packet-ticket {
              color: #ff4747;
            }

            .red-packet-expire {
              font-size: 12px;
              color: #ff4747;
              margin-top: 2px;
              line-height: 16px;
            }
          }
        }

        .red-packet-btn {
          background-color: #ff4d4f;
          color: #fff;
          font-size: 12px;
          padding: 5px 8px;
          border-radius: 4px;
        }
      }
    }

    // 右侧：会员中心
    .member-section {
      width: 102px;
      height: 137px;
      padding: 8px 8px 13px 8px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #ffffff;
      display: flex;
      flex-direction: column;

      .member-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .section-title {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 500;
          color: #222222;
        }

        .arrow-right {
          width: 12px;
          height: 12px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 12px;
            height: 12px;
          }
        }
      }

      .member-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .member-tag {
          display: inline-flex;
          align-items: center;
          width: fit-content;

          .member-tag-icon {
            width: 14px;
            height: 14px;
            margin-right: 2px;
          }

          span {
            font-family: PingFang SC;
            font-size: 12px;
            color: #222222;
            font-weight: 500;
          }
        }

        .growth-box {
          color: #fff;
          padding: 12px 0;
          box-sizing: border-box;
          text-align: center;
          width: 64px;
          height: 62px;
          border-radius: 6px;
          background: linear-gradient(161deg, #ff7547 -6%, #fc3939 87%);
          margin-bottom: 8px;

          .growth-value {
            font-size: 18px;
            font-weight: 600;
            font-family: DIN;
            line-height: 16px;
          }

          .growth-label {
            font-size: 12px;
            margin-top: 6px;
          }
        }
      }
    }
  }
}