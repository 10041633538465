@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .page__scroll {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // background: olivedrab;
    background-color: #f1f2f5;
    overflow: hidden scroll;

    // .body__bg {
    //   width: 100vw;
    //   height: 170px;
    //   object-fit: contain;
    // }
    .scroll__body {
      // position: relative;
      // top: 120px;
      flex-grow: 1;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 100% 180px;

      .body__shop {
        margin-top: 120px;
        padding: 10px;
        background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 100%);
        border-radius: 10px 10px 0 0;
      }
      .body__product {
        margin-top: 120px;
        padding: 10px 0;

        .scroll__watch-sticky-line {
          height: 1px;
          background: transparent;
        }

        .product__sticky {
          position: sticky;
          top: 0;
          z-index: 10;

          .sticky__body {
            // background: #ff2357;

            .body {
              padding-top: 8px;
              background: #fff;
              border-radius: 10px 10px 0 0;
              // overflow: hidden;
            }
          }
        }
        .product__list {
          background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 100%);
        }
      }
    }
  }

  .page__scroll__disable {
    overflow: hidden;
  }

  .page__footer {
    flex-shrink: 0;
    padding: 6px 12px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    width: 100vw;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;

    .footer__btn {
      margin-bottom: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 45px;
      background: #ff4747;
      border-radius: 6px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #fff;

      &::before {
        margin-right: 4px;
        width: 20px;
        height: 20px;
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    .footer__btn_e {
      &::before {
        background-image: url("https://akim-oss.aikucun.com/mshop/eeaaa563822b7c0c875f3c49e65c48e966db9716_1715076368864_24.png");
      }
    }
    .footer__btn_c {
      &::before {
        background-image: url("https://akim-oss.aikucun.com/mshop/915a29a021a8bc6d425c8f091a8dee0ad4b66728_1715076824503_79.png");
      }
    }
  }

  .loading {
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }
  .loadingFixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
