@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 350px;
  margin: 5px auto;
  min-height: 213px;
  border: 1px solid #ffffff;
  border-radius: 12px;
  font-size: 12px;
  background: url(https://akim-oss.aikucun.com/98fbc42faedc02492397cb5962ea3a3ffc0a9243_1706521359025_65.png)
    left top no-repeat;
  background-size: 100% auto;
  overflow: hidden;
  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    .aside {
      font-size: 12px;
      padding-right: 16px;
      background: url(https://akim-oss.aikucun.com/f6e1126cedebf23e1463aee73f9df08783640400_1706322829018_59.png)
        right center no-repeat;
      background-size: 12px;
    }
  }
  .list {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    overflow-x: scroll;
    padding-right: 12px;
    padding-top: 10px;
    margin-top: -8px;
  }
  .loginBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    margin: 0 10px 10px 10px;
    border-radius: 6px;
    color: #8c4206;
    font-weight: 500;
    font-size: 16px;
    background: linear-gradient(181deg, #ffebda 25%, #ffdcbf 136%);
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    background: #f6dccb;
    .item {
      margin-right: 17.5px;
      padding-left: 20px;
      color: #8c4206;
      background: url(https://akim-oss.aikucun.com/632667547e7cd3e0466547863e1207a8c0c0c549_1706324700364_35.png)
        left center no-repeat;
      background-size: 18px;
      &:nth-child(2) {
        background-image: url(https://akim-oss.aikucun.com/cb4e5208b4cd87268b208e49452ed6e89a68e0b8_1706324700561_40.png);
      }
      &:nth-child(3) {
        margin-right: 0;
        background-image: url(https://akim-oss.aikucun.com/b6692ea5df920cad691c20319a6fffd7a4a766b8_1706324700764_35.png);
      }
    }
    .itemArrow {
      width: 14px;
      height: 14px;
      margin-left: 6px;
      background: url(https://akim-oss.aikucun.com/64e095fe763fc62418378753f9402623bea9e227_1706536141629_79.png)
        center center no-repeat;
      background-size: 14px;
    }
  }
}

.popHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  flex-shrink: 0;
  color: #222222;
  font-weight: 500;
  font-size: 18px;
  .closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    width: 38px;
    height: 38px;
    background: url(https://akim-oss.aikucun.com/ca3512f4dfa95a03169c5a670a4c91a19b3077b4_1706352030065_9.png)
      center center no-repeat;
    background-size: 18px;
  }
}
.popItem {
  padding: 0 14px;
  padding-bottom: 12px;
}
.popItemTitle {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 500;
  color: #222222;
}
.popItemCont {
  color: #666666;
  font-size: 12px;
}

.couponPopWrap {
  display: flex;
  flex-flow: column nowrap;
  min-height: 40vh;
  max-height: 598px;
}
.longCouponList {
  flex: 1;
  padding: 0 10px;
  overflow-y: scroll;
  padding-bottom: 20px;
}
