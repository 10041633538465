@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  .scrollContainer {
    position: relative;
    overflow: auto hidden;
    height: 40px;
    width: 375px;
    // width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    box-sizing: border-box;

    .tabs-row {
      display: flex;
      flex-direction: row;
      width: fit-content;

      &.pr56 {
        padding-right: 56px;
      }

      .tab {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
        white-space: nowrap;

        &.active {
          transform: scale(1.2857);
          font-weight: 500;
        }

        .tabImg {
          height: 16px;
          margin-right: 1px;
        }
      }
    }

    .line {
      position: absolute;
      bottom: 2.5px;
      height: 3px;
      background-color: @themeColor;
      transition: all 0.3s ease-in-out;
    }
  }

  .expandArea {
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 40px;
    display: flex;
    flex-direction: row;

    .gradientArea {
      width: 16px;
      height: 40px;
      background: linear-gradient(to right, #ffffff00, #ffffff);
    }

    .iconWrapper {
      width: 40px;
      height: 40px;
      background-color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .icon {
        width: 18px;
        height: 18px;

        path {
          fill: var(--bgColor);
        }
      }
    }
  }
}

.popupbody {
  position: absolute;
  width: 100vw;
  z-index: 10000;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  top: 81px;
}

.popupContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #ffffff;
  padding: 12px;
  padding-bottom: 4px;
  justify-content: space-between;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  .item {
    width: 24%;
    height: 28px;
    color: #666666;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #f1f2f5;
    border-radius: 4px;
    margin-bottom: 8px;
    font-size: 12px;

    /* 如果最后一行是3个元素 */
    &:last-child:nth-child(4n - 1) {
      margin-right: calc(24% + 4% / 3);
    }

    /* 如果最后一行是2个元素 */
    &:last-child:nth-child(4n - 2) {
      margin-right: calc(48% + 8% / 3);
    }

    .tabImg {
      height: 18px;
    }

    &.active {
      background: @lightColor;
      color: @themeColor;
      font-weight: 500;
    }

    .text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 5px;
    }
  }
}