@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.block {
  position: relative;
  margin-top: 8px;
}
.feedTitle {
  display: flex;
  justify-content: center;
  margin: 12px 0;
  margin-top: 20px;
  .feedTitleImg {
    width: 100px;
    height: 21px;
  }
}
.observer-dom {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0px;
  width: 100%;
  height: 10px;
}
.guide-top {
  position: fixed;
  right: 16px;
  bottom: 120px;
  width: 44px;
  height: 44px;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.3s;
  pointer-events: initial;
}
.waterfallConatainer {
  min-height: 100vh;
  margin: 0 auto;
  background: transparent;
}
