@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.refresh {
  text-align: center;
}

.container {
  width: 14px;
  height: 14px;
  margin-left: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  .refresh_image {
    display: flex;
    width: 14px;
    height: 14px;
    align-items: center;
    align-self: center;
  }
}

.rotate360 {
  animation: rotate360 2s linear infinite;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
