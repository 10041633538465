@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.pageBox {
  width: 375px;
  margin: auto;
  height: 100vh;
  overflow: scroll;
  background-color: #f7ece1;
  scroll-behavior: smooth !important;
  .backIcon {
    width: 32px;
    height: 32px;
    object-fit: contain;
    position: fixed;
    left: 12px;
    top: 12px;
    z-index: 11;
  }
  .topCard {
    //width:355px;
    //height: 104px;
    //background: #ff6938;
    //border-radius: 10px;
    margin: 3px 0 8px;
  }
  .redPacketCard {
    margin-left: 10px;
    margin-right: 10px;
  }
  .together {
    //width: 355px;
    //height: 235px;
    //background: #ffdce1;
    //border-radius: 10px;
    margin: 0 0 8px;
  }
  .bottomBox {
    width: 100%;
    min-height: 80vh;
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 62%);
    .filter {
      height: 40px;
      position: sticky;
      top: 0px;
      z-index: 10;
    }
    .cardContainer {
      min-height: 100vh;
    }
  }
}
