@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.achievement {
  position: relative;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff0de;
  border: 1px solid #ffe9d0;
  border-radius: 8px;

  .achievement__received {
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 49px;
  }

  .achievement__title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #222;
  }

  .achievement__progress {
    position: relative;
    padding-top: 10px;
    width: 255px;
    box-sizing: border-box;

    .progress__list {
      position: relative;
      padding-right: 50px;
      display: inline-flex;
      width: inherit;

      .list__title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: fit-content;

        .item__icon {
          background-image: url("https://akim-oss.aikucun.com/mshop/8b58dff54ecfe1ff72a1a3dc01e0b29b79746b11_1715089931307_89.png");
        }

        .item__icon_active {
          background-image: url("https://akim-oss.aikucun.com/mshop/0e45e482fb226cbc133a0e8653efd37f7476df8e_1715233485125_41.png");
        }

        .item__con {
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #222;
          transform: translateX(-40%);

          .con__gmv {
            padding-top: 6px;
            color: #333;
          }

          .con__award {
            padding-top: 6px;
            color: #ff585e;
          }
        }
      }

      .list__con {
        position: relative;
        top: 0;
        width: inherit;
        display: flex;

        .list__item {
          flex-shrink: 1;
          position: relative;
          width: 33.33%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          .item__line {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 8px;
            background: #fff;

            .line__current {
              height: 8px;
              background: #ff4747;
            }
          }

          .item__icon {
            background-image: url("https://akim-oss.aikucun.com/mshop/8b58dff54ecfe1ff72a1a3dc01e0b29b79746b11_1715089931307_89.png");
          }

          .item__icon_active {
            background-image: url("https://akim-oss.aikucun.com/mshop/5d75fce8efbabcabab8c50ed4bc4ff2e28064d12_1715233675653_57.png");
          }

          .item__con {
            width: fit-content;
            transform: translateX(calc(50% - 6px));
            font-family: "DIN-Medium";
            font-size: 12px;
            font-weight: bold;
            line-height: 16px;
            color: #222;

            .con__gmv {
              padding-top: 6px;
              color: #222;
            }

            .con__award {
              padding-top: 6px;
              color: #ff4747;
            }
          }
        }

        .list__item:first-child {
          .item__line {
            border-radius: 4px 0 0 4px;

            .line__current {
              border-radius: 4px 0 0 4px;
            }
          }
        }

        .list__item:last-child {
          border-radius: 0 4px 4px 0;

          .item__line {
            border-radius: 0 4px 4px 0;

            .line__current {
              border-radius: 0 4px 4px 0;
            }
          }
        }

        .list__lenght_1 {
          width: 100%;

          .item__line {
            border-radius: 4px !important;

            .line__current {
              border-radius: 4px !important;
            }
          }
        }

        .list__lenght_2 {
          width: 50%;
        }

        .list__multistage {
          position: relative;

          .item__line {
            background-color: transparent;

            &::before {
              content: "";
              position: absolute;
              left: -6px;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              width: 100%;
              height: 6px;
              background-image: url("https://akim-oss.aikucun.com/mshop/11617c2851b917cd822c32a1c66b1a79e46e38e7_1732155329332_94.png");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
            }
          }

          &::before {
            content: "...";
            position: absolute;
            top: 18px;
            left: -6px;
            right: 0;
            margin: 0 auto;
            text-align: center;
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #333;
          }

          &::after {
            content: "...";
            position: absolute;
            bottom: 0;
            left: -6px;
            right: 0;
            margin: 0 auto;
            text-align: center;
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #ff585e;
          }
        }
      }

      .item__icon {
        position: relative;
        top: -2px;
        z-index: 2;
        width: 12px;
        height: 12px;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .achievement__gmv {
    padding: 16px 16px 0 16px;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #222;
    line-height: 20px;
    text-align: center;

    span {
      font-family: PingFang SC;
      font-weight: 500;
      color: #ff4747;
    }
  }

  .achievement__info {
    padding: 16px 16px 0 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .info__item {
      margin-bottom: 12px;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 140px;
      background: #fff;
      background-image: url("https://akim-oss.aikucun.com/mshop/d01e6799240a919f4917a5fb3f9607dfa8b0eb36_1716797581985_49.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-radius: 6px;
      box-sizing: border-box;

      .item__value {
        font-family: "DIN-Medium";
        font-weight: 700;
        font-size: 16px;
        color: #222;
      }

      .item__key {
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "苹方-简";
        font-size: 11px;
        font-weight: normal;
        line-height: 11px;
        text-align: center;
        color: #666;

        .key__icon {
          margin-left: 2px;
          display: inline-block;
          width: 14px;
          height: 14px;
        }
      }

      &:nth-child(3) {
        margin-bottom: 0;
      }

      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }

  .achievement__rank {
    margin: 16px 12px 0 12px;
    width: 303px;
    background: #fff;
    border: 1px solid #ffe5c5;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;

    .rank__title {
      padding: 8px 0;
      background: #ffe5c5;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #8c4206;

      .item__0,
      .item__1,
      .item__2 {
        font-family: PingFang SC !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 16px !important;
        color: #8c4206 !important;
        text-align: left;
      }
    }

    .rank__item {
      position: relative;
      padding: 6px 0;
      display: flex;
      align-items: center;
      color: #222;

      .item__0 {
        position: relative;
        padding-left: 20px;
        width: 25%;
        text-align: left;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 12px;
          margin: auto;
          z-index: 1;
          width: 24px;
          height: 24px;
          // background-image: url("https://akim-oss.aikucun.com/mshop/61bd3b480aeb4fab6e1cf9833b6d0510b14e0114_1715418668805_56.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .item__1 {
        width: 37%;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
        text-align: left;
      }

      .item__2 {
        width: 38%;
        font-family: "DIN-Medium";
        font-size: 12px;
        font-weight: bold;
        line-height: 12px;
        text-align: left;
      }
    }

    .rank__item_active {
      background: rgba(255, 71, 71, 0.08);
      color: #ff4747;

      &::before {
        content: "";
        position: absolute;
        left: 8px;
        width: 6px;
        height: 6px;
        background-image: url("https://akim-oss.aikucun.com/mshop/32063ab0c6c9dde75ac78d7c48511cc6cfa87c7f_1715584573330_94.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .rank__1 {
      .item__0::before {
        background-image: url("https://akim-oss.aikucun.com/mshop/61bd3b480aeb4fab6e1cf9833b6d0510b14e0114_1715418668805_56.png");
      }
    }

    .rank__2 {
      .item__0::before {
        background-image: url("https://akim-oss.aikucun.com/mshop/bed65289c03d2b5ed00cbedb3ba8c741e1c84921_1715418743507_93.png");
      }
    }

    .rank__3 {
      .item__0::before {
        background-image: url("https://akim-oss.aikucun.com/mshop/9d43fecc42b50ec34129cff4365dcadfed307e30_1715418783957_12.png");
      }
    }
  }
}

.estimated_rewards {
  margin-top: 8px;
  padding: 12px 16px;
  border-radius: 8px;
  background: #FFF0DE;
  box-sizing: border-box;
  border: 1px solid #FFE9D0;

  .rewards__title {
    padding-bottom: 4px;
    text-align: center;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }

  .rewards__tips {
    padding-bottom: 8px;
    text-align: center;
    font-family: '苹方-简';
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #666;
  }

  .rewards__data {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .data__item {
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 140px;
      background: #fff;
      background-image: url("https://akim-oss.aikucun.com/mshop/d01e6799240a919f4917a5fb3f9607dfa8b0eb36_1716797581985_49.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-radius: 6px;
      box-sizing: border-box;

      .item__value {
        font-family: "DIN-Medium";
        font-weight: 700;
        font-size: 18px;
        color: #FF4747;
      }

      .arrow {
        &::after {
          content: "";
          margin-left: 4px;
          display: inline-block;
          width: 12px;
          height: 12px;
          background-image: url('https://akim-oss.aikucun.com/mshop/8cca236cc68f64a22fa29b158e3ccee6ac422a85_1734070577140_7.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .item__key {
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "苹方-简";
        font-size: 11px;
        font-weight: normal;
        line-height: 11px;
        text-align: center;
        color: #666;
      }
    }
  }
}

.update__tips {
  padding-top: 12px;
  font-family: "苹方-简";
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  color: #222;
  text-align: center;
}