@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.scroll-view {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 46px - env(safe-area-inset-bottom));
  -webkit-overflow-scrolling: touch;

  // background-color: #f5f5f5;
  .personal-container {
    position: relative;
    padding: 16px 12px 10px;
    // min-height: 100vh;
    // background-image: url("~@/assets/personal-bg.png");
    // background-size: contain;
    // background-repeat: no-repeat;

    .wrapper {
      position: relative;
      z-index: 2;
      margin-bottom: 20px;
    }
  }
}

.userInfoWrap {
  margin-bottom: 16px;
}

.loading {
  display: flex;
  justify-content: center;

  .loading__icon {
    width: 30px;
    height: 30px;
    vertical-align: bottom;
    animation: loading 1s linear infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
