@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F1F2F5;
  position: relative;
  overflow: hidden;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: #FFFFFF;
}

.content {
  flex: 1;
  overflow-y: auto;
  margin-top: 44px;
  padding-bottom: 24px;
  /* 头部高度 */
  margin-bottom: calc(58px + var(--safe-area-inset-bottom));
  /* 底部高度 + 安全区域 */
  -webkit-overflow-scrolling: touch;
  /* 增强iOS滚动体验 */
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 10;
  box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
}

.tipContainer {
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFF0F0;
  position: fixed;
  bottom: calc(54px + var(--safe-area-inset-bottom));
}

.tipText {
  font-size: 12px;
  line-height: 17px;
  color: #FF4747;
  text-align: center;
}

.buttonContainer {
  display: flex;
  padding: 10px 12px;
  padding-bottom: calc(10px + var(--safe-area-inset-bottom));
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}

.selectAllWrapper {
  display: flex;
  align-items: center;
}

.selectAllWrapper :global(.adm-checkbox) {
  --icon-size: 20px;
  --font-size: 14px;
  --gap: 8px;
}

.selectAllWrapper :global(.adm-checkbox-content) {
  color: #333333;
}

.checkbox {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.checkboxSelected {
  background-color: #FF4747;
  border-color: #FF4747;
}

.checkIcon {
  width: 12px;
  height: 12px;
}

.selectAllText {
  font-size: 14px;
  color: #333333;
}

.actionButtons {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-left: 16px;
}

.button {
  width: 100px;
  height: 36px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyButton {
  margin-left: 12px;
  background-color: #FF4747;
  color: #FFFFFF;
  border: none;
}

.addButton {
  background-color: #FFFFFF;
  color: #FF4747;
  border: 1px solid #FF4747;
}