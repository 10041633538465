@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.scrollBannerWrapper {
  overflow: hidden;
  /* 隐藏超出边界的内容 */
  white-space: nowrap;
  /* 保证内容在一行内 */
  position: relative;
}

.scrollBannerContent {
  display: inline-flex;
  /* 使用 inline-flex 来确保内容水平排列 */
  animation: scroll-left infinite linear;
  /* 控制滚动动画 */
}

.scrollBannerItem {
  display: inline-block;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
    /* 从左侧开始 */
  }

  100% {
    transform: translateX(-50%);
    /* 滚动到左侧完全消失 */
  }
}