@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.overlayContent {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 375px;
  padding-top: 135px;
  max-height: 450px;
  transform: translate(-50%, -50%);
  background: url(https://akmer.aikucun.com/cb7a1d775e800fd1ee4049f7dca9e041eb9ba083_1600871230835_94.png)
    center top no-repeat;
  background-size: 100% auto;
  border-radius: 0 0 30px 30px;
  overflow: hidden;
}
.header {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 15px;
  color: #fff1ac;
  font-size: 14px;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
  }
  .logo {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    object-fit: cover;
    border-radius: 14px;
  }
  .brandName {
    text-align: center;
  }
}

.list {
  width: 300px;
  max-height: 158px;
  overflow-y: scroll;
  margin-bottom: 10px;
  .item {
    display: flex;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 3px;
  }
  .itemLeft {
    position: relative;
    flex-shrink: 0;
    width: 75px;
    padding: 10px 5px 15px 0;
    text-align: center;
    color: #ff3037;
    font-size: 26px;
    font-family: "DIN-Bold";
    border-right: 1px dashed #cccccc;
    &::before {
      content: "";
      position: absolute;
      right: -5px;
      top: -5px;
      width: 10px;
      height: 10px;
      background-color: #ea2222;
      border-radius: 50%;
    }
    &::after {
      content: "";
      position: absolute;
      right: -5px;
      bottom: -5px;
      width: 10px;
      height: 10px;
      background-color: #ea2222;
      border-radius: 50%;
    }
    .price {
      font-weight: bold;
      &::before {
        content: "¥";
        font-size: 14px;
        padding-right: 3px;
      }
    }
  }
  .itemRight {
    flex: 1;
    padding: 13px 10px 13px 15px;
    display: flex;
    flex-flow: column;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .couponName {
      font-size: 16px;
    }
    .timeRange {
      font-size: 11px;
      color: #aaaaaa;
    }
  }
}

.couponBtn {
  margin: 0 auto 15px;
  padding: 12px 0;
  width: 250px;
  text-align: center;
  font-size: 16px;
  font-family:
    PingFangSC-Semibold,
    PingFang SC;
  font-weight: 600;
  color: #d3151b;
  line-height: 22px;
  background: linear-gradient(180deg, rgba(255, 235, 110, 1) 0%, rgba(249, 195, 84, 1) 100%);
  box-shadow: 0px 2px 4px 0px rgba(215, 46, 46, 0.5);
  border-radius: 24px;

  i {
    margin-left: 5px;
    display: inline-block;
    width: 7px;
    height: 11px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("https://akim-oss.aikucun.com/7ed974be78526b76ae43a6534a47af39fb6bb85c_1710828561518_39.png");
  }
}
