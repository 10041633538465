@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.menu {
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  margin-top: 8px;
  background-color: #ffffff;
  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 74px;
    .menu-item-icon {
      width: 28px;
      height: 28px;
      vertical-align: bottom;
    }
    .menu-item-text {
      font-size: 12px;
      line-height: 16px;
      margin-top: 6px;
      color: #222222;
    }
  }
}
