@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.SwiperContainer {
  position: relative;
  .swiperItem {
    object-fit: cover;
    min-height: 211px;
    max-height: 467px;
    width: 100%;
    height: 100%;
    vertical-align: bottom;
  }
  .badge {
    position: absolute;
    right: 12px;
    top: 12px;
    background-color: #222222;
    padding: 2px 6px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }

  .videoPlayIcon {
    width: 76px;
    height: 76px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-38px, -38px);
  }
  .videoLoading {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 45%;
    left: 45%;
    animation: loading 1s linear infinite;
  }
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .videoVioce {
    position: absolute;
    bottom: 12px;
    right: 12px;
    padding: 2px 5px;
    background-color: rgba(34, 34, 34, 0.8);
    border-radius: 12px;
    .videoVioceIcon {
      width: 18px;
      height: 18px;
    }
  }
  .scrollBar {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    .scrollBarBackground {
      width: 100%;
      background-color: rgba(255, 255, 255, 0.3);
      height: 2px;
      border-radius: 100px;
      .scrollBarInner {
        width: 0;
        background-color: #ff4747;
        height: 2px;
        border-radius: 100px;
        transition: width 0.3s ease;
      }
    }
  }
}
.indicatorClass {
  position: absolute;
  width: 100%;
  bottom: 20px;
}
