@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
  .nav {
    padding: 6px;
    background-color: #fff;
    .nav-box {
      border-radius: 20px;
      border: 0.5px solid #e5e5e5;
      display: flex;
      position: relative;
      z-index: 10;
      width: fit-content;
      .nav-box__image-box {
        width: 44px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        .image-box__image {
          width: 20px;
          height: 20px;
        }
        &:first-child::after {
          content: "";
          width: 1px;
          height: 18px;
          background-color: rgba(0, 0, 0, 0.2);
          position: absolute;
          right: 50%;
        }
      }
    }
  }
  .setting {
    padding-top: 9px;
    .setting-item {
      display: flex;
      align-items: center;
      height: 44px;
      margin-left: 12px;
      padding-right: 12px;
      border-bottom: 1px solid rgba(34, 34, 34, 0.08);
      .setting-item__icon {
        width: 24px;
        height: 24px;
        vertical-align: bottom;
        margin-right: 12px;
      }
      .setting-item__text {
        font-size: 14px;
        line-height: 20px;
        color: #666666;
      }
      .setting-item__arrow-right {
        margin-left: auto;
        display: flex;
        align-items: center;
        .nameauth {
          font-size: 12px;
          color: #a5a5a5;
          margin-right: 4px;
        }
        .arrow-right__icon {
          width: 14px;
          height: 14px;
          vertical-align: bottom;
        }
      }
    }
  }
  .logout-btn {
    position: absolute;
    bottom: 50px;
    left: 50%;
    display: flex;
    width: 350px;
    height: 44px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border: 1px solid rgba(34, 34, 34, 0.08);
    border-radius: 6px;
    color: #cccccc;
    transform: translateX(-50%);
  }
  .backup {
    color: #1859df;
    font-size: 14px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
}
