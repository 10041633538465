@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.coupon11_item {
  width: 352px;
  height: 86px;
  margin-bottom: 6px;
  display: flex;
  .price {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 25.67%;
    height: 100%;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    color: #ff302d;
    .symbol {
      height: 32px;
      display: flex;
      align-items: flex-end;
      font-size: 14px;
      padding-bottom: 2px;
      padding-right: 2px;
    }
    .bigText {
      font-size: 32px;
    }
  }
  .content {
    width: 43%;
    flex-shrink: 0;
    .text {
      font-size: 14px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      color: #ff302d;
      line-height: 20px;
      padding-top: 13px;
      padding-bottom: 2px;
    }
    .divider {
      margin: 0;
      border-style: dashed;
      border-color: rgb(255 16 16 / 20%);
    }
    .date {
      font-size: 12px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #b55f40;
      padding-top: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .limit {
      font-size: 12px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #b55f40;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
    }
  }
  .btn {
    width: 31.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .now {
      width: 78px;
      height: 24px;
      padding-left: 8px;
    }
    .out {
      position: absolute;
      width: 65px;
      top: 0;
      right: 1px;
    }
  }
}
.coupon11_itemOut {
  width: 352px;
  height: 86px;
  margin-bottom: 6px;
  display: flex;
  .price {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 25.67%;
    height: 100%;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    color: rgba(153, 153, 153, 1);
    .symbol {
      height: 32px;
      display: flex;
      align-items: flex-end;
      font-size: 14px;
      padding-bottom: 2px;
      padding-right: 2px;
    }
    .bigText {
      font-size: 32px;
    }
  }
  .content {
    width: 43%;
    flex-shrink: 0;
    .text {
      font-size: 14px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      color: rgba(153, 153, 153, 1);
      line-height: 20px;
      padding-top: 13px;
      padding-bottom: 2px;
    }
    .divider {
      margin: 0;
      border-style: dashed;
      border-color: rgb(255 16 16 / 40%);
    }
    .date {
      font-size: 12px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      padding-top: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .limit {
      font-size: 12px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
    }
  }
  .btn {
    width: 31.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .now {
      width: 78px;
      height: 24px;
      padding-left: 8px;
    }
    .out {
      position: absolute;
      width: 65px;
      top: 0;
      right: 1px;
    }
  }
}
