@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  min-height: 242px;
  border: 1px solid #ffffff;
  border-radius: 12px;
  font-size: 12px;
  background: #ffffff;
  overflow: hidden;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #222222;
    background: url(https://akim-oss.aikucun.com/92cfceb39d57d914ed8b14d0e37643de0797ae56_1706514545369_88.png)
      left top no-repeat;
    background-size: auto 40px;
    .title {
      display: flex;
      align-items: center;
    }
    .titleImg {
      height: 18px;
      margin-right: 2px;
    }
    .aside {
      font-size: 12px;
      padding-right: 2px;
      .swiperWrap {
        display: flex;
        align-items: center;
      }
      .avatar {
        width: 16px;
        height: 16px;
        margin-right: 3px;
        border: 1px solid #ffffff;
        border-radius: 8px;
      }
    }
  }
  .list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 0 10px 5px 0;
  }
}
