@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrapper {
  position: relative;
  font-size: 14px;
  padding: 0 8px;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 3px;
  width: 18px;
  height: 18px;
  background: url(https://akim-oss.aikucun.com/1b6453892473a467d07372d45eb05abc2031647a_1735529124906_82.png)
    center center no-repeat;
  background-size: contain;
}

.header {
  display: flex;
  justify-content: center;
  height: 22px;
  padding-top: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #222222;
}

.section {
  margin-top: 20px;
  text-align: justify;
  line-height: 20px;

  .title {
    font-weight: 500;
  }

  .content {
    color: #666666;
  }

  .mark {
    color: #ff4747;
  }
}

.footer {
  display: flex;
  height: 44px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  background: #ff4747;
  color: #ffffff;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
}
