@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  background: #fff4e6;
  color: @warningColor;
  font-size: 12px;
  height: 32px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  padding: 0 12px 8px 12px;

  path {
    fill: @warningColor;
  }

  .followWxImg {
    width: 15px;
    margin-right: 6px;
  }

  .followAction {
    margin-left: auto;
    margin-right: 4px;
  }

  .followActionImg {
    width: 12px;
    height: 12px;
  }
}

.popup {
  display: flex;
  flex-direction: column;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  .header {
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #333;
    font-size: 15px;
    font-weight: bold;
  }
  .imgWrapper {
    width: 351px;
    margin: 0 auto;
    position: relative;
    min-height: 100px;

    .bg {
      width: 351px;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    text-align: center;
    color: #222;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }
  .content {
    text-align: center;
    color: #666;
    font-size: 15px;
    padding: 12px 0 24px 0;
  }
  .button {
    width: 250px;
    height: 44px;
    color: white;
    background-color: @themeColor;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 44px;
    border-radius: @smallBorderRadius;
  }
}
