@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.shop_info {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f1f2f5;

  .go_back {
    position: fixed;
    top: 25px;
    left: 18px;
    z-index: 999;
    width: 25px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
  .bg_img {
    width: 100%;
    height: 230px;
  }
  .top_info {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .top_center {
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo {
        margin-top: 20px;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        background-color: #ffffff;
      }
      .name {
        margin-top: 6px;
        color: #222222;
        font-size: 14px;
        font-weight: 500;
      }
      .auth {
        margin-top: 6px;
        width: 83px;
        height: 15px;
      }
      .score {
        margin-top: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .value {
          margin-left: 6px;
          color: #7c3b02;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .score_list {
      border-radius: 12px;
      margin: 21px 12px 0 12px;
      padding: 6px 15px;
      background-color: #ffffff;

      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 6px;
        margin-bottom: 6px;
        border-radius: 6px;

        .name {
          color: #222222;
          font-size: 14px;
        }

        .progress {
          border-radius: 6px;
          margin-left: 9px;
          background-color: #eeeeee;
          width: 104px;
          height: 8px;

          .content {
            border-radius: 6px;
            background-image: linear-gradient(to right, #fac840, #fe9d2c);
            height: 100%;
          }
        }
        .number {
          margin-left: 6px;
          color: #7c3b02;
          font-size: 14px;
          font-weight: 500;
        }
        .desc {
          margin-left: 9px;
          color: #ff6600;
          font-size: 12px;
        }
      }
    }
  }

  .content_list {
    border-radius: 12px;
    margin: 21px 12px 0 12px;
    padding: 6px 15px;
    background-color: #ffffff;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;
      margin-bottom: 6px;
      border-radius: 6px;

      .name {
        color: #222222;
        font-size: 14px;
        font-weight: 500;
      }

      .arrow_right {
        width: 14px;
        height: 14px;
      }

      .date {
        color: #666666;
        font-size: 12px;
      }
    }
  }
}
.show_images {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;

  .go_back {
    position: fixed;
    top: 25px;
    left: 18px;
    z-index: 999;
    width: 25px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }

  .img_container {
    margin-top: 26px;
    margin-left: 15px;
    margin-right: 15px;
  }
}
