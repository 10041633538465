@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  min-height: 100vh;
  background: #ffffff;
  .img {
    position: absolute;
    left: 95px;
    top: 192px;
    width: 184px;
    height: 184px;
  }
  .text {
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #666666;
    position: absolute;
    top: 375.5px;
    left: 69px;
  }
  .btn {
    position: absolute;
    left: 146px;
    top: 412px;
    width: 82px;
    height: 32px;
    /* 自动布局 */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    opacity: 1;
    box-sizing: border-box;
    /* 分割线色/#222222 0.16a_分割线_hard */
    border: 1px solid rgba(34, 34, 34, 0.16);
  }
}
