@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #fff;

  .page__tab {
    flex-shrink: 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background: #fff;

    .tab {
      position: relative;
      padding: 0 10px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: normal;
      line-height: 22px;
      color: #666;
    }

    .tab__active {
      font-weight: 500;
      color: #222;

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -3px;
        content: "";
        width: 60%;
        height: 2px;
        background: #ff4747;
      }
    }
  }

  .page__scroll {
    flex-grow: 1;
    overflow: hidden scroll;

    .scroll__body {
      padding: 0 12px 200px 12px;
      min-height: 100vh;
      background:
        url("https://akim-oss.aikucun.com/mshop/0774a9a831b9e82ba09ed3334ca3077c678f67fd_1720082261455_67.png") top left no-repeat,
        linear-gradient(to bottom, #ff2059 20%, #ffdfb9 30%);
      // linear-gradient(180deg, #FF2059 23%, #FFDFB9 32%)
      //  linear-gradient(180deg, #ff2059, #ffdfb9 300px)
      background-size: contain;
      box-sizing: border-box;

      .header {
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .activity-status {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .activity-status__left {
            .status {
              position: relative;
              left: -12px;
              padding: 0 12px;
              height: 26px;
              line-height: 26px;
              background: #ffe5c8;
              border-radius: 0 24px 24px 0;
              font-family: PingFang SC;
              font-size: 12px;
              font-weight: 500;
              color: #333;
            }
          }

          .activity-status__right {
            .right__count-down {
              display: flex;
              align-items: center;
              font-family: PingFang SC;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              color: #fff;

              .block {
                margin: 0 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 18px;
                height: 18px;
                background: #ffe5c8;
                border-radius: 4px;
                font-family: "DIN-Medium";
                font-size: 12px;
                font-weight: bold;
                line-height: 16px;
                color: #222;
              }
            }
          }
        }

        .header__tips {
          margin: 5px 0;
          display: flex;
          justify-content: flex-end;
          width: 100vw;

          .tips {
            padding: 0 12px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 24px;
            background: #FFE5C8;
            border-radius: 20px 0px 0px 20px;
            font-family: '苹方-简';
            font-size: 12px;
            color: #222;
          }
        }

        .activity-name-wrap {
          padding: 20px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;

          .logo-list {
            position: relative;
            flex-shrink: 0;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;

            .item {
              position: relative;
              width: 30px;
              height: 30px;
              background: #fff;
              border: 1px solid #ff4747;
              object-fit: contain;
              border-radius: 4px;
              box-sizing: border-box;
            }
          }

          .activity-name {
            flex-shrink: 0;
            max-width: 270px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
            font-family: PingFang SC;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            color: #ffe5c8;
          }
        }

        .pd0 {
          padding: 0;
        }

        .activity-desc {
          margin: 10px 4px 0 4px;
          padding: 4px 16px;
          line-height: 16px;
          border-radius: 12px;
          background: #ffe5c8;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 500;
          color: #222;
        }
      }

      .customer-list-wrap {
        position: relative;
        margin: 6px 0 0 0;
        display: flex;
        justify-content: flex-start;
        width: 100vw;

        .customer-list {
          position: relative;
          left: -1px;
          padding: 0 10px 0 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          border: 1px solid #fff;
          border-radius: 0px 24px 24px 0px;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 500;
          color: #fff;

          .icon {
            margin-left: 2px;
            width: 12px;
            height: 12px;
          }
        }
      }

      .main {
        .main__body {
          padding: 24px 12px 12px 12px;
          background: #fff;
          border-radius: 10px;

          .body__title {
            margin-bottom: 24px;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            color: #222;
          }

          .body__desc {
            margin-bottom: 8px;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #ff4747;

            span {
              font-family: '苹方-简';
              font-size: 14px;
              font-weight: normal;
              line-height: 20px;
              color: #666;
            }
          }
        }

        .draw-time {
          margin-top: 12px;
          display: flex;
          align-items: center;

          h3 {
            padding-bottom: 4px;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #ff4747;
          }

          p {
            padding-bottom: 4px;
            font-family: "苹方-简";
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            color: #666;
          }
        }

        .rule-info {
          margin-top: 12px;
          font-family: "苹方-简";
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          color: #666;

          h3 {
            padding-bottom: 4px;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #ff4747;
          }

          .info__list {
            word-break: break-all;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: pre-line;
          }

          .info__list__all {
            display: block;
          }

          .show-more {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "苹方-简";
            font-size: 12px;
            font-weight: normal;
            line-height: 16px;
            color: #5a76b0;

            &::after {
              content: "";
              margin-left: 4px;
              display: inline-block;
              width: 12px;
              height: 12px;
              background-image: url(https://akim-oss.aikucun.com/mshop/a562a323aa46c131288790a104d81662f6f37501_1714306763540_74.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
          }

          .show-more-all {
            &::after {
              background-image: url(https://akim-oss.aikucun.com/mshop/a562a323aa46c131288790a104d81662f6f37501_1714306763540_74.png);
              transform: rotate(180deg);
            }
          }
        }
      }

      .loading {
        margin: auto;
        width: 30px;
        height: 30px;
        transform: rotate(360deg);
        -webkit-transition: -webkit-transform 1s linear;
        transition: transform 1s linear;
        -webkit-animation: spin 1s linear 1s 5 alternate;
        animation: spin 1s linear infinite;
      }

      .loadingFixed {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      @-webkit-keyframes spin {
        from {
          -webkit-transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  .page__footer {
    flex-shrink: 0;
    padding: 6px 12px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    width: 100vw;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;

    .footer__btn {
      margin-bottom: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      background: #ff4747;
      border-radius: 6px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #fff;

      &::before {
        margin-right: 4px;
        width: 20px;
        height: 20px;
        content: "";
        background-image: url("https://akim-oss.aikucun.com/mshop/eeaaa563822b7c0c875f3c49e65c48e966db9716_1715076368864_24.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  .text-ellipsis1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}