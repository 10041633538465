@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.logistics-node {
  margin: 0 10px 10px;
  padding-bottom: 12px;
  border-radius: 10px;
  background: #fff;
  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
}

.logistics-notice {
  position: relative;
  padding: 14px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666666;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    transform-origin: left top;
    transform: scaleY(0.5);
    background: rgba(0, 10, 71, 0.05);
  }
  .notice {
    position: relative;
    margin-left: 4px;
    padding: 0 6px;
    height: 18px;
    font-size: 10px;
    line-height: 18px;
    color: #666666;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 200%;
      height: 200%;
      transform-origin: left top;
      transform: scale(0.5);
      border: 1px solid #cccccc;
      border-radius: 8px;
      box-sizing: border-box;
    }
  }
}

.node {
  display: flex;
  .node-left {
    flex-shrink: 0;
    width: 68px;
    &__time {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #666666;
      align-items: center;
      span:last-child {
        font-size: 10px;
        color: #a5a5a5;
      }
    }
  }
  .node-right {
    position: relative;
    padding: 0px 14px 20px 20px;
    border-left: 1px dashed #d6d7d7;
    &__title {
      margin-bottom: 6px;
      font-size: 16px;
      color: #222222;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
    }
    &__content {
      font-size: 12px;
      color: #666666;
    }
    &__remind {
      margin-top: 10px;
      font-size: 12px;
      color: #ff4747;
    }
  }
  &:last-child {
    .node-right {
      border-left: none;
    }
  }
}

.node-received {
  .address-point {
    background: #ff4747 !important;
  }
  .node-right__content {
    color: #222222;
  }
}

.node-abnormal {
  .light-point {
    background: #ff4747 !important;
  }
  .node-right__title {
    color: #ff4747;
  }
  .node-right__content {
    color: #ff4747;
  }
}

.node-complete {
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 5px 6px 5px 12px;
  font-size: 12px;
  color: #222222;
  font-weight: 500;
  font-family:
    PingFangSC-Medium,
    PingFang SC;
  border-radius: 4px;
  border: 1px solid rgba(34, 34, 34, 0.16);
  img {
    margin-left: 4px;
    width: 14px;
    height: 14px;
  }
  .up {
    transform: rotate(180deg);
    transition: all 0.3s;
  }
}

.address-point {
  position: absolute;
  top: 0;
  left: -13px;
  width: 26px;
  height: 26px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  background: #d8d8d8;
}

.dark-point {
  position: absolute;
  top: 8px;
  left: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ddd;
}

.light-point {
  position: absolute;
  top: 8px;
  left: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ffb200;
}

.received-point {
  position: absolute;
  top: 0;
  left: -13px;
  width: 26px;
  height: 26px;
  background: url("https://akim-oss.aikucun.com/mshop/77777a4ce2238103eeffae1d2a8c373c9092adee_1722393888541_36.png")
    no-repeat;
  background-size: 100% 100%;
}

.notice-popup {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  &__header {
    padding: 12px;
    position: relative;
    border-bottom: 1px solid rgba(34, 34, 34, 0.08);
    .title {
      font-size: 16px;
      font-weight: 500;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      line-height: 22px;
      text-align: center;
      color: #222222;
    }
    .close {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__content {
    padding: 12px;
    font-size: 14px;
  }
  &__footer {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    color: #fff;
    text-align: center;
    background: #ff4747;
  }
}
