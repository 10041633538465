@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.card {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 351px;
  height: 86px;
  background: url(https://akim-oss.aikucun.com/80e28a51cbc26fa4bd34938c5e593b36146f5e0c_1723543039370_80.png) center center no-repeat;
  background-size: 351px 86px;

  .endTime {
    width: 58px;
    height: 29px;
    padding-top: 2px;
    color: #8C4206;
    font-size: 10px;
    text-align: center;
    background: url(https://filetest-oss.aikucun.com/akucun-member-aggregation-file/4220d4bcaa288512baea8607de85b2f0ece184ea_1735550241482_5.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 4px;
    top: 4.11px;
  }

  .left,
  .redPacketLeft {
    display: flex;
    width: 90px;
    justify-content: center;
    align-items: baseline;
    flex-shrink: 0;
    color: #ff4747;
    font-weight: bold;
    font-size: 32px;
    font-family: "DIN-Bold";

    .price::before {
      content: "¥";
      font-size: 16px;
    }
  }

  .redPacketLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: normal;

    .priceDesc {
      font-size: 10px;
    }
  }

  .center {
    flex: 1;
    padding-left: 15px;
    color: #ffffff;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .desc {
      margin-top: 5px;
      font-size: 10px;

      .flex {
        display: flex;
      }

      .descRow {
        width: 130px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .descRow2 {
        display: inline-block;
        line-height: normal;
        padding-right: 5px;
        max-width: 90px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .right,
  .redPacketRight {
    width: 102px;
    flex-shrink: 0;

    .textBtn {
      color: #fcedd7;
      font-size: 14px;
      font-weight: 500;
    }

    .btn {
      display: inline-block;
      padding: 5px 20px;
      background: #ffebcb;
      color: #8c4206;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
    }
  }

  .redPacketRight {
    position: absolute;
    top: 17px;
    right: 0;
  }
}