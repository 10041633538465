@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.tabs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  background: #ffffff;
  z-index: 5;
  .tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "苹方-简";
    font-size: 14px;
    color: #595959;
    //字体大小渐变
    transition: all 0.1s;
  }
  .active {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    // transform: scale(1.15);
  }
  .line {
    height: 3px;
    background: #ff585e;
    position: absolute;
    bottom: 3px;
    transition: transform 0.1s;
  }
}
.tabs.mini {
  box-sizing: border-box;
  padding: 0 4px;
  height: 32px;
  justify-content: flex-start;
  background: transparent;
  .tab {
    flex: 0 0 auto;
  }
  .active {
    font-size: 14px;
    font-weight: 500;
    color: #ff4747;
  }
  .line {
    height: 2px;
    bottom: 2px;
  }
}
