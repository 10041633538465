@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  border-radius: 10px;
  height: 195px;
  width: 355px;
  background-image: url("https://akim-oss.aikucun.com/mshop/7394d356ea05346f9a117df5e9552ea530d16672_1700776311175_32.png");
  background-size: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    padding-right: 10px;
    font-size: 12px;
    .aside {
      margin-top: 4px;
      &::after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 2px;
        background: url(https://akim-oss.aikucun.com/mshop/9b0c6c7858bffb024ef29c5813a2776f6f79815f_1698132764001_14.png)
          center center no-repeat;
        background-size: 10px;
      }
    }
  }

  .itemWrap {
    width: 75px;
    padding: 0 10px;
  }

  .item {
    width: 112px;
    height: 148px;
    padding: 5px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    font-weight: 500;
    background: linear-gradient(146deg, #ffe1e1 1%, #fff 23%, #fff 77%, #fdd 104%);
    .img {
      width: 100%;
      border-radius: 5px;
      vertical-align: middle;
    }
    .title {
      width: 100%;
      justify-content: center;
      white-space: nowrap;
      padding-top: 2px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      font-size: 12px;
    }
    .desc {
      text-align: center;
      color: #ff4747;
    }
  }
}
