@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.priceExp {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  margin-top: 8px;
  padding-bottom: 12px;
  .title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #222222;
    margin: 12px;
  }
  .img {
    width: 100%;
  }
}
