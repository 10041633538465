@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.App {
  width: 100%;
  height: 100vh;
  display: flow-root;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin: auto;

  .sale-calendar {
    position: fixed;
    top: 70px;
    right: 0;
    z-index: 101;
    width: 76px;
    height: 26px;
  }

  .video_container {
    width: 375px;
    padding: 12px;
    background: linear-gradient(180deg, #d2e8dd 0%, #d2e8dd 100%);
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.83);
    border-radius: 16px;
    backdrop-filter: blur(10px);
    box-sizing: border-box;
  }

  .share_btn {
    position: fixed;
    bottom: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 999;
    display: flex;

    .share_img {
      width: 161px;
    }
  }

  .go_back {
    position: fixed;
    top: 25px;
    left: 18px;
    z-index: 999;
    width: 25px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }

  .nullStatus {
    width: 100%;
    height: calc(100vh - 250px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .noSecKill {
      width: 183px;
      height: 183px;

      .text {
        font-size: 14px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
      }
    }
  }

  .back_top {
    padding: 6px 12px 6px 6px;
    position: sticky;
    display: flex;
    justify-content: space-between;
    top: 0;
    z-index: 110;

    .left {
      width: 120px;
      height: 32px;
      border: 0px solid rgba(255, 255, 255, 0.25);
      background: #4d000000;
      background: rgba(0, 0, 0, 0.3);
      border: 0.5px solid rgba(255, 255, 255, 0.25);
      border-radius: 16px;
      display: flex;
      align-items: center;

      .left_back {
        width: 20px;
        height: 20px;
        padding-left: 12px;
        padding-right: 12px;
      }

      .line {
        width: 0.5px;
        height: 18px;
        background: rgba(255, 255, 255, 0.3);
      }

      .left_home {
        width: 20px;
        height: 20px;
        padding-left: 12px;
      }

      .text {
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
        padding-left: 4px;
      }
    }

    .right {
      width: 32px;
      height: 32px;
      position: relative;

      .cart {
        width: 32px;
        height: 32px;
      }

      .num {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #ff302d;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        color: #ffffff;
        text-align: center;
        position: absolute;
        top: -4px;
        right: -6px;
      }
    }
  }

  .back_top_sticky {
    padding: 6px 12px 6px 6px;
    position: sticky;
    display: flex;
    justify-content: space-between;
    top: 0;
    z-index: 110;
    background: #ffffff;
    animation: appear 0.5s ease-in-out forwards;

    .left {
      width: 120px;
      height: 32px;
      border-radius: 20px;
      opacity: 1;
      background: rgba(255, 255, 255, 0.7);
      box-sizing: border-box;
      border: 0.5px solid #e5e5e5;
      display: flex;
      align-items: center;

      .left_back {
        width: 20px;
        height: 20px;
        padding-left: 12px;
        padding-right: 12px;
      }

      .line {
        width: 0.5px;
        height: 18px;
        background: rgba(0, 0, 0, 0.2);
      }

      .left_home {
        width: 20px;
        height: 20px;
        padding-left: 12px;
      }

      .text {
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #222222;
        padding-left: 4px;
      }
    }

    .right {
      width: 32px;
      height: 32px;
      position: relative;

      .cart {
        width: 32px;
        height: 32px;
      }

      .num {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #ff302d;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        color: #ffffff;
        text-align: center;
        position: absolute;
        top: -4px;
        right: -6px;
      }
    }

    .center {
      position: absolute;
      left: 138px;
      top: 14px;
      display: flex;

      .img {
        width: 16px;
        height: 16px;
      }

      .text {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        color: #222222;
        margin-left: 2px;
      }
    }
  }

  @keyframes appear {
    0% {
      opacity: 0.6;
    }

    40% {
      opacity: 0.8;
    }

    100% {
      opacity: 1;
    }
  }
}

.infinite {
  margin-bottom: 40px;
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
  position: absolute;
  z-index: 9999;
}

.loadingMore {
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.conference_invalid_popup {
  width: 300px;
  height: 102px;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #222222;
  }

  .content {
    margin-top: 12px;
    font-size: 14px;
    color: #666666;
  }
}
