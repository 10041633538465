@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN-Bold";
  src: url("../../../../assets/fonts/DIN-Bold.otf");
  /* 注意路径 */
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  background-color: white;
  padding: 12px;
  margin-top: 8px;

  .img {
    flex-shrink: 0;
    width: 86px;
    height: 86px;
    border-radius: 6px;
  }

  .right {
    max-width: calc(100% - 86px - 8px);
    position: relative;
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    height: 86px;
    margin-left: 8px;

    .fir {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .title {
        flex: 1;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        color: #222222;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
      }

      .price {
        flex-shrink: 0;
        font-family: DIN-Bold;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0px;
        color: #222222;

        .symbol {
          font-family: DIN-Bold;
          font-size: 12px;
          font-weight: bold;
          line-height: 16px;
          text-align: right;
          letter-spacing: 0px;
          color: #222222;
        }
      }
    }

    .sec {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;

      .sku {
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        letter-spacing: 0px;
        color: #a5a5a5;
        margin-right: 10px;
      }

      .sale {
        flex-shrink: 0;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0px;
        color: #a5a5a5;
      }
    }

    .btn {
      align-self: flex-end;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0px;
      color: #ff4747;
      border: 1px solid #ff4747;
      border-radius: 4px;
      padding: 3px 8px;
      text-align: center;
      vertical-align: middle;
      position: absolute;
      bottom: 0;
    }
  }
}
