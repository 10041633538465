@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
// PurchaseLimitContent.less
.wrap {
  display: flex;
  flex-direction: column;
}

.productWrap {
  display: flex;
  margin-bottom: 12px;
  padding: 6px;
  background: #F6F7FA;
  border-radius: 10px;
}

.productPic {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
}

.productMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  overflow: hidden;
}

.productTitle {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.productInfo {
  font-size: 12px;
  color: #999;
  margin: 4px 0 0 0;
}

.textNotice {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  text-align: center;
}

.hl {
  color: #FF6000;
  font-weight: 500;
}