@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN-Bold";
  src: url("../../../assets/fonts/DIN-Bold.otf"); /* 注意路径 */
}
.container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-left: 12px;
  margin-right: 12px;
  .first {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .name {
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: #222222;
    }
    .firNum {
      font-family: DIN-Bold;
      font-size: 16px;
      line-height: 22px;
      color: #222222;
    }
    .active {
      color: #ff4747;
    }
  }
  .second {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
    .balance {
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #a5a5a5;
    }
    .date {
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #a5a5a5;
    }
  }
  .order {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 6px;
    .orderNum {
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #a5a5a5;
    }
    .copy {
      border-radius: 4px;
      opacity: 1;
      box-sizing: border-box;
      border: 1px solid rgba(34, 34, 34, 0.16);
      font-family: PingFang SC;
      font-size: 10px;
      font-weight: normal;
      line-height: 15px;
      text-align: center;
      color: #666666;
      margin-left: 4px;
      padding: 0px 2px;
    }
  }

  .line {
    height: 0.5px;
    width: 100%;
    background-color: #eee;
    margin-top: 14px;
  }
}
.btm {
  margin-bottom: 16px;
}
