@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  min-height: 500px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 15px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 15px);
  border-radius: 10px;
  background: #fff0e4
    url(https://akim-oss.aikucun.com/mshop/da4b9237bacccdf19c0760cab7aec4a8359010b0_1728900278743_17.png)
    left top no-repeat;
  background-size: 100% auto;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 96px;
    padding-top: 50px;
    box-sizing: border-box;
    color: #222222;
    font-size: 14px;
    line-height: 20px;

    .time {
      color: #ff4747;
    }

    .title {
      padding-top: 12px;
    }
  }

  .closeBtn {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 26px;
    right: 26px;
    background: url(https://akim-oss.aikucun.com/b3f0c7f6bb763af1be91d9e74eabfeb199dc1f1f_1723182066267_98.png)
      center center no-repeat;
    background-size: 16px;
  }

  .calendar {
    width: 351px;
    padding: 30px 10px 13px 10px;
    box-sizing: border-box;
    margin: 0 auto;
    background: url(https://akim-oss.aikucun.com/mshop/1f9ea2f77043a87a90820e1b41601c31c980c3b6_1724296506957_69.png)
      left top no-repeat;
    background-size: 100% 100%;

    .calendarHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 6px;
      font-size: 14px;
      color: #ff4747;
      line-height: 20px;

      .headerCenter {
        flex: 1;
        text-align: center;
        font-size: 18px;
        color: #222222;
        font-weight: 500;
      }

      .headerLeft {
        display: flex;
        align-items: center;
        min-width: 50px;

        &::before {
          content: "";
          width: 16px;
          height: 16px;
          background: url(https://akim-oss.aikucun.com/472b07b9fcf2c2451e8781e944bf5f77cd8457c8_1723201959358_68.png)
            center center no-repeat;
          background-size: 16px;
        }

        &.disable {
          opacity: 0;
        }
      }

      .headerRight {
        display: flex;
        align-items: center;
        min-width: 50px;

        &::after {
          content: "";
          width: 16px;
          height: 16px;
          transform: rotate(180deg);
          transform-origin: center;
          background: url(https://akim-oss.aikucun.com/472b07b9fcf2c2451e8781e944bf5f77cd8457c8_1723201959358_68.png)
            center center no-repeat;
          background-size: 16px;
        }

        &.disable {
          opacity: 0;
        }
      }
    }

    .tableHeader {
      display: flex;
      flex-wrap: nowrap;
      margin-top: 15px;
      padding-bottom: 5px;
      color: #666666;
      font-size: 12px;

      .dayItem {
        display: flex;
        justify-content: center;
        width: 44px;
        flex-shrink: 0;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .row {
      display: flex;
      flex-wrap: nowrap;

      margin-top: 8px;

      .dateItem {
        position: relative;
        display: flex;
        justify-content: center;
        width: 44px;
        height: 55px;
        flex-shrink: 0;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }

        .dateItemBg {
          width: 44px;
        }

        .dateItemText {
          position: absolute;
          bottom: 2px;
          left: 0;
          width: 100%;
          height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
        }
      }

      .blankDateItem {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        box-sizing: border-box;
        padding-bottom: 2px;
        width: 44px;
        height: 55px;
        flex-shrink: 0;
        margin-right: 4px;
        border-radius: 6px;
        background: #f1f2f5;
        font-weight: bold;
        color: #a5a5a5;

        &:last-child {
          margin-right: 0;
        }
      }

      &:first-child {
        justify-content: flex-end;
      }
    }
  }
}
