@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.coupon_venue_popup {
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
    display: none;
  }

  box-sizing: border-box;
  background: transparent;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .coupon_content {
    position: absolute;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 330px;
    padding: 25px 8px 100px;
    border-radius: 20px;
    background: linear-gradient(112deg, #fc483f 5%, #fb8653 75%);
    z-index: 10;

    .icon_close {
      position: absolute;
      top: -26px;
      right: 10px;
      width: 16px;
      height: 16px;
      background: url("https://akim-oss.aikucun.com/mshop/b2cf17403df560247ff69f9fe21289289f05867c_1706766191965_25.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .coupon_title {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon_title1 {
        width: 110px;
        height: 26px;
        background: url("https://akim-oss.aikucun.com/mshop/3f9641d5b4b896e22a2a4ecb4b5df6699bf4106d_1706787763327_81.png")
          no-repeat;
        background-size: 100% 100%;
      }

      .icon_title2 {
        width: 82px;
        height: 26px;
        background: url("https://akim-oss.aikucun.com/mshop/cce7435a064478d60b87af3106f843466bd4b480_1706787781474_58.png")
          no-repeat;
        background-size: 100% 100%;
      }

      .amount {
        margin: 0 6px;
        font-family: "DIN-Bold";
        font-size: 28px;
        font-weight: bold;
        color: #f8f56a;
      }
    }

    .coupon_label {
      margin: 4px auto 2px;
      width: 236px;
      height: 18px;
      background: url("https://akim-oss.aikucun.com/mshop/5d4f2c04ce32072bac747075997dfe4ad8d925b3_1706788763807_4.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .coupon_btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 24px;
      width: 260px;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      color: #ed300a;
      font-weight: 500;
      text-align: center;
      border-radius: 6px;
      background: linear-gradient(172deg, #fbead3 6%, #ffdaaa 77%);
      cursor: pointer;
    }

    .coupon_icon {
      position: absolute;
      left: -16px;
      bottom: 64px;
      width: 370px;
      height: 88px;
      background: url("https://akim-oss.aikucun.com/mshop/c1c50bf5a6fe4eb61580701d39a2cb46796008b8_1707122132972_16.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .coupon_newcomer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 10px 13px;
      height: 200px;
      background: url("https://akim-oss.aikucun.com/mshop/f2899e7178e9c2a5b674a0fe3dacbaf31cc6f6cb_1706787538304_85.png")
        no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;

      &.couple {
        padding-left: 38px;
        padding-right: 38px;
      }

      &.single {
        height: 184px;
        padding-left: 14px;
        padding-right: 14px;
        padding-bottom: 16px;
        background: url("https://akim-oss.aikucun.com/mshop/71c80b2f8327ee62c856a49e61b97e0a05aeef42_1707116552893_71.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }

    .coupon_venue {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      align-items: flex-end;
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 10px 0;
      height: 162px;
      background: url("https://akim-oss.aikucun.com/mshop/298dd72b7ad9b46e80e09191aeb7bae4066fed5a_1706787334027_1.png")
        no-repeat;
      background-size: 100% 100%;

      &.single {
        height: 130px;
        padding-left: 14px;
        padding-right: 14px;
        padding-bottom: 14px;
        background: url("https://akim-oss.aikucun.com/mshop/7eb770389e71c48ca3befa93cffad2bd5c85d2e7_1708503680145_34.png")
          no-repeat;
        background-size: 100% 100%;
      }

      &.couple {
        padding-left: 38px;
        padding-right: 38px;
      }

      &.triple {
        padding-left: 13px;
      }

      .coupon_venue_list {
        display: flex;
        white-space: nowrap;
        overflow-x: auto;
      }

      .flex {
        display: flex;
      }
    }
  }
}
