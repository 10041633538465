@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@LIVING_BG: "https://akim-oss.aikucun.com/1ed8169947529695e141c8be7329dc4632094d4a_1709029217066_30.png";
@FORECAST_BG: "https://akim-oss.aikucun.com/d41a6ac937501319e9693a1de58500d0843bf5e8_1709029296530_95.png";

.container {
  padding: 12px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 10px;
  margin-bottom: 8px;
  background-color: #fff;
  .live-desc__content {
    z-index: 1;
    position: relative;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    min-height: 63px;
    color: #222222;
    margin-top: 11px;
    .content__logo {
      width: 61px;
      height: 61px;
      margin-right: 8px;
      background-color: #fff;
      overflow: hidden;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo__img {
        width: 92%;
        height: 92%;
      }
      .living__bd {
        border: 1px solid rgba(255, 71, 71, 0.3);
        border-radius: 6px;
      }
      .forecast__bd {
        border: 1px solid rgba(17, 201, 147, 0.3);
        border-radius: 6px;
      }
    }
  }
  .live-product {
    margin-top: 8px;
  }
}
.container__living {
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #fff, #fff), linear-gradient(360deg, #fdb0b0, #ffecec);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(@LIVING_BG);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.container__forecast {
  background-image: url(@FORECAST_BG);
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #ffffff;
}
.living-header {
  display: flex;
  align-items: center;
  z-index: 1;
  position: relative;
  .living-header__title {
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    .living__icon {
      background-color: #ff4747;
      display: flex;
      flex-direction: column;
      height: 16px;
      padding: 0 4px;
      justify-content: center;
      .item__list {
        transform: rotate(270deg);
        width: 8px;
        .icon__item {
          background-color: #fff;
          width: 8px;
          height: 2px;
          border-radius: 1px;
          &:nth-child(1) {
            animation: 0.3s linear 1s infinite alternate animation_item;
          }
          &:nth-child(2) {
            margin: 2px 0px;
            animation: 0.4s linear 1s infinite alternate animation_item;
          }
          &:nth-child(3) {
            animation: 0.5s linear 1s infinite alternate animation_item;
          }
          @keyframes animation_item {
            form {
              width: 8px;
            }
            to {
              width: 2px;
            }
          }
          @-webkit-keyframes animation_item {
            form {
              width: 8px;
            }
            to {
              width: 2px;
            }
          }
          @-moz-keyframes animation_item {
            form {
              width: 8px;
            }
            to {
              width: 2px;
            }
          }
        }
      }
    }
    .title__text {
      padding: 0 5px;
      background-color: #fff;
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      color: #ff4747;
    }
  }
  .living-header__code {
    margin-left: auto;
    padding: 5px 8px;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #ffffff;
    background-color: #ff4747;
    border-radius: 4px;
  }
}
.forecast-header {
  display: flex;
  height: 18px;
  .title {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 65px;
  }
  .time {
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    color: #11c993;
    background-color: rgba(17, 201, 147, 0.08);
    padding: 0 4px;
    border: 1px solid rgba(17, 201, 147, 0.16);
    border-left: unset;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
