@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.comp {
  display: flex;
  flex-direction: column;
  border-width: 10px;
  margin-bottom: 10px;

  .letterBg {
    position: relative;
    width: 100%;
    height: 84px;
    margin-top: -30px;
  }

  .c1 {
    background-color: #ffffff;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 10px;
  }

  .msg {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
    border: 0.5px solid #ffe7d2;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-bottom: 37px;
    padding-top: 10px;
    padding-left: 12px;
    padding-right: 12px;

    .tv1 {
      color: #8c4206;
      font-size: 16px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
    }

    .tv2 {
      color: #222222;
      font-size: 14px;
    }

    .tv3 {
      color: #ff4747;
      font-size: 14px;
    }
  }
}
