@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  background: linear-gradient(180deg, #fff0f0 58%, rgba(255, 240, 240, 0) 88%);
  .fixedBackIcon {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    width: 28px;
    height: 28px;
    background: url(https://akim-oss.aikucun.com/58e6b3a414a1e090dfc6029add0f3555ccba127f_1708952871126_11.png)
      center center no-repeat;
    background-size: 28px;
  }
  .floor {
    margin-bottom: 8px;
    padding: 0 12px;
    &:first-child {
      margin-top: 8px;
    }
    &.full {
      border-radius: 12px 12px 0px 0px;
      padding: 0;
      box-sizing: border-box;
      background: #ffffff;
    }
  }
}
