@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN";
  src: url("../../../assets/fonts/DIN-Bold.otf");
  /* 注意路径 */
}

.certificateBase {
  width: 100vw;
  height: 415px;
  box-sizing: border-box;
  background: url(https://akim-oss.aikucun.com/8d8ec86fff9905b24a71d9c96fb6ccc94d8df54e_1741829778996_47.png) no-repeat;
  background-size: contain;

  .transform {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    min-height: 10px;

    .certificateContent {
      text-align: center;
      padding-top: 12px;

      .certificateContentMain {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 4px;

        .titleWrapper {
          width: 240px;
          /* 减小宽度，确保有足够空间显示省略号 */
          padding: 0;
          margin: 0 auto;
          box-sizing: border-box;
          white-space: normal;
          /* 允许换行，这对于-webkit-line-clamp很重要 */
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          /* 限制为1行 */
          -webkit-box-orient: vertical;
          font-family: PingFang SC;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0px;
          font-variation-settings: "opsz" auto;
          background: linear-gradient(180deg, #FFFFFF 31%, #FFEED0 87%), #FFFFFF;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          /* text-fill-color: transparent; */
        }

        .title {
          font-family: PingFang SC;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0px;
          font-variation-settings: "opsz" auto;
          background: linear-gradient(180deg, #FFFFFF 31%, #FFEED0 87%), #FFFFFF;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          /* text-fill-color: transparent; */
          display: inline-block;
        }
      }

      .footer {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        .btn {
          width: 210px;
          height: 90px;
          background: url(https://akim-oss.aikucun.com/013e9807f6c66318857e20f96770a7ccffcecb86_1741779940403_34.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}

.certificateOther {
  width: 100vw;
  height: 435px;
  box-sizing: border-box;
  background: url(https://akim-oss.aikucun.com/bdfe6980076f862beabde25c9dddb32bfce763ab_1741829778978_91.png) no-repeat;
  background-size: contain;

  .transform {
    position: absolute;
    top: 42%;
    left: 0;
    width: 100%;
    min-height: 10px;

    .certificateContent {
      text-align: center;
      // margin-top: 16px;

      .title {
        font-family: PingFang SC;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        letter-spacing: 0px;
        font-variation-settings: "opsz" auto;
        color: #742810;
        padding: 0 30px;
        // 单行文本缩略
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 300px;
        box-sizing: border-box;
        margin: 0 auto;
      }

      .certificateContentMain {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: red;

        .brandWrapper {
          width: 240px;
          /* 减小宽度，确保有足够空间显示省略号 */
          margin: 0 auto;
          padding: 0;
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden;
          font-size: 22px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: normal;
        }

        .brandName {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 160px;
          font-size: 14px;
          /* 为品牌名称设置最大宽度，确保有足够空间显示"品牌赠送" */
        }

        .brandSuffix {
          white-space: nowrap;
          font-size: 14px;
          font-weight: 500;
        }

        .brand {
          font-size: 14px;
          font-weight: 500;
          display: inline-block;
        }
      }

      .footer {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-top: 8px;

        .btn {
          width: 210px;
          height: 90px;
          background: url(https://akim-oss.aikucun.com/953b135231737e234db2d886c67e1c2afb640b7f_1741779940399_55.png) no-repeat;
          background-size: 100% 100%;
          transform: translateY(10px);
        }
      }
    }
  }
}


.amount {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  line-height: 1;
}

.amountText {
  font-family: DIN;
  font-size: 74px;
  font-weight: bold;
  letter-spacing: 0px;
  font-variation-settings: "opsz" auto;
  background: linear-gradient(180deg, #FFFFFF 30%, #FFEED0 88%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  position: relative;
  transform: translateX(-12px);
}

.amountTextOther {
  font-family: DIN;
  font-size: 74px;
  font-weight: bold;
  letter-spacing: 0px;
  font-variation-settings: "opsz" auto;
  background: linear-gradient(180deg, #FF3616 0%, #FF3332 144%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  position: relative;
  transform: translateX(-12px);
}

.amountSuffix {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  letter-spacing: 0px;
}

.amountSuffixUnit {
  font-family: PingFang SC;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0px;
  font-variation-settings: "opsz" auto;
  background: linear-gradient(180deg, #FFFFFF 30%, #FFEED0 88%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.amountSuffixUnitOther {
  font-family: PingFang SC;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0px;
  font-variation-settings: "opsz" auto;
  background: linear-gradient(180deg, #FF3616 0%, #FF3332 144%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.amountSuffixText {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8C4206;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("https://akim-oss.aikucun.com/67e57cb2cffb68a15f4c28176c4e07bbb1f6cbb0_1741338029386_82.png");
  padding: 6px;
  -webkit-text-fill-color: #8C4206;
  /* text-fill-color: #8C4206; */
}