@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.filterContainer {
  //position: sticky;
  //top: 0;
  .filterBox {
    width: 100%;
    background: #ffffff;
    height: 40px;
    display: flex;
    justify-content: space-between;
    border-radius: 12px 12px 0 0;
    z-index: 10;
    position: absolute;
    .filterItem {
      height: 100%;
      width: 94px;
      font-size: 14px;
      color: #666666;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .item_active {
      font-weight: 500;
      color: #ff4747;
    }
  }
  .contentBox {
    width: 100%;
    height: fit-content;
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    z-index: 10;
    position: absolute;
    top: 40px;
  }
  .mask {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1;
  }
}
.priceBox {
  padding: 10px 12px 12px;
  .priceItemBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .priceItem {
      margin-right: 7px;
      width: 110px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f1f2f5;
      color: #666666;
      font-size: 12px;
      margin-bottom: 9px;
      border-radius: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .price_active {
      background: rgba(255, 71, 71, 0.16);
      border: 1px solid rgba(255, 71, 71, 0.5);
      width: 108px;
      height: 30px;
      font-weight: 500;
      color: #ff4747;
    }
  }
  .price_range {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 30px;
    .priceInput {
      border: unset;
      background: #f1f2f5;
      width: 160px;
      height: 32px;
      border-radius: 4px;
      color: #666666;
      text-align: center;
      &::placeholder {
        color: #cccccc;
        font-size: 12px;
        text-align: center;
      }
    }
    .price_line {
      width: 10px;
      height: 1px;
      background: rgba(34, 34, 34, 0.16);
    }
  }
  .price_bottom {
    display: flex;
    justify-content: space-between;
    .price_reset {
      width: 176px;
      height: 44px;
      background: rgba(255, 71, 71, 0.08);
      border: 1px solid #ff4747;
      font-size: 14px;
      font-weight: 500;
      color: #ff4747;
      border-radius: 6px 0 0 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .price_confirm {
      width: 176px;
      height: 44px;
      background: #ff4747;
      border: 1px solid #ff4747;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      border-radius: 0 6px 6px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.brandContainer {
  padding: 0 12px 12px;
  .brandBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 50vh;
    overflow-y: scroll;
    padding-bottom: 30px;
    .brandItem {
      width: 109px;
      height: 32px;
      font-size: 12px;
      border-radius: 4px;
      background: #f1f2f5;
      color: #666666;
      margin-right: 8px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .brandIcon {
        margin: 0 4px;
        width: 24px;
        height: 24px;
        border-radius: 4px;
      }
      .brandName {
        width: 77px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item_active {
      background: rgba(255, 71, 71, 0.16);
      border: 1px solid rgba(255, 71, 71, 0.5);
      font-weight: 500;
      color: #ff4747;
      width: 107px;
      height: 30px;
    }
  }
  .brandBottom {
    display: flex;
    .brandReset {
      width: 176px;
      height: 44px;
      background: rgba(255, 71, 71, 0.08);
      border: 1px solid #ff4747;
      font-size: 14px;
      font-weight: 500;
      color: #ff4747;
      border-radius: 6px 0 0 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .brandConfirm {
      width: 176px;
      height: 44px;
      background: #ff4747;
      border: 1px solid #ff4747;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      border-radius: 0 6px 6px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
