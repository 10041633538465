@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.comp {
  display: flex;
  flex-direction: row;
  padding: 12px;
  padding-top: 16px;
  padding-bottom: 0px;

  .card__image {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    align-self: center;
    opacity: 1;
    box-sizing: border-box;
    border: 1px solid rgba(34, 34, 34, 0.08);
  }

  .card__content {
    display: flex;
    flex: 1;
    margin-left: 8px;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    .content__title {
      display: flex;
      margin-bottom: 4px;
      width: 100%;
      overflow: hidden;

      .title__left {
        display: inline-block; /* 或者使用 display: block;，取决于你的需求 */
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 隐藏超出的内容 */
        text-overflow: ellipsis; /* 添加省略号 */
        overflow: hidden;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222222;
        flex-shrink: 1;
      }

      .title__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1px 4px;
        background: #ffd4b6;
        border-radius: 2px;
        margin-left: 6px;

        .right__title {
          font-family: PingFang SC;
          font-size: 10px;
          font-weight: 500;
          display: flex;
          align-items: center;
          color: #8c4206;
        }
        flex-shrink: 0;
      }
    }

    .content__desc {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #666666;
    }
  }
}
