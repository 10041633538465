@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.address_list {
  padding-top: 16px;

  .count_down {
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #a5a5a5;
    text-align: center;

    .time {
      color: #ff4747;
    }
  }
  .tips {
    padding-top: 12px;
    .tips__item {
      padding-bottom: 6px;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 0px;
      color: #8c4206;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .title {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title__left {
      flex-shrink: 0;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 0px;
      color: #a5a5a5;
    }
    .title__right {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: #ff4747;

      &::before {
        content: "";
        margin-right: 2px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url("https://akim-oss.aikucun.com/mshop/c07b50f4a8c59f9fbe1aa006360948daabb94bf5_1714990094311_49.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }
    }
  }
  .list {
    padding-top: 12px;
  }
  // .submit {
  //   margin: 16px auto 0 auto;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 180px;
  //   height: 45px;
  //   line-height: 22px;
  //   background: #ff4747;
  //   border-radius: 6px;
  //   font-family: PingFang SC;
  //   font-size: 16px;
  //   font-weight: 500;
  //   color: #fff;
  // }
}
