@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.previewContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 1000;
  opacity: 1;
  background: rgba(255, 255, 255, 0.01);
  font-size: 14px;

  .closeBox {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 136px;

    .closeBtmImg {
      width: 24px;
      height: 24px;
    }
  }

  .counter {
    color: #fff;
    font-size: 14px;
  }
}

.mediaContent {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mediaWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.mediaSlider {
  display: flex;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.mediaItem {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.media {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
  pointer-events: auto !important;
  touch-action: auto !important;
  -webkit-touch-callout: default !important;
  user-select: auto !important;
}

.mediaVideo {
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.contextList {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 36px 16px 42px 16px;
  box-sizing: border-box;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 22%, rgba(0, 0, 0, 0) 100%);
  z-index: 1000;

  .contextText {
    width: 100%;
    color: #fff;
    font-size: 14px;
    margin-bottom: 14px;
    word-break: break-all;
  }

  .contextBtnBox {
    display: flex;
    justify-content: flex-end;

    .btnBox {
      padding: 0 8px;
      height: 26px;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #FFF;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
    }

    .btnIcon {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
}

.imageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}