@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  background-color: #f1f2f5;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .loading {
    position: absolute;
    top: 30%;
    left: 50%;
    align-content: center;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }

  .headerBg {
    position: absolute;
    display: flex;
    width: 100%;
    height: auto;
    z-index: -1;
  }

  .container {
    overflow: auto;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 110px;
    -webkit-overflow-scrolling: touch;
    z-index: 2;

    .topC {
      display: flex;
      margin-top: 20px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .logo {
        height: 30px;
        width: 30px;
        border-radius: 4px;
        border: 1px solid rgba(34, 34, 34, 0.08);
      }
    }
    .topTitle {
      display: flex;
      font-family: PingFang SC;
      font-size: 22px;
      font-weight: 500;
      color: #222222;
      margin-top: 8px;
      align-self: center;
    }

    .inviteHeader {
      display: flex;
      flex-direction: column;
      border-width: 10px;
      margin-top: 3px;
      margin: 0 12px;

      .headerBg {
        position: relative;
        width: 100%;
        height: 84px;
        margin-top: -30px;
        z-index: 4;
      }

      .c1 {
        background-color: #ffffff;
        margin-left: 4px;
        margin-right: 4px;
        border-radius: 10px;
        margin-top: 10px;
      }

      .msg {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 8px;
        border: 0.5px solid #ffe7d2;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        padding-bottom: 37px;
        padding-top: 10px;
        padding-left: 12px;
        padding-right: 12px;
        .tv1 {
          color: #8c4206;
          font-size: 16px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
        }
        .tv2 {
          color: #222222;
          font-size: 14px;
          margin-top: 4px;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      border-radius: 10px;
      margin-top: 10px;
      margin-left: 12px;
      margin-right: 12px;

      .info__line {
        margin: 16px 12px;
        background-color: #22222214;
        height: 0.5px;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      .tv1 {
        color: #222222;
        font-size: 16px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        padding-bottom: 8px;
      }
      .contentC {
        margin-top: 6px;
        margin: 0 12px;
      }
    }
  }

  .emptyC {
    overflow: auto;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 12px;
    -webkit-overflow-scrolling: touch;

    .topC {
      display: flex;
      margin-top: 20px;
      flex-direction: row;
      align-items: center;
      .logo {
        display: flex;
        height: 30px;
        border-radius: 4px;
        padding: 1px 1px;
        background-color: #ffffff;
      }
    }

    .topTitle {
      display: flex;
      font-family: PingFang SC;
      font-size: 22px;
      font-weight: 500;
      color: #222222;
      margin-top: 20px;
      text-align: center;
      white-space: pre-wrap;
    }

    .topTitle1 {
      display: flex;
      font-family: PingFang SC;
      font-size: 22px;
      font-weight: 500;
      color: #222222;
      margin-top: 8px;
      white-space: pre-wrap;
    }

    .c1 {
      background-color: #ffffff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 100%;
      height: 100%;
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .empty {
      width: 120px;
      height: 120px;
      margin-top: 100px;
    }

    .tv1 {
      color: #666666;
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 8px;
      text-align: center;
      white-space: pre-wrap;
    }

    .action1 {
      color: #222222;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid #22222229;
      padding: 5px 20px;
      border-radius: 4px;
      margin-top: 16px;
    }
  }

  .bottom {
    z-index: 3;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 56px;
    display: flex;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #ffffff;

    .actionC {
      width: 100vw;
      margin-left: 12px;
      margin-right: 12px;
      margin-top: 6px;
      margin-bottom: 6px;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .actionTv1 {
      color: #ff4747;
      flex: 1;
      height: 100%;
      font-size: 16px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      background-color: #ff474714;
      margin-right: 4.5px;
      text-align: center;
      align-content: center;
      border: 1px solid #ff4747;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .actionTv2 {
      margin-left: 4.5px;
      flex: 1;
      height: 100%;
      background-color: #ff4747;
      border-radius: 6px;
      flex-direction: column;
      justify-content: center;
      display: flex;
      align-content: center;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      font-family: PingFangSC-Medium;
    }
  }

  .error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 160px;
    align-items: center;
    background: #fff;
    .mask1 {
      position: absolute;
      top: 0;
      width: 100%;
      height: 108px;
      z-index: 10;
    }
    .mask2 {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 36.95%;
      z-index: 10;
    }
    img {
      width: 184px;
      height: 184px;
    }
    .errorText {
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      color: #666666;
      padding-bottom: 12px;
    }
    .retry {
      border-radius: 4px;
      opacity: 1;
      box-sizing: border-box;
      border: 1px solid rgba(34, 34, 34, 0.16);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 96px;
      height: 32px;
      color: #222;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
