@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.addPurchasePanel {
  width: 100vw;
}

:root {
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

.imageMask {
  z-index: 20000;
}