@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.popup_wrapper {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  user-select: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // .popup_mask {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.7);
  //   opacity: 0.7;
  // }

  .popup_content {
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .image {
      border-radius: 12px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .text {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: right;
      letter-spacing: 0px;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;

      &::before {
        width: 21px;
        height: 21px;
        background: url("https://akim-oss.aikucun.com/1b4148d1babd98d53b08b3a09f5fe8d460e704e6_1669111122655_99.png")
          center center no-repeat;
        background-size: 21px;
        margin-right: 3px;
        content: "";
        display: inline-block;
      }
    }
  }
}
