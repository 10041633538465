@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.taskItemGroup {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 8px;
}

.taskItemTop {
  position: relative;
  margin-bottom: -10px;
  padding: 5px 12px 15px;
  font-family: PingFang SC;
  font-size: 10px;
  font-weight: normal;
  line-height: 14px;
  color: #fff6f6;
  border-radius: 10px 10px 0 0;
  background: #5a76b0;
  z-index: 1;

  & + .taskItemBadge {
    top: 18px;
  }

  &+.taskItemNewBadge {
    top: 18px;
  }
}

.taskItemNewBadge {
  position: absolute;
  top: -6px;
  right: 0;
  font-family: PingFang SC;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  z-index: 3;
}

.taskItemBadge {
  position: absolute;
  top: -6px;
  right: 0;
  padding: 2px 6px;
  font-family: PingFang SC;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  border-radius: 4px 4px 0 4px;
  background-color: #ff4747;
  z-index: 3;

  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -6px;
    box-sizing: border-box;
    width: 6px;
    height: 6px;
    background-color: #ff4747;
    border-left: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
  }

  &::after {
    border-radius: 0 6px 0 0;
    background: #ffffff;
  }
}

.taskItemBox {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px rgba(248, 248, 248, 0.5542);
  z-index: 2;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 200%;
    height: 200%;
    border: 1px solid #f0f0f0;
    border-radius: 20px;
    transform: scale(0.5);
    transform-origin: left top;
    pointer-events: none;
  }
}

.taskItemContent {
  position: relative;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  background: #ffffff;
  overflow: hidden;

  & > * {
    position: relative;
    z-index: 2;
  }
}

.taskItemEquity {
  position: relative;
  border-radius: 10px;

  .taskItemBadge::before {
    border-left: 1px solid #ffedcf;
    border-bottom: 1px solid #ffedcf;
  }

  .taskItemBadge::after {
    background: #ffedcf;
  }

  .taskItemContent {
    background: linear-gradient(247deg, #ffedcf 0%, #ffc99d 98%, #ffd1ab 98%);
  }

  .taskItemContent::before {
    content: "";
    position: absolute;
    left: -38px;
    bottom: -82px;
    width: 124px;
    height: 124px;
    opacity: 1;
    border-radius: 100%;
    background: linear-gradient(230deg, #ffc08f 25%, #ffebca 70%, #ffd1ab 122%);
    z-index: 1;
  }

  .taskItemContent::after {
    content: "";
    position: absolute;
    left: 27px;
    top: -96px;
    width: 195px;
    height: 195px;
    opacity: 0.6475;
    border-radius: 100%;
    background: linear-gradient(230deg, #ffc08f 25%, #ffebca 70%, #ffd1ab 122%);
    z-index: 1;
  }

  .taskItemBox {
    background: none;
    box-shadow: none;

    &::after {
      border-color: #ffffff;
    }
  }

  .taskItemCountDown > div {
    background: #ffffff;
  }
}

// 活动顶部 品牌信息布局样式
.taskItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  height: 30px;
  font-family: "苹方-简";
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #666666;

  .arrowIcon {
    display: inline-block;
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }

  & > *:first-child {
    flex: 1;
    width: 0;
    margin-right: 12px;
    display: flex;
    align-items: center;
  }

  & > *:last-child {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }

  .taskItemBrandList {
    flex: 0 0 auto;
    display: flex;
    margin-right: 8px;
  }

  .taskItemBrand {
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid rgba(34, 34, 34, 0.08);
    background-color: #ffffff;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    & + .taskItemBrand {
      margin-left: -12px;
    }
  }

  .taskItemBrandMore {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #222222;
  }

  .taskItemBrandName {
    flex: 1;
    width: 0;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #222222;
    // 单行文本溢出...
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// 活动主体内容布局样式
.taskItemLeft {
  flex: 0 0 auto;
  margin-right: 8px;

  .logo-list {
    position: relative;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    .item {
      position: relative;
      width: 48px;
      height: 48px;
      background: #fff;
      border: 1px solid rgba(34, 34, 34, 0.08);
      object-fit: contain;
      border-radius: 6px;
      box-sizing: border-box;
    }
  }
}

.taskItemCover {
  width: 100px;
  height: 75px;
  border-radius: 4px;
  overflow: hidden;

  // background-color: #eee;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.taskItemCover + div {
  margin-top: 8px;
  width: 100px;
  display: flex;
  justify-content: center;
  font-family: PingFang SC;
  font-size: 10px;
  font-weight: normal;
  line-height: 14px;
  color: #a5a5a5;
}

.taskItemRight {
  flex: 1;
  width: 0;
  display: flex;
  flex-direction: column;

  button {
    margin-top: 8px;
    margin-left: auto;
    width: 102px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }
}

.taskItemInfo {
  width: 100%;
  min-height: 60px;

  .taskItemTitle {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #222222;
    // 多行文本溢出...
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .taskItemDesc {
    margin-top: 4px;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #666666;
    // 单行文本溢出...
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.taskItemProgress {
  color: #222222;

  .taskItemProgressTitle {
    margin-top: 7px;
    margin-bottom: 8px;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
  }

  .taskItemProgressInfo {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: normal;
    line-height: 14px;

    & > *:first-child {
      color: #ff4747;
    }
  }
}

.taskItemReward {
  margin-top: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #ff4747;
  line-height: 22px;

  // & > *:first-child {
  //   display: flex;
  //   align-items: center;
  // }
  svg {
    display: inline-block;
    /* 让SVG对象可以像文本一样被处理 */
    vertical-align: sub;
    // margin-top: 2px;
    // margin-right: 4px;
  }

  .rewardNum {
    font-family: "DIN-Medium";
    font-weight: 700;
  }
}

.taskItemCountDown {
  display: flex;
  align-items: center;
  font-family: "苹方-简";
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  text-align: right;
  color: #666666;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0 4px;
    padding: 0 2px;
    min-width: 18px;
    height: 18px;
    border-radius: 2px;
    font-family: "DIN-Medium";
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    color: #ff4747;
    text-align: center;
    background: #f1f2f5;
  }
}
