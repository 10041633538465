@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

  opacity: 1;
  background: linear-gradient(180deg, #FFFFFF 5%, #F1F2F5 26%);
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.0134);

  position: relative;
}

// .noBounce {
//   overflow: hidden; // 禁用滚动
//   position: fixed; // 固定位置
//   height: 100%; // 设置高度为 100%
//   width: 100%; // 设置宽度为 100%
// }

.noBounce {
  overscroll-behavior-y: none; // 禁止反弹
  overflow: hidden; // 禁止溢出
  -webkit-overflow-scrolling: touch; // 确保 iOS 上的平滑滚动
}

.noBottomBounce {
  overscroll-behavior-y: contain; // 或者使用其他样式来控制弹起效果
}


.bottomBtnTextMoney {
  color: #FFFFFF;
  border-radius: 6px;
  opacity: 1;
  background: #FF4747;
  /* 品牌色/#FF4747_AKC红 */
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  margin-top: 6px;
  z-index: 189;
  margin-left: 12px;
  margin-bottom: 10px;
  margin-right: 12px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(255, 71, 71, 0.3);

  &:active {
    transform: scale(0.98);
    background: #FF4747;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    background: url('https://akim-oss.aikucun.com/eeaaa563822b7c0c875f3c49e65c48e966db9716_1742009858312_9.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
}


.bottomBtnText {
  color: #FFFFFF;
  border-radius: 6px;
  opacity: 1;
  background: #FF4747;
  /* 品牌色/#FF4747_AKC红 */
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  margin-top: 6px;
  margin-left: 12px;
  margin-bottom: 10px;
  margin-right: 12px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(255, 71, 71, 0.3);

  &:active {
    transform: scale(0.98);
    background: #FF4747;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    background: url('https://akim-oss.aikucun.com/7716474716fc755c73519708fdb2f0ae293b5564_1742010830638_25.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
}


.bottomBtnBg {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  /* 自动布局 */
  flex-direction: row;
  // align-items: center;
  z-index: 100;
}


.bottomBtn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #FFFFFF;
  display: flex;
  /* 自动布局 */
  flex-direction: row;
  // align-items: center;
  z-index: 100;
}

.scrollable_content {
  overflow-x: hidden;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  /* 只允许垂直滚动 */
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(180deg, #FFFFFF 5%, #F1F2F5 26%);
}


.loading {
  width: 100vw;
  // height: 100vh-200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
}

.scrollable_content_loading {
  overflow-x: hidden;
  position: fixed;
  top: 130px;
  margin-bottom: 60px;
  justify-content: center;
  /* 留出顶部固定元素的空间 */
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  /* 只允许垂直滚动 */
  -webkit-overflow-scrolling: touch;
  /* 平滑滚动效果 */
  /* 确保在背景之上，但在其他固定元素之下 */
  // background: #F1F2F5;
  background: linear-gradient(180deg, #FFFFFF 5%, #F1F2F5 26%);

  /* 添加顶部空间，默认值，具体值在JS中动态设置 */
  padding-top: 10px;
  transition: padding-top 0.3s ease;
}


.bg_img {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
  height: 68px;
  background-image: url(https://akim-oss.aikucun.com/26ca19ab0172fd9078ab06dcedc0fa883776f9b3_1742366747177_20.png);
  background-position: top left;
  background-size: 100vw 68px;
  background-repeat: no-repeat;
}


.bg_imgapp {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
  height: 102px;
  background-image: url(https://akim-oss.aikucun.com/8f9cf81b949944ba315d147ce7d3532fe42a72d0_1742462693561_23.png);
  background-position: top left;
  background-size: 100vw 102px;
  background-repeat: no-repeat;
}

.shop_headerapp {
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;

  .header_icon_left {
    width: 12px;
    height: 24px;
  }

  .header_titleapp {
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.04em;
    font-variation-settings: "opsz" auto;
    background: linear-gradient(180deg, #FFFFFF 6%, #FFE7C9 105%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-left: 10px;
    margin-right: 10px;
  }

  .header_icon_right {
    width: 12px;
    height: 24px;
  }
}

.shop_header {
  position: fixed;
  top: 20px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;

  .header_icon_left {
    width: 12px;
    height: 24px;
  }

  .header_title {
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.04em;
    font-variation-settings: "opsz" auto;
    background: linear-gradient(180deg, #FFFFFF 6%, #FFE7C9 105%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-left: 10px;
    margin-right: 10px;
  }

  .header_icon_right {
    width: 12px;
    height: 24px;
  }
}

.tabs {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 40px;
  box-sizing: border-box;

  .back {
    padding-left: 12px;
    padding-right: 12px;
    width: 24px;
    height: 24px;
  }

  .btn {
    position: absolute;
    right: 0;
    // top: 10px;
    width: 40px;
    height: 40px;
    z-index: 10;
    /* 增加z-index确保按钮显示在最上层 */
    border-top-right-radius: 12px;
  }

  .tadDiv {

    width: 100vw;
    height: 40px;
    z-index: 2;

    .line11 {
      border-bottom: 1px solid #f1f2f5;
    }

    .tabLine {
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* IE 10+ */
      position: relative;
      overflow-y: hidden;
      overflow-x: auto;
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      width: 100vw;
      height: 100%;
      background: #fff;
      padding-bottom: 5px;
      //
      transition: all 0.3s ease;
      /* 添加底部空间，为下划线留出空间 */

      &::-webkit-scrollbar {
        display: none;
        /* Chrome Safari */
      }

      .tabTile {
        display: flex;
        width: 100vw;
        flex-direction: row;
      }
    }

    .tab {
      white-space: nowrap;
      flex-shrink: 0;
      font-size: 16px;
      color: #222222;
      // margin-right: 20px;
      position: relative;

      .text {
        position: relative;
        text-align: center;
        z-index: 2;
      }

      /* 当Tab被选中时，文字变为红色 */
      &.curTab {
        /* 正文，长文本_中黑 */
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0px;
        /* 文字色/#222222_主要文字色 */
        /* 样式描述：用于标题，主要信息 */
        color: #222222;
      }

      /* 当Tab被选中时，文字变为红色 */
      &.uncurTab {
        /* 次要内容_常规 */
        font-family: pingFang SC;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0px;
        /* 文字色/ct2_#666666_次要文字色 */
        color: #666666;
      }

    }

    .tab:first-child {
      margin-left: 16px;
    }

    .tab:last-child {
      padding-right: 25px;
    }

    .curTab {
      font-size: 16px;
      font-weight: 500;
    }

    .uncurTab {
      /* 次要内容_常规 */
      font-family: pingFang SC;
      font-size: 14px;
      margin-top: 2px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      /* 文字色/ct2_#666666_次要文字色 */
      color: #666666;
    }

    .selectedLineContainer {
      height: 2px;
      position: absolute;
      margin-top: 5px;
      left: 0;
      right: 0;
      width: 100%;
      pointer-events: none;
      /* 确保下划线不影响点击事件 */

      .selectedLine {
        background: #FF4747;
        height: 100%;
        width: 32px;
        /* 使用部分宽度，使其看起来更好看 */
        margin: 0 auto;
      }
    }
  }
}


/* 吸顶状态下的tadDiv样式 */
.stickyTadDiv {
  background: #fff;
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.popBottom {
  position: fixed;
  background-color: #FF4747;
}

.tabModal {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: calc(100vh - 130px);
  left: 0;
  z-index: 200;
  // transition: top 0.3s ease;

  /* 确保弹框在滚动内容之上 */
  .topline {
    position: relative;
    bottom: 0px;
    height: 10px;
    width: 100%;
    z-index: 2222;
    background-color: #194be0;
  }

  .content {
    background-color: #fff;
    padding: 14px 6px 5px 16px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    display: flex;
    flex-wrap: wrap;
    max-height: calc(100vh - 130px);
    overflow-y: auto;

    .category {
      width: 80px;
      height: 28px;
      border-radius: 4px;
      background: #F1F2F5;
      color: #666666;
      font-family: PingFang SC;
      font-weight: 500;
      // border: 0.5px solid #cccccc;
      margin-bottom: 9px;
      margin-right: 8px;
      display: flex;
      padding: 0 4px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 8px;
        /* 辅助内容_中黑 */
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0px;
        /* 背景色/cg3_#FFFFFF_卡片 */
        color: #FFFFFF;
      }


      .untext {

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 8px;
        /* 辅助内容_中黑 */
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0px;
        /* 背景色/cg3_#FFFFFF_卡片 */
        color: #666666;
      }

    }

    .category_selected {
      background-color: #ff4747;
      color: #ffffff;
    }

    .uncategory_selected {
      background-color: #F1F2F5;
      color: #666666;
    }
  }
}

.loading_box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  margin: auto;
  // width: 100px;
  // height: 100px;
  // background: rgba(0, 0, 0, 0.3);
  // border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;

  .loading_icon {
    animation: rotate 5s linear infinite;
    -webkit-animation: rotate 5s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    25% {
      transform: rotate(90deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(270deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


}