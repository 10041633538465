@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.resItemTxt {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  color: #222222;
  margin: 12px 12px 0px 12px;
  white-space: pre-wrap;
}
.copy {
  margin-left: 2px;
  padding: 0 3px;
  position: relative;
  bottom: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  font-size: 10px;
  letter-spacing: 0px;
  font-family:
    PingFangSC-Regular,
    PingFang SC;
  font-weight: 400;
  color: #666666;
}
.copy::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  border: 0.5px solid #ccc;
  border-radius: 2px;
  transform-origin: 0 0;
  transform: scale(0.5);
}
