@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.activityDescWrap {
  display: flex;
  flex-direction: column;
  background-color: #f1f2f5;
  flex: 1;
  border-radius: @bigBorderRadius;
  .activityDescCont {
    display: flex;
    // padding-bottom: 8px;
    flex-direction: column;
  }
}
.popupContainer {
  padding-bottom: calc(0px + env(safe-area-inset-bottom));
  .popupHeader {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium;
    text-align: center;
    color: #333;
    height: 42px;
    line-height: 42px;
    .popupClose {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 22px;
      height: 22px;
      background: url("https://akim-oss.aikucun.com/mshop/3ab0928e68161f5d2b9e2c1ebcdc8d2c335089b1_1707014108466_77.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .popupContent {
    max-height: 500px;
    overflow-y: scroll;
  }
}
.servicePopup {
  background: linear-gradient(0deg, #fff0e4 78%, #ffdbbe 99%, #ffd1ab 99%);
  border-radius: 12px 12px 0px 0px;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 158px;
    border-radius: 12px 12px 0px 0px;
    background: url("https://akim-oss.aikucun.com/mshop/a923ad06661eb74230c718a02e93ba28c7a8a715_1723786804152_94.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .popupHeader {
    position: relative;
    z-index: 9;
    height: 56px;
    line-height: 56px;
    color: #222222;
    .popupClose {
      top: 20px;
      right: 16px;
      width: 16px;
      height: 16px;
      background: url("https://akim-oss.aikucun.com/mshop/bc69c00fdb50e13bcc782013a46f438a8471f41b_1723776741969_86.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .popupContent {
    position: relative;
    z-index: 10;
    min-height: 34vh;
    .btmd_item_title {
      color: #222222;
    }
  }
}
:global(.dialog_hide) {
  :global(.adm-auto-center-content) {
    color: #5e5b5b;
  }
  :global(.adm-dialog-button) {
    color: #666 !important;
  }
  :global(.adm-dialog-button-bold) {
    color: #333 !important;
  }
}
