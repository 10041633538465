@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.navigationWrap {
  position: fixed;
  z-index: 999; // TODO 需规范下 当前值太高
  width: 50px;
  background: linear-gradient(180deg, #ff7346 0%, #ff2b2b 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
  border-radius: 25px;
  color: #333;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    width: 40px;
    height: 50px;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    line-height: 14px;
    border-bottom: 1px solid rgba(238, 238, 238, 0.41);

    img {
      width: 24px;
      height: 24px;
    }
  }

  .box:last-child {
    border-bottom: none;
  }

  .topBoxMargin {
    margin-bottom: 40px;
    border: none;
  }

  .cartBox {
    .relative {
      position: relative;

      .tag {
        position: absolute;
        top: 0%;
        right: -30%;
        padding: 0 3px;
        height: 17px;
        line-height: 14px;
        text-align: center;
        background-color: #fff;
        border-radius: 9px;
        font-size: 10px;
        font-weight: 500;
        color: #ff302d;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .br50 {
        width: 17px;
        padding: 0;
        border-radius: 50%;
      }
    }
  }
}
