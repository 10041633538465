@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.productCard {
  padding: 8px;
  display: flex;
  background: #f7f8f9;
  border-radius: 10px;

  .cardLeft {
    position: relative;
    flex-shrink: 0;
    width: 82px;
    height: 82px;
    border-radius: 6px;
    overflow: hidden;

    .mainPic {
      width: inherit;
      height: inherit;
    }

    .commission {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0 4px;
      display: flex;
      align-items: center;
      height: 16px;
      background: #ffd4b6;
      border-radius: 2px;
      font-family: "DIN-Bold";
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      color: #8c4206;
    }
  }

  .cardRight {
    padding-left: 8px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .labelBox {
    }
    .priceBox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .boxLeft {
        display: flex;
        align-items: baseline;

        .price {
          font-family: "DIN-Bold";
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          color: #ff4747;

          &::before {
            content: "¥";
            font-family: "DIN-Bold";
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #ff4747;
          }
        }
        .tagPrice {
          margin-left: 4px;
          font-family: "DIN-Bold";
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #a5a5a5;
          text-decoration: line-through;

          &::before {
            content: "¥";
            font-family: "DIN-Bold";
            font-size: 12px;
            font-weight: bold;
            line-height: 16px;
            color: #a5a5a5;
          }
        }
      }
      .boxRight {
        .btn {
          padding: 0 8px;
          display: flex;
          align-items: center;
          height: 26px;
          background: #ff4747;
          border-radius: 4px;
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #fff;
        }
      }
    }
  }
}
