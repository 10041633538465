@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.popup__box {
  padding-bottom: 50px;

  .box__header {
    position: relative;
    padding: 16px 0;

    .header__title {
      text-align: center;
      font-family: PingFang SC;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: #222;
    }
    .header__icon {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 16px;
      height: 16px;
    }
  }

  .box__body {
    padding: 0 12px;

    .body__item {
      margin-bottom: 8px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      background: #f6f7fa;
      border-radius: 10px;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: #222;

      .item__btn {
        align-self: flex-end;
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 24px;
        background: #fff0ef;
        border-radius: 4px;
        border: 0.5px solid #ff312f;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        color: #ff302d;
      }
    }
  }
}
