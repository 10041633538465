@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.material-card {
  display: flex;

  .card__left {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    overflow: hidden;

    .left__cover {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
  .card__right {
    flex-grow: 1;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .right__up {
      .product-title {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }
      .material__data {
        padding-top: 2px;
        display: flex;
        align-items: center;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        color: #666;

        .data__buyer {
          display: flex;
          align-items: center;

          .buyer__avatars {
            position: relative;

            .avatar {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border: 1px solid #ffffff;
            }
            .avatar--negative {
              margin-left: -4px;
            }
          }
          .buyer__desc {
            padding: 0 4px;
          }
        }

        .data__browse-person {
          position: relative;
        }
        .data__browse-person--both {
          padding-left: 6px;

          &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            content: "";
            width: 0;
            height: 10px;
            border: 0.5px solid rgba(34, 34, 34, 0.16);
          }
        }
      }
    }
    .right__down {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price-box {
        display: flex;
        align-items: center;

        .price {
          display: flex;
          align-items: baseline;
          font-family: DIN;
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          color: #ff4747;

          &::before {
            content: "¥";
            font-family: DIN;
            font-size: 13px;
            font-weight: bold;
            line-height: 16px;
            color: #ff4747;
          }
        }
        .commission {
          margin-left: 4px;
          padding: 0 4px;
          display: flex;
          align-items: center;
          height: 16px;
          background: #ffd4b6;
          border-radius: 2px;
          font-family: DIN;
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
          color: #8c4206;
        }
      }
      .btn__share {
        padding: 0 8px;
        display: flex;
        align-items: center;
        height: 24px;
        border: 1px solid #ff4747;
        border-radius: 4px;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #ff4747;
      }
    }
  }
}
