@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.recommend {
  margin-top: 10px;
  margin-left: -12px;
  margin-right: -12px;
  .recommend-header {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    .recommend-header__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      margin: 0 8px;
      position: relative;
      width: fit-content;
      color: #333333;
    }
    .recommend-header__icon {
      width: 10px;
      height: 9px;
    }
  }
  .skeleton-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 10px;
    .customSkeleton {
      --width: 48%;
      --height: 300px;
      --border-radius: 8px;
      margin-bottom: 20px;
    }
  }
}
