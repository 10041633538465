@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.popupContainer {
  width: 100%;
  height: 85vh;
  background-color: #F1F2F5;
  border-radius: 12px 12px 0 0;
}

.popupHeader {
  position: relative;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 12px 12px 0 0;

  .title {
    color: #222222;
    font-size: 18px;
    font-weight: 500;
  }

  .closeIcon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background: url('https://akim-oss.aikucun.com/6c14bd7f6f9e54e46024d35dfe2c4548c9a3821d_1731921628452_65.png') no-repeat center;
    background-size: 16px;
  }
}

.tabWrapper {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  padding: 0 5px;
  background-color: #fff;

  .tabItem {
    position: relative;
    margin-right: 24px;
    font-size: 14px;
    color: #666666;
    line-height: 36px;

    &.active {
      color: #222222;
      font-size: 16px;
      font-weight: 500;
    }

    &.active:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 5px;
      width: 100%;
      height: 2px;
      background: #FF4747;
      border-radius: 1px;
    }
  }
}

.contentList {
  padding: 0 10px;
  max-height: 70vh;
  overflow-y: auto;
  padding-bottom: 32px;
  box-sizing: border-box;

  .contentItem::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(90deg, #645DFF 0%, #B77DFF 100%);
    opacity: 0.5;
    border-radius: 50px 0 0 50px;
  }

  .contentItem {
    position: relative;
    padding: 14px 12px 0 16px;
    background: #fff;
    border-radius: 10px;
    margin-top: 8px;
    font-size: 14px;
    line-height: 1.5;

    .contentText {
      color: #222;
      font-size: 14px;
      line-height: 22px;
      white-space: pre-wrap;
      /* 保留空格，允许换行 */
      word-wrap: break-word;
      /* 长单词断行 */
      word-break: break-all;
      /* 强制断行，即使单词内部 */
    }

    .contentBottom {
      position: relative;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;

      .usageCount {
        font-size: 12px;
        color: #999;
        display: flex;
        align-items: center;
      }

      .usageIcon {
        width: 17px;
        height: 12px;
        margin-right: 4px;
      }

      .copyBox {
        position: absolute;
        right: 0px;
        top: 12px;
        width: 62px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid rgba(34, 34, 34, 0.16);

        .copyBtn {
          font-size: 12px;
          color: #222;
          line-height: 28px;
        }

        .copyIcon {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
      }
    }
  }
}