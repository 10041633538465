@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
    position: fixed;
    left: 0;
    right: 0;
    padding: 8px 12px;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out;
    touch-action: pan-y;
    animation: slideIn 0.3s ease-out forwards;

    &.leave {
        animation: slideOut 0.3s ease-in forwards;
    }
}

.content {
    width: 100%;
    height: 44px;
    background: #fff;
    border-radius: 12px;
    padding: 0 10px 0 14px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 12px 0px rgba(5, 0, 56, 0.2);

    .description {
        display: flex;
        align-items: center;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #222222;

        .icon {
            width: 40px;
            height: 12px;
            margin-right: 8px;
        }
    }

    .packet {
        position: relative;
        background: url('https://akim-oss.aikucun.com/eeb720ec6f81085954371b1233237ae89c5c0460_1733725967223_22.png') no-repeat;
        background-size: 100% 100%;
        width: 32px;
        height: 30px;
        display: flex;
        justify-content: center;
        padding-top: 4px;
        box-sizing: border-box;

        .packetIcon {
            width: 4px;
            height: 10px;
            font-size: 7px;
            text-align: center;
            font-weight: bold;
            line-height: 10px;
            color: #FF3B30;
            margin: 2px 2px 0 0;
        }

        .packetText {
            font-family: DIN;
            font-size: 10px;
            font-weight: bold;
            line-height: 14px;
            letter-spacing: 0px;
            color: #FF3B30;
        }


    }
}


@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
}