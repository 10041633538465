@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.info__header {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  padding-bottom: 10px;

  .header__title {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #8c4206;
  }

  .header__desc {
    width: 100%;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: normal;
    color: #666666;
    padding-top: 8px;
    display: flex;
    white-space: pre-wrap;
    word-break: break-all;
  }
}
