@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.header {
  height: 200px;
  width: 100%;
  background-size: cover;
  margin-top: -44px;
  font:
    0.24rem/150% tahoma,
    arial,
    Microsoft YaHei,
    Hiragino Sans GB,
    "u5b8bu4f53",
    sans-serif;

  .headerContainer {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    box-sizing: border-box;
    padding-top: 60px;

    .brandCard {
      margin: 0 auto;
      box-sizing: border-box;
      padding: 8px;
      border-radius: 10px;
      width: 351px;
      height: 76px;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
      display: flex;
      flex-direction: row;
      align-items: center;

      .brandLogo {
        width: 60px;
        height: 60px;
        border-radius: 6px;
        margin-right: 6px;
        background-size: contain;
        background-color: white;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        &::after {
          content: "正品保障";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 16px;
          line-height: 16px;
          text-align: center;
          font-size: 10px;
          font-weight: 500;
          color: #06b581;
          background: #cdf0e6;
          border-radius: 0px 0px 6px 6px;
          z-index: 1;
        }
      }

      .brandText {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: auto;
        height: 100%;
        flex: 1;
        padding-right: 12px;
        line-height: 1;

        .brandName {
          color: white;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .brandTopLabel {
            display: inline-flex;

            .numTag {
              border-radius: 2px;
              opacity: 1;
              background: #ffd4b6;
              height: 16px;
              /* 自动布局 */
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 1px 4px;
              font-size: 10px;
              margin-right: 4px;
              color: #8c4206;
              font-size: 800;
            }

            .labelIcon {
              height: 16px;
              margin-right: 4px;
              object-fit: contain;
            }
          }

          .brandNameText {
            max-width: 160px;
            display: inline-block;
          }

          .moreBrandInfoButton {
            height: 12px;
            margin-left: 4px;
          }
        }

        .brandDesc {
          color: rgba(255, 255, 255, 0.8);
          font-size: 12px;
          display: flex;
          flex-direction: row;

          .brandDescText {
            max-width: 135px;
            display: inline-block;
          }

          .brandDescSep {
            margin: 0 4px;
          }

          .brandMaterialCountContainer {
            display: inline-flex;
            align-items: center;
            font-size: 12px;
            color: #ffe2b6;

            .jumpIcon {
              width: 12px;
              height: 12px;
              margin-left: 4px;
            }
          }
        }
      }

      .followButtonContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .followButton {
        border-radius: 4px;
        width: 48px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;

        &.followed {
          color: white;
          background: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.2);
        }

        &.unfollowed {
          color: white;
          background-color: @themeColor;
        }

        .followButtonIcon {
          width: 14px;
        }
      }

      .hideButton {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-left: 6px;
        margin-top: 8px;
        width: 26px;
        height: 26px;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.2);

        .hideButtonIcon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
}