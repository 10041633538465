@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.topTabs {
  :global(.adm-tabs-header) {
    border-bottom-width: 0;
  }

  :global(.adm-tabs-tab) {
    font-size: 14px;
    color: #666666;
  }

  :global(.adm-tabs-tab-active) {
    font-size: 16px;
    color: #222222;
    font-weight: bold;
  }
}