@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN-Bold";
  src: url("../../../../assets/fonts/DIN-Bold.otf"); /* 注意路径 */
}
.sharerProductCard {
  width: 104px;
  font-family: PingFang SC;
  .img {
    width: 104px;
    height: 104px;
    position: relative;
    .img_item {
      border-radius: 4px;
      width: 104px;
      height: 104px;
    }
    .save_money_content {
      position: absolute;
      bottom: 0;
      background-color: #ffd4b6;
      display: flex;
      max-width: 100%;
      line-height: 14px;
      border-radius: 2px;
      .save_money {
        font-size: 10px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #8c4206;
        display: flex;
        align-items: center;
        padding: 1px 4px;
        white-space: nowrap;
      }
    }
  }
  .title {
    height: 32px;
    margin-top: 4px;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #222222;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
  .price_real {
    font-size: 12px;
    font-family: DIN-Bold;
    color: #ff302d;
    position: relative;
    height: 21px;
    display: flex;
    align-items: baseline;
    margin-top: 4px;
    .symbol {
      position: relative;
      bottom: 0;
      display: flex;
      align-items: flex-end;
    }
    .oldP {
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
    .price_big {
      padding-left: 2px;
      padding-right: 1px;
      position: relative;
      bottom: 0;
      height: 18px;
      line-height: 12px;
      font-size: 18px;
    }
    .decimal {
      display: flex;
      align-items: flex-end;
    }
    .price_old {
      font-size: 12px;
      font-weight: 500;
      color: #a5a5a5;
      padding-left: 3px;
      text-decoration: line-through;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding-top: 2px;
    }
  }
}
