@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN-Bold";
  src: url("../../assets/fonts/DIN-Bold.otf"); /* 注意路径 */
}
@font-face {
  font-family: "DIN-Medium";
  src: url("../../assets/fonts/DIN-Medium.otf"); /* 注意路径 */
}
@font-face {
  font-family: "DIN-Regular";
  src: url("../../assets/fonts/DIN-Regular.otf"); /* 注意路径 */
}
.search {
  background-color: #fff;
  position: relative;
  height: 100vh;
  overflow: hidden;
  .searchBox {
    width: 100%;
    background-color: #ffff;
    height: 44px;
    display: flex;
    align-items: center;
    .back {
      padding-left: 12px;
      padding-right: 12px;
      width: 24px;
      height: 24px;
    }
    .inputBox {
      width: 269px;
      height: 32px;
      border-radius: 12px;
      background: #f6f7fa;
      display: flex;
      align-items: center;
      position: relative;
      .visiable {
        visibility: visible;
      }
      .hid {
        visibility: hidden;
      }
      .img {
        width: 18px;
        height: 18px;
        margin-left: 9px;
      }
      .input {
        padding-left: 6px;
        height: 32px;
        border: unset;
        outline: none;
        padding: 4px 0;
        padding-left: 6px;
        line-height: 32px;
        background: #f6f7fa;
        box-sizing: border-box;
        position: absolute;
        font-size: 14px;
        caret-color: #ff302d;
        width: 240px;
        border-radius: 12px;
        position: relative;
      }
      .input::placeholder {
        color: #a5a5a5;
        opacity: 0.6;
      }
      .tag {
        height: 24px;
        border-radius: 9px;
        background: #ffffff;
        padding: 2px 9px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-left: 6px;
        position: absolute;
        .close {
          margin-left: 6px;
          width: 7px;
          height: 7px;
        }
      }
    }
    .text {
      color: #222222;
      font-size: 14px;
      margin-left: 12px;
    }
  }
  .history {
    width: 100%;
    background-color: #ffff;
    padding: 16px 15px 3px 15px;
    box-sizing: border-box;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        color: #333333;
        font-size: 18px;
        font-weight: 500;
      }
      .img {
        width: 24px;
        height: 24px;
      }
    }
    .tagsDiv {
      margin-top: 16px;
      overflow: hidden;
      position: relative;
      .tags {
        display: flex;
        flex-wrap: wrap;
        max-width: 300px;
        .item {
          min-width: 60px;
          height: 27px;
          border-radius: 10px;
          background: #f1f2f5;
          text-align: center;
          color: #222222;
          margin-right: 6px;
          margin-bottom: 12px;
          padding: 4px 4px 0 4px;
          max-width: 280px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          box-sizing: border-box;
        }
      }
      .down {
        width: 34px;
        height: 27px;
        position: absolute;
        right: 5px;
        top: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f2f5;
        border-radius: 10px;
        img {
          width: 12px;
          height: 12px;
        }
      }
      .up {
        width: 34px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f2f5;
        border-radius: 10px;
        img {
          width: 12px;
          height: 12px;
          transform: rotate(180deg);
          transition: all 0.3s;
        }
      }
    }
  }
  .hot {
    width: 100%;
    background-color: #ffff;
    padding: 16px 15px 3px 15px;
    box-sizing: border-box;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        color: #333333;
        font-size: 18px;
        font-weight: 500;
      }
      .img {
        width: 24px;
        height: 24px;
      }
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      max-height: 75px;
      overflow: hidden;
      .item {
        min-width: 60px;
        height: 27px;
        border-radius: 10px;
        background: #f1f2f5;
        text-align: center;
        color: #222222;
        margin-right: 6px;
        margin-bottom: 12px;
        padding: 4px 4px 0 4px;
        max-width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }
    }
  }
  .content {
    height: calc(100vh - 44px);
    padding: 8px 12px;
    overflow-y: auto;
    overflow-x: hidden;
    .cards {
      .card_item {
        margin-bottom: 12px;
      }
    }
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 106px;
    .img {
      width: 118px;
    }
    .text {
      color: #666666;
      font-size: 14px;
    }
    .btn {
      width: 64px;
      height: 26px;
      border: 1px solid rgba(34, 34, 34, 0.16);
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 12px;
      line-height: 26px;
      text-align: center;
    }
  }
}
.loadingMore {
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.suggest {
  width: 100%;
  background-color: #ffff;
  padding: 16px 15px 3px 15px;
  box-sizing: border-box;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  .item {
    height: 50px;
    border-bottom: solid #eee 1px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .item:last-child {
    border: unset;
  }
}
.water-fall-box {
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
  position: relative;
  padding: 0 12px;
  margin-top: 8px;
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: 44px;
  animation: appear 0.5s ease-in-out forwards;
  background: #fff;
}
.waterfall {
  // min-height: -webkit-calc(100vh - 80px);
  // min-height: -moz-calc(100vh - 80px);
  // min-height: calc(100vh - 80px);
  width: 360px;
  display: flex;
  flex-wrap: wrap;
}
.card-item {
  display: flex;
  flex-direction: column;
  // width: 145px;
  float: left;
  margin-right: 7px;
  margin-bottom: 15px;
}
.discovery-show {
  width: 18px;
  height: 18px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: auto;
}
.hide {
  background-image: url(./assets/hide.png);
}
.unhide {
  background-image: url(./assets/reveal.png);
}
.darkList {
  position: absolute;
  top: 5px;
  left: 33px;
  height: 20px;
  overflow: hidden;
  .darkList_item {
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #cccccc;
    transition: all 1s;
    position: relative;
  }
}
:global(.dialog_search) {
  :global(.adm-auto-center-content) {
    color: #5e5b5b;
  }
  :global(.adm-dialog-button) {
    color: #bfbaba !important;
  }
  :global(.adm-dialog-button-bold) {
    color: #ff4747 !important;
  }
}
.del {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
}
.card-item {
  float: left;
  margin-right: 7px;
  margin-bottom: 15px;
  height: fit-content;
}
.waterfall {
  width: 360px;
  display: flex;
  flex-wrap: wrap;
}
