@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);

  // display: none;
  z-index: 10;
  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .popupButton {
      display: flex;

      align-items: center;
      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-weight: 500;
      }
      .right {
        background: #ff4747;
        color: #ffffff;
      }
    }

    .popupContent {
      background: url(https://akim-oss.aikucun.com/b13d33a52865faaa847dae41e89930b55157dbf5_1699345802587_95.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .modalCloseIcon {
        margin-left: 259px;
        width: 18px;
        height: 18px;
        padding: 12px 12px 0 0;
      }

      .popTitle {
        display: flex;
        justify-content: center;
      }
      .popCard {
        background: url(https://akim-oss.aikucun.com/8f024d78c68a788b472e61be142ffea9edadec2e_1702630671280_58.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: column;
        align-items: center;
        .popCardTitle {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0px;
          color: #222222;
          padding: 13px 30px 14px 30px;
          border-bottom: 1px dashed #d8d8d8;
        }
        .popCardBottom {
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0px;
          color: #666666;
          padding-top: 14px; //这个有待调整
        }
      }
    }
  }
}
