@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  .header {
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #333;
    font-size: 15px;
    font-weight: bold;
  }

  .body {
    overflow-y: scroll;
    height: calc(90vh - 106px - constant(safe-area-inset-bottom));
    height: calc(90vh - 106px - env(safe-area-inset-bottom));
    padding: 0 @bigPadding;

    .subtitle {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      color: #333;
      font-weight: 700;
    }

    .itemsWrapper {
      &:last-child {
        margin-bottom: 24px;
      }

      .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
          width: 32.2%;
          margin-top: 9px;
          padding-left: 4px;
          box-sizing: border-box;
          font-size: 12px;
          height: 32px;
          border-radius: @smallBorderRadius;
          color: #666;
          background-color: #f5f5f5;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &:last-child {
            margin-bottom: 18px;
          }

          /* 如果最后一行是2个元素 */
          &:last-child:nth-child(3n - 1) {
            margin-right: calc(32.2% + 3.4% / 2);
          }

          &.active {
            color: @themeColor;
            background-color: @lightColor;
            font-weight: 500;
          }

          .icon {
            width: 9px;
            height: 5px;
            margin-left: 4px;
          }
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    height: 56px;
    display: flex;
    flex-direction: row;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    justify-content: space-between;
    width: 100%;

    .reset {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 50%;
      background-color: @lightColor;
      color: @themeColor;
      font-size: 17px;
      font-weight: 500;
    }

    .confirm {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 50%;
      background-color: @themeColor;
      color: white;
      font-size: 17px;
      font-weight: 500;
    }
  }
}
