@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.comp {
  margin: 0 12px;

  .title {
    flex-direction: row;
    position: relative;
    display: flex;

    .title_pre {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: 400;
      color: #ff302d;
    }

    .title_text {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: 400;
      color: #666;
    }
  }

  .tips {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: 400;
    color: #ff302d;
  }

  .input {
    margin: 16px 0;
    height: 132px;
    background-color: #f1f2f5;
    border-radius: 6px;
    padding: 12px;

    .input_area {
      height: 100%;
      width: 100%;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      background-color: #f1f2f5;
      border-color: #f1f2f5;
      border-width: 0px;
      resize: none;
    }
    ::placeholder {
      color: "#A5A5A5"; /* 设置 placeholder 字体颜色 */
      font-size: 14px;
    }
  }
}
