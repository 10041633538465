@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  height: 100vh;
  width: 375px;
  margin: auto;
  overflow: hidden scroll;
  background: #f1f2f5;
  position: relative;

  .content {
    position: relative;

    .sticky {
      position: sticky;
      top: 44px;
      z-index: 2;
      background-color: #ffffff;
    }

    .back {
      width: 100%;
      height: 165px;
      position: absolute;
      top: 0;
    }

    .list {
      padding: 8px;
      position: relative;

      .card {
        margin-bottom: 8px;
      }

      .empty {
        position: absolute;
        top: 112px;
        left: 94px;

        .img {
          width: 184px;
          height: 184px;
        }

        .text {
          font-family: "苹方-简";
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0px;
          /* 文字色/#666666_次要文字色 */
          color: #666666;
        }
      }
    }
  }

  .topBar {
    width: 100%;
    height: 44px;
    background-color: #ffffff;
    padding: 6px 0;
    box-sizing: border-box;
    position: sticky;
    z-index: 100;
    top: 0;
  }

  .backImg {
    width: 100%;
    position: absolute;
    top: 0;
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0px;
      color: #222222;
    }

    .nums {
      height: 14px;
      border-radius: 12px;
      opacity: 1;
      box-sizing: border-box;
      border: 0.5px solid #a5a5a5;
      margin-left: 1px;

      .text {
        font-family: PingFang SC;
        font-size: 10px;
        font-weight: normal;
        line-height: 14px;
        letter-spacing: 0px;
        /* 文字色/#666666_次要文字色 */
        color: #666666;
        padding: 0 4px;
      }
    }
  }
}

.filterBoard {
  position: relative;

  :global(.adm-mask) {
    z-index: 100;
  }

  :global(.adm-popup-close-icon) {
    right: 20px;
    top: 12px;
  }
}
