@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.brand-material {
  height: 100vh;

  &-content {
    height: calc(100vh - 36px);
    overflow-y: scroll;
    padding: 10px;
    padding-bottom: env(safe-area-inset-bottom) !important;
    padding-bottom: constant(safe-area-inset-bottom) !important;

    .material-list-item {
      margin-bottom: 8px;
    }

    .loading {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
