@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  position: sticky;
  z-index: 1;
  top: 0;
  padding: 12px 0;
  border-radius: 12px 12px 0px 0px;
  background-color: #ffffff;
  &.noBorderRadius {
    border-radius: 0;
  }
  .list {
    display: flex;
    flex-wrap: nowrap;
    padding-right: 75px;
    overflow: scroll;
  }
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border: 0.5px solid rgba(34, 34, 34, 0.08);
    border-radius: 4px;
    color: #222222;
    font-weight: 500;
    font-size: 10px;
    margin-left: 10px;
    &.active {
      border-width: 1px;
      border-color: #ff4747;
    }
    &:first-child {
      margin-left: 12px;
    }
    .pic {
      width: 32px;
      height: 32px;
      object-fit: contain;
      overflow: hidden;
    }
  }
  .more {
    position: absolute;
    right: 0;
    top: 12px;
    width: 75px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #666666;
    background: linear-gradient(270deg, #ffffff 78%, rgba(255, 255, 255, 0) 94%);
    &::after {
      content: "";
      width: 14px;
      height: 14px;
      background: url(https://akim-oss.aikucun.com/5b384ce32d8cdef02bc3a139d4cac0a22bb029e8_1706347114263_5.png)
        center center no-repeat;
      background-size: 14px;
    }
  }
}
.popWrap {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  min-height: 40vh;
  max-height: 95vh;
}
.popHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  flex-shrink: 0;
  background-color: #ffffff;
  border-radius: 12px 12px 0 0;
  color: #222222;
  font-weight: 500;
  font-size: 18px;
  .backBtn {
    position: absolute;
    left: 0;
    top: 10px;
    display: flex;
    width: 35px;
    height: 35px;
    background: url(https://akim-oss.aikucun.com/af3e133428b9e25c55bc59fe534248e6a0c0f17b_1706496810016_61.png)
      center center no-repeat;
    background-size: 9.5px 18px;
  }
}
.popMain {
  flex: 1;
  overflow-y: scroll;
}
.popItem {
  .popItemTitle {
    position: sticky;
    top: -1px;
    display: flex;
    align-items: center;
    height: 36px;
    padding-left: 15.5px;
    font-size: 12px;
    font-weight: 500;
    color: #515151;
    background: #f5f5f5;
  }
  .popItemCont {
    display: flex;
    height: 41px;
    align-items: center;
    padding-left: 15px;
    color: #666666;
    font-size: 12px;
    background: #ffffff;
    border-bottom: 0.5px solid #f7f7f7;
    .popItemLogo {
      width: 24px;
      height: 24px;
      border: 0.5px solid #efecec;
      border-radius: 4px;
    }
    .popItemText {
      font-size: 12px;
      padding-left: 6px;
      color: #333333;
    }
    &.active {
      position: relative;
      .popItemLogo {
        border-color: #ff4747;
      }
      .popItemText {
        color: #ff4747;
      }
      &::after {
        content: "";
        position: absolute;
        top: 15px;
        right: 43px;
        width: 17px;
        height: 12px;
        background: url(https://akim-oss.aikucun.com/761f22b2c1593d0bb87e0b606f990ba4974706de_1706510788909_93.png)
          center center no-repeat;
        background-size: 17px 12px;
      }
    }
  }
}

.alphabet {
  position: absolute;
  top: 136px;
  right: 16px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 12px;
  color: #333333;
  .hl {
    color: #ff302d;
  }
}
