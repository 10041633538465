@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.couponPopWrap,
.loginPopWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 294px;
  height: 357px;
  margin: auto;
  background: url(https://akim-oss.aikucun.com/e2b8786627ae2a5aeb9df970865747140c393570_1680181543511_25.png)
    center center no-repeat;
  background-size: 294px auto;
  .topImg {
    width: 210px;
    height: 46px;
    background: url(https://akim-oss.aikucun.com/af2c7b4ca07ae6c74d261bc745e174df8ab3ffef_1680181543565_32.png)
      center center no-repeat;
    background-size: 210px auto;
  }
  .title {
    margin-top: 32px;
    font-size: 18px;
    color: #ffffff;
  }
  .couponCard {
    position: relative;
    display: flex;
    width: 270px;
    height: 76px;
    align-items: center;
    margin-top: 15px;
    background: url(https://akim-oss.aikucun.com/f1abd670358e036c31296e66b3b66c382ac00812_1680241588968_10.png)
      center center no-repeat;
    background-size: 270px auto;
  }

  .couponPrice {
    display: flex;
    align-items: baseline;
    min-width: 115px;
    padding: 0 13.5px;
    font-size: 36px;
    font-weight: bold;
    color: #ff302d;
  }
  .couponPrice::before {
    position: relative;
    bottom: 4px;
    content: "¥";
    padding-right: 10px;
    font-size: 18px;
  }
  .couponDesc {
    font-size: 22px;
    font-weight: bold;
    color: #86270b;
  }

  .extra {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 60px;
    height: 22.5px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }

  .collectBtn {
    width: 86px;
    height: 86px;
    margin-top: 35px;
    background: url(https://akim-oss.aikucun.com/3275142b880fd39f1dbd64201d4e22e370d61f98_1680181543550_13.png)
      center center no-repeat;
    background-size: 86px auto;
  }
}

.loginPopWrap {
  height: 427px;
  background: linear-gradient(177deg, #f88261 0%, #fb5233 12%, #f11f31 90%, #d83057 100%);
  border-radius: 28px;
  .title {
    margin-top: 20px;
  }
  .form {
    width: 270px;
  }
  .inputWrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
    padding: 0 15px;
    box-sizing: border-box;
    margin-top: 10px;
    font-size: 14px;
    background: #ffffff;
    border-radius: 14px;
    .input {
      display: flex;
      width: 100%;
      height: 40px;
      align-items: center;
      border: none;
      outline: none;
    }
    .codeBtn {
      display: flex;
      height: 40px;
      align-items: center;
      flex-shrink: 0;
      margin-left: 5px;
      font-weight: bold;
    }
  }
  .registerBtn {
    width: 270px;
    height: 47px;
    margin-top: 26px;
    background: url(https://akim-oss.aikucun.com/f6e1126cedebf23e1463aee73f9df08783640400_1681307012540_46.png)
      center center no-repeat;
    background-size: 270px auto;
  }

  .agreement {
    display: flex;
    width: 275px;
    padding-left: 5px;
    margin-top: 10px;
    color: #ffffff;
  }
  .radio {
    position: relative;
    top: 1.5px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background: #ffffff;
    border-radius: 8px;
  }
  .radio.checked {
    background: url(https://akim-oss.aikucun.com/bd307a3ec329e10a2cff8fb87480823da114f8f4_1680229044188_71.png)
      center center no-repeat;
    background-size: 16px auto;
  }
  .cont {
    line-height: 18px;
    padding: 0 5px 0 7.5px;
    font-size: 12px;
  }
  .hl {
    color: #fffb0a;
  }
}
