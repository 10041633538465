@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 48px;
  box-sizing: border-box;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 24px;
  padding: 2px 2px;
  box-sizing: border-box;
  font-size: 14px;
  color: #666666;
  .addrIcon {
    width: 16px;
    height: 16px;
  }
  .notDeliver {
    margin-left: 2px;
    height: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ff474714;
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    font-family: "PingFang SC";
    font-size: 10px;
    font-weight: 500;
    color: #ff4747;
  }
  .addrMain {
    margin-left: 4px;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .addrMainNotDeliver {
    color: #ff4747;
  }
  .addrBtn {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 60px;
    height: 20px;
    padding-left: 14px;
    flex-shrink: 0;
    color: #222222;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      width: 2px;
      height: 20px;
      background: rgba(34, 34, 34, 0.08);
    }
  }
}

.main {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;

  .sku {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    border-radius: 10px;
    background: #fff0de;

    .skuTips {
      font-family: "PingFang SC";
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #8c4206;

      .skuTipsTagPrice {
        font-family: "DIN-Bold";
        font-weight: 700;
        color: #ff4747;
      }
    }

    .skuImg {
      margin-top: 8px;
      margin-bottom: 8px;
      width: 82px;
      height: 82px;
      border-radius: 8px;
      border: 1px solid #ffe9d0;
    }

    .skuTitle {
      margin-left: 10px;
      margin-right: 10px;
      font-family: "PingFang SC";
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222222;
      display: -webkit-box; /*将对象作为弹性伸缩盒子模型显示*/
      -webkit-box-orient: vertical; /*设置或检索伸缩盒对象的子元素的排列方式：从上向下垂直排列子元素。*/
      -webkit-line-clamp: 1; /*几行*/
      line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .propList {
    margin-top: 14px;
  }
}

.commitContainer {
  width: 100%;
  background-color: #fff;

  .commitBtn {
    margin-top: 6px;
    margin-bottom: 6px;
    width: 100%;
    height: 44px;
    border-radius: 6px;
    background: #ff4747;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "PingFang SC";
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
  .commitBtnDisable {
    opacity: 0.4;
  }
}

.alertTitle {
  font-size: 16px;
  font-weight: 500;
  color: #222;
}

.alertContent {
  font-size: 14px;
  color: #666;
  text-align: center;
  min-width: 252px;
}

.alertConfirmText {
  font-size: 16px;
  font-weight: 500;
  color: #ff4747;
}

.alertCancelText {
  font-size: 16px;
  color: #666;
}
