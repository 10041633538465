@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.footer {
  // height: 56px;
  background-color: white;
  width: 375px;
  padding: 10px 16px 10px;
  padding-bottom: calc(10px + constant(safe-area-inset-bottom));
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
  margin: auto;

  .shop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 24px;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  .selective {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // margin-left: 10px;
    margin-right: 10px;
    flex: 1;
    height: 42px;
    font-size: 16px;
    font-weight: 500;
    color: @themeColor;
    border-radius: 6px;
    border: 1px solid #ff4747;
    background: rgba(255, 71, 71, 0.08);
  }

  .button {
    height: 44px;
    background-color: @themeColor;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;

    // &:first-child {
    //   margin-left: 10px;
    // }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
}
