@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.adminHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 4px 20px 8px;
  .userInfo {
    display: flex;
    align-items: center;
    .avatarWrap {
      position: relative;
      margin-right: 10px;
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        border: 2px solid #fff;
      }
      .switch {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        width: max-content;
        height: 18px;
        line-height: 18px;
        padding: 0px 3px;
        font-size: 10px;
        color: #222222;
        border-radius: 4px;
        // border: 1px solid rgba(255, 71, 71, 0.5);
        background: #ffffff;
        .iconSwitch {
          flex-shrink: 0;
          margin-right: 2px;
          width: 10px;
          height: 10px;
          background: url("https://akim-oss.aikucun.com/mshop/f3691a4f24317c903855ba408604f12b1d85b77d_1711608942831_96.png")
            no-repeat;
          background-size: 100% 100%;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: -0.5px;
          width: 200%;
          height: 200%;
          transform-origin: left top;
          transform: scale(0.5);
          border: 1px solid rgba(255, 71, 71, 0.5);
          border-radius: 8px;
          box-sizing: border-box;
        }
      }
    }
    .flexColumn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .shopName {
        display: flex;
        align-items: center;
        p {
          max-width: 142px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 22px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #222;
          line-height: 22px;
          font-weight: 600;
        }
        .iconAdmin {
          margin-left: 4px;
          width: 38px;
          height: 16px;
          background: url("https://akim-oss.aikucun.com/mshop/ae0a7afecee1e83d27e322e287cb9bf351ea0018_1711701492999_69.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
      .shopDesc {
        margin-top: 3px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 10px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: normal;
        line-height: 14px;
        color: #666666;
      }
      .userId {
        display: flex;
        align-items: center;
        margin-top: 4px;
        font-size: 12px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: normal;
        line-height: 16px;
        color: #222;
        .copyBtn {
          position: relative;
          margin-left: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 16px;
          border-radius: 4px;
          // border: 1px solid #A5A5A5;
          font-size: 10px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #666;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: -0.5px;
            width: 200%;
            height: 200%;
            transform-origin: left top;
            transform: scale(0.5);
            border: 1px solid #a5a5a5;
            border-radius: 8px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .shareShop {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    font-family:
      PingFangSC-Regular,
      PingFang SC;
    font-weight: normal;
    color: #222222;
    .iconShare {
      margin-bottom: 4px;
      width: 22px;
      height: 22px;
      background: url("https://akim-oss.aikucun.com/mshop/8114ed65ffe915616358db7674e087ef472820b4_1711701148364_34.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
