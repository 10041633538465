@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.selection {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  color: #666666;
  opacity: 1;
  transition: opacity 0.3s;

  &.hide {
    opacity: 0;
  }

  .seperator {
    height: 10px;
    width: 1px;
    background-color: rgba(34, 34, 34, 0.16);
    margin: 0 6px;
  }
}
