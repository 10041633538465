@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.notice {
  width: 100vw;
  margin-top: 12px;
  padding: 0 6px;
  box-sizing: border-box;

  .container {
    border-radius: 12px;
    background: linear-gradient(180deg, #FFF0DF 0%, #FFFFFF 9%);
    overflow: hidden;
    border: 1px solid #ffffff;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 12px 0;
      margin-top: 2px;
      background: linear-gradient(180deg, #FFF3E4 0%, #fff 20%);

      .title {
        width: 108px;
        height: 20px;
        background: url('https://akim-oss.aikucun.com/1a0147c87c02a754daa7820d83e00d971a077c74_1741346645166_40.png') no-repeat;
        background-size: contain;
        background-position: center;
      }

      .share {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 74px;
        height: 26px;
        padding-right: 6px;
        background: #FF4747;
        color: #fff;
        border-radius: 20px;
      }
    }

    .noticeBarContainer {
      // height: 138px;
      padding: 12px 0 16px;
      box-sizing: border-box;

      .noticeBarContent {
        &>div {
          margin-right: 16px;
        }
      }

      .noticeBarContentLast {
        padding-left: 80px;
        margin-top: 8px;

        &>div {
          margin-right: 10px;
        }
      }
    }
  }

  .info {
    display: inline-flex;
    align-items: center;
    height: 44px;
    padding: 6px 12px 6px 6px;
    border-radius: 32px;
    background: linear-gradient(90deg, #FFBD57 0%, #FFFFFF 100%);
    box-sizing: border-box;
    border: 2px solid #FFCB7A;

    .left {
      display: inline-flex;
      position: relative;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1.5px solid #FFFFFF;
        box-sizing: border-box;
      }

      .leftTag {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 25px;
        height: 14px;
        background: #000000;
        border-radius: 24px;
        box-sizing: border-box;
        border: 1px solid #FFCD81;
        color: #FFD99F;
        font-size: 8px;
        font-weight: 500;
        box-sizing: border-box;
        text-align: center;
      }
    }

    .main {
      margin-left: 6px;
      font-size: 12px;
      font-weight: 500;
      max-width: 180px;
      /* 限制最大宽度 */
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .light {
        color: #FF4747;
      }
    }
  }
}

.highLight {
  color: #FFE6BF
}

// 活动结束空态样式
.emptyActivity {
  width: 100vw;
  margin-top: 12px;
  padding: 0 6px;
  box-sizing: border-box;

  .emptyActivityInner {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0 12px 0;
    position: relative;
  }

  .title {
    width: 108px;
    height: 20px;
    background: url('https://akim-oss.aikucun.com/1a0147c87c02a754daa7820d83e00d971a077c74_1741346645166_40.png') no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 10px;
    left: 12px;
  }

  .emptyIcon {
    width: 110px;
    height: 110px;
    margin-bottom: 4px;
  }

  .emptyText {
    font-size: 12px;
    color: #A5A5A5;
    text-align: center;
    margin: 0;
  }
}