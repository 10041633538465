@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  background-color: #f1f2f5;
  width: 375px;
  margin: auto;
  position: flex;
  flex-direction: column;
  box-sizing: border-box;

  .topNavigation {
    background-color: #fff;
  }

  .scrollContent {
    height: calc(100vh - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;

    .stickyContent {
      position: sticky;
      z-index: 99;
      top: -1px; // 防止有缝隙
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #f1f2f5;

      .top {
        background-color: #fff;
        border-radius: 10px;
        margin-left: 12px;
        margin-right: 12px;
        padding: 12px;
        display: flex;
        flex-direction: column;

        .title {
          font-family: PingFangSC-Medium, "PingFang SC";
          font-size: 16px;
          color: #333;
        }

        .value {
          margin-top: 8px;
          font-family: PingFangSC-Regular, "PingFang SC";
          font-size: 14px;
          color: #333;
        }
      }

      .bottom {
        background-color: #fff;
        border-radius: 10px;
        margin-top: 8px;
        margin-left: 12px;
        margin-right: 12px;
        padding: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 17px;
        color: #666;

        .img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.popupContainer {
  padding-bottom: calc(0px + env(safe-area-inset-bottom));

  .popupHeader {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium;
    text-align: center;
    color: #333;
    height: 42px;
    line-height: 42px;

    .popupClose {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 22px;
      height: 22px;
      background: url("https://akim-oss.aikucun.com/mshop/3ab0928e68161f5d2b9e2c1ebcdc8d2c335089b1_1707014108466_77.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .popupContent {
    max-height: 70vh;
    overflow-y: scroll;

    .text {
      padding: 20px;
      font-size: 12px;
      color: #666;
    }
  }
}