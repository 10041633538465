@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.product-card {
  display: flex;
  .card__left {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    overflow: hidden;

    .product-image {
      width: inherit;
      height: inherit;
      object-fit: contain;
    }
  }
  .card__right {
    flex-grow: 1;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .product-title {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }
    .line1 {
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
    }
    .line2 {
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
    }
    .product-tag-box {
      overflow: hidden;
    }
    .product-price-box {
      display: flex;
      align-items: center;
      .price-box {
        display: flex;
        align-items: baseline;
        .promotion-type {
          margin-right: 1px;
          color: #ff4747;
          font-size: 10px;
          font-family:
            PingFangSC-Semibold,
            PingFang SC;
          font-weight: 500;
        }
        .price {
          display: flex;
          align-items: baseline;
          font-family: DIN;
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          color: #ff4747;
          &::before {
            content: "¥";
            font-family: DIN;
            font-size: 13px;
            font-weight: bold;
            line-height: 16px;
            color: #ff4747;
          }
        }
      }
      .commission {
        margin-left: 4px;
        padding: 0 4px;
        display: flex;
        align-items: center;
        height: 16px;
        background: #ffd4b6;
        border-radius: 2px;
        font-family: DIN;
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;
        color: #8c4206;
      }
    }
  }
}
