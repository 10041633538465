@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.order-center {
  position: relative;
  border-radius: 10px;
  padding-top: 12px;
  margin-bottom: 8px;
  background-color: #fff;
  .order-center-header {
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 14px;
    padding-bottom: 6px;
    .order-center-header__title {
      font-size: 14px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      line-height: 20px;
      color: #222222;
    }
    .order-center-header__more {
      font-size: 12px;
      line-height: 16px;
      color: #666666;
      display: flex;
      align-items: center;
      margin-left: auto;
      img {
        width: 10px;
        height: 10px;
        margin-left: 4px;
      }
    }
  }
  .order-center-body {
    display: flex;
    .order-center-body__item {
      width: 71px;
      height: 74px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .order-center-body__item__icon {
        width: 28px;
        height: 28px;
        vertical-align: bottom;
      }
      .order-center-body__item__text {
        font-size: 12px;
        line-height: 16px;
        margin-top: 6px;
        color: #222222;
      }
    }
  }
  .order-center-guide {
    &__line {
      position: absolute;
      left: 240px;
      bottom: -55px;
      width: 10px;
      height: 51px;
      background: url("https://akim-oss.aikucun.com/mshop/c31361341b603076eb87ba7fafde4f02f1bfdc13_1717729139344_24.png")
        no-repeat;
      background-size: 100% 100%;
    }
    &__txt {
      position: absolute;
      left: 94px;
      bottom: -127px;
      width: 197px;
      min-height: 60px;
      padding: 10px;
      box-sizing: border-box;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #ffffff;
      border-radius: 6px;
      background: #ff4747;
      &::after {
        content: "";
        position: absolute;
        top: -6px;
        left: 143px;
        width: 16px;
        height: 8px;
        background: url("https://akim-oss.aikucun.com/mshop/e1fa4795d11da15cdbb80a572ebc830ec77a8ba8_1717730853677_63.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
    &__button {
      position: absolute;
      left: 208px;
      bottom: -165px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 30px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      border: 0.8px solid #ffffff;
      background: rgba(255, 255, 255, 0.1);
    }
  }
}
