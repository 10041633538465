@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  width: 100vw;
  height: 100vh;
  overflow: hidden scroll;
  background: #f1f2f5;
  position: relative;
  box-sizing: border-box;

  .page__header {
    position: sticky;
    top: 0;
    background: #fff;

    .header__tabs {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 40px;
      background: #fff;

      .tabs__item {
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #666;
      }
      .tabs__item--active {
        position: relative;
        font-weight: 500;
        line-height: 22px;
        color: #222;

        &::before {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 0;
          right: 0;
          margin: auto;
          width: inherit;
          height: 2px;
          background: #ff4747;
        }
      }
    }
    .header__tips {
      padding: 8px 12px;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #666;

      &::before {
        content: "*";
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        color: #ff4747;
      }
    }
  }
  .page__body {
    padding: 0 10px 10px 10px;

    .header__tips {
      padding: 8px 12px;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #666;

      &::before {
        content: "*";
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        color: #ff4747;
      }
    }

    .empty {
      margin: 0 auto;
      padding-top: 130px;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      color: #666666;

      .emptyIcon {
        width: 184px;
        height: 184px;
      }
    }
  }

  .loading {
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }
  .loadingFixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
