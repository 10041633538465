@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.redpackTop {
  position: relative;
  margin: -12px -12px -8px;
  height: 214px;
  overflow: hidden;
  :global(.adm-image) {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
    }
  }
  .redpackTopRule {
    position: absolute;
    top: 16px;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 6px 0 8px;
    height: 22px;
    font-family: "苹方-简";
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0px;
    color: #ffffff;
    border-radius: 22px 0 0 22px;
    background: rgba(0, 0, 0, 0.5);
  }
}
.redpackContent {
  position: relative;
  box-sizing: border-box;
  margin: 0 -12px;
  padding: 12px;
  border-radius: 12px 12px 0 0;
  // background: #F1F2F5;
  z-index: 2;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 91px;
    border-radius: 12px 12px 0 0;
    background: linear-gradient(
      180deg,
      #ffffff 25%,
      rgba(255, 255, 255, 0.8979) 52%,
      rgba(216, 216, 216, 0) 100%
    );
    z-index: 1;
  }
  & > * {
    position: relative;
    margin-bottom: 8px;
    z-index: 2;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.redpackContentShop {
  padding-bottom: calc(62px + env(safe-area-inset-bottom));
}
.redpackSummary {
  padding: 12px;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  /* 背景色/#FFFFFF_卡片 */
  border: 1px solid #ffffff;
  backdrop-filter: blur(20px);
  background: linear-gradient(180deg, #fff0d2 -27%, #ffffff 41%);
  .redpackTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222222;
    span {
      font-family: DIN;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      letter-spacing: -0.03em;
      // font-variation-settings: "opsz" auto;
      color: #ff4747;
    }
    .unit {
      margin-left: 4px;
      font-size: 10px;
    }
    & > *:first-child {
      display: flex;
      align-items: baseline;
    }
    & > *:last-child {
      display: flex;
      align-items: center;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #666666;
    }
  }
  .redpackProgress {
    margin-top: 6px;
    :global(.adm-slider) {
      padding: 6px 0 2px;
      :global(.adm-slider-track),
      :global(.adm-slider-fill) {
        height: 4px;
        border-radius: 4px;
        &::after {
          display: none;
        }
      }
      :global(.adm-slider-track) {
        background: #f1f2f5;
      }
      :global(.adm-slider-thumb) {
        background: none;
        box-shadow: none;
      }
      :global(.adm-slider-thumb svg) {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
      }
    }
  }
  .redpackInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: #a5a5a5;
    span {
      font-weight: 500;
      color: #ff4747;
    }
  }
}

.redpackItem {
  padding: 12px 10px;
  border-radius: 10px;
  background: #ffffff;
  .redpackItemInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "苹方-简";
    & > *:first-child {
      width: 0;
      flex: 1;
      display: flex;
      align-items: center;
    }
    & > *:nth-child(2) {
      margin-left: 8px;
    }
  }
  .redpackItemCover {
    margin-right: 8px;
    width: 48px;
    height: 48px;
    border-radius: 6px;
    border: 1px solid rgba(34, 34, 34, 0.08);
    background-color: #ffffff;
  }
  .redpackItemTitle {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #222222;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .redpackItemDesc {
    margin-top: 4px;
    display: flex;
    align-items: center;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: #666666;
  }
  .redpackItemTag {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > *:first-child {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 4px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .redpackItemContent {
    margin-top: 12px;
    margin-right: -10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    & > * {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .redpackItemTag + .redpackItemContent {
    margin-top: 6px;
  }
  .redpackItemContentCover {
    position: relative;
    width: 104px;
    height: 104px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #f1f2f5;
    :global(.adm-image) {
      width: 100%;
      height: 100%;
    }
    .livingIcon {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 58px;
      height: 18px;
      z-index: 2;
    }
    .playIcon {
      position: absolute;
      left: 4px;
      bottom: 4px;
      width: 24px;
      height: 24px;
      z-index: 2;
    }
    .tagIcon {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .redpackItemContentPrice {
    display: flex;
    align-items: baseline;
    margin-top: 6px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    color: #ff4747;
    .unit {
      font-size: 12px;
      line-height: 16px;
    }
    .linePrice {
      margin-left: 4px;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 0px;
      text-decoration: line-through;
      color: #a5a5a5;
    }
  }
}

.redpackItemCountDown {
  display: flex;
  align-items: center;
  font-family: PingFang SC;
  font-size: 10px;
  font-weight: normal;
  line-height: 14px;
  text-align: right;
  color: #666666;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0 4px;
    padding: 0 2px;
    min-width: 18px;
    height: 18px;
    border-radius: 2px;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    color: #222222;
    text-align: center;
    background: #f1f2f5;
  }
}

.redpackModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 375px;
  height: 375px;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("./images/redpacket-close.png");
    background-position: 12px 29px;
    background-repeat: no-repeat;
    background-size: 340px 344px;
    z-index: 1;
  }
}
.redpackModal__open {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("./images/redpacket-open.png");
    background-position: 20px 28px;
    background-repeat: no-repeat;
    background-size: 333px 335px;
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("./images/light-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
  }
}

.redpackModal__content {
  position: absolute;
  top: 49px;
  left: 69px;
  right: 69px;
  box-sizing: border-box;
  padding: 20px;
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}
.redpackModal__brandLogo {
  --adm-image-width: 38px;
  --adm-image-height: 38px;
  margin-bottom: 4px;
  box-sizing: border-box;
  width: 38px;
  height: 38px;
  border-radius: 6px;
  border: 1px solid #fae4d3;
}
.redpackModal__brandName {
  margin-bottom: 6px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #222222;
}
.redpackModal__amount {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  font-family: DIN;
  font-size: 65px;
  font-weight: bold;
  line-height: 70px;
  letter-spacing: -1px;
  color: #ff4747;
  span {
    margin-left: 4px;
    margin-bottom: 6px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #ff4747;
  }
}
.redpackModal__tips {
  font-family: "苹方-简";
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0px;
  color: #666666;
}

.redpackModal__btn {
  position: absolute;
  top: 279px;
  left: 69px;
  right: 69px;
  height: 48px;
  z-index: 2;
}

.popupContent {
  font-family: PingFang SC;
}
.popupHeader {
  padding: 8px 16px 12px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #222222;
}
.popupText {
  padding: 0 16px 12px;
  font-size: 12px;
  color: #666;
}
.popupTextTitle {
  font-size: 14px;
  color: #222222;
  font-weight: 500;
}
.footerContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px 12px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 6px);
  background-color: #ffffff;
  z-index: 5;
}
.footerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 6px;
  background: #ff4747;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}
