@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.text {
  box-sizing: border-box;
  .layout1 {
    width: 100%;
    padding-top: 50%;
    position: relative;
    .box1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
    }
    .box2 {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
    }
  }
  .layout2 {
    width: 100%;
    padding-top: 100%;
    position: relative;
    .box1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
    }
    .box2 {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 50%;
    }
    .box3 {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
    }
  }
  .layout3 {
    width: 100%;
    padding-top: 83.33333%;
    position: relative;
    .box1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 60%;
    }
    .box2 {
      position: absolute;
      top: 60%;
      left: 0;
      width: 33.33333%;
      height: 40%;
    }
    .box3 {
      position: absolute;
      top: 60%;
      left: 33.33333%;
      width: 33.33333%;
      height: 40%;
    }
    .box4 {
      position: absolute;
      top: 60%;
      left: 66.66666%;
      width: 33.33333%;
      height: 40%;
    }
  }
  .layout4 {
    width: 100%;
    padding-top: 100%;
    position: relative;
    .box1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 50%;
    }
    .box2 {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 50%;
    }
    .box3 {
      position: absolute;
      top: 50%;
      left: 0;
      width: 50%;
      height: 50%;
    }
    .box4 {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
    }
  }
  .layout5 {
    width: 100%;
    padding-top: 33.33333%;
    position: relative;
    .box1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 33.33333%;
      height: 100%;
    }
    .box2 {
      position: absolute;
      top: 0;
      left: 33.33333%;
      width: 33.33333%;
      height: 100%;
    }
    .box3 {
      position: absolute;
      top: 0;
      left: 66.66666%;
      width: 33.33333%;
      height: 100%;
    }
  }
  .layout6 {
    width: 100%;
    padding-top: 66.66666%;
    position: relative;
    .box1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 33.33333%;
      height: 50%;
    }
    .box2 {
      position: absolute;
      top: 0;
      left: 33.33333%;
      width: 33.33333%;
      height: 50%;
    }
    .box3 {
      position: absolute;
      top: 0;
      left: 66.66666%;
      width: 33.33333%;
      height: 50%;
    }
    .box4 {
      position: absolute;
      top: 50%;
      left: 0;
      width: 33.33333%;
      height: 50%;
    }
    .box5 {
      position: absolute;
      top: 50%;
      left: 33.33333%;
      width: 33.33333%;
      height: 50%;
    }
    .box6 {
      position: absolute;
      top: 50%;
      left: 66.66666%;
      width: 33.33333%;
      height: 50%;
    }
  }
  .layout7 {
    width: 100%;
    padding-top: 100%;
    position: relative;
    .box1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 33.33333%;
      height: 33.33333%;
    }
    .box2 {
      position: absolute;
      top: 0;
      left: 33.33333%;
      width: 33.33333%;
      height: 33.33333%;
    }
    .box3 {
      position: absolute;
      top: 0;
      left: 66.66666%;
      width: 33.33333%;
      height: 33.33333%;
    }
    .box4 {
      position: absolute;
      top: 33.33333%;
      left: 0;
      width: 33.33333%;
      height: 33.33333%;
    }
    .box5 {
      position: absolute;
      top: 33.33333%;
      left: 33.33333%;
      width: 33.33333%;
      height: 33.33333%;
    }
    .box6 {
      position: absolute;
      top: 33.33333%;
      left: 66.66666%;
      width: 33.33333%;
      height: 33.33333%;
    }
    .box7 {
      position: absolute;
      top: 66.66666%;
      left: 0;
      width: 33.33333%;
      height: 33.33333%;
    }
    .box8 {
      position: absolute;
      top: 66.66666%;
      left: 33.33333%;
      width: 33.33333%;
      height: 33.33333%;
    }
    .box9 {
      position: absolute;
      top: 66.66666%;
      left: 66.66666%;
      width: 33.33333%;
      height: 33.33333%;
    }
  }
}
