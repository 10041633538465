@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #ff7578;
  overflow: hidden scroll;

  .scroll_view {
    position: relative;
    flex-grow: 1;
    padding: 105px 0 20px 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #ff7578;
    background-image: url("https://akim-oss.aikucun.com/mshop/2c80e1477b82bf26e8eb1e7b46d5e7f5c3128fbd_1733118407152_54.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100vw auto;
    box-sizing: border-box;

    .rule__absolute {
      position: absolute;
      top: 55px;
      right: -5px;
      z-index: 10;
      width: 40px;
      height: 20px;
    }

    .page__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .page__body {
      margin: 14px 16px 10px 16px;

      .body__data {
        position: relative;

        .data__count {
          padding: 10px 0;
          background-color: #fff;
          background-image: url("https://akim-oss.aikucun.com/mshop/fcd55ac46fd33e8358c1d36720d637f33fbe36de_1733118582427_20.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          border-radius: 12px;
          box-sizing: border-box;

          .count__value {
            display: flex;
            justify-content: center;
            align-items: baseline;
            font-family: "DIN-Bold";
            font-size: 32px;
            font-weight: bold;
            line-height: 32px;
            text-align: center;
            color: #ff4747;

            .unit {
              padding-left: 3px;
              font-family: PingFang SC;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: #ff4747;
            }
          }

          .count__title {
            padding: 5px 16px 0 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-family: "苹方-简";
            font-size: 12px;
            font-weight: normal;
            line-height: 16px;
            color: #666;

            .icon {
              margin-left: 2px;
              width: 16px;
              height: 16px;
            }
          }
        }

        .data__list {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .list__item {
            padding: 12px 0 10px 0;
            width: 167px;
            height: 72px;
            background-color: #fff;
            background-image: url("https://akim-oss.aikucun.com/mshop/fb9bfe682bde145526382ea296e2e80cfe185eae_1733120403830_98.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 12px;
            box-sizing: border-box;

            .item__value {
              display: flex;
              justify-content: center;
              align-items: baseline;
              font-family: "DIN-Bold";
              font-size: 24px;
              font-weight: bold;
              line-height: 24px;
              color: #222;

              .unit {
                font-family: PingFang SC;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #222;
              }
            }

            .item__title {
              padding-top: 8px;
              text-align: center;
              font-family: "苹方-简";
              font-size: 12px;
              font-weight: normal;
              line-height: 16px;
              color: #666;
            }
          }
        }
      }

      .body__jiyu {
        margin-top: 12px;
        width: 100%;
        height: auto;
        object-fit: contain;
      }

      .body__jili {
        margin-top: 12px;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .page__strategy {
      width: 100%;
    }
  }

  .page__loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

:global(.dialog__private) {
  :global(.adm-center-popup-wrap) {
    width: 80vw !important;
  }

  :global(.adm-dialog-title) {
    font-family: PingFang SC !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #222 !important;
  }

  :global(.adm-dialog-content) {
    padding: 0 24px 12px 24px !important;
  }

  :global(.adm-auto-center-content) {
    font-family: "苹方-简" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 20px !important;
    color: #666 !important;
  }

  :global(.adm-dialog-button) {
    font-family: PingFang SC !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #ff4747 !important;
  }
}
