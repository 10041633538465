@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.role-switch-popup {
  .role-switch-popup-header {
    padding: 18px;
    position: relative;
    .header__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      color: #222222;
    }
    .header__close {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .role-switch-popup-main {
    background-color: #f1f2f5;
    padding: 0 12px;
    padding-top: 8px;
    padding-bottom: calc(10px + env(safe-area-inset-bottom));
    .main-item {
      background-color: #ffffff;
      display: flex;
      padding: 12px 16px;
      border-radius: 10px;
      margin-bottom: 8px;
      .item-left {
        .left-title {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          .title__name {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            color: #222222;
          }
          .title__label {
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            color: #ff4747;
            padding: 2px 4px;
            border-radius: 2px;
            background: rgba(255, 71, 71, 0.16);
            width: fit-content;
            height: fit-content;
            margin-left: 8px;
          }
        }
        .left__desc {
          font-size: 12px;
          line-height: 16px;
          color: #a5a5a5;
        }
      }
      .item-right {
        display: flex;
        align-items: center;
        margin-left: 12px;
        .right__icon {
          width: 24px;
          height: 24px;
        }
      }
    }
    .confirm-btn {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #ff4747;
      text-align: center;
      border-radius: 6px;
      background: #ffffff;
      border: 1px solid rgba(255, 71, 71, 0.45);
      padding: 10px 0;
    }
  }
}
:global(.adm-popup) {
  z-index: 11000;
  align-self: flex-start;
}
