@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  background: #f5f5f5;
  .container {
    flex: 1;
    padding: 12px;

    .main {
      padding: 12px;
      border-radius: 8px;
      background-color: #ffffff;
    }
    .passage {
      padding-bottom: 6px;
    }
    .title {
      padding-bottom: 8px;
      color: #222222;
      font-size: 16px;
      font-weight: 500;
    }
    .cont {
      padding-bottom: 6px;
      padding-left: 12px;
      font-size: 14px;
      color: #666666;
      &::before {
        content: "";
        position: absolute;
        margin: 10px 0 0 -10px;
        width: 4px;
        height: 4px;
        background: #ff4747;
        border-radius: 2px;
      }
    }

    .footer {
      position: fixed;
      left: 0;
      bottom: 50px;
      width: 100%;
      box-sizing: border-box;
      padding: 0 12px;
      color: #666666;
      .checkWrap {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        .checkBox {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          &.checked {
            display: flex;
            border-color: #ff4747;
            &::after {
              content: "";
              display: inline-block;
              width: 9px;
              height: 9px;
              margin: auto;
              border-radius: 5px;
              background: #ff4747;
            }
          }
        }
        .hl {
          color: #0958d9;
        }
      }
      .longBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        border-radius: 6px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        background: #ff4747;
      }
    }
  }
}
