@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  padding-bottom: calc(24px + constant(safe-area-inset-bottom));
  padding-bottom: calc(24px + env(safe-area-inset-bottom));
  font-family: PingFang SC;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0px;
    color: #a5a5a5;
    padding: 21px 21px 10px 21px;
    .comfirm {
      color: #ff4747;
      font-weight: 500;
    }
  }
  .tab {
    display: flex;
    justify-content: center;
    .mytabs {
      display: flex;
      align-items: center;
      width: 160px;
      height: 28px;
      border-radius: 4px;
      background: #f1f2f5;
      padding: 0px 2px;
      .tab-item {
        width: 50%;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0px;
        color: #666666;
      }
      .active {
        border-radius: 2px;
        background: #ff302d;
        color: #ffffff;
      }
    }
  }
  .date_container {
    height: 320px;
    display: flex;
    flex-direction: column;
  }
  .fix_dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 16px;
    margin-left: 7px;
    margin-right: 7px;
    .fix-item {
      flex: 1;
      font-size: 12px;
      color: #666666;
      font-weight: normal;
      background-color: #f1f2f5;
      text-align: center;
      padding: 6px 16px;
      border-radius: 4px;
      margin-left: 4px;
      margin-right: 4px;
    }
    .active {
      background-color: #ff474729;
      color: #ff4747;
    }
  }
  .time_title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
    .filter_time {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 114px;
      margin: 0px 21.5px 0px 21.5px;
      border-bottom: 1px solid #c5c5c5;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      padding-bottom: 3px;
    }
    .active {
      color: #f62323;
      border-bottom: 1px solid #fb2222;
    }
    .empty {
      border-bottom: 1px solid #c5c5c5;
      color: #b9b9b9;
    }
    .filter_center_t {
      font-size: 14px;
      color: #222222;
    }
  }
  .date_picker {
    margin-top: 16px;
    :global(.adm-picker-view-mask-top) {
      mask: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.8) 50%, #000000 100%);
      -webkit-mask-box-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.8) 50%,
        #000000 100%
      );
    }
    :global(.adm-picker-view-mask-bottom) {
      mask: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.8) 50%, #000000 100%);
      -webkit-mask-box-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.8) 50%,
        #000000 100%
      );
    }
  }
}
