@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.achievement {
  position: relative;
  padding: 12px;
  background: #fff0de;
  border: 1px solid #ffe9d0;
  border-radius: 8px;
  box-sizing: border-box;

  .achievement__received {
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 49px;
  }

  .achievement_title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #222;
  }
  .achievement_gmv {
    padding-top: 8px;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #666;

    span {
      color: #ff4747;
    }
  }
  .achievement__rank {
    margin-top: 16px;
    background: #fff;
    border: 1px solid #ffe5c5;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;

    .rank__title {
      padding: 8px 0;
      background: #ffe5c5;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #8c4206;

      .item__0,
      .item__1,
      .item__2 {
        font-family: PingFang SC !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 16px !important;
        color: #8c4206 !important;
        text-align: left;
      }
    }
    .rank__item {
      position: relative;
      padding: 6px 0;
      display: flex;
      align-items: center;
      color: #222;

      .item__0 {
        position: relative;
        padding-left: 20px;
        width: 25%;
        text-align: left;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 12px;
          margin: auto;
          z-index: 1;
          width: 24px;
          height: 24px;
          // background-image: url("https://akim-oss.aikucun.com/mshop/61bd3b480aeb4fab6e1cf9833b6d0510b14e0114_1715418668805_56.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .item__1 {
        width: 37%;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
        text-align: left;
      }
      .item__2 {
        width: 38%;
        font-family: "DIN-Medium";
        font-size: 12px;
        font-weight: bold;
        line-height: 12px;
        text-align: left;
      }
    }
    .rank__item_active {
      background: rgba(255, 71, 71, 0.08);
      color: #ff4747;

      &::before {
        content: "";
        position: absolute;
        left: 8px;
        width: 6px;
        height: 6px;
        background-image: url("https://akim-oss.aikucun.com/mshop/32063ab0c6c9dde75ac78d7c48511cc6cfa87c7f_1715584573330_94.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .rank__1 {
      .item__0::before {
        background-image: url("https://akim-oss.aikucun.com/mshop/61bd3b480aeb4fab6e1cf9833b6d0510b14e0114_1715418668805_56.png");
      }
    }
    .rank__2 {
      .item__0::before {
        background-image: url("https://akim-oss.aikucun.com/mshop/bed65289c03d2b5ed00cbedb3ba8c741e1c84921_1715418743507_93.png");
      }
    }
    .rank__3 {
      .item__0::before {
        background-image: url("https://akim-oss.aikucun.com/mshop/9d43fecc42b50ec34129cff4365dcadfed307e30_1715418783957_12.png");
      }
    }
  }
}

.rewardRules {
  margin-top: 8px;
  padding: 12px;
  background: #fff0de;
  border: 1px solid #ffe9d0;
  border-radius: 8px;
  box-sizing: border-box;

  .rules_title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #222;
  }
  .rules_subtitle {
    padding-top: 8px;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    text-align: center;
    color: #666;
  }
  .rules_rank {
    margin-top: 16px;
    padding-bottom: 12px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;

    .rank_title {
      padding: 8px 0;
      background: #ffe5c5;

      .item_scope,
      .item_reward {
        font-family: PingFang SC !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 16px !important;
        color: #8c4206 !important;
      }
    }
    .rank_item {
      padding-top: 12px;

      .item_scope {
        display: inline-block;
        width: 45%;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
        color: #222;
        text-align: center;
      }
      .item_reward {
        display: inline-block;
        width: 55%;
        font-family: "DIN-Medium";
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        color: #222;
        text-align: center;
      }
    }
  }
}

.text-ellipsis1 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
