@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  align-items: center;
  padding: 14px 12px;
  margin-bottom: 8px;
  border-radius: 10px;
  background-color: #fff;
  .title {
    font-size: 12px;
    line-height: 16px;
    color: #222222;
  }
  .number {
    margin-right: 4px;
    margin-left: auto;
    display: flex;
    align-items: center;
    .number__text {
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
      color: #222222;
      margin-right: 4px;
    }
    .number__arrow-icon {
      width: 12px;
      height: 12px;
      vertical-align: bottom;
    }
  }
}
