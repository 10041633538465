@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.carousel_swiper_wrap {
  position: relative;
  // padding: 0 10px;

  .entry {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    align-items: center;

    .entry__text {
      padding-right: 2px;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #fff;
    }

    .entry__img {
      width: 14px;
      height: 14px;
    }
  }

  .carousel_swiper {
    padding-top: 2px;
    width: 100%;
    height: 216px;

    .swiper_slide {
      position: relative;
      width: 176px;
      height: 206px;
      background-color: #fff;
      background-position: center;
      background-size: cover;
      border-radius: 10px;
      overflow: hidden;
      box-sizing: border-box;

      .slide_item {
        width: 100%;
        height: 100%;
        object-fit: contain;
        // box-shadow: 0px 2px 16px rgba(222, 120, 120, 1);
      }
    }

    :global(.swiper-slide) {
      // box-shadow: 0px 2px 16px rgba(222, 120, 120, 1);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: rgba(255, 160, 160, 0.3);
      }
    }

    :global(.swiper-slide-prev) {
      // box-shadow: 0px 2px 16px rgba(222, 120, 120, 1);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: rgba(255, 160, 160, 0.3);
      }
    }

    :global(.swiper-slide-next) {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: none;
      }
    }

    :global(.swiper-slide-shadow-right) {
      background-image: none;
    }

    :global(.swiper-slide-shadow-left) {
      background-image: none;
    }
  }
}