@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  flex: 1;
  // padding: 0 12px;
  margin-bottom: 8px;
  background: #fff;
  border-radius: 10px;
  background-image: url(https://akim-oss.aikucun.com/mshop/31638c8d685da2f7a7b147912126d346d379cb27_1723109346961_97.png);
  background-size: 100% 100%;

  .popover-title {
    width: 180px !important;
    color: #fff;
  }

  .card {
    display: flex;
    flex: 1;
    // height: 74px;
    // height: 96px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // padding-top: 3px;
    padding: 16px 16px 12px 12px;

    .tag {
      position: absolute;
      top: -5px;
      // left: -12px;
      left: 0;
      background-image: url(https://akim-oss.aikucun.com/mshop/b43458319d51bc75732f7cd10a785de5f02a3350_1724926569493_25.png);
      background-size: 100% 100%;
      height: 29px;
      width: 58px;
    }

    .card_left {
      display: flex;
      flex-direction: row;
      // overflow: hidden;
      width: 100%;

      .left_content {
        display: flex;
        flex: 1;
        // padding-right: 12px;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        // overflow: hidden;

        .first_container {
          display: flex;
          width: 100%;
        }

        .overdue {
          display: flex;
          align-items: center;
        }

        .popover_container {
          position: relative;

          .hint_message {
            position: absolute;
            display: flex;
            align-items: center;
            width: 180px;
            height: 40px;
            padding: 4px 8px;
            border-radius: 4px;
            background: rgba(34, 34, 34, 0.75);
            color: #fff;
            font-size: 12px;
            box-sizing: border-box;

            &::after {
              content: '';
              position: absolute;
              right: 20px;
              bottom: -6px;
              background: url(https://filetest-oss.aikucun.com/akucun-member-aggregation-file/8fd4870d79a38356ed9ec1585d01945e3019d149_1735631765320_41.png) no-repeat;
              background-size: 100% 100%;
              width: 12px;
              height: 6px;
            }
          }
        }

        .content_title {
          display: flex;
          margin-bottom: 4px;
          overflow: hidden;
          width: 100%;
          align-items: baseline;

          .title_left {
            display: inline-block;
            /* 或者使用 display: block;，取决于你的需求 */
            white-space: nowrap;
            /* 不换行 */
            overflow: hidden;
            /* 隐藏超出的内容 */
            text-overflow: ellipsis;
            /* 添加省略号 */
            text-overflow: ellipsis;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: #fff;
            flex-shrink: 1;
          }

          .title_right {
            display: flex;
            overflow: hidden;
            /* 隐藏超出的内容 */
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            margin-left: 6px;
            align-items: baseline;

            .price_left {
              font-family: DIN-Bold;
              font-size: 14px;
              font-weight: 500;
              display: flex;
              line-height: 20px;
              color: #fff;
            }

            .price_right {
              font-family: DIN-Bold;
              font-size: 20px;
              font-weight: 500;
              line-height: 24px;
              display: flex;
              color: #fff;
            }

            flex-shrink: 0;
          }

          .dec_title {
            display: flex;
            padding-top: 2px;
            margin-left: 10px;
            font-family: PingFang SC;
            font-size: 10px;
            line-height: 14px;
            align-items: center;
            align-self: center;
            color: #fff;
          }

          .dec_image {
            margin-left: 2px;
            padding-top: 2px;
            width: 10px;
            height: 10px;
            align-items: center;
            align-self: center;
          }
        }

        .desc_title {
          display: flex;
          padding-top: 2px;
          font-family: PingFang SC;
          font-size: 12px;
          line-height: 16px;
          color: #fff;
        }

        .total_content {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-top: 2px;
          justify-content: center;

          .c_image {
            display: flex;
            padding-left: 14px;
            width: 14px;
            height: 14px;
            align-items: center;
            align-self: center;
          }

          .c_title {
            display: flex;
            font-family: PingFang SC;
            font-size: 12px;
            line-height: 16px;
            color: #fff;
          }
        }
      }
    }

    .card_right {
      flex-shrink: 0;
      display: flex;
      width: 64px;
      height: 26px;
      border-radius: 4px;
      box-sizing: border-box;
      justify-content: center;
      border: 1px solid #ffffff;
      align-items: center;
      margin-right: 10px;

      .btn_title {
        display: flex;
        align-self: center;
        font-family: PingFang SC;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}