@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.protect-account {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 4px;
  height: 86px;
  border-radius: 10px;
  background: linear-gradient(0deg, #ffeed4 0%, #fff0d5 100%);
  .protect-account_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    padding: 0px 11px;
    .title-l {
      display: flex;
      align-items: center;
      .title_icon1 {
        margin-right: 4px;
        width: 20px;
        height: 20px;
        background: url("https://akim-oss.aikucun.com/mshop/1c32a3d23a472fb39c2d9b79e64686decf76b245_1709546675651_58.png")
          no-repeat;
        background-size: 100% 100%;
      }
      .title_main {
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #8c4206;
      }
    }
    .title-r {
      display: flex;
      align-items: center;
      .title_icon2 {
        margin-left: 2px;
        width: 14px;
        height: 14px;
        background: url("https://akim-oss.aikucun.com/mshop/a817f66f9f5b0b3ff4009a1c0aad4c729372274a_1709547383091_49.png")
          no-repeat;
        background-size: 100% 100%;
      }
      .title_sub {
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
        color: #8c4206;
      }
    }
  }
  .protect-account_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 11px;
    height: 46px;
    border-radius: 8px;
    background: #ffffff;
    font-size: 14px;
    color: #222222;
    .content-l {
      line-height: 20px;
    }
    .content-r {
      display: flex;
      align-items: center;
      line-height: 20px;
    }
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .theme {
    color: #ff4747;
  }
}
.popup_header {
  width: 100%;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  span {
    font-size: 18px;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    font-weight: 500;
    color: #222222;
    line-height: 24px;
  }
  .icon_close {
    position: absolute;
    top: 18px;
    right: 20px;
    width: 16px;
    height: 16px;
    background: url("https://akim-oss.aikucun.com/mshop/6c14bd7f6f9e54e46024d35dfe2c4548c9a3821d_1702626505280_94.png")
      no-repeat;
    background-size: 100% 100%;
  }
}
.popup_content {
  padding: 0px 20px 120px;
  font-family:
    PingFangSC-Regular,
    PingFang SC;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}
