@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: #f1f2f5;

  .headerBg {
    position: absolute;
    display: flex;
    width: 100%;
    height: auto;
    z-index: -1;
  }

  .container {
    position: relative;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    padding-bottom: 110px;
    -webkit-overflow-scrolling: touch;
    z-index: 1;

    .topTitle {
      display: flex;
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 500;
      color: #222222;
      margin-top: 35px;
      margin-bottom: 25px;
    }
    .contentC {
      margin-top: 8px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
  .bottom {
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
    height: 56px;
    display: flex;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #ffffff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    .actionC {
      width: 100vw;
      margin-left: 12px;
      margin-right: 12px;
      margin-top: 6px;
      margin-bottom: 6px;
      background-color: #ff4747;
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .actionTv {
      color: #ffffff;
      font-size: 16px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
    }
  }
}
