@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.videoContainer {
  width: 100vw;
  height: -webkit-calc(100vh - 45px);
  height: -moz-calc(100vh - 45px);
  height: calc(100vh - 45px);
  // height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background: rgba(0, 0, 0);
  position: relative;
  // top: -44px;
  .mySwiper {
    height: -webkit-calc(100vh - 45px);
    height: -moz-calc(100vh - 45px);
    height: calc(100vh - 45px);
    overflow: hidden;
    z-index: 1;
  }
  .tips1 {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 0.5);
    z-index: 0;
  }
  .tips2 {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 0.5);
    z-index: 0;
  }
  .loading {
    img {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 45%;
      left: 45%;
      animation: loading 1s linear infinite;
    }
    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 160px;
    align-items: center;
    background: #fff;
    // background: #FFFFFF;
    .mask1 {
      position: absolute;
      top: 0;
      width: 100%;
      height: 108px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      z-index: 10;
    }
    .mask2 {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 36.95%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 71%);
      z-index: 10;
    }
    img {
      width: 184px;
      height: 184px;
    }
    .errorText {
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      color: #666666;
      padding-bottom: 12px;
    }
    .retry {
      border-radius: 4px;
      opacity: 1;
      box-sizing: border-box;
      border: 1px solid rgba(34, 34, 34, 0.16);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 96px;
      height: 32px;
      color: #222;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.itemWrapper {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
.prev-video {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 52px;
  width: 92px;
  height: 32px;
  border-radius: 0px 24px 24px 0px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: "苹方-简";
  .icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
}

.next-video {
  z-index: 1000;
  right: 0;
  top: 320px;
  position: absolute;
  width: 92px;
  height: 32px;
  border-radius: 24px 0px 0px 24px;
  background: rgba(0, 0, 0, 0.5);
  font-family: "苹方-简";
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0px;
  opacity: 1;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
}
