@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.pdtc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .text {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    color: #333333;
    margin: 0 6px;
  }
  .flag {
    width: 14px;
    height: 11px;
  }
}
