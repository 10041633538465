@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.comp {
  align-items: center;
  flex-direction: column;

  .prog_container {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;

    .prog_item {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;

      .prog_prog {
        width: 100%;
        height: 8px;
        margin-top: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .prog_left {
        height: 100%;
        width: 50%;
      }

      .prog_left_first {
        height: 100%;
        width: 30%;
      }

      .prog_left_last {
        height: 100%;
        width: 60%;
      }

      .prog_nor {
        background-color: #f1f2f5;
      }

      .prog_high {
        background-color: #ff4747;
      }

      .prog_transparent {
        background-color: transparent;
      }

      .prog_right {
        height: 100%;
        width: 50%;
      }

      .prog_right_first {
        height: 100%;
        width: 60%;
      }

      .prog_right_last {
        height: 100%;
        width: 30%;
      }

      .prog_status {
        height: 16px;
        width: 16px;
        margin-top: -12px;
      }

      .prog_status_first {
        height: 16px;
        width: 16px;
        margin-top: -12px;
        margin-left: -20px;
      }

      .prog_status_last {
        height: 16px;
        width: 16px;
        margin-top: -12px;
        margin-left: 20px;
      }

      .prog_title {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        color: #222;
        margin-top: 8px;
      }

      .prog_time {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        font-weight: 400;
        color: #a5a5a5;
        margin-top: 2px;
      }
    }
  }

  .refuse {
    flex: 1;
    background-color: #ff660014;
    padding: 8px 12px;
    border-radius: 6px;
    margin: 0px 16px;

    .refuse_title {
      font-family: PingFang SC;
      font-size: 12px;
      color: #ff6600;
      white-space: pre-wrap;
    }
  }

  .arrow {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-top: 2px;

    .item {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .arrow_item {
      height: 6px;
      width: 12px;
    }
  }

  .line {
    margin: 16px 12px;
    background-color: #22222214;
    height: 0.5px;
  }
}
