@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.overlayContent {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 373.5px;
  padding-top: 152px;
  box-sizing: border-box;
  height: 417px;
  transform: translate(-50%, -50%);
  background: url(https://akim-oss.aikucun.com/c06eec96bd6acad653574514a2864284aac56cff_1710843236212_91.png)
    center top no-repeat;
  background-size: 100% auto;
  overflow: hidden;
}

.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
}

.cornerLabel {
  position: absolute;
  left: 0;
  top: -9px;
  padding: 1px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #8c4206;
  border-radius: 6px 1px 6px 0px;
  background: #ffdfc5;
  box-sizing: border-box;
  border: 0.5px solid #ffffff;
}

.longCard {
  position: relative;
  display: flex;
  width: 286px;
  height: 74px;
  align-items: center;
  background: url(https://akim-oss.aikucun.com/77de68daecd823babbb58edb1c8e14d7106e83bb_1710851019808_43.png)
    left top no-repeat;
  background-size: 100%;
  .left {
    display: flex;
    flex-shrink: 0;
    width: 76.5px;
    justify-content: center;
    align-items: baseline;
    color: #ff4747;
    font-size: 14px;
    .price {
      transform: translateY(2px);
      padding-right: 2px;
      font-size: 36px;
      font-weight: bold;
      font-family: "DIN-Bold";
    }
  }
  .right {
    flex: 1;
    padding: 0 10.5px;
    color: #666666;
    .title {
      font-size: 14px;
      color: #222222;
    }
    .red {
      color: #ff4747;
    }
    .desc {
      font-size: 12px;
    }
  }
}

.list {
  width: 238px;
  display: flex;
  gap: 18px;
  &.longList {
    width: 288px;
    gap: 6px;
  }
}

.normalCard {
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
  background: url("https://akim-oss.aikucun.com/mshop/debb5077d5ccc1becd3007e9f26b76a34fe03261_1708842518648_62.png")
    no-repeat;
  background-size: 100% 100%;
  .title {
    padding-top: 14px;
    font-size: 12px;
    font-weight: 500;
    color: #222222;
  }
  .cont {
    line-height: 35px;
    font-size: 14px;
    color: #ff4747;
    .price {
      font-size: 36px;
      font-weight: bold;
      font-family: "DIN-Bold";
    }
  }
  .desc {
    display: flex;
    width: 92%;
    height: 28.5px;
    align-items: center;
    justify-content: center;
    border-top: 1px dashed #c2ac98;
    font-size: 12px;
    color: #666666;
  }
  .red {
    color: #ff4747;
  }
}

.longBtn {
  position: absolute;
  bottom: 34.5px;
  display: flex;
  width: 260px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  opacity: 1;
  background: linear-gradient(172deg, #fbead3 6%, #ffdaaa 77%);
  border-image: linear-gradient(180deg, #fcf5ec 0%, #ffc67d 100%) 1;
  font-size: 16px;
  color: #ed300a;
  font-weight: 500;
}
