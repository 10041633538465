@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.toSeeAllView {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 999;


  .toSeeAll {
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;

    // width: 88px;
    height: 26px;
    /* 自动布局 */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    /* 圆角4 */
    border-radius: 4px;
    opacity: 1;
    box-sizing: border-box;
    /* 分割线色/#222222 0.16a_分割线_hard */
    border: 1px solid rgba(34, 34, 34, 0.16);

  }

}