@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 12px;

  & > * {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }

  .tab__item {
    position: relative;
    box-sizing: border-box;
    padding: 6px 12px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666666;
    border-radius: 4px;
    background-color: #f1f2f5;

    &.active {
      font-weight: 500;
      color: #ffffff;
      background-color: #ff4747;

      .badge::before {
        border-width: 2px;
      }
    }

    .badge {
      position: absolute;
      top: -4px;
      right: -4px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      min-width: 12px;
      height: 12px;
      background-color: #ffffff;
      border-radius: 12px;

      &::before {
        content: attr(data-label);
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 6px;
        min-width: 200%;
        height: 200%;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        color: #ff4747;
        text-align: center;
        border: 1px solid #ff4747;
        border-radius: 24px;
        transform: scale(0.5);
        transform-origin: 100% 0;
        background-color: #ffffff;
      }
    }
  }
}
