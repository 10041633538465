@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.giveGiftDialog {
    width: 100vw;
    min-height: 35vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-sizing: border-box;
    overflow: scroll;
    background: linear-gradient(0deg, #FFFFFF 44%, #FFF1E6 100%, #FFD1AB 100%);

    .top {
        width: 100vw;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: sticky;
        top: 0;

        .title {
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;
            color: #222222;
        }

        .close {
            width: 18px;
            height: 18px;
            position: absolute;
            right: 20px;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        margin-top: 4px;
        align-items: flex-start;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 34px;

        .content {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            .contentTitle {
                font-family: PingFang SC;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0px;
                color: #222222;
            }

            .contentDesc {
                font-family: PingFang SC;
                font-size: 12px;
                font-weight: normal;
                line-height: 16px;
                letter-spacing: 0px;
                color: #666666;
                margin-top: 8px;
            }
        }
    }

}