@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  background-color: white;

  .left {
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #666666;
  }

  .center {
    flex: 1;
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #222222;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 16px;
    margin-right: 20px;
  }

  .arrow {
    width: 12px;
    height: 12px;
  }
}
