@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  background: #f5f5f5;
  .container {
    flex: 1;
    padding: 12px;
    .main {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      margin-top: 94px;
      color: #666666;
      font-size: 14px;
      .statusIcon {
        width: 52px;
        height: 52px;
        margin-bottom: 24px;
      }
      .title {
        padding-bottom: 8px;
        font-size: 22px;
        font-weight: 500;
        color: #222222;
      }
      .hl {
        color: #222222;
      }
    }
    .footer {
      position: fixed;
      left: 0;
      bottom: 50px;
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
      .longBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        border-radius: 6px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        background: #ff4747;
      }
    }
  }
}
