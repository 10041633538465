@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.top_list {
  width: 100vw;
  height: 100vh;
  display: flow-root;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  background: #f1f2f5;
  position: relative;
  .top_padding {
    height: 174px;
    background: linear-gradient(110deg, #fc863c, #f5600f 64%);
  }
  .tab_bg {
    margin-top: -150px;
    border-radius: 18px 18px 0 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #f1f2f5 62%);
    height: 152px;
    opacity: 1;
  }

  .toplist {
    margin-top: -150px;
    border-radius: 18px;
    height: calc(100vh - 24px - 54px);
    .nav {
      display: flex;
      align-items: center;
      z-index: 100;
      position: sticky;
      top: 0;
      justify-content: space-around;
      padding: 12px 16px;
      width: 100%;
      box-sizing: border-box;
      .fontImg {
        position: relative;
        z-index: 1;
        width: 51px;
        height: 15px;
      }
      .activeTabImg {
        position: relative;
        z-index: 1;
        width: 62px;
        height: 18px;
      }
      .font {
        position: relative;
        z-index: 1;
      }
      .line {
        width: 60px;
        height: 10px;
        background: linear-gradient(270deg, #ffa349 0%, #ff2b2b 100%);
        border-radius: 5px;
        position: absolute;
        bottom: 15px;
      }
    }
    .nav .item {
      font-size: 15px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #222222;
      display: flex;
      justify-content: center;
    }
    .nav .active {
      font-size: 18px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      color: #222222;
    }

    .swiper-container {
      height: calc(100vh - 24px - 54px);
      overflow: scroll;
    }
    .allCotent {
      position: relative;
      padding: 0 0 9px 0;
      margin: 0 12px;
      .contentDiv {
        .contentOut {
          padding: 0px 12px 0 6px;
          background-color: #ffff;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          .top {
            flex-shrink: 0;
            position: relative;
            .top_level {
              img {
                top: 6px;
                left: 6px;
                height: 25px;
                height: 32.66px;
                position: absolute;
                z-index: 100;
              }
            }
            .one1 {
              width: 100%;
              min-height: 133px;
              background: #ffffff;
              border-radius: 10px;
              padding: 6px 0;
              box-sizing: border-box;
              display: flex;
              .img {
                width: 120px;
                height: 120px;
                border-radius: 8px;
                flex-shrink: 0;
                position: relative;
                .img_play {
                  width: 18px;
                  height: 18px;
                  position: absolute;
                  bottom: 6px;
                  left: 6px;
                  z-index: 9;
                }
                .live {
                  position: absolute;
                  width: 78px;
                  height: 28px;
                  background: rgb(0 0 0 / 40%);
                  border-radius: 100px;
                  top: 5px;
                  left: 5px;
                  z-index: 9;
                  display: flex;
                  align-items: center;
                  .text {
                    font-size: 12px;
                    font-family:
                      PingFangSC-Regular,
                      PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    padding-left: 7px;
                  }
                }
                .save_money_content {
                  position: absolute;
                  bottom: 0;
                  background-color: rgba(251, 73, 59, 1);
                  border-bottom-left-radius: 8px;
                  border-top-right-radius: 8px;
                  display: flex;
                  align-items: center;
                  max-width: 100%;
                  .save_money {
                    background: rgba(255, 224, 234, 0.9);
                    font-size: 10px;
                    font-family:
                      PingFangSC-Medium,
                      PingFang SC;
                    font-weight: 500;
                    color: #fb493b;
                    border-bottom-left-radius: 8px;
                    border-top-right-radius: 8px;
                    padding: 0px 6px;
                    white-space: nowrap;
                    line-height: 18.5px;
                  }
                  .add {
                    color: #ffffff;
                    padding: 0 3px;
                    line-height: 18.5px;
                    flex-shrink: 1;
                    font-size: 10px;
                    white-space: nowrap;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                  }
                }
                .sell_out {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  background: rgba(255, 255, 255, 0.5);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  z-index: 1;
                  .sell_out_circle {
                    width: 79px;
                    height: 79px;
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 50%;
                    text-align: center;
                    font-size: 18px;
                    font-family:
                      PingFangSC-Semibold,
                      PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                    line-height: 79px;
                  }
                }
                .score {
                  position: absolute;
                  bottom: 7px;
                  height: 16px;
                  background: rgba(255, 240, 239, 0.9);
                  display: flex;
                  z-index: 10;
                  color: rgba(255, 48, 45, 1);
                  align-items: center;
                  padding-left: 4px;
                  max-width: 100%;
                  box-sizing: border-box;
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                  .score_text {
                    white-space: nowrap;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                  }
                  .img_score {
                    width: 14px;
                    height: 14px;
                    flex-shrink: 0;
                  }
                }
              }
              .content {
                padding-left: 6px;
                padding-top: 3px;
                width: calc(100% - 126px);
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                .label {
                  display: flex;
                  align-items: center;
                  .label_img {
                    flex-shrink: 0;
                    width: 30px;
                    height: 16px;
                  }
                  .label_yu {
                    width: 20px;
                    height: 13px;
                    flex-shrink: 0;
                  }
                  .name {
                    font-size: 14px;
                    font-family:
                      PingFangSC-Medium,
                      PingFang SC;
                    font-weight: 500;
                    color: #383838;
                    line-height: 20px;
                    white-space: nowrap;
                    padding-left: 3px;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                  }
                }
                .stasticsInfo_label {
                  background: #fdede3;
                  border-radius: 5px;
                  // margin-top: 4px;
                  margin-bottom: 4px;
                  // font-family: 苹方-简;
                  font-size: 10px;
                  font-weight: 500;
                  // line-height: 12px;
                  letter-spacing: 0px;
                  width: max-content;
                  padding: 0 6px;
                  color: #a2633a;
                  display: flex;
                  align-items: center;
                  height: 17px;
                  // padding-top: 1px;
                  img {
                    margin-left: 1px;
                    width: 11.8px;
                    // height: 17px;
                  }
                }
                .discount_label {
                  display: flex;
                  align-items: center;
                  margin-top: 2px;
                  flex-wrap: wrap;
                  width: 100%;
                  overflow: hidden;
                  // flex-wrap: nowrap;
                  margin-bottom: 4px;
                  height: 15px;
                  // flex-wrap: nowrap;
                  // flex-wrap: nowrap;
                  // margin-bottom: 4px;
                  .discount_label_item {
                    width: 42px;
                    height: 15px;
                    border-radius: 4px;
                    border: 1px solid rgb(255 102 0 / 80%);
                    color: rgb(255 102 0 / 80%);
                    text-align: center;
                    line-height: 15px;
                    margin-right: 3px;
                    margin-bottom: 3px;
                  }
                }
                .style_list {
                  display: flex;
                  width: fit-content;
                  .style_list_item {
                    margin-right: 3px;
                    width: 28px;
                    height: 28px;
                    background: #f3f4f6;
                    border-radius: 4px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    line-height: 12px;
                    position: relative;
                    justify-content: center;
                    .item_img {
                      width: 100%;
                      height: 100%;
                      border-radius: 4px;
                    }
                    .item_font {
                      transform: scale(0.8);
                    }
                    .item_sell_out {
                      transform: scale(0.9);
                      font-size: 10px;
                      font-family:
                        PingFangSC-Regular,
                        PingFang SC;
                      font-weight: 400;
                      color: #393737;
                      position: absolute;
                      display: flex;
                      align-items: center;
                    }
                  }
                }
                .price_btn {
                  margin-top: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .price {
                    display: flex;
                    width: calc(100% - 76px);
                    line-height: 22px;
                    align-items: flex-end;
                    .price_text {
                      color: #ff302d;
                      display: flex;
                      height: 19px;
                      align-items: flex-end;
                      transform: scale(0.9);
                      flex-shrink: 0;
                    }
                    .sec_kill {
                      font-size: 10px;
                      font-family:
                        PingFangSC-Medium,
                        PingFang SC;
                      font-weight: 500;
                      color: #ff302d;
                      padding-top: 4px;
                      padding-right: 2px;
                      flex-shrink: 0;
                    }
                    .price_real {
                      font-size: 14px;
                      font-family:
                        PingFangSC-Medium,
                        PingFang SC;
                      font-weight: 500;
                      color: #ff302d;
                      display: flex;
                      align-items: flex-end;
                      .price_big {
                        padding-left: 2px;
                        font-size: 16px;
                        padding-bottom: 1px;
                      }
                    }
                    .price_old {
                      font-size: 12px;
                      font-family:
                        PingFangSC-Medium,
                        PingFang SC;
                      font-weight: 500;
                      color: #a5a5a5;
                      padding-left: 3px;
                      text-decoration: line-through;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      padding-top: 4px;
                      white-space: nowrap;
                    }
                  }
                  .btn,
                  .btn_save {
                    width: 73px;
                    height: 22px;
                    background: linear-gradient(270deg, #ff7346 0%, #ff2b2b 100%);
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                    .btn_img {
                      width: 16px;
                      height: 16px;
                    }
                    .btn_font {
                      font-size: 12px;
                      font-family:
                        PingFangSC-Medium,
                        PingFang SC;
                      font-weight: 500;
                      color: #ffffff;
                    }
                  }
                  .btn_pre {
                    width: 73px;
                    height: 22px;
                    background: rgba(255, 102, 0, 1);
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                    .btn_img {
                      width: 16px;
                      height: 16px;
                    }
                    .btn_font {
                      font-size: 12px;
                      font-family:
                        PingFangSC-Medium,
                        PingFang SC;
                      font-weight: 500;
                      color: #ffffff;
                    }
                  }
                  .btn_save {
                    background: linear-gradient(270deg, #fd7921 0%, #ff6600 100%);
                  }
                  .btn_buy {
                    width: 68px;
                    height: 22px;
                  }
                  .buy_btn {
                    width: 68px;
                    position: relative;
                    .btn_img {
                      width: 68px;
                      height: 22px;
                    }
                    .buy_text {
                      font-size: 12px;
                      font-family:
                        PingFangSC-Medium,
                        PingFang SC;
                      font-weight: 500;
                      color: #ffffff;
                      position: absolute;
                      top: 10%;
                      left: 20%;
                      white-space: nowrap;
                    }
                  }
                }
              }
            }
          }
          .kind {
            padding-top: 6px;
            padding-bottom: 12px;
            flex-grow: 1;
            display: flex;
            align-items: center;
            .active_item {
              width: 46px;
              height: 46px;
              background-color: rgba(255, 48, 45, 1);
              border-radius: 6px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 24px;
              flex-shrink: 0;
              .triangle {
                width: 0;
                height: 0;
                border-bottom: 5px solid rgba(255, 48, 45, 1);
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                position: absolute;
                top: -4px;
                left: 17px;
              }
              .active_item_img {
                width: 42px;
                height: 42px;
                border-radius: 6px;
              }
            }
            .normal_item {
              width: 42px;
              height: 42px;
              background-color: rgba(241, 242, 245, 1);
              border-radius: 6px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 24px;
              flex-shrink: 0;
              .active_item_img {
                width: 38px;
                height: 38px;
                border-radius: 6px;
              }
            }
            .kindMore {
              width: 73px;
              height: 38px;
              background: #f1f2f5;
              border-radius: 6px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .imgMore {
                width: 10px;
                height: 10px;
              }
              .text {
                font-size: 12px;
                font-family:
                  PingFangSC-Regular,
                  PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 15px;
                padding-top: 2px;
              }
            }
          }
        }
      }
    }
  }
  .nullStatus {
    width: 100%;
    height: calc(100vh - 154px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .noSecKill {
      width: 183px;
      height: 183px;
      .text {
        font-size: 14px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.top_list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
}
.loadingMore {
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
