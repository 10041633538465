@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  height: 150px;
  width: 55px;
  background: transparent;
  position: relative;

  .bubble {
    height: 75px;
  }

  .heart {
    position: absolute;
    width: 26px;
    bottom: 60px;
    left: 14.5px;
  }

  .contentWrapper {
    border-top-left-radius: @smallBorderRadius;
    border-top-right-radius: @smallBorderRadius;
    .content {
      border-top-left-radius: @smallBorderRadius;
      border-top-right-radius: @smallBorderRadius;
      height: 55px;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  .action {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-size: 11px;
    color: rgba(255, 255, 255, 1);
    height: 20px;
    background: linear-gradient(90deg, rgba(255, 70, 86, 1) 0%, rgba(255, 16, 16, 1) 100%);
    border-radius: 0 0 @smallBorderRadius @smallBorderRadius;
    &.preheat {
      background: linear-gradient(90deg, rgba(253, 132, 49, 1) 0%, rgba(255, 60, 0, 1) 100%);
    }
  }
}
