@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/
  -webkit-user-select: none;
  user-select: none;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background: #f1f2f5;
  position: relative;
  box-sizing: border-box;
  display: flex;
  overflow: scroll;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;

  .page__header {
    width: 100vw;

    .header__bg {
      padding: 0;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100px;
      background: #fff;
      overflow: hidden;

      .bg__image {
        width: 100vw;
        height: auto;
      }

      .bg__instruct {
        position: absolute;
        display: flex;
        left: 0px;
        top: 44px;
        width: 68px;
        height: 24px;
        border-radius: 0px 24px 24px 0px;
        opacity: 1;
        background: #ffd4b6;

        .instruct__title {
          display: flex;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #222222;
          align-self: center;
          padding-left: 8px;
        }
      }

      .bg__brand {
        position: absolute;
        display: flex;
        top: 20px;
        height: 30px;
        align-items: center;
        overflow: visible;

        .brand__card {
          position: relative;
          display: flex;

          .card__image {
            position: relative;
            display: flex;
            width: 30px;
            padding-left: -15px;
            height: 30px;
            background-color: #ff6600;
            border-radius: 4px;
            opacity: 1;
            box-sizing: border-box;
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }

  .header__notice {
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 32px;
    background: #fff4e6;
    box-sizing: border-box;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;

    .notice__left {
      color: #222;
    }

    .notice__right {
      display: flex;
      align-items: center;
      color: #ff6600;

      .icon__arrow {
        margin-left: 2px;
        width: 12px;
        height: 12px;
      }
    }
  }

  .top__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background: #fff;
    z-index: 1000;
    flex-direction: column;

    &.header__stick {
      top: 0px;
      /* 当滚动到顶部时吸顶 */
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 100%;
      position: fixed;

      &.stick__h5 {
        top: 43px;
      }
    }

    .header__tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      background: #fff;

      .tabs__item {
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #666;
        margin-left: 12px;
        margin-right: 12px;
      }

      .tabs__item--active {
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #222;
        margin-left: 12px;
        margin-right: 12px;

        &::before {
          content: "";
          position: absolute;
          bottom: -7px;
          left: 0;
          right: 0;
          margin: auto;
          width: 24px;
          height: 3px;
          background: #ff4747;
        }
      }
    }

    .category__hidden {
      display: none;
    }

    .category__show {
      display: flex;
    }
  }

  .header__tips {
    padding: 0 12px;
    display: flex;
    align-items: center;
    height: 36px;
    background: #f1f2f5;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #666;
  }

  .list {
    display: flex;
    width: 100vw;

    &.list_stick {
      padding-top: 70px;
    }

    .list_hidden {
      width: 100vw;
      display: none;
    }

    .list_show {
      width: 100vw;
      display: flex;
      height: 100vh;
    }

    .bottom {
      position: fixed;
      z-index: 100;
      bottom: 0;
      height: 56px;
      display: flex;
      flex-direction: row;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      left: 0px;
      right: 0px;
      justify-content: space-between;
      background-color: #fff;

      .bottom__left {
        display: flex;
        flex-direction: row;
        padding-left: 20px;
        padding-top: 18px;
        justify-content: center;

        .left__title {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #222222;
        }

        .left__red {
          padding-left: 3px;
          color: #ff4747;
        }

        .left_arrow {
          padding-left: 4px;
          align-self: fl;
          width: 12px;
          height: 12px;
          padding-top: 5px;
        }
      }

      .bottom__right {
        display: flex;
        flex-direction: row;
        margin-right: 12px;
        margin-top: 6px;
        height: 44px;
        width: 171px;
        justify-content: center;
        align-items: center;
        background-color: #ff4747;
        border-radius: 6px;

        .right__title {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}
