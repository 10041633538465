@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  width: 375px;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  background: #f1f2f5;
  padding-bottom: calc(env(safe-area-inset-bottom) + 90px);

  .topStable {
    width: 130px;
    height: 32px;
  }

  .topBg {
    width: 100vw;
    position: absolute;
    top: 0;
    height: 200px;
    z-index: 1;
  }

  .body {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: 12px 12px 0px 12px;

    .loading {
      margin: auto;
      width: 30px;
      height: 30px;
      transform: rotate(360deg);
      -webkit-transition: -webkit-transform 1s linear;
      transition: transform 1s linear;
      -webkit-animation: spin 1s linear 1s 5 alternate;
      animation: spin 1s linear infinite;
    }

    .loadingFixed {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }

      to {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    .topC {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      align-items: center;

      .swipC {
        flex: 1;
        position: relative;
        overflow: hidden;

        .swip {
          display: inline-block;
          height: 40px;

          .swipItem {
            height: 30px;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            border-radius: 16px;
            opacity: 1;
            background: rgba(255, 255, 255, 0.65);
            /* 毛玻璃/毛玻璃 */
            backdrop-filter: blur(20px);
            padding-left: 5px;
            padding-right: 8px;

            .swipItemIcon {
              width: 20px;
              height: 20px;
              border: 1px solid #fff;
              border-radius: 10px;
            }

            .swipItemName {
              font-family: PingFang SC;
              font-size: 12px;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: 0px;
              color: #222222;
              margin-left: 6px;
              max-width: 170px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .countDown {
        margin-top: 7px;
      }
    }

    .productCardInfo {
      margin-top: 15px;
    }

    .part {
      margin-top: 8px;
    }

    .resAndAttr {
      display: flex;
      flex-direction: column;
      background-color: white;
      margin-top: 8px;
      border-radius: 10px;
      padding-bottom: 12px;

      .fenge {
        margin: 12px 12px 0px 12px;
      }

      .productAttr {
        padding-top: 7px;
      }

      .imgProductSize {
        width: 100%;
      }
    }

    .productCard {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 10px;
      margin-top: 8px;

      .pCard {
        border-width: 0px;
        border-color: transparent;
      }

      .medicationGuidanceComp {
        margin: 0px 8px 8px 8px;
      }
    }
  }

  .bottomBtns {
    width: 100vw;
    position: fixed;
    bottom: 0;
    z-index: 10;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: calc(env(safe-area-inset-bottom));

    .couponTips {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 8px 12px;

      .couponsContents {
        font-size: 12px;
        color: #ff302d;
      }

      .couponDesc {
        font-size: 12px;
        color: #222222;
      }
    }
  }
}