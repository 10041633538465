@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  background: #f7f7f7;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  .search__box {
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
  }

  .header {
    position: relative;
    box-sizing: border-box;
    padding: 6px 16px;
    width: 100vw;
    background: #ffffff;
    z-index: 10;

    .search {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      border-radius: 6px;
      background: #f1f2f5;

      .icon {
        margin: 0 4px 0 8px;
        width: 18px;
        height: 18px;
      }

      .text {
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        color: #a5a5a5;
      }
    }
  }

  .header_h5 {
    padding-top: 6px;
    display: flex;
    justify-content: flex-end;

    .header__search_icon {
      width: 32px;
      height: 32px;
    }
  }

  .banner {
    position: relative;
    box-sizing: border-box;
    padding: 4px 15px;
    width: 100vw;
    background: #ffffff;
    z-index: 10;

    &>div {
      width: 100%;
      height: 94px;
      border-radius: 10px;
      box-sizing: border-box;
      background-size: 100% 100%;
      background-color: #f5444a;
      overflow: hidden;
    }
  }

  .filters {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    position: sticky;
    top: 0;
    z-index: 10;

    &.filtersOpen {
      position: fixed;
    }

    .filterTab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 0 16px;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      color: #666666;

      &>* {
        padding-right: 24px;
        transition: all 0.1s;

        &:last-child {
          padding-right: 0;
        }

        &.active {
          color: #ff4747;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .filterList {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 12px;

      &>* {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }
      }

      .filterItem {
        position: relative;
        box-sizing: border-box;
        padding: 6px 12px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666666;
        border-radius: 4px;
        background-color: #f1f2f5;

        &.active {
          font-weight: 500;
          color: #ffffff;
          background-color: #ff4747;

          .badge::before {
            border-width: 2px;
          }
        }

        .badge {
          position: absolute;
          top: -4px;
          right: -4px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          min-width: 12px;
          height: 12px;
          background-color: #ffffff;
          border-radius: 12px;

          &::before {
            content: attr(data-label);
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            right: 0;
            padding: 0 6px;
            min-width: 200%;
            height: 200%;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
            color: #ffffff;
            text-align: center;
            border: 1px solid #ffffff;
            border-radius: 24px;
            transform: scale(0.5);
            transform-origin: 100% 0;
            background-color: #ff4747;
          }
        }
      }
    }

    .filterListBox {
      padding: 8px 12px 10px;
    }

    .pb12 {
      padding-bottom: 12px;
    }

    .moreFilter {
      position: absolute;
      padding-top: 10px;
      width: 100vw;
      box-shadow: inset 0px 1px 0px 0px rgba(233, 233, 233, 1);
      background: #ffff;
      z-index: 1;

      .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 12px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;

        .reset {
          flex: 1;
          height: 32px;
          background: rgba(255, 71, 71, 0.08);
          box-sizing: border-box;
          border: 1px solid #ff4747;
          border-radius: 4px;
          color: #ff4747;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .submit {
          flex: 1;
          margin-left: 8px;
          height: 32px;
          background: #ff4747;
          box-sizing: border-box;
          border-radius: 4px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
        }
      }
    }
  }

  .content {
    position: relative;
    padding: 12px;
    z-index: 1;
  }

  .contentTips {
    // margin-top: -12px;
    padding: 8px 12px 0;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #666666;

    .tipsNum {
      font-family: PingFang SC;
      font-weight: 500;
      color: #222222;
    }
  }
}

.container {
  &.mask {

    // overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      z-index: 5;
    }
  }
}

.tabs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  background: #ffffff;
  z-index: 10;

  .tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "苹方-简";
    font-size: 14px;
    color: #595959;
    //字体大小渐变
    transition: all 0.1s;
  }

  .active {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    // transform: scale(1.15);
  }

  .line {
    height: 3px;
    background: #ff585e;
    position: absolute;
    bottom: 3px;
    transition: transform 0.1s;
  }
}

.infinite {
  :global(.adm-image-tip) {
    display: none;
  }
}

.loadingMore {
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.followWrap {
  padding-top: 6px;
  background: #ffffff;
}