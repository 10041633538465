@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.shopServiceCard {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 12px 0px 4px;
  border-radius: 10px;
  background: #ffffff;
  .serviceTitle {
    padding-left: 12px;
    font-size: 14px;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #222222;
  }
  .serviceList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
    .serviceItem {
      width: 20%;
      height: 76px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
      }
      .serviceName {
        margin-top: 4px;
        font-size: 12px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: 400;
        line-height: 16px;
        color: #222222;
      }
    }
  }
}
