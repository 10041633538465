@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  flex: 1;
  padding: 10px 12px;
  margin-bottom: 8px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: space-between;
    .top_left {
      font-family: PingFang SC;
      font-size: 12px;
      color: #a5a5a5;
      line-height: 16px;
      margin-bottom: 10px;
    }
    .top_right {
      font-family: PingFang SC;
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      margin-bottom: 10px;
    }
    .top_right_r {
      color: #ff4747;
    }
  }

  .topLine {
    display: flex;
    height: 1px;
    margin-bottom: 10px;
  }

  .card {
    display: flex;
    width: 100%;
    flex: 1;
    position: relative;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;

    .card_left {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: row;
      overflow: hidden;
      align-self: center;
      .left_image {
        width: 56px;
        height: 56px;
        border-radius: 6px;
        align-self: center;
        box-sizing: border-box;
        object-fit: contain;
        border: 0.5px solid rgba(34, 34, 34, 0.08);
      }
      .left_content {
        display: flex;
        flex: 1;
        margin-left: 8px;
        padding-right: 12px;
        align-items: flex-start;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        .content_title {
          display: flex;
          overflow: hidden;
          width: 100%;
          .title_left {
            text-overflow: ellipsis;
            font-family: PingFang SC;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏超出的内容 */
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: #222;
            flex-shrink: 1;
          }
          .title_image {
            width: 14px;
            height: 14px;
            padding-left: 1px;
            align-self: center;
          }
        }

        .content_price {
          display: flex;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .price_title {
            display: flex;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            color: #ff4747;
          }
          .desc_title {
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            color: #222222;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .content_space {
          margin-top: 7px;
        }

        .content_desc {
          width: 100%;
          font-family: PingFang SC;
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;
          color: #666;
          flex-shrink: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .bottomLine {
    display: flex;
    height: 1px;
    margin-top: 10px;
  }

  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;

    .bottom_left {
      display: flex;
      align-items: center;

      .left_content {
        display: flex;
        flex-direction: row;
        align-items: center;
        .c_image {
          width: 20px;
          height: 20px;
          border-radius: 10px;
        }
        .c_content {
          display: flex;
          align-self: center;
          font-family: PingFang SC;
          font-size: 12px;
          padding-left: 3px;
          color: #666;
        }
        .c_arrow {
          width: 14px;
          height: 14px;
        }
      }
    }

    .bottom_right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .right_normal {
        display: flex;
        width: 74px;
        height: 26px;
        border-radius: 4px;
        justify-content: center;
        background: rgba(255, 71, 71, 0.08);
        box-sizing: border-box;
        border: 0.5px solid #ff4747;
        .btn_title {
          display: flex;
          align-self: center;
          font-family: PingFang SC;
          font-size: 12px;
          color: #ff4747;
        }
      }

      .right_red {
        display: flex;
        width: 74px;
        height: 26px;
        border-radius: 4px;
        box-sizing: border-box;
        justify-content: center;
        background: #ff4747;
        align-items: center;
        .btn_title {
          display: flex;
          align-self: center;
          font-family: PingFang SC;
          font-size: 12px;
          color: #fff;
        }
      }

      .right_unable {
        background: rgba(255, 71, 71, 0.5);
      }

      .right_space {
        margin-right: 8px;
      }
    }
  }
}
