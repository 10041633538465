@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.couponContent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 330px;
  padding: 25px 8px 100px;
  border-radius: 20px;
  background: linear-gradient(112deg, #fc483f 5%, #fb8653 75%);
  z-index: 10;
  box-sizing: border-box;
  .iconClose {
    position: absolute;
    top: -26px;
    right: 10px;
    width: 16px;
    height: 16px;
    background: url("https://akim-oss.aikucun.com/mshop/b2cf17403df560247ff69f9fe21289289f05867c_1706766191965_25.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .couponTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    .iconTitle1 {
      width: 110px;
      height: 26px;
      background: url("https://akim-oss.aikucun.com/mshop/3f9641d5b4b896e22a2a4ecb4b5df6699bf4106d_1706787763327_81.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .iconTitle2 {
      width: 82px;
      height: 26px;
      background: url("https://akim-oss.aikucun.com/mshop/cce7435a064478d60b87af3106f843466bd4b480_1706787781474_58.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .amount {
      margin: 0 6px;
      font-family: "DIN-Bold";
      font-size: 30px;
      font-weight: bold;
      color: #f8f56a;
    }
  }
  .couponLabel {
    margin: 4px auto 2px;
    width: 236px;
    height: 18px;
    background: url("https://akim-oss.aikucun.com/mshop/5d4f2c04ce32072bac747075997dfe4ad8d925b3_1706788763807_4.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .couponBtn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
    width: 260px;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    color: #ed300a;
    font-weight: 500;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    text-align: center;
    border-radius: 6px;
    background: linear-gradient(172deg, #fbead3 6%, #ffdaaa 77%);
  }
  .couponIcon {
    position: absolute;
    left: -16px;
    bottom: 64px;
    width: 370px;
    height: 88px;
    background: url("https://akim-oss.aikucun.com/mshop/c1c50bf5a6fe4eb61580701d39a2cb46796008b8_1707122132972_16.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .couponNewcomer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;
    padding: 10px 13px;
    height: 200px;
    background: url("https://akim-oss.aikucun.com/mshop/f2899e7178e9c2a5b674a0fe3dacbaf31cc6f6cb_1706787538304_85.png")
      no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    &.couple {
      padding-left: 38px;
      padding-right: 38px;
    }
    &.single {
      height: 184px;
      padding-left: 14px;
      padding-right: 14px;
      padding-bottom: 16px;
      background: url("https://akim-oss.aikucun.com/mshop/71c80b2f8327ee62c856a49e61b97e0a05aeef42_1707116552893_71.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .couponVenue {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;
    padding: 10px 0px;
    height: 162px;
    background: url("https://akim-oss.aikucun.com/mshop/298dd72b7ad9b46e80e09191aeb7bae4066fed5a_1706787334027_1.png")
      no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    .couponVenueList {
      width: 100%;
      display: flex;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      box-sizing: border-box;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
    .flex {
      display: flex;
      box-sizing: border-box;
    }
    &.single {
      height: 130px;
      padding-left: 14px;
      padding-right: 14px;
      padding-bottom: 14px;
      background: url("https://akim-oss.aikucun.com/mshop/7eb770389e71c48ca3befa93cffad2bd5c85d2e7_1708503680145_34.png")
        no-repeat;
      background-size: 100% 100%;
    }
    &.couple {
      padding-left: 38px;
      padding-right: 38px;
    }
    &.triple {
      padding-left: 13px;
    }
  }
}
