@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "Alimama ShuHeiTi";
  src: url("https://akim-oss.aikucun.com/e0fca7a890887dd1980587e12cdb6e78e25dfa8f_1742894800773_63.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.container {
  width: 100%;
  height: 70px;
  background-image: url("https://akim-oss.aikucun.com/c713b52a0065fd765a5996f01f3bf1a9e92c4e58_1743582434080_62.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
  box-sizing: border-box;
}

.title {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 4px;
}

.titleRed {
  font-family: "Alimama ShuHeiTi", sans-serif;
  font-weight: bold;
  color: #FF4747;
  display: inline-block;
  transform: skewX(-8deg);
}

.titleBlack {
  font-family: "Alimama ShuHeiTi", sans-serif;
  font-weight: bold;
  color: #222222;
  padding: 0 4px;
}

.subtitle {
  font-family: "苹方-简", PingFangSC-Regular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #666666;
}