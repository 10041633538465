@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 100px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 84px;
  .icon {
    width: 68px;
    height: 68px;
    vertical-align: middle;
  }
  .cartIconWrap {
    position: relative;
    .cartIcon {
      width: 82px;
      height: 82px;
      vertical-align: middle;
    }
    .cornerNum {
      position: absolute;
      right: 15px;
      top: 10px;
      display: flex;
      height: 18px;
      min-width: 18px;
      justify-content: center;
      align-items: center;
      padding: 0 4px;
      box-sizing: border-box;
      border: 1px solid #ff4747;
      border-radius: 10px;
      color: #ff4747;
      background-color: #ffffff;
    }
  }
}
