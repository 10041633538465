@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.pdBottomTip {
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    flex-direction: column;

    .newBottomNotice {
      display: flex;
      align-items: center;
      padding: 7.75px 12px;
      border-radius: 8px 8px 0 0;
      background: linear-gradient(90deg, #fa422d 0%, #ff6017 100%);

      &::before {
        content: "";
        flex-shrink: 0;
        width: 21px;
        height: 21px;
        background: url(https://akim-oss.aikucun.com/fe5dbbcea5ce7e2988b8c69bcfdfde8904aabc1f_1703679326540_71.png) center center no-repeat;
        background-size: auto 16.5px;
      }

      &::after {
        position: absolute;
        z-index: 1;
        right: 30px;
        top: -13px;
        content: "";
        flex-shrink: 0;
        width: 42px;
        height: 42px;
        background: url(https://akim-oss.aikucun.com/22d200f8670dbdb3e253a90eee5098477c95c23d_1703754137947_50.png) center center no-repeat;
        background-size: 42px auto;
      }

      .bottomNoticeMsg {
        position: relative;
        z-index: 2;
        flex: 1;
        padding: 0 7px;
        color: #ffffff;
        font-weight: 400;
        font-size: 12px;
      }

      .arrowBtn {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        background-image: url(https://akim-oss.aikucun.com/0ade7c2cf97f75d009975f4d720d1fa6c19f4897_1703679326692_52.png);
        background-size: 100%;
      }
    }

    .bottomNoticeWrapper {
      position: relative;
      display: flex;
      align-items: center;
      height: 39px;
      padding: 0 12px;
      background: #fff1df;
      color: #824f11;
      font-size: 14px;

      .bottomNoticeCont {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow-x: scroll;

        &::before {
          content: "";
          flex-shrink: 0;
          width: 21px;
          height: 21px;
          margin-right: 3px;
          background: url(https://akim-oss.aikucun.com/62c1c891f9d04961ccb26d43f240dfa4503719b0_1694164054675_40.png) center center no-repeat;
          background-size: 21px 21px;
        }
      }

      .arrowBtn {
        position: absolute;
        width: 39px;
        height: 39px;
        top: 0;
        right: 2px;
        background: url(https://akim-oss.aikucun.com/356a192b7913b04c54574d18c28d46e6395428ab_1678689006869_18.png) center center no-repeat;
        background-size: 16px 16px;
        transition: transform 0.5s;

        &.down {
          transform: rotate(180deg);
          transform-origin: center;
        }

        &.close {
          background-image: url(https://akim-oss.aikucun.com/bc69c00fdb50e13bcc782013a46f438a8471f41b_1694164054568_17.png);
        }
      }
    }

    .bottomTipWrapper {
      height: 35px;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .couponTips {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 8px 12px;
    background-color: white;

    .couponsContents {
      font-size: 12px;
      color: #ff302d;
    }

    .couponDesc {
      font-size: 12px;
      color: #222222;
    }
  }
}