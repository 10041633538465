@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.share-otc-before-confirm-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  width: 85vw;
  height: 415px;
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;

  .popup__header {
    padding: 16px 0;
    flex-shrink: 0;

    .header__title {
      position: relative;
      top: 10px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #222;
      text-align: center;
    }
    .header__icon {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 18px;
      height: 18px;
    }
  }
  .popup__body {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    height: 100px;

    .body__title {
      flex-grow: 0;
      flex-shrink: 0;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .body__subtitle {
      flex-grow: 0;
      flex-shrink: 0;
      padding-top: 6px;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: #222;
    }

    .body__list {
      flex-grow: 1;
      flex-shrink: 1;
      overflow-y: scroll;
      margin-top: 8px;
      padding: 12px;
      background: #f7f8f9;
      border-radius: 8px;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: #666;

      .list__item {
        padding-bottom: 2px;

        &:last-child {
          padding-bottom: 0;
        }
      }
      .read-all {
        height: 2px;
      }
    }
  }
  .popup__footer {
    flex-shrink: 0;
    padding: 16px 0;

    .footer__protocol {
      padding-bottom: 10px;
      display: flex;

      .protocol__check {
        position: relative;
        top: 2px;
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        background-image: url("https://akim-oss.aikucun.com/mshop/48fc6c0d746cdf062ed6e0c08b4ba1cceffbf248_1717381309051_29.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .protocol__check--agree {
        background-image: url("https://akim-oss.aikucun.com/mshop/2d6ea936c8e4f406ea954097dec418d2775cf7a0_1717381350064_20.png");
      }
      .protocol__right {
        flex-grow: 1;
        padding-left: 2px;
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #a5a5a5;
        .protocol__prefix {
        }
        .protocol__link {
          padding-left: 2px;
          color: #ff4747;
        }
      }
    }

    .footer__btns {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        box-sizing: border-box;
        border-radius: 6px;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
      .btn__cancel {
        margin-right: 8px;
        flex-grow: 1;
        width: 130px;
        background: rgba(255, 71, 71, 0.16);
        border: 1px solid rgba(255, 71, 71, 0.5);
        color: #ff4747;
      }
      .btn__confirm {
        flex-grow: 1;
        width: 215px;
        background: #ff4747;
      }
      .btn__disable {
        opacity: 0.5;
      }
    }
  }
}
