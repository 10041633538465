@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  width: 100%;
  position: relative;
  &.provideBackground&::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 34%);
  }

  .skeletonContainer {
    margin: 0 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &.hasSidebar {
      margin: 0 8px;
      .cardSkeleton {
        width: 143.5px;
      }
    }

    .cardSkeleton {
      width: 171px;
      height: 286px;
      border-radius: 8px;
      z-index: 1;
      margin-bottom: 8px;
    }
  }

  .waterfallConatainer {
    margin: 0 12px;
    &.hasSidebar {
      margin: 0 8px;
    }
  }

  .outerMargin {
    margin: 0;
  }

  .infiniteScrollContainer {
    position: relative;
  }

  .seeMoreBtn {
    margin: 0 auto;
    margin-bottom: 8px;
    align-items: center;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 73px;
    color: #a5a5a5;
    font-size: 12px;
    font-weight: 400;
    .icon {
      margin-left: 3px;
      width: 10px;
      height: 10px;
    }
    &::after {
      content: "";
      position: absolute;
      top: -8px;
      left: -8px;
      bottom: -8px;
      right: -8px;
    }
  }

  .emptyContainer {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;

    .text {
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #666666;
    }
  }
}

.loadingMore {
  margin-bottom: 8px;
  margin-top: 2px;
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 21px;
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
