@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.containerC {
  display: flex;
  flex-direction: column;
  .basicInfo {
    display: flex;
    border-radius: 10px;
    background-color: #ffffff;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 16px;

    .firstColumn {
      padding-top: 12px;
    }

    .itemV {
      display: flex;
      flex-direction: column;
      .itemV1 {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 24px;
        padding-bottom: 12px;
        .left {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .flag {
            color: #ff302d;
            font-size: 14px;
          }
          .title {
            color: #666666;
            font-size: 14px;
          }
        }
        .right {
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          .input {
            border: none;
            font-size: 14px;
            outline: none;
            text-align: end;
            color: #222222;
          }
          .cbx {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-content: center;
            .des {
              font-size: 14px;
              margin-left: 2px;
              color: #222222;
            }
            .cbIv {
              width: 18px;
              height: 18px;
            }
          }
          .cbx1 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-left: 28px;
            .des {
              font-size: 14px;
              margin-left: 2px;
              color: #222222;
            }
            .cbIv {
              width: 18px;
              height: 18px;
            }
          }
          .arrowIv {
            width: 14px;
            height: 14px;
            align-content: center;
            display: inline-block;
          }
          .cityTv {
            color: #222222;
            font-size: 14px;
            align-items: center;
            align-self: center;
            align-content: center;
          }
          .chooseTv {
            color: #cccccc;
            font-size: 14px;
            align-items: center;
            align-self: center;
            align-content: center;
          }
        }
      }
      .divider {
        background-color: rgba(34, 34, 34, 0.08);
        height: 0.5px;
      }
      .inputTips {
        color: #ff302d;
        font-size: 12px;
        text-align: end;
        margin-top: 4px;
      }
    }
    .item2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 24px;
      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        .flag {
          color: #ff302d;
          font-size: 14px;
        }
        .title {
          color: #666666;
          font-size: 14px;
        }
      }
      .input {
        border: none;
        font-size: 14px;
        outline: none;
        color: #222222;
        background-color: #f7f8f9;
        border-radius: 6px;
        margin-top: 10px;
        height: 120px;
        padding-top: 10px;
        padding-left: 12px;
        padding-right: 12px;
        resize: none;
      }
    }
    .inputTips1 {
      color: #ff302d;
      font-size: 12px;
      text-align: end;
      margin-top: 4px;
    }
  }
  .attention {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 8px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
    .tv1 {
      color: #222222;
      font-size: 14px;
      font-weight: 500;
      margin-top: 14px;
    }
    .tv2 {
      color: #666666;
      font-size: 12px;
      margin-top: 8px;
    }
    .tv3 {
      color: #666666;
      font-size: 12px;
    }
    .dv1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      .flag {
        color: #ff302d;
        font-size: 14px;
      }
      .title {
        color: #222222;
        font-size: 14px;
      }
    }
    .tv4 {
      color: #ff302d;
      font-size: 14px;
      margin-top: 4px;
    }
    .dv2 {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      flex: 1;
      position: relative;
      .copyInput {
        width: 100%;
        min-height: 64px; /* 设置最小高度 */
        box-sizing: border-box;
        border: none;
        font-size: 14px;
        outline: none;
        color: #222222;
        background-color: #f7f8f9;
        resize: none;
        border-radius: 6px;
        margin-top: 10px;
        padding-top: 10px;
        padding-left: 12px;
        padding-right: 12px;
      }
      .v2Tv1 {
        color: #5a76b0;
        font-size: 12px;
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }
}
