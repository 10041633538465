@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.CouponItemGroup {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 8px;
}

.CouponItemBox {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 16px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px rgba(6, 34, 83, 0.0784);
  background: #ffffff;
  z-index: 2;
  overflow: hidden;
  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   box-sizing: border-box;
  //   width: 200%;
  //   height: 200%;
  //   border: 1px solid #F0F0F0;
  //   border-radius: 20px;
  //   transform: scale(0.5);
  //   transform-origin: left top;
  //   pointer-events: none;
  // }
}
.CouponItemContent {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  & > * {
    position: relative;
    z-index: 2;
  }
}

// 活动主体内容布局样式
.CouponItemLeft {
  flex: 0 0 auto;
  margin-right: 8px;
  min-width: 74px;
}
.CouponItemAmount {
  margin-top: 4px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-family: DIN;
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  color: #ff4747;
}
.CouponItemUnit {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}
.CouponItemAmount + div {
  margin-top: 8px;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 10px;
  color: #666666;
  text-align: center;
}
.CouponItemCount {
  color: #ff4747;
}

.CouponItemRight {
  flex: 1;
  width: 0;
  display: flex;
  flex-direction: column;
  button {
    margin-top: 4px;
    margin-left: auto;
    height: 26px;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
}
.CouponItemInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "苹方-简";
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: #666666;
  & > * {
    flex: 0 0 auto;
    &:first-child {
      flex: 1;
      width: 0;
    }
  }
  .CouponItemTitle {
    margin-bottom: 8px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #ff4747;
    // 单行文本溢出...
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.CouponItemDate {
  margin-top: 8px;
  font-family: "苹方-简";
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: #a5a5a5;
}

.CouponItemFooter {
  margin-top: 8px;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "苹方-简";
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: #666666;
  border-top: 1px dashed rgba(255, 102, 0, 0.2);
  svg {
    margin-left: 2px;
  }
}
