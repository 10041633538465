@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.logistics-package {
  margin: 10px 0px;
  .package-list {
    padding-bottom: 4px;
    width: 100%;
    height: 46px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: auto;
  }
  .package-item {
    position: relative;
    margin-right: 10px;
    width: 100px;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    background: #fff;
    &:first-child {
      margin-left: 10px;
    }
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #333;
      img {
        margin-right: 4px;
        width: 12px;
        height: 12px;
      }
    }
    &__abnormal {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0px 3px;
      height: 16px;
      font-size: 10px;
      line-height: 16px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      color: #ffffff;
      background: #ff4747;
      border-radius: 0px 4px 0px 4px;
    }
    &__line {
      position: absolute;
      bottom: -2px;
      width: 100%;
      height: 5px;
      background: url("https://akim-oss.aikucun.com/mshop/264f39cab871e4cfd65b3a002f7255888bb5ed97_1722591387012_22.png")
        no-repeat;
      background-size: 100% 100%;
      background-color: transparent !important;
    }
    &.active {
      .package-item__content {
        span {
          color: #ff4747;
          font-family:
            PingFangSC-Semibold,
            PingFang SC;
        }
      }
    }
  }
}
