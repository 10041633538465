@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.bottomNotice {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 32px;
  padding-bottom: calc(50px + constant(safe-area-inset-bottom)); // 50px 是 tabbar 的高度
  padding-bottom: calc(50px + env(safe-area-inset-bottom));
  background: #FFF2BF;
  z-index: 1001;
  display: flex;
  flex-direction: row;
  align-items: center;

  .noticeIcon {
    margin-left: 12px;
    width: 18px;
    height: 18px;
  }

  .noticeText {
    margin-left: 4px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    line-height: 16px;
    color: #222222;

    .hightLight {
      font-family: PingFangSC-Medium;
      font-weight: 500;
      color: #FF4747;
    }
  }

  .noticeClose {
    margin-left: auto;
    margin-right: 0px;
    width: 44px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .closeIcon {
      width: 12px;
      height: 12px;
    }
  }
}

// 档期特殊定制下  底部比常用的更高
.isActivity {
  padding-bottom: calc(62px + env(safe-area-inset-bottom));
  z-index: 9;
}