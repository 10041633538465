@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.videoContainer {
  position: relative;
  z-index: 1;
  * {
    box-sizing: border-box;
  }
  .video {
    object-fit: contain;
  }
  .videoBar {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 990;

    height: 60px;
    padding: 21px 12px 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.41) 100%);
    opacity: 0.61;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      .playBtn {
        width: 27px;
        height: 27px;
      }
    }
    .right {
      .volumeBtn {
        width: 30px;
        height: 18px;
      }
    }
  }
}
