@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.dispatch-task {
  margin: 8px 0;
  padding: 12px 12px 14px;
  background: #fff;
  border-radius: 12px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header__left {
      display: flex;
      align-items: center;
      .left__title {
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222;
      }
      .left__customer {
        margin-left: 8px;
        padding: 0 4px;
        display: flex;
        align-items: center;
        height: 16px;
        background: rgba(255, 71, 71, 0.08);
        border-radius: 2px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;
        color: #ff4747;
      }
    }
    .header__right {
      display: flex;
      align-items: center;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #666;
      .icon__arrow {
        margin-left: 4px;
        width: 12px;
        height: 12px;
        background: url("https://akim-oss.aikucun.com/mshop/35b362ad53d641a08e9d5c54f336832077907f4d_1711614550393_56.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .body {
    .user-card {
      margin: 8px 0 8px 0;
      padding: 8px;
      background: #f7f8f9;
      border-radius: 6px;
      .user__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info__left {
          display: flex;
          align-items: center;
          .left__avatar {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            object-fit: cover;
          }
          .left__nickname {
            padding-left: 4px;
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #222;
          }
        }
        .info__right {
          display: flex;
          align-items: center;
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          color: #666666;
          .icon__arrow {
            margin-left: 2px;
            width: 10px;
            height: 10px;
          }
        }
      }
      .user__action {
        padding-top: 5px;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        color: #666;
      }
    }
  }
}
