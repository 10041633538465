@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.nav {
  padding: 6px;
  background-color: #fff;
  .nav-box {
    border-radius: 20px;
    border: 0.5px solid #e5e5e5;
    display: flex;
    position: relative;
    z-index: 10;
    width: fit-content;
    .nav-box__image-box {
      width: 44px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      .image-box__image {
        width: 20px;
        height: 20px;
      }
      &:first-child::after {
        content: "";
        width: 1px;
        height: 18px;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        right: 50%;
      }
    }
  }
}
