@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.product-container {
  position: relative;
  .product-item {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 12px;
      bottom: 0;
      width: 351px;
      height: 1px;
      background-color: rgba(34, 34, 34, 0.08);
      transform: scaleY(0.5);
    }
    &.last::after {
      display: none;
    }
  }

  .skeleton-item {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 12px;
    width: 100%;
    height: 155px;
    box-sizing: border-box;
    &::after {
      content: "";
      position: absolute;
      left: 12px;
      bottom: 0;
      width: 351px;
      height: 1px;
      background-color: rgba(34, 34, 34, 0.08);
      transform: scaleY(0.5);
    }
    &:last-child::after {
      display: none;
    }
    .ridio {
      margin-right: 12px;
      width: 22px;
      height: 22px;
      border-radius: 4px;
    }
    .product {
      margin-right: 12px;
      width: 110px;
      height: 110px;
      border-radius: 6px;
    }
    .paragraph {
      flex: 1;
    }
  }

  .infinite {
    margin-bottom: calc(8px + constant(safe-area-inset-bottom));
    margin-bottom: calc(8px + env(safe-area-inset-bottom));
    :global(.adm-image-tip) {
      display: none;
    }
  }

  .loadingMore {
    margin-top: 12px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
    .adm-image-tip {
      display: none;
    }
  }

  .emptyContainer {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;

    .text {
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #666666;
    }
  }

  .observer-dom {
    position: absolute;
    z-index: -1;
    left: 0;
    top: -88px;
    width: 100%;
    height: calc(100vh - 150px);
  }

  .guide-top {
    position: fixed;
    right: 16px;
    bottom: 160px;
    width: 44px;
    height: 44px;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.3s;
    pointer-events: initial;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
