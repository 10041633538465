@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.tabs {
  width: 100%;
  height: 40px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 44px;
  z-index: 100;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  .tab {
    display: flex;
    align-items: center;
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    color: #666666;
    height: 34px;
    position: relative;
    .line {
      height: 2px;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      background: #ffffff;
      width: 100%;
      .item {
        width: 60%;
        background: #ff4747;
        height: 100%;
      }
    }
  }
  .active {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #222222;
  }
  .nums {
    border-radius: 12px;
    opacity: 1;
    box-sizing: border-box;
    border: 1px solid #a5a5a5;
    color: #666666;
    line-height: 12px;
    padding: 0 4px;
    margin-left: 2px;
    font-size: 10px;
  }
}
