@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.addressItem {
  display: flex;
  flex-direction: row;
  margin: 0px 20px 0px 20px;
  padding-top: 20px;
  align-items: center;
  background-color: white;

  .left {
    display: flex;
    flex-direction: column;
    flex: 1;

    .concats {
      display: flex;
      flex-direction: row;
      align-items: center;

      .name {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
      }

      .tel {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        margin-left: 8px;
      }

      .default {
        margin-left: 3px;
        background-color: #ff302d;
        border-radius: 4px;
        color: #ffffff;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3px;
        height: 13px;
        min-width: 26px;
        font-weight: 500;
      }

      .company {
        border-radius: 4px;
        margin-left: 3px;
        background-color: #3377ff;
        border-radius: 4px;
        color: #ffffff;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3px;
        height: 13px;
        min-width: 26px;
        font-weight: 500;
      }
    }

    .loacation {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 2px;
    }
  }

  .right {
    margin-left: 10px;
    margin-right: 10px;

    .checkbox-active {
      width: 18px;
      height: 18px;
      background-image: url("https://akim-oss.aikucun.com/6cfac2d8c88af444eb24b357cca03746416d5c62_1683873807262_49.png");
      background-size: contain;
      margin-left: 12px;
      background-repeat: no-repeat;
    }

    .checkbox-inactive {
      width: 18px;
      height: 18px;
      background-image: url("https://akim-oss.aikucun.com/7850a178c23902e1c2c0acff096e3a83780fcd5d_1683873873825_91.png");
      background-size: contain;
      margin-left: 12px;
    }
  }
}