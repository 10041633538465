@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  padding: 40px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  // background-color: #ff3a44;
  // background-image: url("https://akim-oss.aikucun.com/mshop/75a64574824c5d7c8571bc5682f4ce66b446c42a_1714976635555_33.png");
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: top center;
  background:
    url("https://akim-oss.aikucun.com/mshop/0774a9a831b9e82ba09ed3334ca3077c678f67fd_1720082261455_67.png")
      top left no-repeat,
    linear-gradient(180deg, #ff2059 23%, #ffdfb9 32%);
  background-size: contain;
  box-sizing: border-box;

  .page__source {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #ffffff;

    .source__avatar {
      width: 24px;
      height: 24px;
      border: 0.5px solid #ffffff;
      border-radius: 50%;
      box-sizing: border-box;
    }

    .source__nickname {
      padding: 0 12px 0 6px;
    }
  }

  .award__name {
    flex-shrink: 0;
    padding-top: 12px;
    font-family: PingFang SC;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #ffe5c8;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .award__img {
    flex-shrink: 0;
    margin-top: 16px;
    width: 120px;
    height: 120px;
    border: 2px solid #fff0de;
    border-radius: 6px;
    overflow: hidden;
  }

  .page__from {
    flex-grow: 1;
    margin-top: 20px;
    padding: 16px 12px 12px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;

    .from__body {
      .from__title {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        color: #222;
      }
      .from__tips {
        padding-top: 4px;
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        text-align: center;
        color: #a5a5a5;
      }
      .from__not_address {
        margin-top: 12px;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f7f8f9;
        border-radius: 8px;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #222;

        &::before {
          content: "";
          margin-right: 8px;
          width: 16px;
          height: 16px;
          background-image: url("https://akim-oss.aikucun.com/mshop/30bfa04390c0e835dae009e54ba5c57c2a945b0f_1715052436921_39.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }
      .from__address {
        margin-top: 12px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f7f8f9;
        border-radius: 8px;

        .address__left {
          padding-right: 12px;

          .left__top {
            display: flex;
            align-items: center;

            .user_name {
              font-family: PingFang SC;
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              color: #222;
            }
            .phone {
              padding-left: 6px;
              font-family: "苹方-简";
              font-size: 14px;
              font-weight: normal;
              line-height: 20px;
              color: #222;
            }
          }
          .address {
            padding-top: 6px;
            font-family: "苹方-简";
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            color: #666;
            word-break: break-all;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .address_right {
          width: 16px;
          height: 16px;
        }
      }
    }

    .from__footer {
      .footer__submit {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        background: #ff4747;
        border-radius: 6px;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #fff;
      }
      .footer__submit__disable {
        opacity: 0.4;
      }
    }
  }

  .logistics {
    flex-grow: 1;
    margin-top: 20px;
    padding: 0 12px 12px 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden scroll;

    .logistics__tab {
      position: sticky;
      top: 0;
      z-index: 10;
      flex-shrink: 0;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: #fff;

      .tab {
        position: relative;
        padding: 0 10px;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: normal;
        line-height: 22px;
        color: #666;
      }
      .tab__active {
        font-weight: 500;
        color: #222;

        &::after {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: -3px;
          content: "";
          width: 50%;
          height: 2px;
          background: #ff4747;
        }
      }
    }

    .logistics__current {
      .logistics__brand {
        padding: 20px 0 12px 0;
        display: flex;
        align-items: center;
        width: 100%;
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #222;
        border-bottom: 0.5px solid rgba(34, 34, 34, 0.08);

        .brand__logo {
          width: 16px;
          height: 16px;
        }
        .brand__name {
          padding: 0 4px;
        }
      }

      .logistics__trace {
        padding: 10px 0;

        .trace__item {
          padding: 0 0 10px 0;
          // border-left: 1px dotted #ccc;

          .item__line1 {
            display: flex;
            align-items: center;

            .item__icon {
              flex-shrink: 0;
              margin-right: 12px;
              width: 24px;
              height: 24px;
            }

            .item__status {
              font-family: PingFang SC;
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              color: #666;
            }
            .item__time {
              padding-left: 4px;
              font-family: "苹方-简";
              font-size: 12px;
              font-weight: normal;
              line-height: 16px;
              color: #666;
            }
          }

          .item__line2 {
            padding-top: 6px;
            display: flex;

            .item__node {
              flex-shrink: 0;
              position: relative;
              margin-right: 12px;
              width: 24px;
              display: flex;
              flex-direction: column;
              align-items: center;

              // .node__start,
              // .node__end {
              //   flex-shrink: 0;
              //   width: 6px;
              //   height: 6px;
              //   background: #a5a5a5;
              //   border-radius: 50%;
              // }
              .node__line {
                flex-shrink: 1;
                width: 1px;
                height: 100%;
                background: rgba(34, 34, 34, 0.08);
              }
            }

            .item__content {
              font-family: "苹方-简";
              font-size: 14px;
              font-weight: normal;
              line-height: 20px;
              color: #666;
            }
          }
        }
        .trace__item:first-child {
          .item__line1 {
            .item__status {
              color: #ff4747;
            }
            .item__time {
              color: #ff4747;
            }
          }
        }
      }
    }
  }
}

.page_invalid {
  background: #ffffff;

  .invalid {
    padding-top: 175px;

    .invalid__icon {
      width: 184px;
      height: 184px;
    }
    .invalid__text {
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      color: #666;
    }
  }
}

.loading {
  margin: auto;
  width: 30px;
  height: 30px;
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
}
.loadingFixed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
