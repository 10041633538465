@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.adminContainer {
  position: relative;
  padding: 0 12px;
  .group {
    margin-bottom: 8px;
    padding: 0 16px;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
    .item {
      padding: 14px 0;
      border-bottom: 1px solid rgba(34, 34, 34, 0.08);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      &:last-child {
        border-bottom: none;
      }
      .itemLeft {
        font-size: 14px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #222222;
      }
      .itemRight {
        display: flex;
        align-items: center;
        .itemRightCount {
          margin-right: 4px;
          font-size: 12px;
          font-family:
            PingFangSC-Regular,
            PingFang SC;
          color: #666666;
        }
        .iconRight {
          width: 14px;
          height: 14px;
          background: url("https://akim-oss.aikucun.com/mshop/890a6158d5b75701e98735b397939cfa0caeec28_1711613398559_5.png")
            no-repeat;
          background-size: 100% 100%;
          &.rotateUp {
            animation: rotate-up 0.3s ease-in-out forwards;
          }
        }
      }
    }
  }
  .address-item-guide {
    &__line {
      position: absolute;
      left: 240px;
      bottom: -55px;
      width: 10px;
      height: 51px;
      background: url("https://akim-oss.aikucun.com/mshop/c31361341b603076eb87ba7fafde4f02f1bfdc13_1717729139344_24.png")
        no-repeat;
      background-size: 100% 100%;
    }
    &__txt {
      position: absolute;
      left: 94px;
      bottom: -107px;
      width: 197px;
      min-height: 40px;
      padding: 10px;
      box-sizing: border-box;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #ffffff;
      border-radius: 6px;
      background: #ff4747;
      &::after {
        content: "";
        position: absolute;
        top: -6px;
        left: 143px;
        width: 16px;
        height: 8px;
        background: url("https://akim-oss.aikucun.com/mshop/e1fa4795d11da15cdbb80a572ebc830ec77a8ba8_1717730853677_63.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
    &__button {
      position: absolute;
      left: 208px;
      bottom: -145px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 30px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      border: 0.8px solid #ffffff;
      background: rgba(255, 255, 255, 0.1);
    }
  }
  .followWeChat {
    margin-bottom: 8px;
    :global(.follow-wechat) {
      margin-top: 0 !important;
    }
  }
  .footer {
    a {
      margin-top: 20px;
      margin-bottom: 24px;
      display: block;
      font-size: 12px;
      line-height: 16px;
      color: #5a76b0;
      text-align: center;
      text-decoration: underline;
    }
  }
  @keyframes rotate-up {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-90deg);
    }
  }
}
