@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.goodsRecommond {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .goodsRecommondTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    .flag {
      width: 14px;
      height: 11px;
    }
    .text {
      height: 18px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 18px;
      margin: 0 6px;
    }
  }
}
