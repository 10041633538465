@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.materialPGuideC {
  width: 100vw;
  background-color: #f1f2f5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  overflow: hidden scroll;
  .top {
    width: 100vw;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    .title {
      font-family: PingFang SC;
      font-size: 18px;
      font-weight: 500;
      color: #222222;
    }
    .close {
      width: 18px;
      height: 18px;
      position: absolute;
      right: 20px;
    }
  }
  .body {
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: calc(84px + constant(safe-area-inset-bottom));
    padding-bottom: calc(84px + env(safe-area-inset-bottom));
    .desc {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      color: #222222;
      .selected {
        color: #ff4747;
      }
    }
    .imgC {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 16px;
      .material {
        margin-right: 4.5px;
      }
      .general {
        margin-left: 4.5px;
      }
    }
  }
  .bottomC {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    padding-top: 6px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: calc(constant(safe-area-inset-bottom));
    padding-bottom: calc(env(safe-area-inset-bottom));
    .settingBtn {
      flex: 1;
      height: 42px;
      background-color: #ff474714;
      border: 1px solid #ff4747;
      text-align: center;
      vertical-align: middle;
      font-size: 16px;
      color: #ff4747;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin-right: 4.5px;
    }
    .confirmBtn {
      flex: 1;
      height: 44px;
      background-color: #ff4747;
      text-align: center;
      vertical-align: middle;
      font-size: 16px;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin-left: 4.5px;
    }
  }
}
