@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  position: relative;

  .header {
    position: absolute;
    z-index: 100;
    top: -240px;
    width: 375px;
    height: 368px;
    background: url(https://akim-oss.aikucun.com/mshop/0ade7c2cf97f75d009975f4d720d1fa6c19f4897_1728900442487_56.png) center center no-repeat;
    background-size: 375px auto;

    &.POINTS,
    &.PRODUCT {
      background-image: url(https://akim-oss.aikucun.com/mshop/fe5dbbcea5ce7e2988b8c69bcfdfde8904aabc1f_1728900426338_87.png);
    }

    &.COUPON {
      background-image: url(https://akim-oss.aikucun.com/mshop/902ba3cda1883801594b6e1b452790cc53948fda_1728900409210_91.png);
    }

    .title {
      margin-top: 95px;
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 600;
      font-style: italic;
      padding: 0 50px;
      text-align: center;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0px;
      font-variation-settings: "opsz" auto;
      background: linear-gradient(180deg, #ffffff 12%, #ffdda2 89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .closeBtn {
    position: absolute;
    top: -140px;
    right: 10px;
    z-index: 300;
    width: 36px;
    height: 36px;
    background: url(https://akim-oss.aikucun.com/f6e1126cedebf23e1463aee73f9df08783640400_1723264328930_95.png) center center no-repeat;
    background-size: 36px;
  }
}

.wrap {
  position: relative;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 456px;
  max-height: 75vh;
  overflow-y: scroll;
  padding: 12px;
  padding-bottom: calc(12px + constant(safe-area-inset-bottom));
  padding-bottom: calc(12px + env(safe-area-inset-bottom));
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;

  &.wrapProduct {
    height: 75vh;
  }

  .main {
    flex: 1;
    width: 100%;
    margin-top: 10px;
  }

  .pointsContHeader {
    margin: 30px 0 16px 0;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }

  .couponCont {
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #666666;

    .pic {
      width: 255px;
      height: 150px;
    }
  }

  .bottomBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    background: linear-gradient(168deg, #ffebcb 1%, #ffe0ae 56%);
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    color: #8c4206;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      margin-right: 4px;
      background: url("https://akim-oss.aikucun.com/fc074d501302eb2b93e2554793fcaf50b3bf7291_1723281947075_16.png") center center no-repeat;
      background-size: 20px;
    }
  }
}

.products {
  display: flex;
  justify-content: flex-start;

  .productCard {
    width: 112px;
    border-radius: 4px;
    margin-right: 8px;
    overflow: hidden;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .productPicWrap {
    position: relative;
    margin-bottom: 6px;

    .productPic {
      width: 100%;
      vertical-align: middle;
    }

    .commWrap {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      height: 16px;
      padding: 0 4px;
      font-size: 11px;
      font-weight: 500;
      color: #8c4206;
      background: #ffd4b6;
    }
  }

  .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 32px;
    /* 设置为行高的两倍 */
    line-height: 16px;
    color: #222222;
  }

  .priceWrap {
    display: flex;
    align-items: baseline;
    color: #ff4747;
    font-weight: 500;

    .price {
      margin-right: 6px;
      font-weight: bold;
      font-size: 16px;

      &::before {
        content: "¥";
        padding-right: 2px;
        font-size: 12px;
      }
    }

    .tagPrice {
      font-size: 12px;
      color: #999999;
      text-decoration: line-through;

      &::before {
        content: "¥";
      }
    }
  }

  .pointWrap {
    display: flex;
    align-items: center;
    color: #ff4747;
    font-weight: 500;

    .point {
      display: flex;
      align-items: center;
      font-family: "DIN-Bold";
      margin-right: 3px;
      font-weight: bold;
      font-size: 16px;

      &::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 2px;
        background: url(https://akim-oss.aikucun.com/cb4e5208b4cd87268b208e49452ed6e89a68e0b8_1723279181188_32.png) center center no-repeat;
        background-size: 14px;
      }
    }
  }
}

.noPriczeWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;

  .title {
    font-size: 16px;
  }

  .pic {
    width: 46px;
    height: 46px;
    margin: 16px 0;
    background: url(https://akim-oss.aikucun.com/bc33ea4e26e5e1af1408321416956113a4658763_1723271635061_9.png) center center no-repeat;
    background-size: 46px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    margin-top: 16px;
    background: #ff4747;
    border-radius: 6px;
    font-size: 16px;
    color: #ffffff;
  }

  .closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    background: url(https://akim-oss.aikucun.com/0a57cb53ba59c46fc4b692527a38a87c78d84028_1723271953261_45.png) center center no-repeat;
    background-size: 16px;
  }
}