@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.product {
  position: relative;
  box-sizing: border-box;

  .one1 {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 9px;

    .img {
      width: 108px;
      height: 108px;
      border-radius: 8px;
      flex-shrink: 0;
      position: relative;
      overflow: hidden;
      border: 0.5px solid #eee;

      .item {
        border-radius: 8px;
      }

      .img_play {
        width: 18px;
        height: 18px;
        position: absolute;
        bottom: 6px;
        left: 6px;
        z-index: 9;
      }

      .item_block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        .live {
          width: fit-content;
          height: 18px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 9px;
          color: #ffffff;
          padding: 0 0 0 3px;
          display: flex;
          align-items: center;
          position: absolute;
          z-index: 9;
          left: 6px;
          top: 6px;
          padding-right: 3px;

          .barTotal {
            width: 14px;
            height: 14px;
            display: inline-block !important;
            position: relative;
            border-radius: 50%;
            background-color: rgba(255, 48, 45, 1);
          }

          .barTotal span {
            position: absolute;
            width: 1.5px;
            bottom: 3px;
            border-radius: 1px;
          }

          .bar1 {
            left: 3px;
            height: 10px;
            background-color: #ffff;
          }

          .bar2 {
            left: 6px;
            height: 5;
            background-color: #ffff;
          }

          .bar3 {
            left: 9px;
            height: 10px;
            background-color: #ffff;
          }

          .a1 {
            -webkit-animation: ani1 1s infinite alternate both;
            -moz-animation: ani1 1s infinite alternate both;
            animation: ani1 1s infinite alternate both;
          }

          .a2 {
            -webkit-animation: ani2 1s infinite alternate both;
            -moz-animation: ani2 1s infinite alternate both;
            animation: ani2 1s infinite alternate both;
          }

          .a3 {
            -webkit-animation: ani3 1s infinite alternate both;
            -moz-animation: ani3 1s infinite alternate both;
            animation: ani3 1s infinite alternate both;
          }

          @-webkit-keyframes ani1 {
            0% {
              height: 1px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 5px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 5px;
            }
          }

          @-moz-keyframes ani1 {
            0% {
              height: 1px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 5px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 5px;
            }
          }

          @keyframes ani1 {
            0% {
              height: 1px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 5px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 5px;
            }
          }

          @-webkit-keyframes ani2 {
            0% {
              height: 5px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 1px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 2px;
            }
          }

          @-moz-keyframes ani2 {
            0% {
              height: 5px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 1px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 2px;
            }
          }

          @keyframes ani2 {
            0% {
              height: 5px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 1px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 2px;
            }
          }

          @-webkit-keyframes ani3 {
            0% {
              height: 3px;
            }

            25% {
              height: 1px;
            }

            50% {
              height: 2px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 1px;
            }
          }

          @-moz-keyframes ani3 {
            0% {
              height: 3px;
            }

            25% {
              height: 1px;
            }

            50% {
              height: 2px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 1px;
            }
          }

          @keyframes ani3 {
            0% {
              height: 3px;
            }

            25% {
              height: 1px;
            }

            50% {
              height: 2px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 1px;
            }
          }

          .text {
            padding-left: 3px;
            transform: scale(0.9);
            white-space: nowrap;
          }
        }

        .liveDesc {
          width: fit-content;
          height: 14px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 0px 8px 0px 0;
          position: absolute;
          bottom: 0;
          left: 0;
          color: #ffffff;
          padding: 0 3px;

          .desc {
            font-size: 12px;
            transform: scale(0.9);
          }
        }
      }

      .sell_out {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .sell_out_circle {
          width: 79px;
          height: 79px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 50%;
          text-align: center;
          font-size: 18px;
          font-family:
            PingFangSC-Semibold,
            PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 79px;
        }
      }

      .quantity {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        border-bottom-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        max-width: 100%;
        color: #ffff;
        padding: 0 6px;
      }

      .score {
        position: absolute;
        bottom: 7px;
        height: 16px;
        background: rgba(255, 240, 239, 0.9);
        display: flex;
        z-index: 10;
        color: rgba(255, 48, 45, 1);
        align-items: center;
        padding-left: 4px;
        max-width: 100%;
        box-sizing: border-box;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        .score_text {
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }

        .img_score {
          width: 14px;
          height: 14px;
          flex-shrink: 0;
        }
      }
    }

    .content {
      padding-left: 6px;
      padding-top: 6px;
      width: calc(100% - 126px);
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      .label {
        display: flex;
        align-items: center;

        .label_img {
          flex-shrink: 0;
          width: 30px;
          height: 16px;
        }

        .label_yu {
          width: 20px;
          height: 13px;
          flex-shrink: 0;
        }

        .label_advance {
          width: 28px;
          height: 16px;
          flex-shrink: 0;
        }

        .name {
          font-size: 14px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #383838;
          line-height: 20px;
          white-space: nowrap;
          padding-left: 3px;
          overflow-x: hidden;
          overflow-y: hidden;
          text-overflow: ellipsis;
          flex-grow: 1;
        }
      }

      .discount_label {
        display: -webkit-box;
        /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;
        /* TWEENER - IE 10 */
        display: -webkit-flex;
        /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;
        align-items: center;
        padding-top: 4px;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 4px;
        overflow: hidden;

        .discount_label_item {
          width: 42px;
          height: 15px;
          border-radius: 4px;
          border: 1px solid rgb(255 102 0 / 80%);
          color: rgb(255 102 0 / 80%);
          text-align: center;
          line-height: 15px;
          margin-right: 3px;
          margin-bottom: 3px;
        }
      }

      .style_list {
        display: flex;
        width: fit-content;

        .style_list_item {
          margin-right: 3px;
          width: 28px;
          height: 28px;
          background: #f3f4f6;
          border-radius: 4px;
          text-align: center;
          display: flex;
          align-items: center;
          line-height: 12px;
          position: relative;
          justify-content: center;
          border: solid #eeeeee 0.5px;

          .item_img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }

          .item_font {
            transform: scale(0.8);
          }

          .item_sell_out {
            transform: scale(0.9);
            font-size: 10px;
            font-family:
              PingFangSC-Regular,
              PingFang SC;
            font-weight: 400;
            color: #393737;
            position: absolute;
            display: flex;
            align-items: center;
          }
        }
      }

      .price_btn {
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          display: flex;
          align-items: baseline;
          width: calc(100% - 76px);
          line-height: 22px;

          .price_text {
            color: #ff302d;
            display: flex;
            align-items: flex-end;
            transform: scale(0.9);
            flex-shrink: 0;
            line-height: 25px;
          }

          .sec_kill {
            font-size: 10px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ff302d;
            padding-top: 4px;
            padding-right: 2px;
            flex-shrink: 0;
            white-space: nowrap;
          }

          .price_real {
            font-size: 14px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ff302d;
            display: flex;
            align-items: flex-end;

            .price_big {
              padding-left: 2px;
              font-size: 18px;
              line-height: 25px;
            }
          }

          .price_old {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #a5a5a5;
            padding-left: 3px;
            text-decoration: line-through;
            overflow-x: hidden;
            text-overflow: ellipsis;
            padding-top: 4px;
            white-space: nowrap;
          }
        }

        .btn,
        .btn_save {
          width: 73px;
          height: 23px;
          background: linear-gradient(270deg, #ff7346 0%, #ff2b2b 100%);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          .btn_img {
            width: 16px;
            height: 16px;
          }

          .btn_font {
            font-size: 12px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }

        .btn_save {
          background: linear-gradient(270deg, #fd7921 0%, #ff6600 100%);
        }

        .btn_buy {
          width: 68px;
          height: 22px;
        }

        .buy_btn {
          width: 72px;
          position: relative;

          .btn_img {
            width: 68px;
            height: 22px;
          }

          .buy_text {
            font-size: 12px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ffffff;
            position: absolute;
            top: 10%;
            left: 20%;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .small_one1 {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 9px;

    .img {
      width: 100px;
      height: 100px;
      border-radius: 8px;
      flex-shrink: 0;
      position: relative;

      .item {
        border-radius: 8px;
      }

      .img_play {
        width: 18px;
        height: 18px;
        position: absolute;
        bottom: 6px;
        left: 6px;
        z-index: 9;
      }

      .item_block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        .live {
          width: fit-content;
          height: 18px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 9px;
          color: #ffffff;
          padding: 0 0 0 3px;
          display: flex;
          align-items: center;
          position: absolute;
          z-index: 9;
          left: 6px;
          top: 6px;
          padding-right: 3px;

          .barTotal {
            width: 14px;
            height: 14px;
            display: inline-block !important;
            position: relative;
            border-radius: 50%;
            background-color: rgba(255, 48, 45, 1);
          }

          .barTotal span {
            position: absolute;
            width: 1.5px;
            bottom: 3px;
            border-radius: 1px;
          }

          .bar1 {
            left: 3px;
            height: 10px;
            background-color: #ffff;
          }

          .bar2 {
            left: 6px;
            height: 5;
            background-color: #ffff;
          }

          .bar3 {
            left: 9px;
            height: 10px;
            background-color: #ffff;
          }

          .a1 {
            -webkit-animation: ani1 1s infinite alternate both;
            -moz-animation: ani1 1s infinite alternate both;
            animation: ani1 1s infinite alternate both;
          }

          .a2 {
            -webkit-animation: ani2 1s infinite alternate both;
            -moz-animation: ani2 1s infinite alternate both;
            animation: ani2 1s infinite alternate both;
          }

          .a3 {
            -webkit-animation: ani3 1s infinite alternate both;
            -moz-animation: ani3 1s infinite alternate both;
            animation: ani3 1s infinite alternate both;
          }

          @-webkit-keyframes ani1 {
            0% {
              height: 1px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 5px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 5px;
            }
          }

          @-moz-keyframes ani1 {
            0% {
              height: 1px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 5px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 5px;
            }
          }

          @keyframes ani1 {
            0% {
              height: 1px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 5px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 5px;
            }
          }

          @-webkit-keyframes ani2 {
            0% {
              height: 5px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 1px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 2px;
            }
          }

          @-moz-keyframes ani2 {
            0% {
              height: 5px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 1px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 2px;
            }
          }

          @keyframes ani2 {
            0% {
              height: 5px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 1px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 2px;
            }
          }

          @-webkit-keyframes ani3 {
            0% {
              height: 3px;
            }

            25% {
              height: 1px;
            }

            50% {
              height: 2px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 1px;
            }
          }

          @-moz-keyframes ani3 {
            0% {
              height: 3px;
            }

            25% {
              height: 1px;
            }

            50% {
              height: 2px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 1px;
            }
          }

          @keyframes ani3 {
            0% {
              height: 3px;
            }

            25% {
              height: 1px;
            }

            50% {
              height: 2px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 1px;
            }
          }

          .text {
            padding-left: 3px;
            transform: scale(0.9);
            white-space: nowrap;
          }
        }

        .liveDesc {
          width: fit-content;
          height: 14px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 0px 8px 0px 8px;
          position: absolute;
          bottom: 0;
          left: 0;
          color: #ffffff;
          padding: 0 3px;

          .desc {
            font-size: 12px;
            transform: scale(0.9);
          }
        }
      }

      .sell_out {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .sell_out_circle {
          width: 79px;
          height: 79px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 50%;
          text-align: center;
          font-size: 18px;
          font-family:
            PingFangSC-Semibold,
            PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 79px;
        }
      }

      .score {
        position: absolute;
        bottom: 7px;
        height: 16px;
        background: rgba(255, 240, 239, 0.9);
        display: flex;
        z-index: 10;
        color: rgba(255, 48, 45, 1);
        align-items: center;
        padding-left: 4px;
        max-width: 100%;
        box-sizing: border-box;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;

        .score_text {
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }

        .img_score {
          width: 14px;
          height: 14px;
          flex-shrink: 0;
        }
      }
    }

    .content {
      padding-left: 6px;
      padding-top: 6px;
      width: calc(100% - 104px);
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      .label {
        display: flex;
        align-items: center;

        .label_img {
          flex-shrink: 0;
          width: 30px;
          height: 15px;
        }

        .label_yu {
          width: 20px;
          height: 13px;
          flex-shrink: 0;
        }

        .name {
          font-size: 14px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #383838;
          line-height: 20px;
          white-space: nowrap;
          padding-left: 3px;
          overflow-x: hidden;
          overflow-y: hidden;
          text-overflow: ellipsis;
        }
      }

      .discount_label {
        display: flex;
        align-items: center;
        padding-top: 4px;
        flex-wrap: wrap;

        .discount_label_item {
          width: 42px;
          height: 15px;
          border-radius: 4px;
          border: 1px solid rgb(255 102 0 / 80%);
          color: rgb(255 102 0 / 80%);
          text-align: center;
          line-height: 15px;
          margin-right: 3px;
          margin-bottom: 3px;
        }
      }

      .style_list {
        display: flex;
        width: fit-content;

        .style_list_item {
          margin-right: 3px;
          width: 28px;
          height: 28px;
          background: #f3f4f6;
          border-radius: 4px;
          text-align: center;
          display: flex;
          align-items: center;
          line-height: 12px;
          position: relative;
          justify-content: center;
          border: solid #eeeeee 0.5px;

          .item_img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }

          .item_font {
            transform: scale(0.8);
          }

          .item_sell_out {
            transform: scale(0.9);
            font-size: 10px;
            font-family:
              PingFangSC-Regular,
              PingFang SC;
            font-weight: 400;
            color: #393737;
            position: absolute;
            display: flex;
            align-items: center;
          }
        }
      }

      .price_btn {
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          display: flex;
          width: calc(100% - 76px);
          line-height: 22px;

          .sec_kill {
            font-size: 10px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ff302d;
            padding-top: 4px;
            padding-right: 2px;
            flex-shrink: 0;
            white-space: nowrap;
          }

          .price_real {
            font-size: 14px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ff302d;
            display: flex;
            align-items: flex-end;

            .price_big {
              padding-left: 2px;
              font-size: 18px;
              line-height: 25px;
            }
          }

          .price_old {
            font-size: 14px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #a5a5a5;
            padding-left: 3px;
            text-decoration: line-through;
            overflow-x: hidden;
            text-overflow: ellipsis;
            padding-top: 4px;
            white-space: nowrap;
          }
        }

        .btn,
        .btn_save {
          width: 68px;
          height: 22px;
          background: linear-gradient(270deg, #ff7346 0%, #ff2b2b 100%);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          .btn_img {
            width: 16px;
            height: 16px;
          }

          .btn_font {
            font-size: 12px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }

        .btn_save {
          background: linear-gradient(270deg, #fd7921 0%, #ff6600 100%);
        }

        .btn_buy {
          width: 68px;
          height: 22px;
        }
      }

      .content_content {
        width: 204px;
        transform: scale(0.8);
        margin-left: -22px;
      }
    }
  }

  .one2 {
    >div {
      border: 0.5px solid #eee;
    }

    .img {
      position: relative;
      border-radius: 6px 6px 0 0;

      .img_play {
        width: 18px;
        height: 18px;
        position: absolute;
        bottom: 6px;
        left: 6px;
      }

      .item_block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px 6px 0 0;

        .item {
          border-radius: 6px 6px 0 0;
        }

        .live {
          width: fit-content;
          height: 18px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 9px;
          color: #ffffff;
          padding: 0 0 0 3px;
          display: flex;
          align-items: center;
          position: absolute;
          z-index: 9;
          left: 6px;
          top: 6px;
          padding-right: 3px;

          .barTotal {
            width: 14px;
            height: 14px;
            display: inline-block !important;
            position: relative;
            border-radius: 50%;
            background-color: rgba(255, 48, 45, 1);
          }

          .barTotal span {
            position: absolute;
            width: 1.5px;
            bottom: 3px;
            border-radius: 1px;
          }

          .bar1 {
            left: 3px;
            height: 10px;
            background-color: #ffff;
          }

          .bar2 {
            left: 6px;
            height: 5;
            background-color: #ffff;
          }

          .bar3 {
            left: 9px;
            height: 10px;
            background-color: #ffff;
          }

          .a1 {
            -webkit-animation: ani1 1s infinite alternate both;
            -moz-animation: ani1 1s infinite alternate both;
            animation: ani1 1s infinite alternate both;
          }

          .a2 {
            -webkit-animation: ani2 1s infinite alternate both;
            -moz-animation: ani2 1s infinite alternate both;
            animation: ani2 1s infinite alternate both;
          }

          .a3 {
            -webkit-animation: ani3 1s infinite alternate both;
            -moz-animation: ani3 1s infinite alternate both;
            animation: ani3 1s infinite alternate both;
          }

          @-webkit-keyframes ani1 {
            0% {
              height: 1px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 5px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 5px;
            }
          }

          @-moz-keyframes ani1 {
            0% {
              height: 1px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 5px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 5px;
            }
          }

          @keyframes ani1 {
            0% {
              height: 1px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 5px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 5px;
            }
          }

          @-webkit-keyframes ani2 {
            0% {
              height: 5px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 1px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 2px;
            }
          }

          @-moz-keyframes ani2 {
            0% {
              height: 5px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 1px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 2px;
            }
          }

          @keyframes ani2 {
            0% {
              height: 5px;
            }

            25% {
              height: 3px;
            }

            50% {
              height: 1px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 2px;
            }
          }

          @-webkit-keyframes ani3 {
            0% {
              height: 3px;
            }

            25% {
              height: 1px;
            }

            50% {
              height: 2px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 1px;
            }
          }

          @-moz-keyframes ani3 {
            0% {
              height: 3px;
            }

            25% {
              height: 1px;
            }

            50% {
              height: 2px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 1px;
            }
          }

          @keyframes ani3 {
            0% {
              height: 3px;
            }

            25% {
              height: 1px;
            }

            50% {
              height: 2px;
            }

            75% {
              height: 4px;
            }

            100% {
              height: 1px;
            }
          }

          .text {
            padding-left: 3px;
            transform: scale(0.9);
            white-space: nowrap;
          }
        }

        .liveDesc {
          width: fit-content;
          height: 14px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 0px 8px 0px 0;
          position: absolute;
          bottom: 0;
          left: 0;
          color: #ffffff;
          padding: 0 3px;

          .desc {
            font-size: 12px;
            transform: scale(0.9);
          }
        }
      }

      .save_money_content {
        position: absolute;
        bottom: 0;
        background-color: rgba(251, 73, 59, 1);
        border-top-right-radius: 8px;
        display: flex;
        max-width: 100%;

        .save_money {
          background: rgba(255, 224, 234, 0.9);
          font-size: 12px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #fb493b;
          border-bottom-left-radius: 8px;
          border-top-right-radius: 8px;
          padding: 1px 6px;
          white-space: nowrap;
        }

        .add {
          color: #ffffff;
          padding: 0 3px;
          font-size: 12px;
          line-height: 18.5px;
          flex-shrink: 1;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }

      .sell_out {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 6;

        .sell_out_circle {
          width: 79px;
          height: 79px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 50%;
          text-align: center;
          font-size: 18px;
          font-family:
            PingFangSC-Semibold,
            PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 79px;
        }
      }

      .quantity {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        border-top-right-radius: 8px;
        display: flex;
        max-width: 100%;
        color: #ffff;
        padding: 0 6px;
      }

      .score {
        position: absolute;
        bottom: 7px;
        height: 16px;
        background: rgba(255, 240, 239, 0.9);
        display: flex;
        z-index: 10;
        color: rgba(255, 48, 45, 1);
        align-items: center;
        padding-left: 4px;
        max-width: 100%;
        box-sizing: border-box;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        .score_text {
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }

        .img_score {
          width: 14px;
          height: 14px;
          flex-shrink: 0;
        }
      }
    }

    .content {
      padding-top: 6px;
      padding-bottom: 9px;

      .style_list {
        display: flex;
        padding-left: 6px;
        width: fit-content;

        .style_list_item {
          margin-right: 3px;
          width: 28px;
          height: 28px;
          background: #f3f4f6;
          border-radius: 4px;
          text-align: center;
          display: flex;
          align-items: center;
          line-height: 12px;
          position: relative;
          justify-content: center;
          border: solid #eeeeee 0.5px;

          .item_img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }

          .item_font {
            transform: scale(0.8);
          }

          .item_sell_out {
            transform: scale(0.9);
            font-size: 10px;
            font-family:
              PingFangSC-Regular,
              PingFang SC;
            font-weight: 400;
            color: #393737;
            position: absolute;
            display: flex;
            align-items: center;
          }
        }
      }

      .price {
        display: flex;
        width: 100%;
        align-items: flex-end;
        padding: 6px 6px 0 6px;
        box-sizing: border-box;
        flex-wrap: wrap;

        .yuPng {
          width: 20px;
          height: 13.3px;
          align-self: center;
          flex-shrink: 0;
        }

        .advancePng {
          width: 28px;
          height: 16px;
          align-self: center;
          flex-shrink: 0;
        }

        .sec_kill {
          font-size: 12px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          color: #ff302d;
          padding-right: 2px;
          flex-shrink: 0;
          padding-bottom: 1px;
          transform: scale(0.83);
          white-space: nowrap;
        }

        .price_content {
          display: flex;
          flex-grow: 1;

          .price_real {
            font-size: 12px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ff302d;

            .price_big {
              padding-left: 2px;
              font-size: 18px;
              line-height: 18px;
            }

            .oldP {
              line-height: 18px;
            }

            .decimal {
              line-height: 18px;
            }
          }

          .price_old {
            font-size: 12px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #a5a5a5;
            padding-left: 3px;
            text-decoration: line-through;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            align-items: center;
            padding-top: 2px;

            .oldP {
              height: 100%;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
              // max-width: 30px;
            }
          }
        }
      }

      .content_title {
        padding-left: 6px;
        padding-right: 6px;
        position: relative;

        .title_img {
          width: 30px;
          height: 14px;
          position: absolute;
          top: 0;
        }

        .title_text {
          font-size: 14px;
          line-height: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
      }

      .content_label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 7px;
        padding-left: 6px;

        .overHidden {
          height: 15px;
          overflow: hidden;
        }

        .discount_label {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .label_image {
            img {
              height: 100%;
              width: auto;
            }
          }

          .discount_label_item {
            width: 42px;
            height: 15px;
            border-radius: 4px;
            border: 1px solid rgb(255 102 0 / 80%);
            color: rgb(255 102 0 / 80%);
            text-align: center;
            line-height: 15px;
          }
        }

        .score {
          background: rgba(255, 240, 239, 0.9);
          display: flex;
          z-index: 10;
          color: rgba(255, 48, 45, 1);
          align-items: center;
          padding-left: 4px;
          padding-right: 4px;
          max-width: 100%;
          box-sizing: border-box;

          .score_text {
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }

          .img_score {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
          }
        }
      }

      .content_save_money {
        padding-left: 6px;
        padding-top: 6px;

        .price_real {
          display: flex;
          font-size: 12px;
          font-family:
            PingFangSC-Regular,
            PingFang SC;
          font-weight: 400;
          color: #ff302d;

          .symbol {
            padding: 0 2px;
            font-weight: 600;
          }

          .price_big {
            font-size: 16px;
            line-height: 14px;
            font-weight: 400;
            padding-top: 1px;
          }

          .decimal {
            font-weight: 400;
            padding-top: 1px;
            font-size: 16px;
          }

          .add {
            font-size: 16px;
          }
        }
      }

      .buy_btn {
        width: 100%;
        padding: 9px 6px 0 6px;
        position: relative;

        .buy_now {
          width: calc(100% - 12px);
          height: 26px;
        }

        .buy_text {
          font-size: 12px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #ffffff;
          position: absolute;
          top: 13px;
          left: 35%;
        }
      }

      .normal {
        width: 100%;
        display: flex;
        justify-content: center;

        .buy_btn_normal {
          width: calc(100% - 12px);
          height: 26px;
          background: linear-gradient(270deg, #ff7346 0%, #ff2b2b 100%);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffff;
          margin-top: 9px;

          .normal_img {
            width: 15px;
            height: 15px;
            padding-right: 4px;
          }
        }
      }
    }
  }

  .small_one2 {
    .img {
      position: relative;

      .img_play {
        width: 18px;
        height: 18px;
        position: absolute;
        bottom: 6px;
        left: 6px;
      }

      .live {
        position: absolute;
        width: 78px;
        height: 28px;
        background: rgb(0 0 0 / 40%);
        border-radius: 100px;
        top: 5px;
        left: 5px;
        z-index: 9;
        display: flex;
        align-items: center;

        .text {
          font-size: 12px;
          font-family:
            PingFangSC-Regular,
            PingFang SC;
          font-weight: 400;
          color: #ffffff;
          padding-left: 7px;
        }
      }
    }

    .content {
      padding-top: 6px;
      padding-bottom: 6px;

      .style_list {
        display: flex;
        transform: scale(0.83);
        margin-left: -10px;
        width: fit-content;

        .style_list_item {
          margin-right: 3px;
          width: 28px;
          height: 28px;
          background: #f3f4f6;
          border-radius: 4px;
          text-align: center;
          display: flex;
          align-items: center;
          line-height: 12px;
          position: relative;
          justify-content: center;
          flex-shrink: 0;
          border: solid #eeeeee 0.5px;

          .item_img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }

          .item_font {
            transform: scale(0.8);
          }

          .item_sell_out {
            transform: scale(0.9);
            font-size: 10px;
            font-family:
              PingFangSC-Regular,
              PingFang SC;
            font-weight: 400;
            color: #393737;
            position: absolute;
            display: flex;
            align-items: center;
          }
        }
      }

      .price {
        display: flex;
        align-items: flex-end;
        padding: 0 6px 0 6px;
        box-sizing: border-box;
        width: 170px;
        transform: scale(0.8);
        margin-left: -20px;

        .yuPng {
          width: 20px;
          height: 13.3px;
          align-self: center;
          flex-shrink: 0;
        }

        .advancePng {
          width: 28px;
          height: 16px;
          align-self: center;
          flex-shrink: 0;
        }

        .sec_kill {
          font-size: 12px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          color: #ff302d;
          padding-right: 2px;
          flex-shrink: 0;
          padding-bottom: 1px;
          transform: scale(0.83);
          white-space: nowrap;
        }

        .price_content {
          display: flex;
          width: 65%;

          .price_real {
            font-size: 12px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ff302d;
            display: flex;
            align-items: flex-end;
            flex-shrink: 0;

            .price_big {
              padding-left: 2px;
              padding-bottom: 6px;
              height: 16.5px;
              font-size: 16px;
            }
          }

          .price_old {
            font-size: 12px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #a5a5a5;
            padding-left: 3px;
            text-decoration: line-through;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            align-items: center;
            padding-top: 4px;
            flex-shrink: 1;
          }
        }
      }

      .content_title {
        padding-left: 6px;
        padding-right: 6px;
        position: relative;

        .title_img {
          width: 30px;
          height: 14px;
          position: absolute;
          top: 0;
        }

        .title_text {
          font-size: 13px;
          line-height: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
      }

      .content_label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 7px;
        padding-left: 6px;

        .overHidden {
          height: 15px;
          overflow: hidden;
        }

        .discount_label {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .label_image {
            img {
              height: 100%;
              width: auto;
            }
          }

          .discount_label_item {
            width: 42px;
            height: 15px;
            border-radius: 4px;
            border: 1px solid rgb(255 102 0 / 80%);
            color: rgb(255 102 0 / 80%);
            text-align: center;
            line-height: 15px;
          }
        }

        .score {
          background: rgba(255, 240, 239, 0.9);
          display: flex;
          z-index: 10;
          color: rgba(255, 48, 45, 1);
          align-items: center;
          padding-left: 4px;
          padding-right: 4px;
          max-width: 100%;
          box-sizing: border-box;

          .score_text {
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }

          .img_score {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
          }
        }
      }

      .content_save_money {
        padding-left: 6px;
        padding-top: 6px;

        .price_real {
          display: flex;
          font-size: 12px;
          font-family:
            PingFangSC-Regular,
            PingFang SC;
          font-weight: 400;
          color: #ff302d;

          .symbol {
            padding: 0 2px;
            font-weight: 600;
          }

          .price_big {
            font-size: 16px;
            line-height: 14px;
            font-weight: 600;
          }

          .decimal {
            font-weight: 600;
          }
        }
      }

      .buy_btn {
        width: 100%;
        padding: 9px 6px 0 6px;
        position: relative;

        .buy_now {
          width: calc(100% - 12px);
          height: 26px;
        }

        .buy_text {
          font-size: 12px;
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #ffffff;
          position: absolute;
          top: 13px;
          left: 35%;
        }
      }

      .normal {
        width: 100%;
        display: flex;
        justify-content: center;

        .buy_btn_normal {
          width: calc(100% - 12px);
          height: 26px;
          background: linear-gradient(270deg, #ff7346 0%, #ff2b2b 100%);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffff;
          margin-top: 9px;

          .normal_img {
            width: 15px;
            height: 15px;
            padding-right: 4px;
          }
        }
      }
    }
  }

  .one3 {
    .one3_item {
      width: 100%;
      padding-bottom: 6px;
      background: #ffffff;
      border-radius: 6px;
      height: 196px;

      .img {
        width: 100%;
        height: 113px;
        position: relative;

        .img_item {
          width: 100%;
          height: 100%;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        .img_play {
          width: 18px;
          height: 18px;
          position: absolute;
          bottom: 6px;
          left: 6px;
        }

        .item_block {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 6px 6px 0 0;

          .item {
            border-radius: 6px 6px 0 0;
          }

          .live {
            width: fit-content;
            height: 18px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 9px;
            color: #ffffff;
            padding: 0 0 0 3px;
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 9;
            left: 6px;
            top: 6px;
            padding-right: 3px;

            .barTotal {
              width: 14px;
              height: 14px;
              display: inline-block !important;
              position: relative;
              border-radius: 50%;
              background-color: rgba(255, 48, 45, 1);
            }

            .barTotal span {
              position: absolute;
              width: 1.5px;
              bottom: 3px;
              border-radius: 1px;
            }

            .bar1 {
              left: 3px;
              height: 10px;
              background-color: #ffff;
            }

            .bar2 {
              left: 6px;
              height: 5;
              background-color: #ffff;
            }

            .bar3 {
              left: 9px;
              height: 10px;
              background-color: #ffff;
            }

            .a1 {
              -webkit-animation: ani1 1s infinite alternate both;
              -moz-animation: ani1 1s infinite alternate both;
              animation: ani1 1s infinite alternate both;
            }

            .a2 {
              -webkit-animation: ani2 1s infinite alternate both;
              -moz-animation: ani2 1s infinite alternate both;
              animation: ani2 1s infinite alternate both;
            }

            .a3 {
              -webkit-animation: ani3 1s infinite alternate both;
              -moz-animation: ani3 1s infinite alternate both;
              animation: ani3 1s infinite alternate both;
            }

            @-webkit-keyframes ani1 {
              0% {
                height: 1px;
              }

              25% {
                height: 3px;
              }

              50% {
                height: 5px;
              }

              75% {
                height: 4px;
              }

              100% {
                height: 5px;
              }
            }

            @-moz-keyframes ani1 {
              0% {
                height: 1px;
              }

              25% {
                height: 3px;
              }

              50% {
                height: 5px;
              }

              75% {
                height: 4px;
              }

              100% {
                height: 5px;
              }
            }

            @keyframes ani1 {
              0% {
                height: 1px;
              }

              25% {
                height: 3px;
              }

              50% {
                height: 5px;
              }

              75% {
                height: 4px;
              }

              100% {
                height: 5px;
              }
            }

            @-webkit-keyframes ani2 {
              0% {
                height: 5px;
              }

              25% {
                height: 3px;
              }

              50% {
                height: 1px;
              }

              75% {
                height: 4px;
              }

              100% {
                height: 2px;
              }
            }

            @-moz-keyframes ani2 {
              0% {
                height: 5px;
              }

              25% {
                height: 3px;
              }

              50% {
                height: 1px;
              }

              75% {
                height: 4px;
              }

              100% {
                height: 2px;
              }
            }

            @keyframes ani2 {
              0% {
                height: 5px;
              }

              25% {
                height: 3px;
              }

              50% {
                height: 1px;
              }

              75% {
                height: 4px;
              }

              100% {
                height: 2px;
              }
            }

            @-webkit-keyframes ani3 {
              0% {
                height: 3px;
              }

              25% {
                height: 1px;
              }

              50% {
                height: 2px;
              }

              75% {
                height: 4px;
              }

              100% {
                height: 1px;
              }
            }

            @-moz-keyframes ani3 {
              0% {
                height: 3px;
              }

              25% {
                height: 1px;
              }

              50% {
                height: 2px;
              }

              75% {
                height: 4px;
              }

              100% {
                height: 1px;
              }
            }

            @keyframes ani3 {
              0% {
                height: 3px;
              }

              25% {
                height: 1px;
              }

              50% {
                height: 2px;
              }

              75% {
                height: 4px;
              }

              100% {
                height: 1px;
              }
            }

            .text {
              padding-left: 3px;
              transform: scale(0.9);
              white-space: nowrap;
            }
          }

          .liveDesc {
            width: fit-content;
            height: 14px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 0px 8px 0px 0;
            position: absolute;
            bottom: 0;
            left: 0;
            color: #ffffff;
            padding: 0 3px;

            .desc {
              font-size: 12px;
              transform: scale(0.9);
            }
          }
        }

        .sell_out {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 6;

          .sell_out_circle {
            width: 79px;
            height: 79px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            text-align: center;
            font-size: 18px;
            font-family:
              PingFangSC-Semibold,
              PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 79px;
          }
        }

        .quantity {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
          border-top-right-radius: 8px;
          display: flex;
          max-width: 100%;
          color: #ffff;
          padding: 0 6px;
        }

        .score {
          position: absolute;
          bottom: 7px;
          height: 16px;
          background: rgba(255, 240, 239, 0.9);
          display: flex;
          z-index: 10;
          color: rgba(255, 48, 45, 1);
          align-items: center;
          padding-left: 4px;
          max-width: 100%;
          box-sizing: border-box;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;

          .score_text {
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }

          .img_score {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
          }
        }
      }

      .content {
        padding: 0 6px;
        width: 100%;
        box-sizing: border-box;

        .price {
          display: flex;
          width: 100%;
          align-items: flex-end;
          box-sizing: border-box;
          padding-top: 6px;

          .yuPng {
            width: 20px;
            height: 13.3px;
            align-self: center;
            flex-shrink: 0;
          }

          .advancePng {
            width: 28px;
            height: 16px;
            align-self: center;
            flex-shrink: 0;
          }

          .advancePng13 {
            width: 18px;
            height: 16px;
            align-self: center;
            flex-shrink: 0;
          }

          .sec_kill {
            font-size: 12px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            color: #ff302d;
            flex-shrink: 0;
            width: 36px;
            transform: scale(0.83);
            margin-left: -2px;
            white-space: nowrap;
          }

          .price_content {
            display: flex;
            align-items: flex-end;
            width: 64px;
            margin-left: -2px;
            flex-grow: 1;

            .price_real {
              font-size: 12px;
              font-family:
                PingFangSC-Medium,
                PingFang SC;
              font-weight: 500;
              color: #ff302d;
              display: flex;
              align-items: flex-end;
              height: 21px;

              .oldP {
                height: 100%;
                display: flex;
                align-items: flex-end;
              }

              .price_big {
                padding-left: 2px;
                padding-right: 1px;
                display: flex;
                align-items: flex-end;
                height: 16.5px;
                // line-height: 16.5px;
                transform: scale(1.2);
                margin-bottom: 2px;
              }
            }

            .price_old {
              font-size: 12px;
              font-family:
                PingFangSC-Medium,
                PingFang SC;
              font-weight: 500;
              color: #a5a5a5;
              padding-left: 3px;
              text-decoration: line-through;
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex-shrink: 1;
            }
          }
        }

        .content_title {
          position: relative;

          .title_img {
            width: 30px;
            height: 14px;
            position: absolute;
            top: 1.4px;
          }

          .title_text {
            display: -webkit-box;
            font-size: 13px;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            line-height: 16px;
          }
        }
      }
    }
  }

  .oneMore {
    width: 100%;
    border-radius: 5px;
    display: flex;

    .back_img {
      width: 90px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      flex-shrink: 0;
    }

    .back_img_top {
      width: 100%;
      height: 50px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      flex-shrink: 0;
    }

    .oneMore_content {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;

      /* IE 10+ */
      .content_body {
        display: flex;
      }

      .oneMore_content_item {
        height: 202px;
        background: #ffffff;
        border-radius: 6px;
        flex-shrink: 0;
        margin-left: 6px;
        position: relative;

        &:last-child {
          margin-right: 6px;
        }

        .img_item {
          width: 112px;
          height: 112px;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        .img_play {
          width: 18px;
          height: 18px;
          position: absolute;
          bottom: 6px;
          left: 6px;
        }

        .item_block {
          position: absolute;
          top: 0;
          left: 0;
          width: 112px;
          height: 112px;
          border-radius: 6px 6px 0 0;

          .item {
            border-radius: 6px 6px 0 0;
          }

          .live {
            width: fit-content;
            height: 18px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 9px;
            color: #ffffff;
            padding: 0 0 0 3px;
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 9;
            left: 3px;
            top: 3px;
            padding-right: 3px;

            .barTotal {
              width: 14px;
              height: 14px;
              display: inline-block !important;
              position: relative;
              border-radius: 50%;
              background-color: rgba(255, 48, 45, 1);
            }

            .barTotal span {
              position: absolute;
              width: 1.5px;
              bottom: 3px;
              border-radius: 1px;
            }

            .bar1 {
              left: 3px;
              height: 10px;
              background-color: #ffff;
            }

            .bar2 {
              left: 6px;
              height: 5;
              background-color: #ffff;
            }

            .bar3 {
              left: 9px;
              height: 10px;
              background-color: #ffff;
            }

            .a1 {
              -webkit-animation: ani1 1s infinite alternate both;
              -moz-animation: ani1 1s infinite alternate both;
              animation: ani1 1s infinite alternate both;
            }

            .a2 {
              -webkit-animation: ani2 1s infinite alternate both;
              -moz-animation: ani2 1s infinite alternate both;
              animation: ani2 1s infinite alternate both;
            }

            .a3 {
              -webkit-animation: ani3 1s infinite alternate both;
              -moz-animation: ani3 1s infinite alternate both;
              animation: ani3 1s infinite alternate both;
            }

            @-webkit-keyframes ani1 {
              0% {
                height: 30%;
              }

              25% {
                height: 50%;
              }

              50% {
                height: 60%;
              }

              75% {
                height: 30%;
              }

              100% {
                height: 60%;
              }
            }

            @-moz-keyframes ani1 {
              0% {
                height: 30%;
              }

              25% {
                height: 50%;
              }

              50% {
                height: 60%;
              }

              75% {
                height: 30%;
              }

              100% {
                height: 60%;
              }
            }

            @keyframes ani1 {
              0% {
                height: 30%;
              }

              25% {
                height: 50%;
              }

              50% {
                height: 60%;
              }

              75% {
                height: 30%;
              }

              100% {
                height: 60%;
              }
            }

            @-webkit-keyframes ani2 {
              0% {
                height: 55%;
              }

              25% {
                height: 35%;
              }

              50% {
                height: 46%;
              }

              75% {
                height: 27%;
              }

              100% {
                height: 11%;
              }
            }

            @-moz-keyframes ani2 {
              0% {
                height: 55%;
              }

              25% {
                height: 35%;
              }

              50% {
                height: 46%;
              }

              75% {
                height: 27%;
              }

              100% {
                height: 11%;
              }
            }

            @keyframes ani2 {
              0% {
                height: 55%;
              }

              25% {
                height: 35%;
              }

              50% {
                height: 46%;
              }

              75% {
                height: 27%;
              }

              100% {
                height: 11%;
              }
            }

            @-webkit-keyframes ani3 {
              0% {
                height: 56%;
              }

              25% {
                height: 60%;
              }

              50% {
                height: 45%;
              }

              75% {
                height: 25%;
              }

              100% {
                height: 60%;
              }
            }

            @-moz-keyframes ani3 {
              0% {
                height: 56%;
              }

              25% {
                height: 60%;
              }

              50% {
                height: 45%;
              }

              75% {
                height: 25%;
              }

              100% {
                height: 60%;
              }
            }

            @keyframes ani3 {
              0% {
                height: 56%;
              }

              25% {
                height: 60%;
              }

              50% {
                height: 45%;
              }

              75% {
                height: 25%;
              }

              100% {
                height: 60%;
              }
            }

            .text {
              padding-left: 3px;
              transform: scale(0.9);
              white-space: nowrap;
            }
          }

          .liveDesc {
            width: fit-content;
            height: 14px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 0px 8px 0px 0;
            position: absolute;
            bottom: 0;
            left: 0;
            color: #ffffff;
            padding: 0 3px;

            .desc {
              font-size: 12px;
              transform: scale(0.9);
            }
          }
        }

        .sell_out {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 6;

          .sell_out_circle {
            width: 79px;
            height: 79px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            text-align: center;
            font-size: 18px;
            font-family:
              PingFangSC-Semibold,
              PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 79px;
          }
        }

        .quantity {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
          border-top-right-radius: 8px;
          display: flex;
          max-width: 100%;
          color: #ffff;
          padding: 0 6px;
        }

        .score {
          position: absolute;
          bottom: 7px;
          height: 16px;
          background: rgba(255, 240, 239, 0.9);
          display: flex;
          z-index: 10;
          color: rgba(255, 48, 45, 1);
          align-items: center;
          padding-left: 4px;
          max-width: 100%;
          box-sizing: border-box;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;

          .score_text {
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }

          .img_score {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
          }
        }

        .content {
          padding: 0 6px;
          width: 100%;
          box-sizing: border-box;
          padding-bottom: 3px;

          .price {
            display: flex;
            width: 100%;
            align-items: flex-end;
            box-sizing: border-box;
            padding-top: 6px;

            .yuPng {
              width: 20px;
              height: 13.3px;
              align-self: center;
              flex-shrink: 0;
            }

            .advancePng {
              width: 28px;
              height: 16px;
              align-self: center;
              flex-shrink: 0;
            }

            .advancePng13 {
              width: 18px;
              height: 16px;
              align-self: center;
              flex-shrink: 0;
            }

            .sec_kill {
              font-size: 12px;
              font-family:
                PingFangSC-Medium,
                PingFang SC;
              color: #ff302d;
              flex-shrink: 0;
              width: 36px;
              transform: scale(0.83);
              margin-left: -2px;
              white-space: nowrap;
            }

            .price_content {
              display: flex;
              align-items: flex-end;
              width: 64px;
              margin-left: -2px;
              flex-grow: 1;

              .price_real {
                font-size: 12px;
                font-family:
                  PingFangSC-Medium,
                  PingFang SC;
                font-weight: 500;
                color: #ff302d;
                display: flex;
                align-items: flex-end;

                .price_big {
                  padding-left: 2px;
                  padding-right: 1px;

                  height: 16.5px;
                  transform: scale(1.2);
                  margin-bottom: 2px;
                }
              }

              .price_old {
                font-size: 12px;
                font-family:
                  PingFangSC-Medium,
                  PingFang SC;
                font-weight: 500;
                color: #a5a5a5;
                padding-left: 3px;
                text-decoration: line-through;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex-shrink: 1;
              }
            }
          }

          .content_title {
            position: relative;

            .title_img {
              width: 30px;
              height: 14px;
              position: absolute;
              top: 1px;
            }

            .title_text {
              font-size: 13px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              line-height: 16px;
            }
          }
        }
      }
    }

    .oneMore_content::-webkit-scrollbar {
      display: none;
      /* Chrome Safari */
    }
  }

  .topHeight {
    max-height: 380px !important;
  }

  .twoMore {
    width: 100%;
    min-height: 237px;
    max-height: 414px;
    border-radius: 5px;
    display: flex;

    .back_img {
      width: 90px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      flex-shrink: 0;
    }

    .back_img_top {
      width: 100%;
      height: 50px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      flex-shrink: 0;
    }

    .twoMore_content {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      flex-grow: 1;
      overflow-x: auto;
      align-content: baseline;
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;

      /* IE 10+ */
      &::-webkit-scrollbar {
        display: none;
        /* Chrome Safari */
      }

      &::after {
        display: block;
        content: "";
        width: 6px;
        height: 100%;
        position: relative;
      }

      .twoMore_content_item {
        width: 112px;
        height: 198px;
        background: #ffffff;
        border-radius: 6px;
        flex-shrink: 0;
        margin-left: 6px;
        margin-top: 6px;
        overflow: hidden;

        .img_item {
          width: 112px;
          height: 112px;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        .img_play {
          width: 18px;
          height: 18px;
          position: absolute;
          bottom: 6px;
          left: 6px;
        }

        .item_block {
          position: absolute;
          top: 0;
          left: 0;
          width: 112px;
          height: 112px;
          border-radius: 6px 6px 0 0;

          .item {
            border-radius: 6px 6px 0 0;
          }

          .live {
            width: fit-content;
            height: 18px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 9px;
            color: #ffffff;
            padding: 0 0 0 3px;
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 9;
            left: 3px;
            top: 3px;
            padding-right: 3px;

            .barTotal {
              width: 14px;
              height: 14px;
              display: inline-block !important;
              position: relative;
              border-radius: 50%;
              background-color: rgba(255, 48, 45, 1);
            }

            .barTotal span {
              position: absolute;
              width: 1.5px;
              bottom: 3px;
              border-radius: 1px;
            }

            .bar1 {
              left: 3px;
              height: 10px;
              background-color: #ffff;
            }

            .bar2 {
              left: 6px;
              height: 5;
              background-color: #ffff;
            }

            .bar3 {
              left: 9px;
              height: 10px;
              background-color: #ffff;
            }

            .a1 {
              -webkit-animation: ani1 1s infinite alternate both;
              -moz-animation: ani1 1s infinite alternate both;
              animation: ani1 1s infinite alternate both;
            }

            .a2 {
              -webkit-animation: ani2 1s infinite alternate both;
              -moz-animation: ani2 1s infinite alternate both;
              animation: ani2 1s infinite alternate both;
            }

            .a3 {
              -webkit-animation: ani3 1s infinite alternate both;
              -moz-animation: ani3 1s infinite alternate both;
              animation: ani3 1s infinite alternate both;
            }

            @-webkit-keyframes ani1 {
              0% {
                height: 30%;
              }

              25% {
                height: 50%;
              }

              50% {
                height: 60%;
              }

              75% {
                height: 30%;
              }

              100% {
                height: 60%;
              }
            }

            @-moz-keyframes ani1 {
              0% {
                height: 30%;
              }

              25% {
                height: 50%;
              }

              50% {
                height: 60%;
              }

              75% {
                height: 30%;
              }

              100% {
                height: 60%;
              }
            }

            @keyframes ani1 {
              0% {
                height: 30%;
              }

              25% {
                height: 50%;
              }

              50% {
                height: 60%;
              }

              75% {
                height: 30%;
              }

              100% {
                height: 60%;
              }
            }

            @-webkit-keyframes ani2 {
              0% {
                height: 55%;
              }

              25% {
                height: 35%;
              }

              50% {
                height: 46%;
              }

              75% {
                height: 27%;
              }

              100% {
                height: 11%;
              }
            }

            @-moz-keyframes ani2 {
              0% {
                height: 55%;
              }

              25% {
                height: 35%;
              }

              50% {
                height: 46%;
              }

              75% {
                height: 27%;
              }

              100% {
                height: 11%;
              }
            }

            @keyframes ani2 {
              0% {
                height: 55%;
              }

              25% {
                height: 35%;
              }

              50% {
                height: 46%;
              }

              75% {
                height: 27%;
              }

              100% {
                height: 11%;
              }
            }

            @-webkit-keyframes ani3 {
              0% {
                height: 56%;
              }

              25% {
                height: 60%;
              }

              50% {
                height: 45%;
              }

              75% {
                height: 25%;
              }

              100% {
                height: 60%;
              }
            }

            @-moz-keyframes ani3 {
              0% {
                height: 56%;
              }

              25% {
                height: 60%;
              }

              50% {
                height: 45%;
              }

              75% {
                height: 25%;
              }

              100% {
                height: 60%;
              }
            }

            @keyframes ani3 {
              0% {
                height: 56%;
              }

              25% {
                height: 60%;
              }

              50% {
                height: 45%;
              }

              75% {
                height: 25%;
              }

              100% {
                height: 60%;
              }
            }

            .text {
              padding-left: 3px;
              transform: scale(0.9);
              white-space: nowrap;
            }
          }

          .liveDesc {
            width: fit-content;
            height: 14px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 0px 8px 0px 0;
            position: absolute;
            bottom: 0;
            left: 0;
            color: #ffffff;
            padding: 0 3px;

            .desc {
              font-size: 12px;
              transform: scale(0.9);
            }
          }
        }

        .sell_out {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 6;

          .sell_out_circle {
            width: 79px;
            height: 79px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            text-align: center;
            font-size: 18px;
            font-family:
              PingFangSC-Semibold,
              PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 79px;
          }
        }

        .quantity {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
          border-top-right-radius: 8px;
          display: flex;
          max-width: 100%;
          color: #ffff;
          padding: 0 6px;
        }

        .score {
          position: absolute;
          bottom: 7px;
          height: 16px;
          background: rgba(255, 240, 239, 0.9);
          display: flex;
          z-index: 10;
          color: rgba(255, 48, 45, 1);
          align-items: center;
          padding-left: 4px;
          max-width: 100%;
          box-sizing: border-box;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;

          .score_text {
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }

          .img_score {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
          }
        }

        .content {
          padding: 0 6px;
          width: 100%;
          box-sizing: border-box;

          .price {
            display: flex;
            width: 100%;
            align-items: flex-end;
            box-sizing: border-box;
            padding-top: 2px;

            .yuPng {
              width: 20px;
              height: 13.3px;
              align-self: center;
              flex-shrink: 0;
            }

            .advancePng {
              width: 28px;
              height: 16px;
              align-self: center;
              flex-shrink: 0;
            }

            .advancePng13 {
              width: 18px;
              height: 16px;
              align-self: center;
              flex-shrink: 0;
            }

            .sec_kill {
              font-size: 12px;
              font-family:
                PingFangSC-Medium,
                PingFang SC;
              color: #ff302d;
              flex-shrink: 0;
              width: 36px;
              transform: scale(0.83);
              margin-left: -2px;
              white-space: nowrap;
            }

            .price_content {
              display: flex;
              align-items: flex-end;
              width: 64px;
              margin-left: -2px;
              flex-grow: 1;

              .price_real {
                font-size: 12px;
                font-family:
                  PingFangSC-Medium,
                  PingFang SC;
                font-weight: 500;
                color: #ff302d;
                display: flex;
                align-items: flex-end;
                height: 21px;

                .oldP {
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                }

                .price_big {
                  padding-left: 2px;
                  padding-right: 1px;
                  transform: scale(1.2);
                  margin-bottom: 2px;
                  padding-top: 2px;
                }
              }

              .price_old {
                font-size: 12px;
                font-family:
                  PingFangSC-Medium,
                  PingFang SC;
                font-weight: 500;
                color: #a5a5a5;
                padding-left: 3px;
                text-decoration: line-through;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex-shrink: 1;
              }
            }
          }

          .content_title {
            position: relative;

            .title_img {
              width: 30px;
              height: 14px;
              position: absolute;
              top: 1px;
            }

            .title_text {
              font-size: 13px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}

.rank_label_wrapper {
  display: inline-flex;
  align-items: center;
  height: 16px;
  margin-top: 4px;
  background: #FFF2E8;
  border: 0.5px solid #FFDEC8;
  border-radius: 2px;
  font-size: 10px;
  color: #8C4206;

  &.rank_label_wrapper_one2 {
    margin-left: 6px;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 15px;
    background: url(https://akim-oss.aikucun.com/356a192b7913b04c54574d18c28d46e6395428ab_1741231934211_7.png) center center no-repeat;
    background-size: contain;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background: url(https://akim-oss.aikucun.com/da4b9237bacccdf19c0760cab7aec4a8359010b0_1741231934521_84.png) center center no-repeat;
    background-size: contain;
  }

  .rank_label_main {
    padding: 0 2px 0 4px;
    line-height: normal;
  }
}


.swiper_pro {
  display: flex;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper_pro_item {
    width: 85px !important;
    height: 92px;
    flex-shrink: 0;
    box-sizing: border-box;
    background: linear-gradient(90deg, #f36226 0%, #ed1533 100%);
    border-radius: 18px;

    .item_img {
      width: 100%;
      height: 72px;
      border-radius: 18px;
    }

    .price_real {
      color: #ffff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      box-sizing: border-box;

      .price_big {
        padding-left: 2px;
        padding-right: 1px;
        height: 16.5px;
        transform: scale(1.2);
      }
    }
  }

  .swiper-slide-active {
    width: 100px !important;
    height: 107px;

    .item_img {
      width: 100%;
      height: 83px;
      border-radius: 18px;
    }

    .price_real {
      color: #ffff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 23px;
      box-sizing: border-box;

      .price_big {
        padding-left: 2px;
        padding-right: 1px;
        height: 16.5px;
        transform: scale(1.2);
      }
    }
  }
}

.more {
  display: flex;
  justify-content: center;
  padding-bottom: 6px;
  margin-top: 6px;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    width: 73px;
    height: 20px;

    .text {
      font-size: 12px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #a5a5a5;
      padding-right: 3px;
    }
  }
}

.infinite {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;

  .loadingMore {
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}