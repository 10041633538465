@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.tabs {
  // position: sticky;
  // top: 0;
  // z-index: 100;
  position: relative;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 40px;
  box-sizing: border-box;
  background: linear-gradient(94deg, #ffeeee 0%, #e6ecff 95%);

  .back {
    padding-left: 12px;
    padding-right: 12px;
    width: 24px;
    height: 24px;
  }
  .btn {
    position: absolute;
    right: 0;
    width: 56px;
    height: 40px;
    z-index: 2;
    border-top-right-radius: 12px;
  }
  .tadDiv {
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #ff2357;

    .tabLine {
      position: relative;
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: auto hidden;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */

      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }

      .tabTile {
        display: flex;
        flex-direction: row;
        padding-right: 32px;
      }
    }

    .tab {
      white-space: nowrap;
      flex-shrink: 0;
      font-size: 15px;
      color: #fff;
      margin-right: 20px;
      position: relative;
      .text {
        position: relative;
        text-align: center;
        z-index: 2;
      }
    }
    .tab:first-child {
      margin-left: 16px;
    }
    .curTab {
      font-size: 16px;
      font-weight: 500;
    }
    .selectedLineContainer {
      height: 3px;
      position: absolute;
      bottom: 2px;
      transition:
        margin 0.3s,
        width 0.3s;
      .selectedLine {
        background: #fff;
        height: 100%;
        width: 75%;
        margin: 0 auto;
      }
    }
  }

  .tabModal {
    position: absolute;
    top: 45px;
    z-index: 99;
    width: 100%;
    height: calc(100vh - 44px);
    background: rgba(0, 0, 0, 0.7);

    .content {
      background-color: #fff;
      padding: 10px 6px 5px 16px;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      display: flex;
      flex-wrap: wrap;
      .category {
        width: 80px;
        height: 33px;
        border-radius: 10px;
        background: #ffffff;
        color: #666666;
        border: 0.5px solid #cccccc;
        margin-bottom: 9px;
        margin-right: 8px;
        display: flex;
        padding: 0 4px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
        }
      }
      .category_selected {
        border-color: #ff302d;
        color: #ff302d;
      }
    }
  }
}

.tabs__sticky {
  .tadDiv {
    background: #fff;

    .tab {
      color: #222;
    }
    .selectedLineContainer {
      .selectedLine {
        background: #222;
      }
    }
  }
}
