@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  z-index: 102;
  pointer-events: auto;

  .ic_back {
    width: 18px;
    height: 18px;
  }

}