@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.poster-unify-toast {
  z-index: 19999 !important;
  border-radius: 8px;
  background: rgba(34, 34, 34, 0.8);
}

.poster_swipe_wrapper {
  touch-action: none;
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/
  user-select: none;

  &::before {
    content: "";
    // height: 20px;
    width: 100%;
    display: block;
    margin: auto;
    flex: none;
  }

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .poster__mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    opacity: 0.8;
  }

  .poster__tip {
    font-size: 14x;
    font-family:
      PingFangSC-Regular,
      PingFang SC;
    font-weight: 400;
    color: #ffffff;
    position: relative;
    margin: auto;
    width: fit-content;
    display: flex;
    align-items: center;

    .tip-img {
      width: 22px;
      height: 22px;
      margin-right: 4px;
    }
  }

  .poster__action {
    flex: none;
    width: 100%;
    height: 50px;
    position: relative;
    box-sizing: border-box;
    background-color: #000;

    .poster__action__header {
      display: flex;
      justify-content: space-between;

      .text {
        font-size: 12px;
        font-family:
          PingFangSC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 12px;
      }

      .btn {
        font-size: 12px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 12px;

        .icon {
          width: 13px;
          height: 10px;
          object-fit: contain;
          margin: 0 2px 0 0;
          display: inline-block;
        }
      }
    }

    .poster__action__description {
      background: #f2f2f2;
      border-radius: 12px;
      padding: 10px;
      box-sizing: border-box;
      margin: 10px 0 0;

      .text {
        user-select: none;
        font-size: 12px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        white-space: pre-wrap;
        height: 44px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
    }

    .poster__action__list {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .poster__action__item {
        flex: none;
        width: fit-content;
        margin: 10px 0px;

        .item-wrapper {
          padding: 0px 12px;
          height: 30px;
          display: flex;
          align-items: center;
          color: #ccc;
          border: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 14px;
          box-sizing: border-box;

          .icon {
            width: 16px;
            height: 16px;
            object-fit: contain;
            margin-right: 4px;
          }
        }

        .text {
          font-size: 14px;
          font-family:
            PingFangSC-Regular,
            PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }

  .poster-abnormal-con {
    background: #ffffff;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;

    .con__img {
      width: 183px;
      height: 183px;
    }

    .con__tips {
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 19px;
    }

    .reload-btn {
      width: 96px;
      height: 36px;
      background: #ffffff;
      border-radius: 14px;
      border: 1px solid #cccccc;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 500;
      color: #666666;
      margin-top: 10px;
    }
  }

  .swiper-container {
    width: 100%;

    :global(.swiper-wrapper) {
      display: flex;
      align-items: center;
    }

    .swiper-slide {
      text-align: center;
      flex-direction: column;
      display: flex;
      justify-content: center;

      .poster__img {
        display: block;
        object-fit: contain;
        position: relative;
        border-radius: 12px;
        width: 100%;
        max-height: 80vh;
      }

      .act-agg-width {
        width: 250px;
      }

      .act-width {
        width: 305px;
      }

      .pro-width {
        width: 225px;
      }

      .xpoint-width {
        width: 283px;
      }

      .conference-width {
        width: 305px;
      }

      .shop-width {
        width: 250px;
      }
    }

    .swiper-slide {
      align-items: center;
    }

    :global(.swiper-slide:not(.swiper-slide-active)) {
      transform: scale(0.92);
      transition: 0.4s ease-in-out;
    }

    :global(.swiper-slide.swiper-slide-active) {
      transform: scale(1);
      transition: 0.4s ease-in-out;
    }
  }

  .poster-loading {
    z-index: 101;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .safe--bottom {
    padding-bottom: calc(50px + constant(safe-area-inset-bottom));
    padding-bottom: calc(50px + env(safe-area-inset-bottom));
  }
}

.gift-tips {
  position: absolute;
  top: 24px;
  width: 100%;

  .gift-tips-header {
    font-size: 32px;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gift-tips-sub-header {
    font-size: 16px;
    font-weight: normal;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}