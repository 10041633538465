@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.price_container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: #222222;

  &_symbol {
    font-size: 12px;
  }

  &_int {
    font-size: 16px;
  }

  &_decimals {
    font-size: 12px;
  }
}
