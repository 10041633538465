@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.cropper-wrap {
  overflow-y: auto;
  border-radius: none;
  position: relative;

  .cropper-view-box {
    outline: none;
  }

  .cropper-line {
    background-color: #ffffff !important;
  }

  .cropper-point {
    background-color: #ffffff !important;
  }

  .cropper-face,
  .cropper-line {
    opacity: 0;
  }

  .cropper-center,
  .cropper-center::before,
  cropper-center::after {
    display: none;
  }

  .point-e,
  .point-s,
  .point-w,
  .point-n {
    width: 0;
    height: 0;
  }

  .point-se {
    width: 20px;
    height: 20px;
    border: 2.5px solid #ffffff !important;
    background-color: rgba(255, 255, 255, 0) !important;
    border-top: none !important;
    border-left: none !important;
    border-bottom-right-radius: 6px;
    transform: translate(-1.25px, -1.25px) !important;
  }

  .point-ne {
    width: 20px;
    height: 20px;
    border: 2.5px solid #ffffff !important;
    background-color: rgba(255, 255, 255, 0) !important;
    border-bottom: none !important;
    border-left: none !important;
    border-top-right-radius: 6px;
    transform: translate(-1.25px, 1.25px) !important;
  }

  .point-sw {
    width: 20px;
    height: 20px;
    border: 2.5px solid #ffffff !important;
    background-color: rgba(255, 255, 255, 0) !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom-left-radius: 6px;
    transform: translate(1.25px, -1.25px) !important;
  }
  .point-nw {
    width: 20px;
    height: 20px;
    border: 2.5px solid #ffffff !important;
    background-color: rgba(255, 255, 255, 0) !important;
    border-bottom: none !important;
    border-right: none !important;
    border-top-left-radius: 6px;
    transform: translate(1.25px, 1.25px) !important;
  }
  .image-container {
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
