@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.tradeSnapshotListC {
  width: 100vw;
  height: 100vh;
  overflow: hidden scroll;
  background-color: #f1f2f5;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px 12px;

  .desc {
    background-color: white;
    border-radius: 12px;
    padding: 12px;
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #222222;
    margin-top: 8px;
  }

  .title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #222222;
    margin-left: 2px;
    margin-top: 12px;
  }

  .loading {
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }

  .loadingFixed {
    position: fixed;
    top: 280px;
    right: 0;
    left: 0;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .empty {
    position: absolute;
    top: 160px;
    left: 94px;
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0px;
    color: #666666;

    .empty_icon {
      width: 184px;
      height: 184px;
    }
  }

  .footer {
    padding-top: 16px;
  }
}
