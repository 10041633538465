@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .cardSkeleton {
    height: 233px;
    margin-bottom: 10px;
    border-radius: 10px;
    z-index: 1;
    position: relative;
  }

  .sortSection {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    place-content: space-around;
    font-size: 14px;
    color: #666666;
    background-color: #ffffff;

    &.onTop {
      border-top-left-radius: @bigBorderRadius;
      border-top-right-radius: @bigBorderRadius;
    }

    .item {
      z-index: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      &.active {
        .filterIcon {
          path {
            fill: @themeColor;
          }
        }
      }

      .icon {
        width: 14px;
        height: 14px;
        margin-left: 2px;
      }

      &.active {
        color: @themeColor;
        font-weight: 500;
      }
    }
  }

  .waterfallWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 458px;
      background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 34%);
    }

    .waterfallContainer {
      width: 353px;
    }
  }
}

.listWrap {
  min-height: 100vh;
}

.popupBody {
  z-index: 10000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.filterContainer {
  display: flex;
  flex-direction: column;

  .header {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #222222;
  }

  .content {
    border-top: 0.5px solid rgba(0, 0, 0, 0.0629);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.0629);
    padding: 0 12px;

    .subHeader {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
      color: #222222;
    }

    .options {
      display: flex;
      flex-direction: row;
      margin-top: 8px;
      margin-bottom: 20px;

      .option {
        width: 80px;
        height: 28px;
        background-color: #f7f8f9;
        border-radius: 4px;
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &.active {
          background-color: @lightColor;
          color: @themeColor;
        }
      }
    }
  }

  .footer {
    margin: 6px 8px;
    display: flex;
    flex-direction: row;
    border: 1px solid @themeColor;
    height: 44px;
    border-radius: 6px;

    .reset {
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: @themeColor;
      font-size: 14px;
      font-weight: 500;
      background-color: @lightColor;
    }

    .confirm {
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: white;
      background-color: @themeColor;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
