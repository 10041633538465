@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.medicateC {
  width: 100vw;
  height: 60vh;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  overflow: hidden scroll;
  .top {
    width: 100vw;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    background-color: white;
    .title {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
    .close {
      width: 18px;
      height: 18px;
      position: absolute;
      right: 20px;
    }
  }
  .body {
    width: 100vw;
    overflow: scroll;
    padding-bottom: 64px;
    .textContainer {
      padding: 0px 14px;
      .text {
        font-size: 14px;
        font-weight: 500;
        color: "#222222";
      }
      .subText {
        font-weight: 400;
        color: "#666";
        font-size: 14px;
        margin: 8px 0px 24px 8px;
      }
    }
  }
  .bottomC {
    background-color: white;
    padding-bottom: 20px;
    position: fixed;
    bottom: 0;
    left: 20px;
    right: 20px;
    .confirmBtn {
      height: 44px;
      list-style: 44px;
      background-color: #ff4747;
      text-align: center;
      vertical-align: middle;
      font-size: 15px;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }
  }
}
