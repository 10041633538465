@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.confirmDialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background: white;
  border-radius: 10px;
  max-height: 402px;
  overflow-y: scroll;
  margin-bottom: 2px;

  .body__title {
    position: sticky;
    height: 46px;
    display: flex;
    flex: 1;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 1000;
    justify-content: center;
    justify-items: center;
    align-items: center;
    .title__content {
      height: 22px;
      display: flex;
      flex: 1;
      justify-content: center; // 确保内容在内部横向居中
      align-items: center; // 确保内容在内部纵向居中
      width: 100%;
      margin-top: 24px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      color: #222222;
    }

    .image__content {
      position: absolute;
      display: flex;
      flex: 1;
      top: 16px;
      right: 16px;
      width: 16px;
      height: 16px;
      z-index: 1001;
    }
  }

  .body__btn {
    position: sticky;
    display: flex;
    background-color: #fff;
    height: 68px;
    left: 0px;
    right: 0px;
    bottom: 0;
    flex: 1;

    .btn__item {
      height: 44px;
      display: flex;
      flex: 1;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 4px;
      background-color: #ff4747;
      border-radius: 6px;
      justify-content: center;
      .btn__text {
        font-family: PingFang SC;
        align-self: center;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .dialog__body {
    padding: 16px 20px 16px 20px;
    font-size: 16px;
    font-family:
      PingFangSC-Regular,
      PingFang SC;
    font-weight: 400;
    color: #333333;
    overflow: hidden;

    .body__desc {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      z-index: 1;
      color: #222222;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
}
