@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.activity_status {
}

.component {
  margin: 0 auto;
  padding: 2px;
  display: inline-flex;
  // background: linear-gradient(180deg, #FCB37E 0%, #FEB951 85%);
  border-radius: 20px;

  .component__body {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    // background: radial-gradient(12% 88% at 92% 50%, #FE0189 0%, #FE013C 100%);
    border-radius: 20px;
    font-family: "苹方-简";
    font-size: 10px;
    font-weight: 600;
    color: #fff;

    .count_down {
      display: flex;
      justify-content: center;
      align-items: center;

      .day {
        margin-right: 6px;
      }

      .block {
        display: inline-block;
        width: 12px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 1px;
        font-size: 10px;
        font-family:
          PingFangSC-Semibold,
          PingFang SC;
        font-weight: 600;
        color: #fff;
        margin-right: 2px;
      }
    }
  }
}
