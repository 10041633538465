@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.floatM {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 12px;
  bottom: 150px;
  z-index: 10;
  .floatIcon {
    width: 44px;
    height: 44px;
    margin-top: 16px;
  }
  .hideDisabled {
    opacity: 0.5;
  }
}
