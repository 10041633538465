@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .img {
    width: 300px;
    border-radius: 5px;
  }

  .closeBtn {
    width: 36px;
    height: 36px;
    background: url(https://akim-oss.aikucun.com/f6e1126cedebf23e1463aee73f9df08783640400_1723264328930_95.png)
      center center no-repeat;
    background-size: 36px;
  }
}
