@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  background-color: white;
  position: relative;

  .sizeSelector {
    border-top: 1px solid rgba(34, 34, 34, 0.08);
    min-height: 40px;
    max-height: 300px;
  }
}

.options {
  background-color: white;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  flex-wrap: wrap;
  align-content: baseline;

  .placeholder {
    visibility: hidden;
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.pb8 {
    padding-bottom: 8px;
  }

  .selection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    .item {
      margin-top: 0;
      margin-left: 12px;
    }
  }

  .item {
    height: 24px;
    border-radius: 4px;
    background: #f7f8f9;
    margin-right: 8px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: 8px;
    max-width: 290px;

    .name {
      color: #222222;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .count {
      color: #a5a5a5;
      flex-shrink: 0;
    }

    .icon {
      width: 12px;
      height: 12px;
    }

    &.active {
      background-color: @lightColor;
      max-width: 200px;
      .name {
        color: @themeColor;
      }
      .count {
        color: @themeColor;
      }
    }
  }
}

.popup {
  min-height: 244px;
  max-height: 90vh;

  .header {
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #333;
    font-size: 15px;
    font-weight: bold;
  }
  .body {
    min-height: 194px;
    max-height: calc(90vh - 50px);
    overflow-y: scroll;
    padding-bottom: calc(10px + constant(safe-area-inset-bottom));
    padding-bottom: calc(10px + env(safe-area-inset-bottom));
    box-sizing: border-box;
  }
}
