@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
}

/* 标签tabs样式 */
.tabsContainer {
  width: 100%;
  padding: 14px 0 18px 0;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.tabsScroll {
  display: flex;
  overflow-x: auto;
  align-items: flex-end;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  /* Firefox */
  padding: 0 19px;
}

.tabsScroll::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Edge */
}

.tabItem {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  font-size: 14px;
  position: relative;
  flex-shrink: 0;
  /* 次要内容_常规 */
  font-family: "苹方-简";
  font-weight: normal;
  letter-spacing: 0px;
  /* 文字色/#666666_次要文字色 */
  color: #666666;
}

.tabItem:first-child {
  padding-left: 0;
}

.tabItem:last-child {
  padding-right: 0;
}

.activeTab {
  /* 正文，长文本_中黑 */
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  /* 品牌色/#FF4747_AKC红 */
  /* 样式描述：爱库存品牌色 */
  color: #FF4747;
  height: 22px;
  line-height: 22px;
}


/* 图文列表样式 */
.mediaGrid {
  padding: 0 12px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
  overflow-y: auto;
  flex: 1;
}

.mediaItem {
  position: relative;
  height: 151px;
}

.mediaContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.mediaText {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 18px;
  z-index: 10;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  font-weight: normal;
  border-radius: 0px 4px 0px 4px;
  background: rgba(0, 0, 0, 0.2);
}

.mediaImage {
  width: 100%;
  height: 100%;
}

.videoIcon {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.videoIconImage {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}



.useCount {
  position: absolute;
  left: 8px;
  bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.downloadIcon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
}

.downloadIconImage {
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 18px;
  height: 18px;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 14px;
  grid-column: span 3;
  align-items: center;
  justify-content: center;
}

.btmPlaceholder {
  height: 40px;
}