@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.points {
  .one2 {
    padding: 0 10px;
    .card {
      width: 173px;
      height: 281px;
      border-radius: 8px;
      opacity: 1;
      background: #ffffff;
      position: relative;
      .top {
        width: 173px;
        height: 173px;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        background: #fff8ed;
        position: relative;
        padding: 38px 27px;
        box-sizing: border-box;
        overflow: hidden;
        .img {
          width: 120px;
          height: 97px;
          position: relative;
          z-index: 2;
        }
        .textDiv {
          width: 120px;
          height: 97px;
          position: absolute;
          top: 38px;
          left: 27px;
          z-index: 2;
          .priceInfo {
            display: flex;
            align-items: baseline;
            justify-content: center;
            padding-top: 13px;
            .symbol {
              font-family: DIN-Bold;
              font-size: 16px;
              font-weight: bold;
              line-height: 20px;
              letter-spacing: 0px;
              color: #723403;
            }
            .price {
              font-family: DIN-Medium;
              font-size: 38px;
              font-weight: 500;
              line-height: 38px;
              letter-spacing: 0px;
              color: #723403;
            }
          }
          .line {
            width: 102px;
            height: 1px;
            position: absolute;
            top: 60.5px;
            left: 7px;
          }
          .text {
            font-family: "苹方-简";
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #8c4206;
            padding-top: 18px;
            text-align: center;
          }
        }
        .backImg {
          width: 90px;
          height: 67.5px;
          position: absolute;
          top: 127px;
          left: 110px;
          z-index: 1;
        }
      }
      .out {
        width: 173px;
        height: 173px;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        background: #fff8ed;
        padding: 38px 27px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        z-index: 2;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
          width: 78px;
          height: 78px;
        }
      }
      .bottom {
        width: 173px;
        height: 108px;
        padding: 8px;
        box-sizing: border-box;
        .title {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0px;
          color: #222222;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 157px;
        }
        .sub_title {
          padding-top: 4px;
          height: 32px;
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;
          color: #666666;
          width: 157px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .score_bootom {
          width: 100%;
          height: 26px;
          display: flex;
          margin-top: 8px;
          .score {
            display: flex;
            align-items: center;
            width: 100px;
            margin-right: 5px;
            .img {
              width: 16px;
              height: 16px;
            }
            .text {
              margin-left: 2px;
              font-family: PingFang SC;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              text-align: center;
              letter-spacing: 0px;
              color: #ff4747;
            }
          }
          .btn {
            width: 52px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            opacity: 1;
            background: #ff4747;
            color: #ffff;
          }
          .out_btn {
            width: 52px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            opacity: 1;
            background: #dbdbdb;
            color: #ffff;
          }
        }
      }
    }
  }
}
