@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  width: 375px;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #f1f2f5;
  .sticky {
    position: sticky;
    top: 0;
    z-index: 9;
  }
  .order {
    width: 100%;
    height: 40px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #a5a5a5;
    box-sizing: border-box;
    background: #ffffff;
    span:last-child {
      color: #222222;
    }
  }
}
.h5 {
  padding-top: 45px;
  box-sizing: border-box;
}
.empty {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  .img {
    width: 216px;
    height: 114px;
  }
}
