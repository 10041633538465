@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
/* src/pages/daily-reward/rewardOrder.module.less */

.container {
  min-height: 100vh;
  background: #F1F2F5;
}

.header {
  width: 100%;
  height: 60px;
  position: relative;
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  color: #222222;
  background: url(https://akim-oss.aikucun.com/mshop/30fb38d6e4b1018e5479ebc0db19e45bd1c0cf19_1679648384334_35.png) left top no-repeat;
  background-size: 100% 100%;

  .back {
    position: absolute;
    left: 12px;
    top: 14px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.content {
  padding: 0 12px;
  box-sizing: border-box;

}

.transport {
  margin-bottom: 8px;
  background-color: #fff;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 10px;

  .transportStatus {
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  .transportIcon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    background: url(https://akim-oss.aikucun.com/8f4d1822000722aec5c6e5bf30b32ae3e4b26b29_1733812732981_18.png) no-repeat;
    background-size: 100% 100%;
  }

  .transportTitle {
    font-size: 14px;
    color: #222222;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .transportDesc {
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: #A5A5A5;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-clamp: 2;
  }

  .transportInfo {
    width: 269px;
    font-size: 14px;
    color: #666;

    .status {
      color: #333;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }

  .transportRight {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 12px;
    top: 20px;
  }

  .transportLine {
    width: 100%;
    height: 1px;
    background-color: rgba(34, 34, 34, 0.08);
    margin: 12px 0 14px 0;
  }
}


.address {
  background-color: #fff;
  box-sizing: border-box;
  display: flex;

  .addressIcon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    background: url(https://akim-oss.aikucun.com/e301933ef53fda4ad4fa2069f89d9f00ffb89745_1733812732609_69.png) no-repeat;
    background-size: 100% 100%;
  }

  .addressContent {
    .addressInfo {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      .addressName {
        font-size: 14px;
        color: #222222;
        font-weight: 500;
      }

      .addressPhone {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #A5A5A5;
        margin: 0 4px 0 6px;
      }

      .addressCopy {
        display: inline-block;
        font-family: PingFang SC;
        font-size: 10px;
        font-weight: 500;
        padding: 1px 4px;
        color: #666666;
        border: 0.5px solid #CCCCCC;
        border-radius: 2px;
      }
    }

    .addressDetail {
      width: 305px;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 0px;
      color: #A5A5A5;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-clamp: 2;
    }
  }

}

.storeInfo {
  background-color: #fff;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 8px;

  .storeHeader {
    display: flex;
    align-items: center;

    .storeLogo {
      width: 20px;
      height: 20px;
      border-radius: 4px;
    }

    .storeName {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      color: #222222;
      margin: 0 2px 0 4px;
    }

    .storeArrow {
      width: 12px;
      height: 12px;
      background: url(https://akim-oss.aikucun.com/1db67b528a43de2bebca495a6b1a0ef4931e3316_1733821011658_20.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}

.product {
  padding-top: 12px;

  .productItem {
    display: flex;
  }

  .productImage {
    width: 86px;
    height: 86px;
    border-radius: 8px;
    margin-right: 8px;
    object-fit: cover;
    box-sizing: border-box;
  }

  .productInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    // padding: 12px 0 34px 0;

    .productTitle {
      margin-bottom: 6px;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #222;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-clamp: 2;
    }

    .productDetail {
      display: flex;
      height: 16px;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #A5A5A5;
      margin-bottom: 8px;
    }

    .productPrice {
      display: flex;
      height: 16px;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #A5A5A5;
      // margin-bottom: 8px;

      .productPriceValue {
        font-family: DIN;
        font-weight: 700;
        font-size: 12px;
      }

      .productTotal {
        color: #222222;
        font-weight: 400;
        font-size: 12px;

        .productTotalValue {
          font-family: DIN;
          font-weight: 700;
          font-size: 12px;
        }
      }
    }
  }

  .productFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    .productLogistics {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px 8px;
      border-radius: 4px;
      color: #222;
      font-size: 12px;
      font-weight: 500;
      border: 1px solid rgba(34, 34, 34, 0.16);
    }
  }
}

.summary {
  background-color: #fff;
  padding: 12px;
  border-radius: 10px;

  .summaryItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: #666666;

    &:last-child {
      margin-bottom: 0;
    }

    .summaryValue {
      font-family: DIN;
      font-weight: bold;
      color: #222222;
      font-size: 14px;
    }

    .summaryValueRed {
      font-family: DIN;
      font-weight: bold;
      color: #ff4d4f;
      font-size: 14px;
    }
  }

  .summaryLine {
    width: 100%;
    height: 1px;
    background-color: rgba(34, 34, 34, 0.08);
    margin: 10px 0;
  }
}


.checkLogistics {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 16px;
  background-color: #f5f5f5;
  color: #666;
  font-size: 12px;
  margin-top: 12px;
}