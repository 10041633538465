@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.settingItem {
  margin: 0px 12px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  background-color: #fff;
  .flexBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  .itemLeft {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    font-weight: 500;
    color: #333;
    img {
      display: inline-block;
      padding-right: 8px;
      width: 24px;
      height: 24px;
    }
  }
  .itemRight {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      color: #666;
      &.red {
        color: #ff4747;
      }
    }
    .amount {
      font-size: 14px;
      font-family: "DIN-Bold";
      font-weight: bold;
      color: #222222;
    }
    .arrow {
      margin-left: 4px;
      width: 12px;
      height: 12px;
      background: url("https://akim-oss.aikucun.com/mshop/890a6158d5b75701e98735b397939cfa0caeec28_1711613398559_5.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
.lastItem {
  border-bottom: none;
}
:global(.adm-center-popup-wrap) {
  max-width: 320px !important;
}
:global(.dialog_switch) {
  :global(.adm-auto-center-content) {
    color: #5e5b5b;
  }
  :global(.adm-dialog-button) {
    color: #bfbaba !important;
  }
  :global(.adm-dialog-button-bold) {
    color: #ff4747 !important;
  }
}
