@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.brandStoreContainer {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px 14px;
  background-color: white;
  margin-top: 8px;

  &&:first-child {
    margin-top: 0;
  }

  .header {
    font-size: 13px;
    color: #999999;
  }

  .content {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;

    .img {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      flex-shrink: 0;
      background-size: 100% 100%;
      margin-right: 8px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;

      .imgCount {
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        font-size: 12px;
        border-radius: 9px;
        padding: 0 6px;
        height: 18px;
        line-height: 18px;
        margin-bottom: 4px;
      }
    }

    .text {
      flex: 1;
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 13px;
      color: #333333;
    }

    .icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-left: 12px;
    }
  }
}

.materialList {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-top: 8px;

  &&:first-child {
    margin-top: 0;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 22px;
    align-items: center;

    .title {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      font-weight: 500;
      color: #222222;

      .count {
        margin-left: 4px;
      }
    }

    .more {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      color: #666666;

      .icon {
        width: 12px;
        height: 12px;
        margin-left: 4px;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 327px;
    overflow-x: auto;
    margin-top: 12px;

    .item {
      background-color: rgb(247, 248, 249);
      border-radius: @smallBorderRadius;
      margin-right: 8px;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      width: 194px;
      // height: 64px;
      padding: 8px;

      &.onlyOne {
        width: 311px;
      }

      .img {
        width: 64px;
        height: 64px;
        border-radius: @smallBorderRadius;
        background-color: rebeccapurple;
        position: relative;
        flex-shrink: 0;

        .count {
          position: absolute;
          left: 4px;
          bottom: 4px;
          background-color: rgba(0, 0, 0, 0.7);
          color: white;
          height: 14px;
          padding: 0 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 10px;
          border-radius: 10px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 8px;

        .text {
          height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #333333;
          font-size: 12px;
          line-height: 17px;

          .lable {
            height: 17px;
            margin-right: 2px;
            display: inline-block;
            vertical-align: bottom;
          }
        }

        .tagContainer {
          .tag {
            color: #ff6600;
            background-color: rgba(255, 102, 0, 0.08);
            padding: 0 4px;
            border-radius: 2px;
            font-size: 10px;
            font-weight: 500;
            height: 14px;
            line-height: 14px;
            display: inline-block;
          }
        }
      }
    }
  }
}
