@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.materialCard {
  padding: 10px;
  // padding: 12px 0;
  background: #f7f8f9;
  border-radius: 10px;
  overflow: hidden;
}
