@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  position: relative;
  overflow: hidden;

  .pairContainer {
    position: absolute;
    top: 0;

    .item {
      height: 29px;
      line-height: 29px;
    }
  }
}
