@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.address__item {
  margin-bottom: 12px;
  padding: 12px 16px;
  background: #f7f8f9;
  border-radius: 10px;

  .item__line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .line1__left {
      flex-grow: 1;
      padding-right: 12px;
      display: flex;
      align-items: center;

      .left__name {
        display: -webkit-box;
        word-break: break-all;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222;
      }
      .left__label {
        flex-shrink: 0;
        margin-left: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 4px;
        height: 16px;
        border-radius: 2px;
        font-family: PingFang SC;
        font-size: 10px;
        font-weight: 500;
      }
      .wait_invite {
        background: rgba(255, 71, 71, 0.08);
        border: 0.5px solid rgba(255, 71, 71, 0.16);
        color: #ff4747;
      }
      .fill_in {
        background: rgba(17, 201, 147, 0.08);
        border: 0.5px solid rgba(17, 201, 147, 0.16);
        color: #06b581;
      }
      .accomplish {
        background: #f1f2f5;
        border: 0.5px solid rgba(34, 34, 34, 0.16);
        color: #222;
      }
      .overtime {
        background: #f7f8f9;
        border: 0.5px solid rgba(34, 34, 34, 0.08);
        color: #a5a5a5;
      }
    }
    .line1__right {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .icon_delete {
        width: 18px;
        height: 18px;
      }
      .icon_refresh {
        margin-left: 12px;
        width: 18px;
        height: 18px;
      }
    }
  }
  .item__line2 {
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .line2__left {
      // background: red;
    }
    .line2__right {
      display: flex;
      align-items: center;

      .user {
        display: flex;
        align-items: center;

        .user__avatar {
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }
        .user__nickname {
          padding-left: 6px;
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: normal;
          line-height: normal;
          color: #333;
        }
      }

      .select {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #222;

        &::after {
          content: "";
          display: inline-block;
          width: 14px;
          height: 14px;
          background-image: url("https://akim-oss.aikucun.com/mshop/c07b50f4a8c59f9fbe1aa006360948daabb94bf5_1714997068516_53.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }
    }
  }
  .item__line3 {
    margin-top: 12px;
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 0.5px solid rgba(34, 34, 34, 0.08);

    .line3__left {
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #a5a5a5;
    }
    .line3__right {
      flex-shrink: 0;

      .right__btn {
        padding: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 26px;
        border-radius: 4px;
        box-sizing: border-box;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        color: #ff4747;

        // &::before {
        //   content: "";
        //   position: relative;
        //   right: 4px;
        //   width: 14px;
        //   height: 14px;
        //   background-image: url("https://akim-oss.aikucun.com/mshop/7716474716fc755c73519708fdb2f0ae293b5564_1714996035306_66.png");
        //   background-repeat: no-repeat;
        //   background-size: contain;
        //   background-position: center;
        // }
      }
      .wait_invite {
        background: rgba(255, 71, 71, 0.08);
        border: 1px solid rgba(255, 71, 71, 0.5);
        color: #ff4747;
      }
      .fill_in {
        background: transparent;
        border: 1px solid rgba(34, 34, 34, 0.16);
        color: #222;
      }
      .accomplish {
        background: rgba(255, 71, 71, 0.08);
        border: 1px solid rgba(255, 71, 71, 0.5);
        color: #ff4747;
      }
      .overtime {
        background: rgba(255, 71, 71, 0.08);
        border: 1px solid rgba(255, 71, 71, 0.5);
        color: #ff4747;
      }
    }
  }
  .item__address {
    padding-top: 12px;
    display: flex;
    align-items: center;
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #666;

    .logo {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
    }
    .username {
      flex-shrink: 0;
      padding-left: 4px;
    }
    .phone {
      flex-shrink: 0;
      padding-left: 4px;
    }
    .address {
      padding-left: 4px;
      display: -webkit-box;
      word-break: break-all;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .item__logistics {
    padding-top: 12px;

    .logistics__line1 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .line1__left {
        display: flex;
        align-items: center;
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #666;

        .logo {
          width: 16px;
          height: 16px;
        }
        .no {
          padding-left: 4px;
        }
      }
      .line1__right {
        .copy {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 22px;
          border-radius: 4px;
          border: 1px solid rgba(34, 34, 34, 0.16);
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: center;
          color: #222;
        }
      }
    }
    .logistics__line2 {
      padding-top: 4px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .line2__left {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;

        .node {
          width: 4px;
          height: 4px;
          background: #a5a5a5;
          border-radius: 50%;
        }
      }
      .line2__right {
        flex-grow: 1;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        color: #a5a5a5;
        word-break: break-all;

        .status {
          float: left;
          flex-shrink: 0;
          margin-right: 2px;
          padding: 1px 4px;
          height: 16px;
          background: rgba(34, 34, 34, 0.08);
          border-radius: 2px;
          font-family: PingFang SC;
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
          color: #666;
          box-sizing: border-box;
        }
      }
    }
  }
}

.address__item_accomplish {
  background: rgba(255, 240, 222, 0.39);
}
