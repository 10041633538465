@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN-Bold";
  src: url("../assets/fonts/DIN-Bold.otf"); /* 注意路径 */
}
@font-face {
  font-family: "DIN-Medium";
  src: url("../assets/fonts/DIN-Medium.otf"); /* 注意路径 */
}
@font-face {
  font-family: "DIN-Regular";
  src: url("../assets/fonts/DIN-Regular.otf"); /* 注意路径 */
}
.market {
  background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 23%);
  position: relative;
  height: 100vh;
  max-width: 500px;
  margin: auto;
  overflow: hidden;
  .backImg {
    width: 100%;
    height: 305px;
    position: fixed;
    max-width: 500px;
    z-index: 0;
  }
  .page {
    position: relative;
    overflow: auto;
    overflow-x: hidden;
    height: calc(100vh - 47px);
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .searchDiv {
      display: flex;
      align-items: center;
      padding: 12px;
      position: sticky;
      z-index: 100;
      top: 0;
      background: linear-gradient(94deg, #ffeeee 0%, #e6ecff 95%);
      .back {
        height: 36px;
        width: 65px;
      }
      .searchBox {
        width: 264px;
        height: 32px;
        margin-left: 24px;
        border-radius: 42px;
        border-width: 0;
        background: #ffffff;
        padding-left: 32px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
      }
      .catagroy {
        height: 26px;
        width: 26px;
        padding-bottom: 2px;
        margin-left: 11px;
      }
      .search_img {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 112px;
      }
    }
    .sticky {
      width: 100%;
      padding: 0;
      box-sizing: border-box;
      position: sticky;
      top: 60px;
    }
    .focus {
      margin-bottom: 10px;
      padding: 0 10px;
    }
    .cards {
      margin-top: 0px;
      padding: 4px 12px 0 12px;
      background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 23%);
      box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.0134);
      .card_item {
        margin-bottom: 8px;
      }
    }
    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 106px;
      background: #f1f2f5d1;
      margin: 0;
      min-height: 100px;
      .img {
        width: 118px;
      }
      .text {
        color: #666666;
        font-size: 14px;
      }
      .btn {
        width: 64px;
        height: 26px;
        border: 1px solid rgba(34, 34, 34, 0.16);
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 12px;
        line-height: 26px;
        text-align: center;
      }
    }
  }
  .tabs {
    width: 100%;
    box-sizing: border-box;
    background: linear-gradient(94deg, #ffeeee 0%, #e6ecff 95%);
    z-index: 100;
    height: 40px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 60px;
    .back {
      padding-left: 12px;
      padding-right: 12px;
      width: 24px;
      height: 24px;
    }
    .btn {
      position: absolute;
      right: 0;
      width: 56px;
      height: 40px;
      z-index: 2;
      border-top-right-radius: 12px;
    }
    .tadDiv {
      background: linear-gradient(94deg, #ffeeee 0%, #e6ecff 95%);

      width: 100%;
      height: 100%;
      z-index: 2;

      .tabLine {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        position: relative;

        overflow-y: hidden;
        overflow-x: auto;
        flex-wrap: nowrap;

        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background: #fff;

        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }

        .tabTile {
          display: flex;
          flex-direction: row;
          padding-right: 32px;
        }
      }

      .tab {
        white-space: nowrap;
        flex-shrink: 0;
        font-size: 15px;
        color: #222222;
        margin-right: 20px;
        position: relative;
        .text {
          position: relative;
          text-align: center;
          z-index: 2;
        }
      }
      .tab:first-child {
        margin-left: 16px;
      }
      .curTab {
        font-size: 16px;
        font-weight: 500;
      }
      .selectedLineContainer {
        height: 3px;
        position: absolute;
        bottom: 2px;
        transition:
          margin 0.3s,
          width 0.3s;
        .selectedLine {
          background: #ff4747;
          height: 100%;
          width: 75%;
          margin: 0 auto;
        }
      }
    }
  }
  .cards {
    margin-top: 4px;
    padding: 0 12px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .card_item {
      margin-bottom: 8px;
    }
    .card_item:last-child {
      margin-bottom: 0;
    }
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 106px;
    background: #f1f2f5;
    margin: 0;
    .img {
      width: 118px;
    }
    .text {
      color: #666666;
      font-size: 14px;
    }
  }
  .tabModal {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: calc(100vh - 44px);
    top: 40px;
    .content {
      background-color: #fff;
      padding: 10px 6px 5px 16px;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      display: flex;
      flex-wrap: wrap;
      .category {
        width: 80px;
        height: 33px;
        border-radius: 10px;
        background: #ffffff;
        color: #666666;
        border: 0.5px solid #cccccc;
        margin-bottom: 9px;
        margin-right: 8px;
        display: flex;
        padding: 0 4px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
        }
      }
      .category_selected {
        border-color: #ff302d;
        color: #ff302d;
      }
    }
  }
}
.loadingMore {
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
  .adm-image-tip {
    display: none;
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sk_top_left {
  margin-left: 12px;
}
.sk_top_right {
  margin-left: 24px;
}
.sk_brand {
  margin-left: 12px;
  margin-top: 12px;
}
.sk_tab {
  margin-top: 8px;
  position: relative;
  padding: 0 12px;
  .sk_card {
    border-radius: 10px;
    margin-bottom: 10px;
  }
}
.stickyClass {
  min-height: 100vh;
}
.tabBack {
  width: 100%;
  height: 40px;
  background: linear-gradient(94deg, #ffeeee 0%, #e6ecff 95%);
  position: absolute;
  top: 0;
  max-width: 500px;
}
.infinite {
  margin-bottom: calc(8px + constant(safe-area-inset-bottom));
  margin-bottom: calc(8px + env(safe-area-inset-bottom));
  :global(.adm-image-tip) {
    display: none;
  }
}
input::-webkit-input-placeholder {
  font-size: 14px;
}

input:-moz-placeholder {
  font-size: 14px;
}

input::-moz-placeholder {
  font-size: 14px;
}

input:-ms-input-placeholder {
  font-size: 14px;
}
.darkList {
  position: absolute;
  top: 21px;
  left: 132px;
  height: 20px;
  overflow: hidden;
  .darkList_item {
    font-family: "苹方-简";
    font-size: 0.28rem;
    font-weight: normal;
    line-height: 0.4rem;
    height: 0.4rem;
    transition: all 0.5s;
    position: relative;
  }
}
