@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.single {
  .single__achievement {
    padding: 12px;
    align-items: center;
    background: #fff0de;
    border-radius: 8px;
    border: 1px solid #ffe9d0;

    .achievement__title {
      padding-bottom: 22px;
      text-align: center;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .achievement__task {
      margin-bottom: 10px;

      .task__title {
        padding-left: 22px;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        color: #222;
      }

      .task__progress {
        // margin: 0 auto 22px;
        margin: 22px auto;
        width: 250px;

        .progress__base {
          position: relative;
          width: inherit;
          height: 12px;
          background: rgba(255, 71, 71, 0.16);
          border-radius: 6px;

          &::before {
            content: "";
            position: absolute;
            left: -8px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 2;
            width: 16px;
            height: 16px;
            background-image: url("https://akim-oss.aikucun.com/mshop/8b58dff54ecfe1ff72a1a3dc01e0b29b79746b11_1715089931307_89.png");
            background-repeat: no-repeat;
            background-size: contain;
          }

          &::after {
            content: "";
            position: absolute;
            right: -8px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 2;
            width: 16px;
            height: 16px;
            background-image: url("https://akim-oss.aikucun.com/mshop/8b58dff54ecfe1ff72a1a3dc01e0b29b79746b11_1715089931307_89.png");
            background-repeat: no-repeat;
            background-size: contain;
          }

          .current {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 0%;
            height: 12px;
            background: #ff4747;
            border-radius: 6px;

            .current__gmv {
              position: absolute;
              top: -20px;
              right: 0;
              z-index: 2;
              transform: translateX(50%);
              padding: 0 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: auto;
              height: 16px;
              white-space: nowrap;
              background: #ff4747;
              border-radius: 4px;
              font-family: PingFang SC;
              font-size: 10px;
              font-weight: 500;
              line-height: 16px;
              color: #fff;

              &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -3px;
                margin: 0 auto;
                width: 6px;
                height: 3px;
                background-image: url("https://akim-oss.aikucun.com/mshop/b61eb480625e0443b8c967a7a1cb8e289650c990_1732774387921_69.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
        }

        .progress__success {
          &::after {
            background-image: url("https://akim-oss.aikucun.com/mshop/0e45e482fb226cbc133a0e8653efd37f7476df8e_1715233485125_41.png");
          }
        }

        .progress__node {
          padding-top: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: bold;
          line-height: 16px;
          color: #333;

          .node__start {
            transform: translateX(-50%);
          }

          .node__end {
            transform: translateX(50%);
          }
        }
      }
    }

    .achievement__rank {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #222;

      .rank__left {
        span {
          color: #ff4747;
        }
      }

      .rank__right {
        position: relative;
        padding-right: 12px;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 12px;
          height: 12px;
          background-image: url("https://akim-oss.aikucun.com/e510552e929a375e0de5b636fcccc193bbbf79ce_1731586282101_66.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }

  .single__rule {
    margin-top: 12px;
    padding: 12px;
    background: #fff0de;
    border-radius: 8px;
    border: 1px solid #ffe9d0;
    box-sizing: border-box;

    .rule__title {
      text-align: center;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .rule__table {
      margin-top: 8px;
      background: #ffffff;
      border: 1px solid #ffe5c5;
      border-radius: 8px;

      .table__header {
        padding: 8px 20px;
        display: flex;
        align-items: center;
        background: #ffe5c5;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #8c4206;

        .header__key {
          flex-grow: 1;
          width: 40%;
        }

        .header__value {
          flex-grow: 1;
          width: 60%;
        }
      }

      .table__body {
        display: flex;
        flex-direction: column;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
        color: #222;

        .body__item {
          padding: 12px;
          display: flex;
          align-items: center;

          .item__key {
            flex-grow: 1;
            width: 40%;
          }

          .item__value {
            flex-grow: 1;
            width: 60%;
          }
        }
      }
    }

    .rule__tips {
      padding-top: 6px;
      font-family: "苹方-简";
      font-size: 10px;
      font-weight: normal;
      line-height: normal;
      color: #ff4747;
    }
  }
}

.multi {
  padding: 12px;
  background: #fff0de;
  border: 1px solid #ffe9d0;
  border-radius: 8px;

  .multi__tab {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .tab__item {
      flex-flow: 1;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .tab__active {
      position: relative;
      color: #ff4747;

      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 50%;
        height: 2px;
        background: #ff4747;
      }
    }
  }

  .multi__achievement {
    margin-top: 12px;
    padding: 12px;
    background: #fff;
    border: 1px solid #ffe5c5;
    border-radius: 8px;

    .achievement__task {
      margin-bottom: 10px;

      .task__title {
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        color: #222;
      }

      .task__progress {
        margin: 22px 8px 0 8px;

        .progress__base {
          position: relative;
          width: inherit;
          height: 12px;
          background: rgba(255, 71, 71, 0.16);
          border-radius: 6px;

          &::before {
            content: "";
            position: absolute;
            left: -8px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 2;
            width: 16px;
            height: 16px;
            background-image: url("https://akim-oss.aikucun.com/mshop/8b58dff54ecfe1ff72a1a3dc01e0b29b79746b11_1715089931307_89.png");
            background-repeat: no-repeat;
            background-size: contain;
          }

          &::after {
            content: "";
            position: absolute;
            right: -8px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 2;
            width: 16px;
            height: 16px;
            background-image: url("https://akim-oss.aikucun.com/mshop/8b58dff54ecfe1ff72a1a3dc01e0b29b79746b11_1715089931307_89.png");
            background-repeat: no-repeat;
            background-size: contain;
          }

          .current {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 0%;
            height: 12px;
            background: #ff4747;
            border-radius: 6px;

            .current__gmv {
              position: absolute;
              top: -20px;
              right: 0;
              z-index: 2;
              transform: translateX(50%);
              padding: 0 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: auto;
              height: 16px;
              white-space: nowrap;
              background: #ff4747;
              border-radius: 4px;
              font-family: PingFang SC;
              font-size: 10px;
              font-weight: 500;
              line-height: 16px;
              color: #fff;

              &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -3px;
                margin: 0 auto;
                width: 6px;
                height: 3px;
                background-image: url("https://akim-oss.aikucun.com/mshop/b61eb480625e0443b8c967a7a1cb8e289650c990_1732774387921_69.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
        }

        .progress__success {
          &::after {
            background-image: url("https://akim-oss.aikucun.com/mshop/0e45e482fb226cbc133a0e8653efd37f7476df8e_1715233485125_41.png");
          }
        }

        .progress__node {
          padding-top: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: bold;
          line-height: 16px;
          color: #333;

          .node__start {
            transform: translateX(-50%);
          }

          .node__end {
            transform: translateX(50%);
          }
        }
      }
    }

    .achievement__rank {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #222;

      .rank__left {
        span {
          color: #ff4747;
        }
      }

      .rank__right {
        position: relative;
        padding-right: 12px;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 12px;
          height: 12px;
          background-image: url("https://akim-oss.aikucun.com/e510552e929a375e0de5b636fcccc193bbbf79ce_1731586282101_66.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }

  .multi__rule {
    margin-top: 12px;

    .rule__title {
      text-align: center;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .rule__table {
      margin-top: 8px;
      background: #ffffff;
      border: 1px solid #ffe5c5;
      border-radius: 8px;

      .table__header {
        padding: 8px 20px;
        display: flex;
        align-items: center;
        background: #ffe5c5;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #8c4206;

        .header__key {
          flex-grow: 1;
          width: 40%;
        }

        .header__value {
          flex-grow: 1;
          width: 60%;
        }
      }

      .table__body {
        display: flex;
        flex-direction: column;
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
        color: #222;

        .body__item {
          padding: 12px;
          display: flex;
          align-items: center;

          .item__key {
            flex-grow: 1;
            width: 40%;
          }

          .item__value {
            flex-grow: 1;
            width: 60%;
          }
        }
      }
    }

    .rule__tips {
      padding-top: 6px;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: normal;
      color: #ff4747;
    }
  }
}
