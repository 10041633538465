@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.alertTitle {
  font-size: 16px;
  font-weight: 500;
  color: #222;
}

.alertContent {
  font-size: 14px;
  color: #666;
  text-align: center;
  min-width: 252px;
}

.alertConfirmText {
  font-size: 16px;
  font-weight: 500;
  color: #ff4747;
}

.alertCancelText {
  font-size: 16px;
  color: #666;
}
