@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  background: #ffffff;
  padding: 0 15px;
}

.followBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 15px;
  background: rgba(255, 71, 71, 0.08);
  font-size: 12px;
  border-radius: 4px;
  // box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.05);
  z-index: 10;
  .followContent {
    position: relative;
    display: flex;
    align-items: center;
    color: #ff4747;
    // &::before {
    //   content: "";
    //   margin-right: 8px;
    //   width: 18px;
    //   height: 18px;
    //   background: url(https://akim-oss.aikucun.com/f1f836cb4ea6efb2a0b1b99f41ad8b103eff4b59_1698929404619_16.png)
    //     center center no-repeat;
    //   background-size: 18px;
    // }
  }
  .btnWrap {
    display: flex;
    align-items: center;
  }
  .followBtn {
    display: flex;
    align-items: center;
    height: 20px;
    margin-right: 8px;
    padding: 0 10px;
    background: #ff4747;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 500;
    font-size: 10px;
  }
  .closeBtn {
    width: 12px;
    height: 12px;
    background: url(https://akim-oss.aikucun.com/2e01e17467891f7c933dbaa00e1459d23db3fe4f_1729843144255_82.png)
      center center no-repeat;
    background-size: 12px;
  }
}
.wrap2 {
  padding: 0;
  .followBox {
    padding-right: 10px;
  }
}
