@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.resItemVideo {
  margin-top: 12px;
  .flag {
    width: 40px;
    height: 52px;
    position: absolute;
  }
}
