@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.image-item-box {
  display: flex;
  gap: 5px; /* 每个项之间的外边距 */
  align-items: center;
  min-height: 20px;
}

.image-item {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2px; /* 保持和选中状态相同的 padding */
  border: 2px solid transparent; /* 未选中时使用透明边框 */
  touch-action: none;
}

.image-item.selected {
  border: 2px solid #ff4747;
}

.image-item.selected::after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 5px;
  background: url("../../assets/arrow.svg") no-repeat;
  background-size: 100% 100%;
  // width: 0;
  // height: 0;
  // border-left: 6px solid transparent;
  // border-right: 6px solid transparent;
  // border-top: 6px solid #ff4747;
  // border-radius: 0 0 6px 6px;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper .item {
  object-fit: contain; /* 确保图片显示为方形 */
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.icon {
  position: absolute;
  bottom: -13px;
  right: -8px;
  width: 22px;
  height: 22px;
}
