@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.star_container {
  display: flex;
  flex-direction: row;
}
.star {
  width: 9px;
  height: 9px;
}
.margin_left {
  margin-left: 3px;
}
