@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN-Bold";
  src: url("../../assets/fonts/DIN-Bold.otf"); /* 注意路径 */
}
.wrapper {
  background-color: #e9e0f1;
  .topTitle {
    display: flex;
    align-items: center;
  }
  .footerWrap {
    position: fixed;
    bottom: 0;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .bottom_btn {
      // background: #ff4747;
      font-family: PingFang SC;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    }
  }
}

.container {
  padding: 8px 0;
  background-image: url("https://akim-oss.aikucun.com/80f87c8847da6c872ce2fc9fec0a98a73ebc578d_1706753422411_59.png");
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-color: #f1f2f5;

  .performance-intro {
    border-radius: 10px;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin: 0 10px;
    margin-bottom: 8px;
    padding: 10px 12px;

    .intro__title {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #222222;
    }
    .intro__right {
      margin-left: auto;
      display: flex;
      align-items: center;
      .pre__price {
        font-family: "苹方-简";
        font-size: 12px;
        line-height: 16px;
        color: #a5a5a5;
        margin-right: 6px;
      }
      .price {
        font-family: "DIN-Bold";
        color: #222222;
        line-height: 14px;
        height: 16px;
      }
      .price__integer {
        font-size: 16px;
      }
      .price__decimal {
        font-size: 12px;
      }
      .intro__arrow__icon {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
  }
}
.notice {
  background-color: #fff4e6;
  padding: 8px 12px;
  display: flex;
  .notice__btn {
    color: #ff6600;
    margin-left: auto;
  }
}
