@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  flex: 1;
  padding: 10px 12px;
  margin-bottom: 8px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  flex-direction: column;

  .top {
    display: flex;
    // justify-content: space-between;
    height: 26px;
    line-height: 26px;
    font-family: PingFang SC;
    font-size: 12px;
    color: #a5a5a5;
    position: relative;

    // margin-bottom: 10px;
    &>div:first-child {
      line-height: 18px;
    }

    .limited_time {
      box-sizing: border-box;
      position: absolute;
      right: -12px;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 18px;
      padding: 4px 4px 4px 8px;
      border-radius: 12px 0px 0px 12px;
      background: #FFDD8E;
      color: #8C4206;
      font-size: 10px;
    }
  }

  .topLine {
    display: flex;
    height: 1px;
    margin-bottom: 10px;
  }

  .card {
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;

    .card_left {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      align-self: center;

      .left_image {
        width: 56px;
        height: 56px;
        border-radius: 6px;
        align-self: center;
        opacity: 1;
        object-fit: contain;
        box-sizing: border-box;
        border: 0.5px solid rgba(34, 34, 34, 0.08);
      }

      .left_content {
        display: flex;
        flex: 1;
        margin-left: 8px;
        padding-right: 12px;
        align-items: center;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        justify-content: center;

        .shop_title {
          display: flex;
          overflow: hidden;
          width: 100%;
          margin-bottom: 4px;

          .title_left {
            text-overflow: ellipsis;
            font-family: PingFang SC;
            white-space: nowrap;
            /* 不换行 */
            overflow: hidden;
            /* 隐藏超出的内容 */
            font-size: 12px;
            line-height: 16px;
            color: #666;
            flex-shrink: 1;
          }

          .title_image {
            width: 14px;
            height: 14px;
            padding-left: 1px;
            align-self: center;
          }
        }

        .content_title {
          display: flex;
          margin-bottom: 4px;
          overflow: hidden;
          width: 100%;
          align-items: baseline;

          .title_left {
            display: inline-block;
            /* 或者使用 display: block;，取决于你的需求 */
            white-space: nowrap;
            /* 不换行 */
            overflow: hidden;
            /* 隐藏超出的内容 */
            text-overflow: ellipsis;
            /* 添加省略号 */
            text-overflow: ellipsis;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: #222;
            flex-shrink: 1;
          }

          .title_red {
            color: #ff4747;
          }

          .title_image {
            width: 14px;
            height: 14px;
            padding-left: 1px;
            align-self: center;
          }

          .title_right {
            display: flex;
            overflow: hidden;
            /* 隐藏超出的内容 */
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            margin-left: 3px;
            align-items: baseline;

            .price_left {
              font-family: DIN-Bold;
              font-size: 14px;
              font-weight: 500;
              display: flex;
              line-height: 20px;
              color: #ff4747;
              padding-right: 1px;
            }

            .price_right {
              font-family: DIN-Bold;
              font-size: 18px;
              font-weight: 500;
              line-height: 20px;
              display: flex;
              color: #ff4747;
            }

            flex-shrink: 0;
          }
        }

        .red_title {
          display: flex;
          overflow: hidden;
          width: 100%;

          .title_left {
            text-overflow: ellipsis;
            font-family: PingFang SC;
            white-space: nowrap;
            overflow: hidden;
            font-size: 12px;
            line-height: 16px;
            color: #666;
            flex-shrink: 1;
          }

          .title_image {
            width: 14px;
            height: 14px;
            padding-left: 1px;
            align-self: center;
          }
        }

        .content_desc {
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;
          margin-top: 3px;
          align-self: flex-start;
          color: #666;
        }

        .content_bottom {
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }
    }
  }

  .bottomLine {
    display: flex;
    height: 1px;
    margin-top: 10px;
  }

  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;

    .bottom_left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .left_image {
        width: 20px;
        height: 20px;
        border-radius: 10px;
      }

      .left_content {
        display: flex;
        align-self: center;
        font-family: PingFang SC;
        font-size: 12px;
        padding-left: 3px;
        color: #666;
      }

      .left_arrow {
        width: 14px;
        height: 14px;
      }
    }

    .bottom_right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .right_forward {
        display: flex;
        width: 74px;
        height: 26px;
        border-radius: 4px;
        box-sizing: border-box;
        justify-content: center;
        background: rgba(255, 71, 71, 0.08);
        box-sizing: border-box;
        border: 1px solid rgba(#ff4747, 0.5);
        align-items: center;

        .btn_title {
          display: flex;
          align-self: center;
          font-family: PingFang SC;
          font-size: 12px;
          color: #ff4747;
        }
      }

      .card_right {
        display: flex;
        width: 74px;
        height: 26px;
        border-radius: 4px;
        justify-content: center;
        background-color: #ff4747;
        align-items: center;

        .btn_title {
          display: flex;
          align-self: center;
          font-family: PingFang SC;
          font-size: 12px;
          color: #fff;
        }
      }

      .right_gray {
        background-color: #dbdbdb;
      }

      .right_space {
        margin-right: 8px;
      }
    }
  }

  .bottom_end {
    justify-content: flex-end;
  }
}