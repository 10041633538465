@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.price_real {
  font-size: 12px;
  font-family:
    PingFangSC-Medium,
    PingFang SC;
  font-weight: 500;
  color: #ff302d;
  position: relative;
  height: 21px;
  display: flex;
  align-items: flex-end;

  .symbol {
    position: relative;
    bottom: 0;
    display: flex;
    align-items: flex-end;
  }
  .oldP {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
  .price_big {
    padding-left: 2px;
    padding-right: 1px;
    position: relative;
    bottom: 0;
    height: 18px;
    line-height: 12px;
    font-size: 18px;
    // display: flex;
    // align-items: flex-end;
    // transform: scale(1.2);
  }
  .decimal {
    display: flex;
    align-items: flex-end;
  }
}
.price_old {
  font-size: 12px;
  font-family:
    PingFangSC-Medium,
    PingFang SC;
  font-weight: 500;
  color: #a5a5a5;
  padding-left: 3px;
  text-decoration: line-through;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
}
