@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.tips {
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;

  .tips_container {
    display: flex;
    flex-direction: column;
    padding: 12px;

    .tips_title {
      display: flex;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #222222;
    }

    .tips_desc {
      display: flex;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #666666;
      white-space: pre-wrap;
      margin-top: 8px;
    }
  }
}
