@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.top_bar {
  padding: 6px 18px 6px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  background: #fff;
  box-sizing: border-box;

  .bar__left {
    flex-shrink: 0;
    position: relative;
    display: flex;

    .leftImageBox {
      padding: 6px 0 6px 12px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .image {
        width: 20px;
        height: 20px;
      }
    }
  }
  .bar__left_h5 {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    border: 0.5px solid #e5e5e5;

    .leftImageBox {
      padding: 6px 12px;
    }

    .leftImageBox:first-child::after {
      content: "";
      width: 1px;
      height: 18px;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      right: 0;
    }
  }
  .bar__center {
    padding: 0 18px;
    flex-grow: 1;
    box-sizing: border-box;

    .center__search {
      padding: 7px 10px;
      display: flex;
      align-items: center;
      min-width: 200px;
      height: 32px;
      background: #f1f2f5;
      border-radius: 6px;
      box-sizing: border-box;

      .search__icon {
        flex-shrink: 0;
        width: 18px;
        height: 18px;
      }
      .search__form {
        flex-grow: 1;
        .search__text {
          width: 100%;
          padding-left: 8px;
          border: 0;
          outline: none;
          background: inherit;
          font-family: "苹方-简";
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          color: #222;

          &::placeholder {
            color: #ccc;
          }
        }
      }

      .search__clear {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
      }
    }
  }
  .bar__right {
    flex-shrink: 0;
    width: 34px;

    .right__btn {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: normal;
      line-height: 22px;
      color: #ff4747;
    }
  }
}
