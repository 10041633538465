@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.emptyWrapper {
  padding: 0 12px;
  background-color: white;
  margin-top: -44px;
  padding-top: 44px;

  .header {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgb(233, 233, 233);

    .logo {
      width: 40px;
      height: 40px;
      border: 1px solid rgb(233, 233, 233);
      border-radius: 4px;
      margin: 0 10px;
      background-color: white;
      position: relative;

      .logoImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .licenseImg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
    .text {
      font-size: 16px;
      color: #333;
    }
  }

  .emptyArea {
    display: flex;
    flex-direction: column;
    align-items: center;

    .emptyImg {
      width: 240px;
      height: 125px;
      margin: 0 auto;
    }
    .text {
      color: #b3b3b3;
      font-size: 13px;
      margin-bottom: 12px;
    }
  }
}

.recommendHeader {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    height: 20px;
  }
}
