@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.giftPanelBase {
  width: 100vw;
  height: 473px;
  padding-top: 176px;
  background: url(https://akim-oss.aikucun.com/b275c1b21b7b08b6fa6bfba603af1b41b1f2833d_1741829779008_98.png) no-repeat;
  background-size: contain;
  box-sizing: border-box;

  .product {
    display: flex;
    justify-content: center;

    &>img {
      width: 124px;
      height: 124px;
      border-radius: 18px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    margin-top: 8px;

    .title {
      max-width: 300px;
      padding: 0 30px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;

    .btn {
      width: 210px;
      height: 90px;
      margin-top: 10px;
      background: url(https://akim-oss.aikucun.com/221af976966a8568048ca20a246662a0bf9b06af_1741779940402_40.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}

.giftPanelOther {
  width: 100vw;
  height: 472px;
  padding-top: 180px;
  background: url(https://akim-oss.aikucun.com/58a230b9a0b6612ae1a8783f1c0b4efc274a914c_1741829778985_72.png) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;

  .title {
    font-size: 20px;
    color: #742810;
    font-weight: 500;

    &>div {
      text-align: center;
    }
  }

  .product {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    &>img {
      width: 118px;
      height: 118px;
      border-radius: 18px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    padding-left: 30px;

    .btn {
      width: 210px;
      height: 90px;
      background: url(https://akim-oss.aikucun.com/953b135231737e234db2d886c67e1c2afb640b7f_1741779940399_55.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}