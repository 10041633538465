@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.component {
  position: relative;
  z-index: 10;

  .component__tabs {
    position: relative;
    padding: 8px 0 0 0;
    display: flex;
    width: 100vw;
    background: #fff;

    .back-today {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 6px;
      width: 52px;
      height: 70px;
      box-sizing: border-box;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);

      .icon {
        margin: 0 0 2px 9px;
        width: 18px;
        height: 18px;
      }
      .text {
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        text-align: center;
        color: #ff4747;
      }
    }
    .scroll-view {
      padding-bottom: 12px;
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 325px;
      overflow-x: scroll;
      overflow-y: hidden;

      .item {
        flex-shrink: 0;
        position: relative;
        margin: 0 3px;
        display: inline-block;
        padding: 6px 8px 8px 8px;
        width: 75px;
        height: 50px;
        box-sizing: border-box;
        text-align: center;
        background: #f7f8f9;
        text-align: center;
        border-radius: 6px;

        &:first-child {
          margin-left: 6px;
        }
        &:last-child {
          margin-right: 6px;
        }

        .item__title {
          font-family: "DIN-Bold";
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          color: #222;
        }
        .item__subtitle {
          margin-top: 2px;
          height: 14px;
          font-family: PingFang SC;
          font-size: 10px;
          font-weight: normal;
          line-height: 14px;
          text-align: center;
          color: #666;
          white-space: nowrap; /* 禁止文本换行 */
          overflow: hidden; /* 隐藏溢出的文本 */
          text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
        }
      }

      .active {
        position: relative;
        background: #ff4747;
        border: 1px solid #ff4747;

        .item__title {
          color: #fff;
        }
        .item__subtitle {
          color: #fff;
        }

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: -6px;
          z-index: 2;
          transform: translateX(-50%);
          display: inline-block;
          width: 14px;
          height: 6px;
          background: url("https://akim-oss.aikucun.com/mshop/11d4129a66925b18e7163b4c95c6c32dbe77df99_1701241538460_70.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .calendar {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 12px;
      width: 50px;
      height: inherit;
      background: #fff;

      .icon__calendar {
        width: 24px;
        height: 24px;
      }
      .text {
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        text-align: center;
        color: #222;
      }
      .icon__arrow {
        width: 12px;
        height: 12px;
      }
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .all-panel-popup {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);

    .all-panel {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
      width: 100vw;
      max-height: 70vh;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      background: #f1f2f5;
      box-sizing: border-box;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      .panel__item {
        flex-shrink: 0;
        margin: 0 8px 8px 0;
        padding: 8px 12px 10px 12px;
        width: 112px;
        height: 88px;
        background: #fff;
        border-radius: 6px;
        box-sizing: border-box;

        &:nth-child(3n + 3) {
          margin-right: 0;
        }

        .item__header {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #222;

          .header__left {
            font-family: "DIN-Bold";
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
          }
          .header__right {
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
          }
        }

        .item__brand {
          margin-bottom: 6px;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #666;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .active {
        background: #ff4747;

        .item__header {
          color: #fff;
        }

        .item__brand {
          color: #fff;
        }
      }

      .active {
      }
    }
  }

  .text-ellipsis1 {
    white-space: nowrap; /* 禁止文本换行 */
    overflow: hidden; /* 隐藏溢出的文本 */
    text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
  }
}
