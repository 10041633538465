@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.component-container {
  margin: 0 12px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.info {
  animation: blink 1s linear infinite alternate;
  margin-top: 20px;
  .bottom {
    width: 375px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #a5a5a5;
    font-size: 12px;
    height: 14px;
  }
  .upImg {
    width: 14px;
    height: 14px;
    margin-left: 2px;
  }
}

.info2 {
  margin-top: 20px;
  .bottom {
    width: 375px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #a5a5a5;
    font-size: 12px;
    height: 14px;
  }
  .upImg {
    width: 14px;
    height: 14px;
    margin-left: 2px;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loadingMore {
  width: 25px;
  animation: loading 1s linear infinite;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
