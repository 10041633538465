@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.popUpTitle {
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  box-shadow: inset 0px -0.5px 0px 0px rgba(34, 34, 34, 0.08);
}
.popUpItem {
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #222222;
  &.active {
    color: #ff4747;
  }
}
.popUpBottomBtn {
  // padding-bottom: 34px;
  border-top: 8px solid #f1f2f5;
}
.popUpWrap {
  z-index: 10001;
  border-radius: 12px 12px 0 0;
}
