@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.tabbar_content {
  z-index: 10001 !important;
  position: fixed;
  bottom: -3px !important;
  left: 0;
  max-width: 500px;
  margin: 0 auto;
  right: 0;
  width: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  box-sizing: content-box;
  background-color: #fff;
  height: 50px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  border-top: 1px solid rgba(34, 34, 34, 0.08);
  .tab_bar_item {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    &.cart {
      z-index: 100;
      background-color: #ffffff;
      border-radius: 6px;
    }
    .icon {
      width: 22px;
      height: 22px;
    }
    .text {
      font-size: 10px;
      font-weight: 500;
      margin-top: 4px;
    }
    .numWrap {
      position: absolute;
      margin: -32px 0 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      min-width: 18px;
      box-sizing: border-box;
      padding: 0 5px;
      background: #ff4747;
      border-radius: 10px;
      color: #ffffff;
    }
  }
  .cart_guide {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    .wrap {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      right: 78px;
      bottom: 50px;
      bottom: calc(50px + constant(safe-area-inset-bottom));
      bottom: calc(50px + env(safe-area-inset-bottom));
      .guide_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 30px;
        margin-bottom: 10px;
        border: 0.5px solid #ffffff;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        color: #ffffff;
        background-color: rgba(255, 255, 255, 0.1);
        font-size: 14px;
      }
      .guide_content {
        width: 197px;
        height: 90px;
        background: url(https://akim-oss.aikucun.com/fea7f657f56a2a448da7d4b535ee5e279caf3d9a_1717743613736_68.png)
          center center no-repeat;
        background-size: 197px auto;
      }
      .guide_line {
        width: 10px;
        height: 51px;
        margin: 5px 29px;
        background: url(https://akim-oss.aikucun.com/43814346e21444aaf4f70841bf7ed5ae93f55a9d_1717744258987_77.png)
          center center no-repeat;
        background-size: 10px auto;
      }
    }
  }
  .tabBar_mask {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 99;
    width: 0;
    height: 0;
    background: rgba(0, 0, 0, 0.7);
  }
}
