@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
body {
  background-color: #ffffff;
}
.PromoteDetailContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  .NavContainer {
    padding: 6px 12px;
    margin-left: -6px;
    position: sticky;
    top: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    .text {
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      /* 文字色/#666666_次要文字色 */
      color: #666666;
      margin-left: 31px;
    }
    .NavContentBox {
      background: rgba(255, 255, 255, 0.7);
      border-radius: 20px;
      border: 0.5px solid #e5e5e5;
      display: flex;
      position: relative;
      z-index: 10;
      width: fit-content;
      .ImageBox {
        width: 44px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        .NavImage {
          width: 20px;
          height: 20px;
        }
        &:first-child::after {
          content: "";
          width: 1px;
          height: 18px;
          background-color: rgba(0, 0, 0, 0.2);
          position: absolute;
          right: 50%;
        }
      }
    }
    .NavCart {
      margin-left: auto;
      width: 24px;
      height: 24px;
      position: relative;
      .NavCartImage {
        width: 100%;
        height: 100%;
      }
      .NavCartBadge {
        position: absolute;
        top: -5px;
        right: -8px;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        background-color: #ff302d;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  .Main {
    padding: 0 12px;
    .divide {
      width: 375px;
      height: 10px;
      background: #f1f2f5;
      position: relative;
      margin-left: -12px;
    }
    .MaterialContainer {
      padding-top: 16px;
      &:first-child {
        padding-top: 12px;
      }
      .TotalInfoContainer {
        margin-top: 12px;
        padding-bottom: 16px;
        // border-bottom: rgba(34, 34, 34, 0.08) solid 0.5px;
        .productContainer {
          display: flex;
          overflow-x: scroll;
          margin-top: 12px;
          height: 96px;
          width: 353px;
          position: relative;
          margin-bottom: 12px;
          .more {
            position: absolute;
            right: 0;
            width: 60px;
            height: 88px;
            border-radius: 10px;
            background: #f1f2f5;
            z-index: 1;
          }
          .font {
            position: absolute;
            right: 0;
            z-index: 3;
            .img {
              width: 12px;
              height: 12px;
              position: absolute;
              right: 4px;
              top: 42px;
            }
            .text {
              font-family: "苹方-简";
              font-size: 12px;
              font-weight: normal;
              line-height: 16px;
              letter-spacing: 0px;
              color: #666666;
              width: 12px;
              height: 64px;
              position: absolute;
              right: 18px;
              top: 15px;
            }
          }
          .productBox {
            border: 1px solid rgba(34, 34, 34, 0.08);
            border-radius: 10px;
            margin-right: 8px;
            width: fit-content;
            height: fit-content;
            position: relative;
            z-index: 2;
            width: 351px;
            .showMoreCardImage {
              width: 90px;
              height: 90px;
              vertical-align: bottom;
            }
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}
