@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  height: 40px;
  color: @themeColor;
  background-color: #f6d1d0;
  font-size: 13px;
  align-items: center;
  padding-left: 12px;
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
  .count {
    margin: 0 2px;
  }

  .arrow {
    margin-right: auto;
  }

  .close {
    width: 14px;
    height: 14px;
    padding: 13px;
  }
}
