@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.activity_incentive_popup {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  background: #fff;
  background-image: url(https://xiangdian-oss.aikucun.com/app_x_ios/819499816537842faf406d5b9794ae1f0c8d5905_1729649611354_70.png);
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 12px 12px 0 0;
  overflow: hidden;

  .header {
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 56px;

    .header__title {
      text-align: center;
      font-family: PingFang SC;
      font-size: 18px;
      font-weight: 500;
      color: #8C4206;
    }

    .header__icon {
      position: absolute;
      top: 20px;
      right: 16px;
      width: 16px;
      height: 16px;
    }
  }


  .scroll_view {
    padding: 0 16px 16px 16px;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100vw;
    max-height: 70vh;
    box-sizing: border-box;
    overflow-y: auto;

    .item {
      margin-bottom: 16px;
      border-bottom: 1px solid #FFD7B5;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}