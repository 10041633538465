@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.popup__box {
  margin-bottom: constant(76px + safe-area-inset-bottom);
  display: flex;
  width: 100vw;
  flex-direction: column;
  overflow: hidden;

  .box__header {
    display: flex;
    position: relative;
    padding: 16px 0;
    justify-content: center;

    .header__title {
      text-align: center;
      font-family: PingFang SC;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: #222;
    }

    .header__icon {
      position: absolute;
      top: 20px;
      right: 16px;
      width: 16px;
      height: 16px;
    }
  }

  .list {
    display: flex;
    overflow: scroll;
    width: 100vw;
    height: 70vh;
    background: #f1f2f5;
    box-sizing: border-box;
    overflow: hidden;

    .page__body {
      padding: 10px;
      width: 100vw;
      overflow: hidden;
      overflow: scroll;

      .list__header {
        display: flex;
        padding-bottom: 6px;
        padding-top: 6px;

        .header__image {
          display: flex;
          width: 8px;
          height: 20px;
        }

        .header__title__0 {
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          font-size: 12px;
          padding-left: 0px;
        }

        .header__title {
          display: flex;
          font-family: "苹方-简";
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          text-align: left;
          color: #222;
          padding-left: 8px;
        }
      }

      .footer {
        height: calc(56px + env(safe-area-inset-bottom));
      }

      .empty {
        position: absolute;
        top: 112px;
        left: 0;
        right: 0;
        font-family: "苹方-简";
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0px;
        color: #666666;

        .emptyIcon {
          width: 184px;
          height: 184px;
        }
      }
    }
  }
}
