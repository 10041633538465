@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.goods-recommond {
  .goods-recommond-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 12px;
  }

  .flag {
    width: 16px;
    height: 12px;
  }

  .text {
    height: 21px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 18px;
    margin: 0 6px;
  }

  .product-list-wrap {
    display: grid;
    /* 启用网格布局 */
    grid-template-columns: repeat(3, 112px);
    /* 每行 3 列，每列宽度 96px */
    row-gap: 12px;
    column-gap: 10px;
    justify-content: space-between;
    /* 网格内容均匀分布，两侧靠拢 */
  }
}
