@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  display: flex;
  flex-flow: column nowrap;
  width: 102px;
  font-size: 14px;
  margin-left: 9px;
  &:first-child {
    margin-left: 12px;
  }
  .pic {
    width: 102px;
    height: 102px;
    object-fit: cover;
    border-radius: 4px;
  }
  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 4px;
    line-height: 20px;
    color: #222222;
  }
  .priceWrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #a5a5a5;
    .price {
      padding-right: 2px;
      font-weight: bold;
      font-size: 16px;
      font-family: DIN-Medium;
      color: #ff4747;
      &::before {
        content: "¥";
        font-weight: normal;
        font-size: 12px;
      }
    }
    .tagPrice {
      color: #a5a5a5;
      font-size: 12px;
      text-decoration: line-through;
    }
  }
  .longLabel {
    width: 100%;
  }
}
