@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.navigation_horizontal {
  width: 100%;
  position: sticky;
  display: flex;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  // top: 44px;
  top: 0;
  z-index: 100;
  transform: translate();
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  .normal_img {
    height: 25px;
    width: 40px;
  }
  .active_img {
    height: 25px;
    width: 40px;
    transform: scale(1.3);
  }
  .nav_text {
    position: relative;
    z-index: 1;
  }
  .active_tab {
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    position: relative;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .normal_tab {
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    position: relative;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav_content {
    width: calc(100% - 18px);
    display: flex;
    justify-content: center;
    top: 25px;
    position: absolute;
    .nav_dot {
      width: 70%;
      height: 10px;
      border-radius: 5px;
    }
  }
}
.navigation_horizontal::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.navigation_vertical {
  display: flex;
  // margin-top: 10px;
  .sider {
    min-height: 0;
    width: 58px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-sizing: border-box;
    .dot {
      width: 3px;
      height: 100%;
      flex-shrink: 0;
      position: absolute;
      z-index: 10;
      left: 0;
    }
    .title {
      position: relative;
      z-index: 10;
      font-size: 12px;
      word-break: break-all;
      width: 100%;
      text-align: center;
    }
    .nav_content {
      .top {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        .top_border {
          width: 100%;
          height: 100%;
          border-top-left-radius: 100%;
          z-index: 9;
          position: relative;
        }
      }
      .bottom {
        width: 16px;
        height: 16px;
        position: absolute;
        bottom: 0px;
        left: 0;
        box-sizing: border-box;
        .bottom_border {
          width: 100%;
          height: 100%;
          border-bottom-left-radius: 100%;
          z-index: 9;
          position: relative;
        }
      }
    }
    .space_content {
      flex: 1;
      border-bottom-right-radius: 6px;
    }
  }
  .container {
    flex: 1;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    box-sizing: border-box;
  }
  .content {
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    width: 100%;
    counter-reset: items;
  }
  .active {
    color: red;
  }
}
.nullStatus {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .noSecKill {
    width: 183px;
    height: 183px;
    .text {
      font-size: 14px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}
.loadingMore {
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
  .adm-image-tip {
    display: none;
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
