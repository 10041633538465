@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.empty-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  color: #666666;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  .image {
    width: 184px;
    height: 184px;
  }
  .back-to-index {
    width: 82px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid rgba(34, 34, 34, 0.16);
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #222222;
    box-sizing: border-box;
    margin-top: 16px;
  }
}
