@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.swiper_pro {
  display: flex;
  .swiper-wrapper {
    align-items: center;
  }
  .swiper_pro_item {
    width: 85px !important;
    height: 92px;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 18px;
    .item_img {
      width: 100%;
      height: 72px;
      border-radius: 18px;
    }
    .price_real {
      color: #ffff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      box-sizing: border-box;

      .symbol {
        height: 23px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 5px;
      }

      .decimal {
        height: 23px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 7px;
      }

      .price_big {
        padding-left: 2px;
        padding-right: 1px;
        height: 23px;
        font-size: 14px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 5px;
      }
    }
  }
  .swiper-slide-active {
    width: 100px !important;
    height: 107px;
    .item_img {
      width: 100%;
      height: 83px;
      border-radius: 18px;
    }
    .price_real {
      color: #ffff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 23px;
      box-sizing: border-box;
      .symbol {
        height: 23px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 5px;
      }
      .price_big {
        padding-left: 2px;
        padding-right: 1px;
        height: 23px;
        font-size: 14px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 5px;
      }
    }
  }
}
.more {
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    width: 73px;
    height: 20px;
    .text {
      font-size: 12px;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #a5a5a5;
      padding-right: 3px;
    }
  }
}
