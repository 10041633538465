@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  position: relative;
  min-height: 100vh;
  padding-bottom: 70px;
  // padding-top: 20px;
  padding-top: 50px;
  // background: #ffebcb
  //   url(https://akim-oss.aikucun.com/mshop/77de68daecd823babbb58edb1c8e14d7106e83bb_1728900334396_5.png)
  //   left top no-repeat;
  background-color: #ffebcb;
  background-image: url(https://akim-oss.aikucun.com/mshop/77de68daecd823babbb58edb1c8e14d7106e83bb_1728900334396_5.png);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100vw auto;

  .topTitle {
    position: absolute;
    top: 36px;
    display: flex;
    width: 100vw;
    justify-content: center;
    color: #ffe5bf;
    font-weight: 500;
    font-size: 10px;
  }

  .topFixedLabel {
    position: absolute;
    top: 56px;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 500;
    height: 26px;
    padding: 0 8px 0 4px;
    border-radius: 0px 24px 24px 0px;
    background: linear-gradient(249deg, #ffbb89 8%, #ff7f5b 100%);

    &::after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-left: 2px;
      background: url(https://akim-oss.aikucun.com/8ee51caaa2c2f4ee2e5b4b7ef5a89db7df1068d7_1724554350390_29.png) center center no-repeat;
      background-size: 10px;
    }
  }

  .regularLabel {
    position: absolute;
    top: 6px;
    right: 0;
    width: 34px;
    height: 40px;
    background: url(https://akim-oss.aikucun.com/d54ad009d179ae346683cfc3603979bc99339ef7_1723626190392_81.png) center center no-repeat;
    background-size: 34px auto;
  }

  &.isInH5 {
    // padding-top: 44px;
    padding-top: 50px;

    .topFixedLabel {
      top: 56px;
    }
  }

  .count {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #FFE6BF;
    margin-bottom: 8px;
  }

  .productSwiperWrap {
    // height: 160px;
    // margin-bottom: -56px;
    height: 218px;
  }

  .signListWrap {
    position: relative;
    z-index: 1;
    // margin-top: -95px;
    margin-top: 8px;
  }

  .footer {
    position: fixed;
    z-index: 100;
    bottom: 0;
    width: 100%;
    padding: 6px 12px;
    box-sizing: border-box;
    background-color: #ffffff;
    padding-bottom: calc(6px + constant(safe-area-inset-bottom));
    padding-bottom: calc(6px + env(safe-area-inset-bottom));

    .longBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      border-radius: 6px;
      background: #ffdab0;
      color: #222222;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.noActivity {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  padding-top: 160px;
  box-sizing: border-box;
  font-size: 14px;
  color: #666666;

  .noActivityPic {
    width: 184px;
    height: 184px;
  }
}