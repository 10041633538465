@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN-Bold";
  src: url("../../../../assets//fonts/DIN-Bold.otf"); /* 注意路径 */
}
.achievement {
  font-family: PingFang SC;
  .achievementWrap {
    padding: 12px;
    border-radius: 10px;
    background: #ffffff;
    margin-bottom: 8px;
    .status_tab {
      display: flex;
      justify-content: space-around;
      height: 40px;
      align-items: center;
      .normal {
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0px;
        color: #666666;
      }
      .active {
        font-weight: 500;
        color: #222222;
        position: relative;
        transition: opacity 0.5s ease-in-out;
        &::after {
          content: "";
          position: absolute;
          width: 32px;
          height: 2px;
          background: #ff4747;
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .shopkeeper {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #666666;
      height: 32px;
      width: 100%;
      background: #f7f8f9;
      border-radius: 4px;
      margin-top: 12px;
      .name {
        margin-left: 6px;
      }
      .shopkeeper_number {
        padding-left: 8px;
        max-width: 118px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        color: #222222;
      }
      .contribute {
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-right: 6px;
        color: #222222;
        img {
          width: 12px;
          height: 12px;
          padding-left: 2px;
        }
      }
    }
    .contentWrap {
      width: 100%;
      border-top: 0.5px solid rgba(34, 34, 34, 0.08);
      border-bottom: 0.5px solid rgba(34, 34, 34, 0.08);
      .contentWrap1,
      .contentWrap2 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 13px 0px 12px 4px;
      }
      .saledata {
        display: flex;
        flex-direction: column;
        width: 30%;
        &:nth-child(2) {
          padding-left: 36px;
        }
        &:nth-child(3) {
          padding-left: 36px;
        }
        .name {
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;
          color: #a5a5a5;
          padding-bottom: 2px;
        }
        .number {
          display: flex;
          white-space: nowrap;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          color: #222222;
          align-items: baseline;
          font-family: "DIN-Bold";
          .decimal {
            font-size: 12px;
          }
        }
      }
    }
    .update_time {
      font-size: 10px;
      font-weight: normal;
      line-height: 14px;
      letter-spacing: 0px;
      color: #cccccc;
      padding-top: 10px;
    }
  }
}

.datePicker {
  a {
    color: #ff4747;
  }
  :global(.adm-picker-view-mask-top) {
    mask: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.8) 50%, #000000 100%);
    -webkit-mask-box-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      #000000 100%
    );
  }
  :global(.adm-picker-view-mask-bottom) {
    mask: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.8) 50%, #000000 100%);
    -webkit-mask-box-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      #000000 100%
    );
  }
}
:global(.adm-picker-popup) {
  :global(.adm-mask) {
    z-index: 1000;
  }
}
