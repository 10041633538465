body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100.1vh; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
* {
  margin: 0;
  padding: 0;
}

/* 自定义对话框样式 */
.custom-dialog-confirm .adm-dialog-button-cancel {
  color: #666666 !important;
}

/* 添加更多选择器以确保覆盖所有可能的情况 */
.adm-dialog-button-cancel {
  color: #666666 !important;
}

.adm-dialog-button.adm-dialog-button-cancel {
  color: #666666 !important;
}

.adm-dialog-footer .adm-dialog-button-cancel {
  color: #666666 !important;
}

/* 使用更通用的选择器 */
.adm-dialog .adm-dialog-footer button:first-child {
  color: #666666 !important;
}

/* 自定义按钮样式 */
.custom-cancel-button {
  color: #666666 !important;
  font-weight: normal !important;
}

.custom-confirm-button {
  color: #FF4D4F !important;
  font-weight: normal !important;
}
