@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 200;
  width: 80px;
  height: 32px;
  border-radius: 20px;
  opacity: 1;
  background: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border: 0.5px solid #e5e5e5;
  display: flex;
  align-items: center;

  .left_back {
    width: 20px;
    height: 20px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .line {
    width: 0.5px;
    height: 18px;
    background: rgba(255, 255, 255, 0.3);
  }

  .left_home {
    width: 20px;
    height: 20px;
    padding-left: 8px;
  }

  .text {
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #ffffff;
    padding-left: 4px;
  }
}

.wrap_relative {
  position: relative;
  flex-shrink: 0;
  top: 0;
  left: 0;
}