@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.modeSelectorContainer {
  position: relative;

  .options {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    font-size: 14px;
    color: #666666;
    // border-bottom: 1px solid rgba(34, 34, 34, 0.08);
    padding: 0 104px;
    background-color: #ffffff;
    border-top-left-radius: @bigBorderRadius;
    border-top-right-radius: @bigBorderRadius;

    & .active {
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
  }

  .modeSelectorLine {
    position: absolute;
    bottom: 2.5px;
    left: 120px;
    width: 32px;
    height: 2px;
    background: @themeColor;
    transition: left 0.3s ease-in-out;

    &.right {
      left: 224px;
    }
  }
}
