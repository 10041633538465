@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.coupon_wrap {
  background: transparent;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .coupon {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    flex: none !important;

    .coupon_sunshine {
      width: 100%;
      height: 429px;
      background: url("https://akim-oss.aikucun.com/mshop/00cf3dafece65d3401f690689bd2fd8f234d5e80_1733897283293_23.png")
        no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: -90px;
      z-index: -1;
    }

    .coupon_top {
      width: 351px;
      height: 162px;
      background: url("https://akim-oss.aikucun.com/mshop/9eb1eac26635c0748ff3430cd09d3cfeb98df5fa_1733897789607_67.png")
        no-repeat;
      background-size: 100% 100%;
      margin-bottom: -1px;

      .total_count {
        position: relative;
        font-size: 38px;
        color: #ff301d;
        padding-top: 48px;
        text-align: center;
        font-weight: bold;

        &::before {
          width: 43px;
          height: 21px;
          background: url("https://akim-oss.aikucun.com/mshop/bc2363a0924a13dec53fca17e695a3adec3a89d9_1733898010766_62.png")
            no-repeat;
          background-size: 100% 100%;
          content: "";
          display: inline-block;
        }

        &::after {
          width: 67px;
          height: 20px;
          background: url("https://akim-oss.aikucun.com/mshop/0d69cad8282e8994c7735d51fa0caae16b895590_1733898109446_10.png")
            no-repeat;
          background-size: 100% 100%;
          content: "";
          display: inline-block;
        }
      }
    }

    .coupon_con {
      background: url("https://akim-oss.aikucun.com/mshop/f1e538513eac3d5f7b5dd0b5da383d86322e2548_1733898151276_30.png")
        repeat-y;
      background-size: 100% 1px;
      padding: 0 33px 0 42px;
      width: 351px;
      box-sizing: border-box;
    }

    .coupon_new_person {
      width: 274px;

      .coupon_new_person_title {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 9px;
        background: linear-gradient(123deg, #f0e0bc 0%, #f9dfae 100%, #e8c37f 100%);
        border-radius: 10px 10px 0px 0px;

        .left {
          font-size: 14px;
          font-family:
            PingFangSC-Semibold,
            PingFang SC;
          font-weight: 600;
          color: #df271c;
          line-height: 20px;

          .line {
            width: 1px;
            height: 10px;
            display: inline-block;
            background-color: #ea2c1c;
            margin: 0 5px 0 6px;
            opacity: 0.17;
          }

          .time_wrap {
            font-size: 11px;
            font-family:
              PingFangSC-Regular,
              PingFang SC;
            font-weight: 400;
            color: #8d7a55;
            line-height: 16px;

            .time {
              font-size: 14px;
              font-family: SFCompactDisplay-Semibold, SFCompactDisplay;
              font-weight: 600;
              color: #df271c;
              line-height: 16px;
            }
          }
        }

        .right {
          font-size: 11px;
          font-family:
            PingFangSC-Regular,
            PingFang SC;
          font-weight: 400;
          color: #e9220c;
          line-height: 16px;

          .icon_arrow_right {
            background: url("https://akim-oss.aikucun.com/mshop/905079e3f508b6b34030ca6007b3faed598e5537_1733898182496_79.png")
              no-repeat;
            background-size: 100% 100%;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-left: 3px;
          }
        }
      }

      .coupon_new_person_con {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        background: linear-gradient(143deg, #ff8a59 0%, #f4c46c 100%);
        border-radius: 0 0 10px 10px;
        padding: 7px 0 9px 9px;

        .coupon_new_person_item {
          width: 80px;
          height: 80px;
          background: #fae6e8;
          border-radius: 10px;
          text-align: center;
          margin-right: 8px;
          position: relative;
          background: url("https://akim-oss.aikucun.com/mshop/2bd0cfae83336f27c447f4e682b70f66105cd8f7_1733898232658_44.png")
            no-repeat;
          background-size: 100% 100%;

          .title_label {
            font-size: 10px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ffffff;
            position: absolute;
            line-height: 14px;
            left: 0;
            right: 0;
            top: 0;
            text-align: center;
          }

          .coupon_count {
            font-size: 30px;
            font-family: SFProDisplay-Semibold, SFProDisplay;
            font-weight: 600;
            color: #ff302d;
            line-height: 30px;
            margin-top: 18px;

            .unit {
              font-size: 16px;
              font-family:
                PingFangSC-Medium,
                PingFang SC;
              font-weight: 500;
              color: #ff302d;
              line-height: 16px;
              margin-right: 3px;
            }
          }

          .coupon_limit {
            font-size: 12px;
            font-family:
              PingFangSC-Regular,
              PingFang SC;
            font-weight: 400;
            color: #ff302d;
            line-height: 17px;
            position: absolute;
            bottom: 5px;
            left: 0;
            right: 0;
          }
        }
      }
    }

    .shop_coupon_title {
      font-size: 14px;
      font-family:
        PingFangSC-Semibold,
        PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 8px 0;

      &::before {
        background: url("https://akim-oss.aikucun.com/mshop/a0bf047763f0eed991fb09d8872b245ddc3d9c21_1733898993244_28.png")
          no-repeat;
        background-size: 100% 100%;
        width: 12px;
        height: 1px;
        content: " ";
        display: block;
        margin-right: 5px;
      }

      &::after {
        background: url("https://akim-oss.aikucun.com/mshop/fdea429c5aeb7a9299ba6c15d61959ce1ae30ea8_1733899050417_74.png")
          no-repeat;
        background-size: 100% 100%;
        width: 12px;
        height: 1px;
        content: " ";
        display: block;
        margin-left: 5px;
      }
    }

    .coupon_center {
      max-height: 210px;
      overflow-y: auto;
      border-radius: 6px;

      &.has_new_person {
        max-height: 123px;
      }

      .coupon_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 71px;
        background: url("https://akim-oss.aikucun.com/mshop/8e5f5920c637bc9031a6be913020c421676b0733_1733899093031_25.png")
          no-repeat;
        background-size: 100% 100%;
        margin-bottom: 7px;

        .left {
          color: #ff302d;
          font-size: 30px;
          font-family: SFProDisplay-Semibold, SFProDisplay;
          font-weight: 600;
          width: 80px;
          text-align: center;

          .unit {
            font-size: 16px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #ff302d;
            margin-right: 3px;
          }
        }

        .right {
          width: 154px;
          margin-right: 26px;

          .coupon_name {
            font-size: 15px;
            font-family:
              PingFangSC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #222222;
            line-height: 21px;
            margin-bottom: 2px;
          }

          .coupon_time {
            font-size: 12px;
            font-family:
              PingFangSC-Regular,
              PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
          }

          .coupon_range {
            font-size: 12px;
            font-family:
              PingFangSC-Regular,
              PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }

    .coupon_btm {
      width: 351px;
      background: url("https://akim-oss.aikucun.com/mshop/c5c9c4f01fd64fdb3e9a3fef95cace9b5e899043_1733899120805_64.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 10px 0 10px 0;
      color: #fff;
      text-align: center;
      margin-top: -1px;

      .btn {
        width: 274px;
        height: 48px;
        background: url("https://akim-oss.aikucun.com/mshop/68a142128b20801b3cce6d5c237243276885ecc0_1733899153630_24.png")
          no-repeat;
        background-size: 100% 100%;
        margin-left: 42px;
        margin-bottom: 4px;

        &.to_use {
          background: url("https://akim-oss.aikucun.com/mshop/b5983a28a9369cd0bcaed2725f886977cd0db0ed_1733899175671_30.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }

      .tips {
        font-size: 12px;
        line-height: 17px;
      }
    }

    .close {
      position: absolute;
      top: -30px;
      right: 30px;
      width: 30px;
      height: 30px;
      background: url("https://akim-oss.aikucun.com/mshop/da38860cb875cb64092e402d80d9fe29c4865b18_1733899209063_2.png")
        no-repeat;
      background-size: 29px 29px;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .switch_radio {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 26px;
      margin: 15px auto 0;
      color: #ffffff;
    }

    .switch_radio::before {
      content: "";
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      margin-right: 5px;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 16px;
      font-size: 14px;
    }

    .switch_radio.checked::before {
      background: url("https://akim-oss.aikucun.com/115a6cff1e7b35162c48c8ae44084510ad4016f2_1688981161598_31.png")
        center center no-repeat;
      background-size: 20px;
      border: none;
    }
  }
}
