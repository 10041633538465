@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.empty {
  width: 217px;
  height: 204px;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  margin-top: 88px;
}
.change-living-room-skeleton {
  padding: 0 10px;
  .skeleton {
    --width: 100%;
    --height: 50vh;
    --border-radius: 10px;
  }
}
.singular-intro {
  margin: 0 10px;
}
.ending-header {
  display: flex;
  height: 18px;
  .title {
    width: 76px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .time {
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    color: #a5a5a5;
    background-color: rgba(34, 34, 34, 0.04);
    padding: 0 4px;
    border: 1px solid rgba(34, 34, 34, 0.08);
    border-left: unset;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.ending-desc {
  font-family: "苹方-简";
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin: 10px 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.empty__ending {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 52px 0;
  .empty__img {
    width: 148px;
    height: 148px;
  }
  .empty__text {
    font-family: "苹方-简";
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
}
