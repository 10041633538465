@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .lotteryBtn {
    padding: 3px 5px 3px 5px;
    border-radius: 72px;
    background: url(https://akim-oss.aikucun.com/2e278916b5a3131a5bc87d9498f6411611caed42_1739936664264_78.png) no-repeat;
    background-size: 100% 100%;

    .innerLayer {
      width: 138px;
      height: 40px;
      border-radius: 53px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lotteryText {
        font-size: 16px;
        font-weight: 500;
        color: #FFE6BF;
        line-height: 1.2;
        margin-bottom: 4px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }

      .lotteryTextNotStart {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        line-height: 1.2;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }

      .lotteryCountInfo {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        color: #fff;
        line-height: 1.2;
        text-align: center;
      }
    }
  }

  .activityEndedButton {
    width: 148px;
    height: 46px;
    border-radius: 72px;
    background: linear-gradient(180deg, #D9D9D9 0%, #BBBBBB 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border: 2px solid #BBBBBB;

  }

  .noChanceImageContainer {
    width: 148px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;

    .noChanceImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .prompt {
    margin-top: 8px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .fontColor {
      color: red;
    }
  }
}

.highLight {
  color: #FFE6BF
}