@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
@font-face {
  font-family: "DIN-Bold";
  src: url("../../assets/fonts/DIN-Bold.otf"); /* 注意路径 */
}
.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden scroll;
  background-color: #f1f2f5;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  .head {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 10;
    .totalBalance {
      display: flex;
      height: 109px;
      flex-direction: column;
      align-items: center;
      background-color: #f1f2f5;
      .balanceN {
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #666666;
        margin-top: 20px;
      }
      .balancePrice {
        margin-top: 9px;
      }
      .balanceT {
        font-family: DIN-Bold;
        font-size: 36px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #ff302d;
      }
      .balanceTD {
        font-family: DIN-Bold;
        font-size: 20px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #ff302d;
      }
    }
    .dateFilterC {
      height: 60px;
      background-color: #f1f2f5;
      .dateFilter {
        display: flex;
        height: 60px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;

        margin-left: 10px;
        margin-right: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding-left: 15px;
        padding-right: 15px;
        &::after {
          content: "";
          position: absolute;
          left: 10px;
          right: 10px;
          bottom: 0;
          height: 0.5px;
          background-color: #eee;
        }
        .titleL {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0px;
          color: #222222;
        }
        .dateC {
          display: flex;
          flex-direction: row;
          align-items: center;
          border: 0.5px solid #ccc;
          border-radius: 10px;
          padding: 2px 10px;
          .dateR {
            font-family: PingFang SC;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0px;
            color: #222222;
          }
          .downIcon {
            width: 10px;
            height: 6px;
            margin-left: 1px;
          }
        }
      }
    }
  }
  .loading {
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }
  .loadingFixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .body {
    margin-left: 10px;
    margin-right: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white;
    overflow: scroll;
    flex: 1;
    .empty {
      position: absolute;
      top: 160px;
      left: 94px;
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0px;
      color: #666666;

      .empty_icon {
        width: 184px;
        height: 184px;
      }
    }
    .footer {
      padding-top: 16px;
    }
  }
}
