@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 44px;
  padding: 0 12px;
  flex-shrink: 0;
  box-sizing: border-box;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1001;
  transform: translate3d(0, 0, 0);

  .brandArea {
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 32px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    .brandIcon {
      width: 22px;
      height: 22px;

      path {
        fill: var(--svgColor);
      }

      rect {
        stroke: var(--svgColor);
      }
    }
    .brandText {
      padding-left: 3px;
      font-size: 14px;
      color: #222222;
    }
  }

  .inputBox {
    display: flex;
    flex-direction: row;
    height: 32px;
    flex: 1;
    border: 1.5px solid @themeColor;
    border-radius: 8px;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-color: white;

    .pictureSearchIcon {
      margin-left: 8px;
      margin-right: 6px;
      width: 22px;
      height: 22px;
    }

    .sperator {
      width: 1px;
      height: 16px;
      background-color: rgba(34, 34, 34, 0.16);
      margin-right: 6px;
    }

    .darkWords {
      flex: 1;
      position: relative;
      height: 29px;
      overflow: hidden;

      .darkWord {
        height: 29px;
        line-height: 29px;
        font-size: 14px;
        color: #a5a5a5;
      }
    }

    .button {
      width: 48px;
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: @themeColor;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
      margin-right: 2.5px;
    }
  }

  .cartIcon {
    margin-left: 16px;
    width: 24px;
    height: 24px;

    path {
      fill: var(--svgColor);
    }
  }
}
