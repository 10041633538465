@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #f1f2f5;
  overflow-y: scroll;

  .scroll_view {
    flex-grow: 1;
    padding: 12px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #f1f2f5;
    box-sizing: border-box;

    .page__header {
      margin-bottom: 12px;
      padding: 8px 12px;
      background: rgba(255, 71, 71, 0.08);
      border-radius: 4px;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #222;
    }

    .page__body {
      .rank__line {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .line__drop {
          margin: 0 4px;
          width: 4px;
          height: 4px;
          background: #999;
          border-radius: 50%;
        }
      }
    }
  }

  .page__footer {
    flex-shrink: 0;
    padding: 6px 12px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    width: 100vw;
    background: #fff;
    box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;

    .footer__btn {
      margin-bottom: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      background: #ff4747;
      border-radius: 6px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #fff;

      .icon {
        margin-right: 4px;
        width: 20px;
        height: 20px;
      }

      // &::before {
      //   margin-right: 4px;
      //   width: 20px;
      //   height: 20px;
      //   content: "";
      //   background-image: url("https://akim-oss.aikucun.com/mshop/eeaaa563822b7c0c875f3c49e65c48e966db9716_1715076368864_24.png");
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   background-position: center center;
      // }
    }

    .disabled {
      opacity: 0.5;
    }
  }
}
