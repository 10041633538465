@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  height: 100vh;
  padding: 6px 12px;
  box-sizing: border-box;
  background: #f1f2f5;
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group {
  margin-top: 20px;
}
.groupTitle {
  margin-left: 12px;
  color: #666;
  font-size: 12px;
}

.list {
  margin-top: 6px;
  background: #ffffff;
  border-radius: 8px;
}

.popUpTitle {
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  box-shadow: inset 0px -0.5px 0px 0px rgba(34, 34, 34, 0.08);
}
.popUpItem {
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #222222;
  &.active {
    color: #ff4747;
  }
}
.popUpBottomBtn {
  padding-bottom: 34px;
  border-top: 8px solid #f1f2f5;
}
.PopUpWrap {
  z-index: 10001;
  border-radius: 12px 12px 0 0;
}

.mpPopup {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #f1f2f5;
  // height: 487px;

  .titleWrap {
    height: 56px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 18px;
      font-weight: 500;
      color: #222222;
    }

    .closeWrap {
      position: absolute;
      height: 100%;
      width: 48px;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .close {
        position: absolute;
        top: 20px;
        right: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .content {
    padding: 5px 12px 16px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .item {
      border-radius: 10px;
      background: #ffffff;
      padding: 10px 8px 8px 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 14px;
        font-weight: 500;
        color: #222222;
      }

      .img {
        margin-top: 10px;
        width: 155px;
        height: 272px;
      }
    }
  }

  .btnContainer {
    padding: 6px 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-sizing: border-box;

    .btn {
      width: 100%;
      height: 44px;
      border-radius: 6px;
      background: #ff4747;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: "PingFang SC";
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
