@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  width: 12px;
  height: 12px;
  position: relative;
  .single_default {
    border-top: 3.5px solid #c5c5c5;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .single_down {
    border-top: 3.5px solid #ff4747;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .double_default {
    position: absolute;
    width: 100%;
    height: 100%;
    &::after {
      content: "";
      border-bottom: 3.5px solid #c5c5c5;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      position: absolute;
      top: 1px;
      left: 50%;
      transform: translateX(-50%);
    }
    &::before {
      content: "";
      border-top: 3.5px solid #c5c5c5;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .double_down {
    position: absolute;
    width: 100%;
    height: 100%;
    &::after {
      content: "";
      border-bottom: 3.5px solid #c5c5c5;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      position: absolute;
      top: 1px;
      left: 50%;
      transform: translateX(-50%);
    }
    &::before {
      content: "";
      border-top: 3.5px solid #ff4747;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .double_up {
    position: absolute;
    width: 100%;
    height: 100%;
    &::after {
      content: "";
      border-bottom: 3.5px solid #ff4747;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      position: absolute;
      top: 1px;
      left: 50%;
      transform: translateX(-50%);
    }
    &::before {
      content: "";
      border-top: 3.5px solid #c5c5c5;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
