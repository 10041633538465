@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.shopContainer {
  position: relative;
  padding: 0 12px 24px;

  .followWeChat {
    margin: 8px 0px 8px;
  }
}

.jifenBoardWrap {
  margin-bottom: 16px;
}
