@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.list {
  height: auto;
  // padding-left: 12px;
  // padding-right: 12px;
  padding-top: 4px;
  // height: 100%;
  // width: 100vw;

  .ic_back {
    width: 18px;
    height: 18px;
  }

  .pCard {
    margin-top: 8px;
    margin-left: 12px;
    margin-right: 12px;
  }

}