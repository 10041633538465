@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  border-top: 0.5px solid rgba(34, 34, 34, 0.08);
  border-radius: 10px 10px 0 0;
  .tab,
  .filterTab {
    display: flex;
    flex: 1;
    height: 44px;
    justify-content: center;
    align-items: center;
    color: #222222;
    font-size: 14px;
    &.active {
      color: @themeColor;
      font-weight: 500;
    }
    &.filterActive {
      color: @themeColor;
      font-weight: 500;
      path {
        fill: @themeColor;
      }
    }
  }
  .filterTab {
    position: relative;
    margin-left: 10px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 15px;
      width: 1px;
      height: 14px;
      background: rgba(34, 34, 34, 0.16);
    }
  }
}

.popContent {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 30px;
  padding-bottom: calc(56px + constant(safe-area-inset-bottom));
  padding-bottom: calc(56px + env(safe-area-inset-bottom));
  .header {
    display: flex;
    height: 56px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.0629);
  }
  .group {
    padding: 12px;
    padding-bottom: 0;
    .title {
      font-size: 14px;
      font-weight: 500;
    }
    .list {
      padding: 8px 0;
    }
    .item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 80px;
      margin-right: 10px;
      padding: 6px 0;
      background: #f7f8f9;
      border-radius: 4px;
      color: #666666;
      &.active {
        background: #fff0ef;
        color: @themeColor;
      }
    }
  }
  .footer {
    margin-top: 12px;
    padding: 6px 8px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.0629);
    .btns {
      display: flex;
      justify-content: space-between;
      border: 1px solid @themeColor;
      border-radius: 6px;
      background-color: rgba(255, 71, 71, 0.08);
      font-weight: 500;
      font-size: 14px;
    }
    .btn {
      flex: 1;
      display: inline-flex;
      height: 40px;
      justify-content: center;
      align-items: center;
      color: @themeColor;
    }
    .primaryBtn {
      flex: 1;
      display: inline-flex;
      height: 40px;
      justify-content: center;
      align-items: center;
      background: @themeColor;
      color: #ffffff;
    }
  }
}
