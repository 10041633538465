@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.beianhao {
  // margin-bottom: calc(46px + env(safe-area-inset-bottom));
  padding: 12px 0 8px;
  background-color: #fff;
  a {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
    color: #5a76b0;
  }
  h3 {
    font-size: 12px;
    font-family:
      PingFangSC-Regular,
      PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }
  p {
    font-size: 12px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: #666666;
    line-height: 20px;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
