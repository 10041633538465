@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  // height: calc(100vh - 56px - constant(safe-area-inset-bottom));
  // height: calc(100vh - 56px - env(safe-area-inset-bottom));
  // overflow-y: scroll;
  background-color: #f1f2f5;
  width: 375px;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  padding-bottom: calc(56px + constant(safe-area-inset-bottom));
  padding-bottom: calc(56px + env(safe-area-inset-bottom));

  &.empty {
    height: calc(100vh - constant(safe-area-inset-bottom));
    height: calc(100vh - env(safe-area-inset-bottom));
  }

  .followWXContainer {
    margin-top: -56px;
  }

  .activitySelectorAndMaterialWrapper {
    margin-top: -8px;
    position: relative;
    padding-top: 8px;

    &::before {
      content: "";
      height: 76px;
      width: 100%;
      border-radius: 10px 10px 0 0;
      background: #ffffff;
      position: absolute;
      top: 0;
    }
  }

  .overviewContainer {
    padding: 0 12px 10px 12px;
    position: relative;
    scroll-margin-top: 115px;
  }

  .bannerContainer {
    margin: 0 12px 8px 12px;
    position: relative;
  }

  .waterfallContainer {
    // min-height: calc(100vh + 250px);
    position: relative;
    scroll-margin-top: 120px;

    &::before {
      content: "";
      position: absolute;
      top: 44px;
      width: 100%;
      height: 182px;
      background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 55%);
    }

    .waterfallWrapper {
      scroll-margin-top: 165px;
    }

    .skelentonContainer {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
    }
  }
}

.section {
  .sticky {
    position: sticky;
    top: 44px;
    z-index: 2;
  }
}

.toTopIcon {
  position: fixed;
  right: 16px;
  bottom: 160px;
  width: 44px;
  height: 44px;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.3s;
  pointer-events: initial;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}
