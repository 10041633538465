@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.shop {
  margin-bottom: 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 6px;

  .shop__left {
    display: flex;

    .shop__logo {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      object-fit: contain;
      border-radius: 6px;
      border: 1px solid rgba(34, 34, 34, 0.08);
    }
    .shop__info {
      padding: 3px 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .shop__name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222;
      }
      .shop__star {
        align-self: baseline;
        padding: 1px 8px;
        display: flex;
        align-items: center;
        background: rgba(255, 71, 71, 0.08);
        border-radius: 15px;
        font-family: PingFang SC;
        font-size: 10px;
        font-weight: normal;
        line-height: 14px;
        color: #222;

        .star__score {
          padding-left: 4px;
        }
      }
    }
  }
  .shop__btn {
    flex-shrink: 0;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    background: #ff4747;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #fff;
  }

  .shop__btn_cancel {
    background: #fff;
    border: 1px solid #ff4747;
    color: #ff4747;
  }
}
