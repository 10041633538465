@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.comp {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;

  .logo {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    padding: 1px 1px;
    background-color: #ffffff;
  }

  .title {
    width: 100%;
    font-family: PingFang SC;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #222222;
    margin-top: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }
}
