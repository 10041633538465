@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  width: 375px;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  background: #f1f2f5;
  padding-bottom: calc(env(safe-area-inset-bottom) + 90px);

  .topStable {
    width: 130px;
    height: 32px;
  }

  .body {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: -44px;

    .swip {
      display: inline-block;
      height: 40px;

      .swipItem {
        height: 30px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        border-radius: 16px;
        opacity: 1;
        background: rgba(255, 255, 255, 0.65);
        /* 毛玻璃/毛玻璃 */
        backdrop-filter: blur(20px);
        padding-left: 5px;
        padding-right: 8px;

        .swipItemIcon {
          width: 20px;
          height: 20px;
          border: 1px solid #fff;
          border-radius: 10px;
        }

        .swipItemName {
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 0px;
          color: #222222;
          margin-left: 6px;
          max-width: 170px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .center {
      margin: 0px 12px;

      .part {
        margin-top: 8px;
      }

      .comb {
        margin-top: 8px;
      }

      .media {
        margin-top: 8px;
      }

      .sku {
        margin-top: 8px;
        background-color: white;
        border-radius: 10px;
        padding: 12px;
      }

      .detailIntroduce {
        padding-top: 15px;
        margin-bottom: 12px;
      }

      .goodsRecommond {
        margin-top: 25px;
        margin-bottom: 12px;
      }

      .banner {
        margin-top: 8px;
      }
    }
  }

  .bottomBtns {
    width: 100vw;
    position: fixed;
    bottom: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: calc(env(safe-area-inset-bottom));
  }
}