@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.content-empty {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    width: 184px;
    height: 184px;
  }
  .text {
    font-size: 14px;
    color: #666666;
  }
  .btn {
    margin-top: 12px;
    padding: 5px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222222;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    border-radius: 4px;
    border: 1px solid #cccccc;
  }
}
