@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
  .header {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #222222;
    padding-top: 20px;
    margin-bottom: 40px;
  }
  .footer {
    margin: 40px;
    display: flex;
    justify-content: center;
    --background-color: #ff4747;
    .footer-btn {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      width: 250px;
      --background-color: #ff4747;
      border: unset;
    }
  }
  .code-text {
    color: #ff4747;
  }
  .code-text-sending {
    color: #5a5a5a;
  }
}
.confirmDialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background: white;
  border-radius: 10px;
  .body {
    padding: 25px 30px 20px;
    font-size: 16px;
    font-family:
      PingFangSC-Regular,
      PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-bottom: 10px;
    .title {
      text-align: center;
      height: 25px;
      line-height: 25px;
      font-size: 18px;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 12px;
    }
    .info {
      p {
        font-size: 16px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-bottom: 12px;
      }
      .store {
        display: flex;
        align-items: center;
        .logo {
          border-radius: 6px;
          background-color: #fff;
          width: 34px;
          height: 34px;
          display: flex;
          align-items: center;
          overflow: hidden;
        }
        img {
          flex-grow: 0;
          flex-shrink: 0;
          width: 100%;
          height: auto;
          border: none;
        }
        .store-name {
          color: #666666;
          margin-left: 8px;
        }
      }
    }
  }
  .btns {
    display: flex;
    border-top: 1px solid #d8d8d8;
    .btn {
      box-sizing: border-box;
      flex: 1;
      width: 50%;
      height: 50px;
      line-height: 51px;
      text-align: center;
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      font-size: 16px;
      border-radius: 0;
      &.cancel {
        color: #222;
      }
      &.ok {
        box-sizing: content-box;
        border-left: 1px solid #d8d8d8;
        color: #ff4747;
      }
    }
  }
}
