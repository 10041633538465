@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.newPersonCon {
  width: 100%;
  box-sizing: border-box;
}
.newPersonWrap {
  width: 355px;
  height: 154px;
  margin: 4px auto;
  padding: 42px 7px 14px 97px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;

  li {
    width: 78px;
    height: 98px;
    padding: 8px 8px 0;
    box-sizing: border-box;
    background: #fff;
    border-radius: 4px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    img {
      width: 62px;
      height: 62px;
      object-fit: cover;
      border-radius: 2px;
    }
    .sellout {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 2;
    }
    .newTag {
      width: 56px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 12px;
      margin: 0 auto;
      background: linear-gradient(90deg, #ff4c19 0%, #f8002f 100%) !important;
      font-size: 10px;
      position: absolute;
      left: 11px;
      top: 62px;
      z-index: 1;
    }
    .price {
      margin-top: 4px;
      text-align: center;
      font-size: 14px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #ff1010;
      line-height: 18px;
      .unit {
        width: 12px;
        height: 12px;
        font-size: 12px;
        font-family:
          PingFangSC-Semibold,
          PingFang SC;
        text-align: center;
        display: inline-block;
        text-align: center;
      }
    }
  }
}
