@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.regularPopWrap {
  height: 80vh;
  .header {
    position: relative;
    display: flex;
    height: 56px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    .closeBtn {
      position: absolute;
      top: 20px;
      right: 16px;
      width: 16px;
      height: 16px;
      background: url(https://akim-oss.aikucun.com/d321d6f7ccf98b51540ec9d933f20898af3bd71e_1723630993821_43.png)
        center center no-repeat;
      background-size: 100% 100%;
    }
  }
  .content {
    height: 68vh;
    overflow-y: scroll;
    padding: 20px;
    padding-top: 10px;
    white-space: pre-line;
    font-size: 14px;
  }
}
