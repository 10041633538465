@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #ba18c9;
  overflow-y: scroll;

  .scroll_view {
    flex-grow: 1;
    padding: 112px 0 100px 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #ba18c9;
    background-image: url("https://akim-oss.aikucun.com/mshop/37c9d7cfa3c5705c25362c209120c3ccb8aef5ff_1731051143543_21.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: border-box;

    .rule__absolute {
      position: absolute;
      top: 55px;
      right: -5px;
      z-index: 10;
      width: 40px;
      height: 20px;
    }

    .page__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .page__body {
      margin: 16px 16px 10px 16px;

      .body__data {
        position: relative;

        .data__list {
          position: relative;
          z-index: 2;
          margin: 0 14px;
          padding: 20px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          background: #fdf5df;
          border-radius: 10px 10px 0 0;

          .list__item {
            .item__value {
              text-align: center;
              font-family: "苹方-简";
              font-weight: 600;
              font-size: 20px;
              color: #333333;

              .unit {
                font-size: 12px;
              }
            }

            .item__label {
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: "苹方-简";
              font-size: 10px;
              font-weight: normal;
              line-height: 12px;
              color: #333333;
              opacity: 0.6;

              .icon {
                margin-left: 2px;
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .data__bg {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;
          width: 100%;
          height: 45px;
          background: #ec45f7;
          border-radius: 10px 10px 0 0;
        }
      }

      .body__content {
        padding: 12px 10px;
        background: #fff;
        border-radius: 0 0 10px 10px;

        .content__tab {
          padding-bottom: 6px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .tab__item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            color: #666;
          }

          .tab__active {
            font-weight: bold;
            color: #222;

            &::after {
              content: "";
              position: absolute;
              bottom: -6px;
              left: 0;
              right: 0;
              margin: auto;
              width: 50%;
              height: 2px;
              border-radius: 2px;
              background: #ff4747;
            }
          }
        }

        .content__activity {
          margin-top: 10px;
          background: #f7f8f9;
          border: 0.5px solid rgba(34, 34, 34, 0.08);
          border-radius: 8px;
          overflow: hidden;

          .activity__pic {
            width: 100%;
            height: 90px;
            object-fit: cover;
          }

          .activity__info {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .info__left {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              h3 {
                margin-bottom: 4px;
                font-family: PingFang SC;
                font-size: 16px;
                font-weight: 500;
                color: #222;
              }

              p {
                font-family: "苹方-简";
                font-size: 12px;
                font-weight: normal;
                color: #666;
              }
            }

            .info__right {
              flex-shrink: 0;

              .right__btn {
                padding: 0 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 26px;
                background: rgba(255, 71, 71, 0.08);
                border: 1px solid rgba(255, 71, 71, 0.5);
                border-radius: 4px;
                font-family: "苹方-简";
                font-size: 12px;
                font-weight: 500;
                color: #ff4747;
              }
            }
          }
        }

        .content__more {
          padding-top: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: normal;
          color: #666;

          .icon__arrow {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .page__strategy {
      width: 100%;
    }
  }

  .page__loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

:global(.dialog__private) {
  :global(.adm-center-popup-wrap) {
    width: 80vw !important;
  }

  :global(.adm-dialog-title) {
    font-family: PingFang SC !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #222 !important;
  }

  :global(.adm-dialog-content) {
    padding: 0 24px 12px 24px !important;
  }

  :global(.adm-auto-center-content) {
    font-family: "苹方-简" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 20px !important;
    color: #666 !important;
  }

  :global(.adm-dialog-button) {
    font-family: PingFang SC !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #ff4747 !important;
  }
}
