@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #fe6e5b;
  overflow: hidden scroll;

  .scroll_view {
    position: relative;
    flex-grow: 1;
    padding: 95px 0 100px 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #fe6e5b;
    background-image: url("https://akim-oss.aikucun.com/mshop/32640ccec6e50122944f88f7d72d8d116e8c2a60_1731051105431_61.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: border-box;

    .rule__absolute {
      position: absolute;
      top: 55px;
      right: -5px;
      z-index: 10;
      width: 40px;
      height: 20px;
    }

    .page__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .page__body {
      margin: 27px 16px 10px 16px;

      .body__data {
        position: relative;

        .data__list {
          position: relative;
          z-index: 2;
          margin: 0 14px;
          padding: 20px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          background: #fdf5df;
          border-radius: 10px 10px 0 0;

          .list__item {
            .item__value {
              text-align: center;
              font-family: "苹方-简";
              font-weight: 600;
              font-size: 20px;
              color: #333333;

              .unit {
                font-size: 12px;
              }
            }

            .item__label {
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: "苹方-简";
              font-size: 10px;
              font-weight: normal;
              line-height: 12px;
              color: #333333;
              opacity: 0.6;

              .icon {
                margin-left: 2px;
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .data__bg {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;
          width: 100%;
          height: 45px;
          background: #d60202;
          border-radius: 10px 10px 0 0;
        }
      }

      .body__content {
        padding: 12px 10px;
        background: #fff;
        border-radius: 0 0 10px 10px;

        .content__more {
          padding-top: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: normal;
          color: #666;

          .icon__arrow {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .page__strategy {
      width: 100%;
    }
  }

  .page__loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    margin: auto;
    width: 30px;
    height: 30px;
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

:global(.dialog__private) {
  :global(.adm-center-popup-wrap) {
    width: 80vw !important;
  }

  :global(.adm-dialog-title) {
    font-family: PingFang SC !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #222 !important;
  }

  :global(.adm-dialog-content) {
    padding: 0 24px 12px 24px !important;
  }

  :global(.adm-auto-center-content) {
    font-family: "苹方-简" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 20px !important;
    color: #666 !important;
  }

  :global(.adm-dialog-button) {
    font-family: PingFang SC !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #ff4747 !important;
  }
}
