@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.commentListContainer {
  padding: 12px;
}

.commentCard {
  margin-bottom: 12px;
  border-radius: 8px;
  background: #fff;
  padding: 12px;
  // 添加底部边框
  border-bottom: 1px solid #f5f5f5;

  &:last-child {
    border-bottom: none; // 最后一个卡片不显示分割线
  }
}

.commentHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

.commentHeaderLeft {
  display: flex;
  align-items: flex-start;
}

.commentHeaderAvatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}

.commentHeaderDesc {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.commentName {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.commentValue {
  display: flex;
  align-items: center;
}

.commentHeaderRight {
  font-size: 12px;
  color: #999;
}

.productShape {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 8px;
}

.shapeItem {
  padding: 2px 8px;
  background: #f5f5f5;
  border-radius: 4px;
  color: #666;
  font-size: 12px;
}

.commentTxt {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
}

.nomoreContainer {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 14px;

  img {
    width: 240px;
    height: 125px;
  }
}

.loadingText,
.noMoreText {
  padding: 12px 0;
  text-align: center;
  color: #999;
  font-size: 12px;
}

.commentListContainer {
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; // 增加 iOS 滚动流畅度
}