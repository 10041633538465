@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.body__product {
  margin-top: 80px;

  .scroll__watch-sticky-line {
    height: 1px;
    background: transparent;
  }

  .product__sticky {
    position: sticky;
    top: 44px;
    z-index: 10;

    .sticky__body {
      padding-top: 8px;
      background: #fff;
    }
  }
  .product__list {
    background: linear-gradient(180deg, #ffffff 0%, #f1f2f5 100%);
  }
}
