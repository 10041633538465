@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.material_card {
  width: 172px;
  position: relative;
}
.material_pic {
  width: 172px;
  border-radius: 8px;
  animation: fade-in 0.2s ease-in;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.video_icon {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 18px;
  height: 18px;
}

.content {
  display: block;
  position: relative;
  margin-top: 6px;
}
.label {
  width: 28px;
  height: 16px;
  line-height: 16px;
  margin-right: 4px;
  margin-top: 2px;
  position: absolute;
  top: 0;
  left: 0;
}
.title {
  vertical-align: middle;
  font-family: PingFang SC;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* 控制显示的行数 */
  -webkit-box-orient: vertical;
  max-height: 60px; /* 控制最大高度，对应两行文字的高度 */
  white-space: normal;
  display: -webkit-box;
}
.sub_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
}
.authpic_wrapper {
  border-radius: 50%;
  width: 24px;
  border: 1px solid rgba(34, 34, 34, 0.08);
  height: 24px;
  margin-right: 8px;
}
.author_pic {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  // filter: blur(1px);
}
.author {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  height: 16px;
  color: #666666;
  width: 77px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.like_wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-right: 4px;
}
.like_pic {
  width: 14px;
  height: 14px;
}
.like_num {
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  height: 14px;
  color: #666666;
  margin-left: 4px;
}
.UserInfoPublishTime {
  font-family: "苹方-简";
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0px;
  color: #666666;
}
