@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.pEvaluateC {
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  margin-top: 8px;
  padding: 0px 12px;
  .left {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #222222;
  }
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    color: #666666;
    vertical-align: middle;
    .arrowRight {
      width: 12px;
      height: 12px;
    }
  }
}
