@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.TotalInfoContent {
  .ContentLabel {
    display: inline-block;
    width: fit-content;
    height: 16px;
    padding: 0 4px;
    border-radius: 2px;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    color: #ffffff;
    background-color: #11c993;
    margin-right: 5px;
  }
  .ContentLabelText {
    vertical-align: middle;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #222222;
  }
}
.TotalInfoSubContent {
  .CopyMsg {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: #222;
    font-size: 14px;
    margin-right: 12px;
  }
  .ContentLabel {
    display: inline-block;
    width: fit-content;
    height: 16px;
    padding: 0 4px;
    border-radius: 2px;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    color: #ffffff;
    background-color: #11c993;
    margin-right: 5px;
  }
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  .ContentArea {
    height: 60px;
    overflow: hidden;
    white-space: pre-wrap;

    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
    line-height: 1.44;
    max-height: 60px;
    &::before {
      content: "";
      float: right;
      height: 41px;
    }
  }
  .ContentArea2 {
    line-height: 1.44;
    white-space: pre-wrap;
  }
  .SwitchBtnOpen {
    float: right;
    clear: both;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    height: 16px;
    color: #222222;
    margin-right: 4px;
    margin-top: 1px;
    position: relative;
    margin-left: 30px;
    &::before {
      // content: "...";
      position: absolute;
      left: -40px;
      color: #666666;
    }
  }
  .SwitchBtnClose {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
  }
  img {
    width: 12px;
    height: 12px;
    margin-left: 4px;
  }
}
.TotalInfoLabel {
  margin-top: 8px;
  display: flex;
  column-gap: 14px;
  .LabelItem {
    display: flex;
    align-items: center;
    .LabelItemText {
      margin-left: 2px;
      font-size: 12px;
      line-height: 16px;
      color: #5a76b0;
    }
  }
}

.poster-unify-toast {
  z-index: 19999 !important;
  border-radius: 12px;
}
