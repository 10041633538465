@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.itemV {
  display: flex;
  flex-direction: column;
  .itemV1 {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 24px;
    padding-bottom: 12px;
    .left {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .flag {
        color: #ff302d;
        font-size: 14px;
      }
      .title {
        color: #666666;
        font-size: 14px;
      }
    }
    .input {
      padding-left: 8px;
      border: none;
      --font-size: 14px;
      outline: none;
      --color: #222222;
      --text-align: right;
      flex: 1;
    }
  }
  .divider {
    background-color: rgba(34, 34, 34, 0.08);
    height: 0.5px;
  }
  .inputTips {
    color: #ff302d;
    font-size: 12px;
    text-align: end;
    margin-top: 4px;
  }
}
