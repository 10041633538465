@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  padding-top: 88px;
  padding-bottom: 100px;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: white;
}
