@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.proMoney {
  position: absolute;
  bottom: 0px;
  z-index: 20;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 2px;
  opacity: 1;
  background: #FFD4B6;

  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  letter-spacing: 0px;
  color: #8C4206;
  font-weight: 500;
  font-size: 10px;
  font-family: DIN;
  font-weight: 700;
  font-size: 10px;

}

.save_money_content {
  position: absolute;
  bottom: -2px;
  margin-top: 2px;
  background-color: rgba(251, 73, 59, 1);
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  // align-items: center;
  max-width: 100%;

  .save_money {
    background: rgba(255, 224, 234, 0.9);
    font-size: 10px;
    padding-top: 2px;
    margin-top: 10px;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    font-weight: 500;
    color: #fb493b;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    // padding: 0px 6px;
    white-space: nowrap;
    line-height: 18.5px;
  }

  .add {
    color: #ffffff;
    padding: 0 3px;
    line-height: 18.5px;
    flex-shrink: 1;
    font-size: 10px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}