@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  position: fixed;
  left: 0;
  right: 0;
  padding: 8px 12px;
  transform: translateY(-100%);
  transition: transform 0.3s ease-out;
  touch-action: pan-y;
  animation: slideIn 0.3s ease-out forwards;

  &.leave {
    animation: slideOut 0.3s ease-in forwards;
  }
}

.content {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 12px 10px;
  box-sizing: border-box;
  box-shadow: 0px 2px 12px 0px rgba(5, 0, 56, 0.2);

  .description {
    display: flex;
    align-items: center;

    .textBox {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #222222;
      line-height: 20px;
    }

    .icon {
      width: 40px;
      height: 12px;
      margin-right: 8px;
    }
  }
}


@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}