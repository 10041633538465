@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.live-intro {
  background-color: #ffffff;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 8px;
  position: relative;
  .live-intro-top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    .intro__avatar {
      width: 24px;
      height: 24px;
    }
    .intro__name {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222222;
      margin-left: 6px;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .intro__link2history {
      margin-left: auto;
      display: flex;
      align-items: center;
      margin-right: 2px;
      height: 24px;
      .history__text {
        font-size: 12px;
        color: #666666;
      }
      .intro__arrow__icon {
        width: 12px;
        height: 12px;
      }
    }
  }
  .live-intro-bottom {
    font-family: "苹方-简";
    height: 30px;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    z-index: 1;
    position: relative;
    .intro__showAll {
      float: right;
      background-color: #ffffff;
      font-family: "苹方-简";
      color: #a5a5a5;
      box-shadow: -8px 0px 10px white;
      padding-left: 5px;
      margin-right: 2px;
      clear: both;
      position: relative;
    }
    &::before {
      content: "";
      float: right;
      width: 0;
      height: 16px;
    }
  }
  .active_bg_img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.live-intro-multiple {
  width: 76vw;
  margin-bottom: 12px;
}
.popup-wapper {
  padding: 0 20px;
  .popup-header {
    margin: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .popup__title {
      font-family: PingFang SC;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: #222222;
    }
    .popup__close {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 4px 0;
    }
  }
  .popup-content {
    padding-top: 8px;
    font-family: "苹方-简";
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
}
