@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.fixed-filter-container {
  position: fixed;
  z-index: 100;
  width: 100%;
  background: #fff;
  line-height: 44px;
}

.toast-mask {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  &::before {
    content: "";
    display: block;
    width: 100vw;
    height: 90px;
    background: #fff;
    top: 0;
    left: 0;
    position: absolute;
  }
}

@keyframes sizeAni {
  0% {
    scale: 1;
  }

  50% {
    scale: 1.4;
  }

  100% {
    scale: 1;
  }
}

.brand-container {
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  padding: 8px 12px;
  padding-top: 0;
  padding-right: 0;
  display: flex;

  .brand-list {
    display: flex;
    flex-grow: 1;
    &::after {
      content: "";
      display: block;
      padding-right: 9px;
    }
  }

  .brand-icon {
    border-radius: 4px;
    background-color: #fff;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eeeeee;
    font-size: 12px;
    line-height: 14px;
    padding: 6px 8px 6px 6px;
    box-sizing: border-box;
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
    img {
      width: 20px;
      height: 20px;
      overflow: hidden;
      border-radius: 4px;
      border: 1px solid #f3f3f3;
      vertical-align: bottom;
      box-sizing: border-box;
      padding: 1px;
    }

    div:nth-child(2) {
      max-width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 4px;
    }

    &.active {
      border: 1px solid #fb2633;
      background-color: #fff4f4;
      color: #ff4747;
      font-weight: 500;
    }
    &:nth-child(1) {
      padding: 6px;
    }
  }
}

.act-list-container {
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  // &::before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 132px;
  //   background: linear-gradient(180deg, #ffffff 0%, transparent 100%);
  //   background: -moz-linear-gradient(180deg, #ffffff 0%, transparent 100%);
  //   background: -webkit-linear-gradien(180deg, #ffffff 0%, transparent 100%);
  //   background: -o-linear-gradient(180deg, #ffffff 0%, transparent 100%);
  //   background: -ms-linear-gradient(180deg, #ffffff 0%, transparent 100%);
  // }

  &.no-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

.product-list {
  display: flex;
  position: relative;
  // z-index: 1;
  min-height: 63vh;
  overflow: hidden;
  .navi-list {
    // height: 88vh;
    width: 64px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    box-sizing: border-box;
    background-color: #f1f2f5;
    transition: height 0.3s;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    flex-shrink: 0;

    // &::after {
    //   content: '';
    //   display: block;
    //   height: 100px;
    //   width: 2;
    // }

    &.navi-float {
      position: fixed;
      left: 0;
      top: 88px;
    }

    .navi-btn {
      min-height: 50px;
      width: 65px;
      color: #666666;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      font-size: 12px;
      padding: 0 8px;
      background-color: #f1f2f5;
      text-align: center;
    }

    .active {
      background-color: #fff;
      color: #222222;

      &::before {
        content: "";
        display: block;
        left: 0;
        width: 3px;
        height: 24px;
        flex-shrink: 0;
        position: absolute;
        z-index: 9;
        background-color: #ff4747;
      }
      &::after {
        content: "";
        display: block;
        right: -1px;
        top: -10px;
        width: 10px;
        height: 10px;
        position: absolute;
        z-index: 30;
        background-color: white;
        background: radial-gradient(circle at left top, transparent 10px, white 0);
      }
    }
    .active + .navi-btn {
      border-top-right-radius: 10px;
    }
  }

  .list-body {
    flex-grow: 1;
    background-color: #fff;
    padding-top: 8px;
    width: 0;
    // padding-bottom: 56px;
    .product-status__tab {
      position: relative;
      padding: 0px 8px 6px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      &.status-float {
        position: fixed;
        right: 0;
        top: 88px;
        z-index: 10;
        width: 311px;
        padding-top: 6px;
        box-sizing: border-box;
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 143px;
        height: 28px;
        font-size: 12px;
        color: #222222;
        border-radius: 6px;
        background: #f1f2f5;
        &.active {
          font-family:
            PingFangSC-Medium,
            PingFang SC;
          font-weight: 500;
          color: #ff4747;
          background: rgba(255, 71, 71, 0.16);
        }
      }
    }
  }
}

.water-fall-box {
  overflow: hidden;
  z-index: 1;
  position: relative;
  padding-left: 9px;
  padding-right: 9px;

  .list {
    display: flex;
    flex-direction: column;
    width: 145px;
    float: left;

    &:first-child {
      margin-right: 9px;
    }
  }
}

.nav-tab-contain {
  position: relative;
  height: 39px;

  .nav-tab {
    width: 192px;
    height: 39px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    font-size: 14px;
    color: #222;
    background-color: #fff2e9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: -7px;
    // transition: all 1s;

    &:last-child {
      position: absolute;
      right: 0;
      left: auto;
    }

    & > div {
      margin-top: -6px;
    }
  }

  .active {
    background-color: #fff;
    font-size: 16px;
    z-index: 1;
    bottom: 0;
    font-family: PingFangSC-Medium;
    & > div {
      margin-top: 0px;
    }
  }
}

:global {
  .over-hidden {
    overflow: hidden;
  }
}
