@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.coupon {
  position: relative;
  .one1 {
  }
  .oneMore {
    width: 100%;
    border-radius: 12px;

    .topImg {
      width: 100%;
      height: 50px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    .content {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .item {
        width: 120px;
        height: 92px;
        margin: 9px 3px;
        position: relative;
        flex-shrink: 0;
        .itemImg {
          width: 120px;
          height: 92px;
        }
        .price {
          display: flex;
          align-items: baseline;
          justify-content: center;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: rgba(255, 48, 45, 1);
          line-height: 32px;
          padding-top: 5px;
          .symbol {
            height: 20px;
            display: flex;
            font-size: 14px;
            padding-bottom: 2px;
            padding-right: 2px;
          }
          .bigText {
            font-size: 32px;
          }
        }
        .gold {
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: #cc7f56;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 120px;
          padding: 0 6px;
          box-sizing: border-box;
          text-align: center;
        }
        .btn {
          width: 100%;
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 6px;
          .nowS {
            width: 78px;
            height: 20px;
          }
          .outS {
            position: absolute;
            width: 65px;
            top: 0;
            right: 1px;
          }
        }
        .btnS {
          width: 100%;
          display: flex;
          justify-content: center;
          position: relative;
          bottom: 6px;
          .nowS {
            width: 78px;
            height: 20px;
          }
          .outS {
            position: absolute;
            width: 65px;
            top: 0;
            right: 1px;
          }
        }
        .topIcon {
          position: absolute;
          top: 0;
          right: 0;
          width: 38px;
        }
      }
    }
  }
  .oneTwoMore {
    width: 100%;
    border-radius: 12px;
    .topImg {
      width: 100%;
      height: 50px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    .content {
      width: 100%;
      overflow-x: auto;
      display: flex;
      height: 196px;
      flex-direction: column;
      flex-wrap: wrap;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .item {
        width: 120px;
        height: 92px;
        margin: 6px;
        position: relative;
        flex-shrink: 0;
        .itemImg {
          width: 120px;
          height: 92px;
        }
        .price {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: rgba(255, 48, 45, 1);
          line-height: 32px;
          padding-top: 5px;
          .symbol {
            height: 32px;
            display: flex;
            align-items: flex-end;
            font-size: 14px;
            padding-bottom: 2px;
            padding-right: 2px;
          }
          .bigText {
            font-size: 32px;
          }
        }
        .gold {
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: #cc7f56;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 120px;
          padding: 0 6px;
          box-sizing: border-box;
          text-align: center;
        }
        .btn {
          width: 100%;
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 6px;
          .nowS {
            width: 78px;
            height: 20px;
          }
        }
        .topIcon {
          position: absolute;
          top: 0;
          right: 0;
          width: 38px;
        }
      }
    }
  }
  .oneLeftMore {
    width: 100%;
    border-radius: 10px;
    display: flex;
    .topImg {
      width: 51px;
      height: 100%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      flex-shrink: 0;
    }
    .leftImg {
      width: 51px;
      height: 100%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      flex-shrink: 0;
    }
    .content {
      width: 100%;
      overflow-x: auto;
      display: flex;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .item {
        width: 120px;
        height: 92px;
        margin: 6px;
        position: relative;
        flex-shrink: 0;
        .icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 38px;
          height: 33px;
        }
        .itemImg {
          width: 120px;
          height: 92px;
        }
        .price {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: rgba(255, 48, 45, 1);
          line-height: 32px;
          padding-top: 5px;
          .symbol {
            height: 23px;
            display: flex;
            font-size: 14px;
            padding-bottom: 2px;
            padding-right: 2px;
          }
          .bigText {
            font-size: 32px;
          }
        }
        .gold {
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: #cc7f56;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 120px;
          padding: 0 6px;
          box-sizing: border-box;
          text-align: center;
        }
        .btn {
          width: 100%;
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 6px;
          .nowS {
            width: 78px;
            height: 20px;
          }
        }
        .topIcon {
          position: absolute;
          top: 0;
          right: 0;
          width: 38px;
        }
      }
    }
  }
  .oneTwoLeftMore {
    width: 100%;
    border-radius: 10px;
    display: flex;
    .topImg {
      width: 51px;
      height: 100%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      flex-shrink: 0;
    }
    .leftImg {
      width: 51px;
      height: 100%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      flex-shrink: 0;
    }
    .content {
      width: 100%;
      overflow-x: auto;
      display: flex;
      height: 202px;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: baseline;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .item {
        width: 120px;
        height: 92px;
        margin: 6px 6px 0 6px;
        position: relative;
        flex-shrink: 0;
        .icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 38px;
          height: 33px;
        }
        .itemImg {
          width: 120px;
          height: 92px;
        }
        .price {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: rgba(255, 48, 45, 1);
          line-height: 32px;
          padding-top: 5px;
          .symbol {
            height: 23px;
            display: flex;
            font-size: 14px;
            padding-bottom: 2px;
            padding-right: 2px;
          }
          .bigText {
            font-size: 32px;
          }
        }
        .gold {
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: #cc7f56;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 120px;
          padding: 0 6px;
          box-sizing: border-box;
          text-align: center;
        }
        .btn {
          width: 100%;
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: 6px;
          .nowS {
            width: 78px;
            height: 20px;
          }
        }
        .topIcon {
          position: absolute;
          top: 0;
          right: 0;
          width: 38px;
        }
      }
    }
  }
}
