@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.header {
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 6px;
  width: 375px;
  height: 44px;
  box-sizing: border-box;
  background-color: white;
  &_left {
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
    .back,
    .home {
      position: relative;
      z-index: 20;
      display: flex;
      align-items: center;
      padding: 0 12px;
      font-size: 14px;
      line-height: 32px;
      color: #222222;
      box-sizing: border-box;
    }
    .back {
      border-right: 0.5px solid rgba(0, 0, 0, 0.2);
    }
    .home img {
      margin-right: 4px;
    }
    img {
      width: 20px;
      height: 20px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 200%;
      height: 200%;
      border-radius: 40px;
      border: 1px solid #e5e5e5;
      transform-origin: left top;
      transform: scale(0.5);
    }
  }
  &_right {
    height: 32px;
    .search {
      width: 32px;
      height: 32px;
    }
  }
}
