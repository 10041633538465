@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.labelList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-height: 16px;
  overflow: hidden;
  flex: 1;
  row-gap: 8px;
  grid-row-gap: 8px;

  .label {
    display: inline-block;
    height: 16px;
    margin: 0 4px 0 0;
    font-size: 10px;
  }
}
