@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.popup__box {
  margin-bottom: constant(76px + safe-area-inset-bottom);
  display: flex;
  width: 100vw;
  flex-direction: column;
  overflow: hidden;

  .box__header {
    display: flex;
    position: relative;
    padding: 16px 0;
    justify-content: center;

    .header__title {
      text-align: center;
      font-family: PingFang SC;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: #222;
    }
    .header__icon {
      position: absolute;
      top: 20px;
      right: 16px;
      width: 16px;
      height: 16px;
    }
  }

  .box__time {
    height: 32px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 71, 71, 0.08);
    .time__title {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: #ff4747;
    }
  }

  .list {
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
    box-sizing: border-box;

    .page__body {
      display: flex;
      flex: 1;
      height: 60vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px 16px calc(76px + env(safe-area-inset-bottom)) 16px;
      flex-direction: column;

      .list__header {
        display: flex;
        padding-bottom: 24px;
        flex-direction: column;

        .header__title {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: #8c4206;
        }

        .header__desc {
          width: 100%;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: normal;
          color: #222222;
          padding-top: 8px;
          display: flex;
          word-break: break-all;
          white-space: pre-wrap;
        }
      }

      .list__card {
        display: flex;
        flex-direction: row;
        padding-top: 6px;
        padding-bottom: 16px;

        .card__image {
          width: 40px;
          height: 40px;
          border-radius: 4px;
          align-self: center;
          opacity: 1;
          box-sizing: border-box;
          border: 1px solid #fff;
        }
        .card__content {
          display: flex;
          flex: 1;
          margin-left: 8px;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          overflow: hidden;
          .content__title {
            display: flex;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            .title__left {
              display: inline-block; /* 或者使用 display: block;，取决于你的需求 */
              white-space: nowrap; /* 不换行 */
              overflow: hidden; /* 隐藏超出的内容 */
              text-overflow: ellipsis; /* 添加省略号 */
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: PingFang SC;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #222222;
              flex-shrink: 1;
            }
            .title__right {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0px 4px;
              background: #ffd4b6;
              border-radius: 2px;
              margin-left: 6px;
              height: 16px;
              .right__title {
                font-family: PingFang SC;
                font-size: 10px;
                font-weight: 500;
                display: flex;
                align-items: center;
                align-self: center;
                color: #8c4206;
              }
              flex-shrink: 0;
            }
          }
          .content__desc {
            font-family: "苹方-简";
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #666666;
          }
        }
      }
      .footer {
        height: calc(76px + env(safe-area-inset-bottom));
      }

      .bottom {
        position: fixed;
        z-index: 100;
        bottom: 0;
        height: 56px;
        display: flex;
        flex: 1;
        flex-direction: row;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        left: 0px;
        right: 0px;
        justify-content: space-between;
        background-color: #fff;
        .bottom__btn {
          display: flex;
          flex: 1;
          margin: 6px 12px;
          background-color: #ff4747;
          height: 44px;
          border-radius: 6px;
          justify-content: center;
          .btn__text {
            font-family: PingFang SC;
            align-self: center;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }
}
