@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.scroll-container {
  width: 100vw;
  // height: 100vh;
  display: flow-root;
  // overflow-y: auto;
  // overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  background-color: #f5f5f5;

  // padding-bottom: calc(50px + env(safe-area-inset-bottom));
  .pd1 {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .pd2 {
    padding-bottom: calc(50px + env(safe-area-inset-bottom));
  }

  .main {
    background-image: url("~@/assets/decorate_header_bg.png");
    background-size: contain;
    background-repeat: no-repeat;

    &.background-empty {
      height: 100vh;
      background-image: unset;
      background-color: #f1f2f5;
    }

    .top-bar {
      padding: 6px 0;
      position: sticky;
      top: 0;
      z-index: 100;
      transition: background-color 0.3s;
      width: 100%;
      background-color: #ffffff;
    }

    .shopInfo-container {
      margin-top: 8px;
      margin-bottom: 4px;
    }

    .tabs-container {
      padding: 8px 0 10px 0;
    }

    .tabs {
      display: flex;
      align-items: center;
      position: sticky;
      justify-content: space-around;
      width: 100%;
      box-sizing: border-box;

      .sign {
        width: 25px;
        height: 12px;
        position: absolute;
        right: -24px;
        top: -7px;
      }

      .line {
        width: 32px;
        height: 2px;
        background: #ff4747;
        position: absolute;
        bottom: -7px;
      }

      .item {
        font-size: 14px;
        color: #222222;
        display: flex;
        justify-content: center;
        position: relative;

        .font_nav {
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          transition: all 1s;
        }

        .active {
          font-size: 16px !important;
          font-weight: normal !important;
          color: #222222 !important;
          font-weight: 500 !important;
        }
      }
    }

    .decorate_equities_card {
      padding: 0 12px;
    }

    .main-container {
      min-height: 90vh;

      .switch-animation {
        animation: fade-in 0.3s ease-in-out;
        margin-top: 6px;
      }
    }
  }

  .share_btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: fit-content;
    opacity: 1;
    background: #ffffff;
    display: flex;
    justify-content: center;
    z-index: 110;
    padding: 10px 16px;
    padding-bottom: calc(6px + env(safe-area-inset-bottom));
    box-sizing: border-box;

    .share_img {
      flex: 1;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background: #ff4747;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      font-family: PingFang SC;

      .img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }

    .share_category {
      width: 137px;
      height: 44px;
      background: #ff4747;
      border-radius: 6px;
      color: #ffffff;
      font-size: 16px;
      line-height: 44px;
      text-align: center;
      margin-right: 9px;
      padding: 0 10px 0 10px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }
    }

    .share_selective_small {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      font-size: 12px;
      line-height: 16px;
      color: #ff4747;

      .img {
        width: 20px;
        height: 20px;
        margin-bottom: 4px;
      }
    }

    .share_selective {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      min-width: 166px;
      height: 42px;
      font-size: 16px;
      font-weight: 500;
      color: @themeColor;
      border-radius: 6px;
      border: 1px solid #ff4747;
      background: rgba(255, 71, 71, 0.08);

      .img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
  position: absolute;
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}