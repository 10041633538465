@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.coupon_list {
  width: 100vw;
  height: 100vh;
  display: flow-root;
  overflow-y: auto;
  overflow-x: hidden;
  .go_back {
    position: fixed;
    top: 25px;
    left: 18px;
    z-index: 999;
    width: 25px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
  .nullStatus {
    width: 100%;
    height: calc(100vh - 55px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .empty_img {
      width: 183px;
      height: 183px;
      .text {
        font-size: 14px;
        font-family:
          PingFangSC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
