@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.hide-category {
  height: 12px;
}
.category-wrapper {
  background-color: #fff;
  box-sizing: border-box;
  position: fixed;
  top: 44px;
  z-index: 100;
  .modalWrapper {
    top: 44px;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100;
    .mask {
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
    }
    .tabWrapper {
      z-index: 11;
      position: absolute;
      border-radius: 0 0 10px 10px;
      background-color: #fff;
      padding: 16px;
      .pull {
        position: absolute;
        right: 16px;
        top: 19px;
        width: 18px;
        height: 18px;
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .tab-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .tab-item {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          height: 28px;
          padding: 6px 10px;
          color: #666;
          background-color: #f1f2f5;
          border-radius: 4px;
          margin-right: 8px;
          margin-bottom: 8px;
          font-size: 12px;
        }
        .active {
          background: rgba(255, 71, 71, 0.16);
          color: #ff4747;
          font-weight: 500;
          font-family: PingFang SC;
        }
        .close-icon {
          width: 8px;
          height: 8px;
          margin-left: 8px;
        }
        .add-icon {
          width: 10px;
          height: 10px;
          margin-right: 6px;
        }
      }
    }
  }
  .add-area {
    position: absolute;
    left: 331px;
    top: 0px;
    width: 44px;
    height: 44px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      270deg,
      #ffffff 65%,
      rgba(255, 255, 255, 0.73) 81%,
      rgba(255, 255, 255, 0) 100%
    );
    .add-icon {
      width: 18px;
      height: 18px;
    }
  }
  .category-list {
    // position: sticky;
    // top: 44px;
    padding: 8px 8px 0px 0px;
    display: flex;
    flex-direction: row;
    width: 360px;
    overflow-y: hidden;
    overflow-x: auto;
    height: 44px;
    margin-left: 12px;
    box-sizing: border-box;
    padding-right: 20px;
    .category-item {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      height: 28px;
      padding: 6px 16px;
      color: #666;
      background-color: #f1f2f5;
      border-radius: 4px;
      margin-right: 8px;
      font-size: 12px;
      flex: 0 0 auto;
      &:last-child {
        margin-right: 20px;
      }
    }
    .active {
      background: rgba(255, 71, 71, 0.16);
      color: #ff4747;
      font-weight: 500;
      font-family: PingFang SC;
    }
  }
}
.NavContentBox {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 6px;
  top: 6px;
  border-radius: 20px;
  display: flex;
  z-index: 10;
  width: fit-content;
  .ImageBox {
    width: 44px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    .NavImage {
      width: 20px;
      height: 20px;
    }
    &:first-child::after {
      content: "";
      width: 1px;
      height: 18px;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      right: 50%;
    }
  }
}
