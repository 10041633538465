@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.pdSkeleton {
  width: 100vw;
  height: 100vh;
  background-color: #f1f2f5;
  padding-top: 375px;
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  .card {
    margin: 0px 12px;
    background-color: white;
    border-radius: 10px;
    padding: 12px;
    margin-top: 8px;

    .skeleton1 {
      height: 20px;
      width: 40%;
      border-radius: 4px;
    }

    .skeleton2 {
      height: 20px;
      width: 60%;
      margin-top: 10px;
      border-radius: 4px;
    }

    .skeleton3 {
      height: 60px;
      width: 85%;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}
