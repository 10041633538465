@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.TotalInfoInterAction {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
  .UserInfoPublishTime {
    font-family: "苹方-简";
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0px;
    color: #666666;
  }
  .InterActionBox {
    display: flex;
    align-items: center;
    margin-left: 24px;
    .InterActionIcon {
      width: 24px;
      height: 24px;
    }
    .InterActionText {
      margin-left: 4px;
      font-size: 12px;
      line-height: 16px;
      color: #666666;
    }
  }
}
.share-mask-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  .share-guide {
    z-index: 1000;
    position: absolute;
    right: 12px;
    width: 207px;
    height: 48px;
    top: 6px;
    opacity: 1;
  }
}
