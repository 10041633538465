@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.promotionC {
  width: 100vw;
  min-height: 35vh;
  max-height: 68vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  overflow: scroll;
  background-color: white;
}

.promotionCG {
  background: linear-gradient(0deg, #fff0e4 78%, #ffdbbe 99%, #ffd1ab 99%);
}

.bgImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 158px;
  border-radius: 10px 10px 0px 0px;
  background: url("https://akim-oss.aikucun.com/mshop/a923ad06661eb74230c718a02e93ba28c7a8a715_1723786804152_94.png") no-repeat;
  background-size: 100% 100%;
}

.top {
  width: 100vw;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;

  .title {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    color: #222222;
  }

  .close {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 20px;
  }
}

.body {
  width: 100vw;
  overflow: scroll;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 10px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 10px);

  .deliveryDesc {
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 20px;
    color: #666;
    margin: 10px 16px 0px 16px;
  }

  .card {
    padding-top: 8px;
  }
}

.bodyB {
  padding-bottom: calc(constant(safe-area-inset-bottom) + 44px + 10px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 44px + 10px);
}

.bottomC {
  background-color: white;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  .confirmBtn {
    height: 44px;
    list-style: 44px;
    background-color: #ff4747;
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin: 0px 20px;
  }
}