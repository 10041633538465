@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.focus {
  padding: 12px 12px 0 12px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff8a;
  .focusItem {
    .line {
      margin-top: 12px;
      margin-bottom: 12px;
      width: 100%;
      background-color: #eeeeee;
      height: 1px;
    }
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 106px;
    .img {
      width: 118px;
    }
    .text {
      color: #666666;
      font-size: 14px;
    }
    .btn {
      width: 64px;
      height: 26px;
      border: 1px solid rgba(34, 34, 34, 0.16);
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 12px;
      line-height: 26px;
      text-align: center;
    }
  }
}
.back {
  position: fixed;
  top: 13px;
  left: 12px;
  z-index: 1000;
  .backIcon {
    width: 30px;
  }
}
.loadingMore {
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
