@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.settingPage {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  background: #f1f2f5;
  .nav {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 99;
    padding: 6px;
    background-color: #fff;
    .nav-box {
      border-radius: 20px;
      border: 0.5px solid #e5e5e5;
      display: flex;
      position: relative;
      z-index: 10;
      width: fit-content;
      .nav-box__image-box {
        width: 44px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        .image-box__image {
          width: 20px;
          height: 20px;
        }
        &:first-child::after {
          content: "";
          width: 1px;
          height: 18px;
          background-color: rgba(0, 0, 0, 0.2);
          position: absolute;
          right: 50%;
        }
      }
    }
  }

  .content {
    margin: 10px 12px 10px;
    .group {
      margin-bottom: 8px;
      border-radius: 10px;
      background-color: #fff;
      :global(.settingItem:last-child) {
        border-bottom: none !important;
      }
    }
  }
  .footer {
    a {
      margin-top: 20px;
      margin-bottom: 20px;
      display: block;
      font-size: 12px;
      line-height: 17px;
      color: #1857ff;
      text-align: center;
      text-decoration: underline;
    }
  }
}
