@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page {
  background-color: #f1f2f5;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .container {
    position: relative;
    flex: 1;
    overflow: auto;
    padding-bottom: 110px;
    -webkit-overflow-scrolling: touch;

    .bg_img {
      position: absolute;
      display: flex;
      width: 100%;
      height: auto;
    }

    .list {
      position: relative;
      flex: 1;
      margin: 0 12px;
      overflow: auto;
    }

    .top_logo {
      display: flex;
      width: 30px;
      height: 30px;
      margin-top: 20px;
      border-radius: 4px;
      padding: 1px 1px;
      background-color: #ffffff;
    }

    .top_title {
      display: flex;
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 500;
      color: #222222;
      margin-top: 8px;
    }

    .info {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #ffffff;
      border-radius: 10px;

      .info__header {
        display: flex;
        flex-direction: column;
        padding: 0 12px;
        padding-bottom: 10px;

        .header__title {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: #8c4206;
        }

        .header__desc {
          width: 100%;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: normal;
          color: #222222;
          padding-top: 8px;
          display: flex;
          white-space: pre-wrap;
        }
      }

      .info__card {
        display: flex;
        flex-direction: row;
        padding-top: 6px;
        padding-bottom: 16px;
        padding: 12px;

        .card__image {
          width: 40px;
          height: 40px;
          border-radius: 4px;
          align-self: center;
          opacity: 1;
          box-sizing: border-box;
          border: 1px solid #fff;
        }
        .card__content {
          display: flex;
          flex: 1;
          margin-left: 8px;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          overflow: hidden;
          .content__title {
            display: flex;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            .title__left {
              display: inline-block; /* 或者使用 display: block;，取决于你的需求 */
              white-space: nowrap; /* 不换行 */
              overflow: hidden; /* 隐藏超出的内容 */
              text-overflow: ellipsis; /* 添加省略号 */
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: PingFang SC;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #222222;
              flex-shrink: 1;
            }
            .title__right {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 1px 4px;
              background: #ffd4b6;
              border-radius: 2px;
              margin-left: 6px;
              .right__title {
                font-family: PingFang SC;
                font-size: 10px;
                font-weight: 500;
                display: flex;
                align-items: center;
                color: #8c4206;
              }
              flex-shrink: 0;
            }
          }
          .content__desc {
            font-family: "苹方-简";
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #666666;
          }
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    padding: 6px 12px;
    padding-bottom: calc(6px + constant(safe-area-inset-bottom));
    padding-bottom: calc(6px + env(safe-area-inset-bottom));
    justify-content: space-between;
    background-color: #fff;

    .footer__left {
      flex: 1;
      display: flex;
      flex-direction: row;
      margin-right: 9px;
      height: 42px;
      justify-content: center;
      align-items: center;
      background-color: #ff474714;
      border-radius: 6px;
      border-color: #ff4747;
      border-width: 1px;
      border-style: solid;

      .left__title {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        color: #ff4747;
      }
    }

    .footer__right {
      flex: 1;
      display: flex;
      flex-direction: row;
      height: 44px;
      justify-content: center;
      align-items: center;
      background-color: #ff4747;
      border-radius: 6px;

      .right__title {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        color: #fff;
      }
    }
  }
}
