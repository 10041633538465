@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  .header {
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #333;
    font-size: 15px;
    font-weight: bold;
  }

  .body {
    overflow-y: scroll;
    height: calc(90vh - 106px - constant(safe-area-inset-bottom));
    height: calc(90vh - 106px - env(safe-area-inset-bottom));

    .brandItem {
      display: flex;
      flex-direction: row;
      align-items: center;

      .logo {
        width: 25px;
        height: 25px;
        border-radius: @smallBorderRadius;
        margin-right: 8px;
        flex-shrink: 0;
        background-color: white;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        border: 1px solid rgb(239, 236, 236);

        &.active {
          border: 1px solid @themeColor;
        }
      }

      .brandName {
        color: rgb(51, 51, 51);
        font-size: 12px;

        &.active {
          color: @themeColor;
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    height: 56px;
    display: flex;
    flex-direction: row;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    justify-content: space-between;
    width: 100%;

    .reset {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 50%;
      background-color: @lightColor;
      color: @themeColor;
      font-size: 17px;
      font-weight: 500;
    }

    .confirm {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 50%;
      background-color: @themeColor;
      color: white;
      font-size: 17px;
      font-weight: 500;
    }
  }
}
