@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.custom_top_back {
  position: absolute;
  padding-top: 6px;
  padding-left: 6px;
  z-index: 102;
  height: 44px;
  pointer-events: auto;
  width: 100vw;
  //
}

.custom_top_back_white {
  position: absolute;
  padding-top: 6px;
  padding-left: 6px;
  padding-bottom: 2px;
  height: 44px;
  z-index: 102;
  pointer-events: auto;
  width: 100vw;
  background-color: #ffffff;
}

.container_with_top_back {
  position: fixed;
}

.titleview {
  flex-direction: row;
  position: absolute;
  height: 32px;
  left: 138px;
  top: 6px;
  display: flex;
  border-radius: 16px;
  box-sizing: border-box;
  /* 分割线色/cd1_#222222 0.08a_分割线_light */
  /* 样式描述：弱表现 */
  border: 1px solid rgba(34, 34, 34, 0.08);
  padding-left: 16px;
  padding-right: 16px;
  justify-content: center;
  align-items: center;
  // padding-top: 5px;
  // padding-bottom: 5px;

}

.text {
  /* 辅助内容_中黑 */
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: normal;
  /* 文字色/ct1_#222222_主要文字色 */
  /* 样式描述：用于标题，主要信息 */
  color: #222222;
}

.img {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}

.title {
  /* 辅助内容_常规 */
  font-family: PingFang SC;
  margin-left: 40px;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0px;
  /* 文字色/ct1_#222222_主要文字色 */
  /* 样式描述：用于标题，主要信息 */
  color: #222222;


}