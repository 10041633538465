@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  position: relative;

  .options {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    place-content: space-around;
    font-size: 14px;
    color: #666666;
    position: relative;
    background-color: #ffffff;

    &.onTop {
      border-top-left-radius: @bigBorderRadius;
      border-top-right-radius: @bigBorderRadius;
    }

    .item {
      z-index: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:last-child {
        width: 60px;
        height: 16px;
        border-left: 1px solid rgba(34, 34, 34, 0.16);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }

      &.active {
        .filterIcon {
          path {
            fill: @themeColor;
          }
        }
        .firstSortOptionIcon {
          path {
            fill: @themeColor;
          }
        }
      }

      .icon {
        width: 14px;
        height: 14px;
        margin-left: 2px;
      }

      &.active {
        color: @themeColor;
        font-weight: 500;
      }
    }
  }

  .filterPopup {
    touch-action: none;
    position: absolute;
    top: 44px;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.55);
    z-index: 1;
    overflow: hidden;

    &.hidden {
      height: 0;
      .content {
        transform: translate(0, -145.5px);
      }
    }

    .content {
      background-color: white;
      border-bottom-left-radius: @bigBorderRadius;
      border-bottom-right-radius: @bigBorderRadius;
      display: flex;
      flex-direction: column;
      transform: translate(0, 0);
      transition: transform 0.3s ease-in-out;

      .item {
        padding: 0 20px;
        height: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        color: #333;
        border-bottom: 0.5px solid #e9e9e9;

        &.active {
          color: @themeColor;
          font-weight: 500;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
