@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  overflow: hidden;
  .box {
    width: fit-content;

    .box-item {
      display: flex;
      align-items: center;
      transition: transform 0.5s;
      .item {
        background-color: #f1f1f1;
        border-radius: 50%;
        flex-shrink: 0;
        text-align: center;
        color: #ffffff;
        transition: background-color 0.3s;
      }
      .active {
        background-color: #ff4747;
      }
      .small {
        transform: scale(0.7);
      }
    }
  }
}
