@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.search-coustom {
  .fiexd-top {
    transform: translateY(-200px);
  }
  .top-bar-left {
    background: #333 !important;
    transform: translateY(200px);
  }
  .top-bar-right {
    transform: translateY(200px);
    width: 30px;
    height: 30px;
    border: 1px solid #666;
    border-radius: 50%;
    background: #333 !important;
    & > div {
      padding: 5px !important;
    }
  }
  .adm-image-img {
    filter: brightness(100);
  }
}
