@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.save_money_content {
  position: absolute;
  bottom: 0;
  background-color: #ffd4b6;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

  display: flex;
  max-width: 100%;
  line-height: 14px;
  padding: 1px 4px;

  .save_money {
    background: #ffd4b6;
    font-size: 10px;
    font-family:
      PingFangSC-Medium,
      PingFang SC;
    font-weight: 500;
    color: #8c4206;
    display: flex;
    align-items: center;
    border-top-right-radius: 8px;
    white-space: nowrap;
  }
  .add {
    color: #8c4206;
    font-weight: 500;

    font-size: 10px;
    // padding-right: 3px;

    display: flex;
    align-items: center;
    flex-shrink: 1;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
