@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.productIntroduce {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 8px;
  padding-bottom: 12px;

  .title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #222222;
    margin: 12px;
  }

  .otcTip {
    display: flex;
    flex-direction: row;
    background-color: #fff4e6;
    padding: 8px;
    align-items: flex-start;

    .flag {
      width: 20px;
      height: 20px;
      margin-top: -2px;
    }

    .txt {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 0px;
      color: #ff6600;
      margin-left: 4px;
    }
  }

  .img {
    width: 100%;
  }
}
