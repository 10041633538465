@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.image {
  position: relative;
  .adm-swiper-track-inner {
    align-items: center;
  }
  .barrage {
    font-size: 12px;
    height: 25px;
    background: rgb(0 0 0 / 40%);
    border-radius: 8px;
    color: #fff;
    line-height: 25px;
    padding-left: 9px;
    padding-right: 9px;
    margin-left: 9px;
    margin-right: 9px;
    width: fit-content;
    max-width: calc(100% - 36px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  .top {
    position: absolute;
    top: 0;
    width: 100%;
    .adm-swipe {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    .adm-swiper {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  .comeInTop {
    position: absolute; /* 或 fixed */
    top: 0; /* 底部定位为负数 */
    animation: slide-down 0.5s ease-in-out forwards;
  }
  @keyframes slide-down {
    from {
      top: -9px;
    }
    to {
      top: 0;
    }
  }
  .comeInBottom {
    position: absolute; /* 或 fixed */
    bottom: 0px; /* 底部定位为负数 */
    animation: slide-up 0.5s ease-in-out forwards;
    transform: translateY(10px); /* 向上移动元素 */
  }
  @keyframes slide-up {
    from {
      transform: translateY(10px);
    }
    to {
      transform: translateY(0px);
    }
  }
}
