@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  // background-color: #ff4747;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .image {
    width: 16px;
    height: 16px;
  }

  .text {
    padding-left: 4px;
    font-size: 14px;
    color: #ffffff;
  }
}