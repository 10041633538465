@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  width: 288px;
  height: 373px;
  display: flex;
  flex-direction: column;
  background-image: url("~@/assets/shop_owner_qrcode-bg.png");
  background-size: cover;
  position: relative;
  .header {
    display: flex;
    padding: 16px 16px 9px 16px;
    background-color: #ffffff;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    .header__logo {
      width: 45px;
      height: 45px;
      vertical-align: bottom;
      border: 1px solid rgba(34, 34, 34, 0.08);
      padding: 2px;
      border-radius: 6px;
      margin-right: 8px;
      flex-shrink: 0;
    }
    .header-info {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      overflow: hidden;
      .info__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222222;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .info__desc {
        font-size: 12px;
        line-height: 16px;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main-body {
      background-color: #ffffff;
      border-radius: 10px;
      padding: 30px 30px 26px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      width: fit-content;
      .body__desc {
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
        color: #222222;
      }
    }
  }
  .close__icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: -32px;
    right: 8px;
  }
}
