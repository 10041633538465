@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.page__empty__status {
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .empty__img {
    width: 140px;
    height: 140px;
    object-fit: contain;
  }
  .empty__tips {
    padding-top: 10px;
    font-family: "苹方-简";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #666;
  }
  .empty__btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 32px;
    border: 1px solid rgba(34, 34, 34, 0.16);
    border-radius: 4px;
    box-sizing: border-box;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }
}
