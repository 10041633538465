@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
}
.subTitleItem {
}

.titleWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title {
  color: #222222;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.titleDetail {
  flex: 1;
  margin-left: 16px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: #ff4747;
  &::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background: url(https://akim-oss.aikucun.com/a562a323aa46c131288790a104d81662f6f37501_1725003482151_68.png)
      center center no-repeat;
    background-size: 12px;
  }
}
.subTitle {
  margin-top: 4px;
  font-size: 12px;
  color: #666;
  line-height: 16px;
}

.detail {
  flex-direction: column;
  justify-content: center;
}
.detailContentTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.switch {
  align-items: center;
}
.switchLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
.switchRight {
  margin-left: 20px;
}

.detailSwitch {
  flex-direction: column;
  justify-content: flex-start;
}
.detailSwitchTop {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.detailSwitchBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.detailSwitchBottomTitle {
  color: #222;
  font-size: 14px;
  line-height: 20px;
}

.aside {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #a5a5a5;
  font-weight: 400;
  &::after {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    background: url(https://akim-oss.aikucun.com/a562a323aa46c131288790a104d81662f6f37501_1718689977925_81.png)
      center center no-repeat;
    background-size: 16px;
  }
}
