@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  position: sticky;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 44px;
  padding: 6px 0 6px 12px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;

  .leftBox {
    height: 32px;
    background: rgba(255, 255, 255, 0.7);
    border: 0.5px solid #e5e5e5;
    border-radius: 44px;
    display: flex;
    align-items: center;
    padding-right: 10px;

    .backIcon {
      width: 20px;
      height: 20px;
      margin-left: 12px;
      object-fit: contain;
    }

    .homeBox {
      margin-left: 12px;
      display: flex;
      align-items: center;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        height: 18px;
        width: 0.5px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(0, 0, 0, 0.2);
      }

      .homeIcon {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 4px;
        margin-left: 12px;
      }

      .homeText {
        color: #222222;
        font-size: 14px;
      }
    }
  }

  .leftBox-white {
    height: 32px;
    border: 0.5px solid transparent;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 44px;
    display: flex;
    align-items: center;
    padding-right: 10px;

    .backIcon {
      width: 20px;
      height: 20px;
      margin-left: 12px;
      object-fit: contain;
    }

    .homeBox {
      margin-left: 12px;
      display: flex;
      align-items: center;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        height: 18px;
        width: 0.5px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(255, 255, 255, 0.25);
      }

      .homeIcon {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 4px;
        margin-left: 12px;
      }

      .homeText {
        color: #222222;
        font-size: 14px;
      }
    }
  }

  // 中间的盒子居中
  .midBox {
    position: absolute;
    // flex-grow: 1;
    // display: flex;
    left: 50%;
    transform: translateX(-40%);
  }

  // .rightBox {
  //   margin-left: auto;
  //   width: 32px;
  //   height: 32px;
  //   background: rgba(255, 255, 255, 0.7);
  //   border: 0.5px solid #e5e5e5;
  //   border-radius: 50%;
  //   margin-right: 6px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   position: relative;
  //   .cart {
  //     width: 20px;
  //     height: 20px;
  //     object-fit: contain;
  //   }
  //   .cartNum {
  //     position: absolute;
  //     background: #ff302d;
  //     width: 18px;
  //     height: 18px;
  //     line-height: 18px;
  //     border-radius: 50%;
  //     color: #ffffff;
  //     font-size: 12px;
  //     font-weight: 500;
  //     right: -6px;
  //     top: -6px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }
  // }
  .rightBox {
    margin-left: auto;
    display: flex;
    flex-direction: row;

    .rightIconWrapper {
      width: 32px;
      height: 32px;
      background: rgba(255, 255, 255, 0.7);
      border: 0.5px solid #e5e5e5;
      border-radius: 50%;
      margin-right: 12px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      position: relative;

      .shareIcon {
        width: 32px;
        height: 32px;
      }
    }

    .rightIconWrapper-white {
      width: 32px;
      height: 32px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      margin-right: 12px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      position: relative;

      .shareIcon {
        width: 32px;
        height: 32px;
      }
    }

    .rightIcon {
      width: 20px;
      height: 20px;
    }

    .favoriteWhite {
      width: 16px;
      height: 16px;
    }

    .iconNum {
      position: absolute;
      background: @themeColor;
      width: 18px;
      height: 18px;
      line-height: 18px;
      border-radius: 50%;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      right: -6px;
      top: -6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .rightTitle {
      display: flex;
      margin-right: 8px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.7);
      box-sizing: border-box;
      border: 0.5px solid #e5e5e5;
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      color: #222222;
      padding-left: 8px;
      padding-right: 8px;
      align-items: center;
      height: 32px;
    }
  }
}

.placeholder {
  position: absolute;
  left: 0;
  right: 0;
  visibility: hidden;
  top: 0;
  height: 44px;
}

.favoriteGuide {
  z-index: 10000;
  background-color: white;
  position: absolute;
  top: 23px;
  right: 10px;
  display: flex;
  flex-direction: column;
  padding: 12px 0 8px 0;
  border-radius: 6px;

  .image1 {
    width: 210px;
    margin-left: -10px;
    margin-bottom: 8px;
  }

  .image2 {
    width: 210px;
  }

  .image3 {
    width: 186px;
    height: 110px;
    padding: 0 12px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-right: 12px;
    align-self: flex-end;

    .button {
      background-color: @themeColor;
      color: white;
      border-radius: @smallBorderRadius;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      height: 25px;
      width: 64px;
    }
  }

  ::after {
    content: "";
    position: absolute;
    right: 8px;
    top: -6px;
    width: 15px;
    height: 15px;
    background: #ffffff;
    border-radius: @smallBorderRadius;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.addCartPopover {
  --background: #ff4747;
}