@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.resultList {
  .list__title {
    padding: 20px 0 12px 0;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #222;
    text-align: center;

    &::before {
      position: relative;
      right: 8px;
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url("https://akim-oss.aikucun.com/mshop/9db496dd5add8a9f0b21dfb28590ffe3ea57cb1e_1715583756445_8.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &::after {
      position: relative;
      left: 8px;
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      // background-image: url("https://akim-oss.aikucun.com/mshop/9db496dd5add8a9f0b21dfb28590ffe3ea57cb1e_1714933584561_90.png");
      background-image: url("https://akim-oss.aikucun.com/mshop/9db496dd5add8a9f0b21dfb28590ffe3ea57cb1e_1715583756445_8.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .list__body {
  }
}
