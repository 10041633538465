@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.userCard {
  .cardHeader {
    display: flex;
    justify-content: space-between;

    .headerLeft {
      display: flex;

      .avatar {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid rgba(229, 229, 229, 0.3838);
        object-fit: contain;
      }

      .info {
        padding: 0 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .nickname {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #222;

          .iconArrow {
            margin-left: 2px;
            width: 12px;
            height: 12px;
          }
        }
        .phone {
          display: flex;
          align-items: center;
          font-family: "苹方-简";
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          color: #666;

          .iconCopy {
            margin-left: 2px;
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .headerRight {
      .footprint {
        font-family: "苹方-简";
        font-size: 12px;
        font-weight: normal;
        line-height: 20px;
        text-align: right;
        color: #666;

        .iconArrow {
          margin-left: 2px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .cardMain {
    position: relative;
    padding: 8px 0;

    .mainAction {
      font-family: "苹方-简";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: #222;

      .iconOrdered {
        margin-right: 6px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 16px;
        background: rgba(255, 71, 71, 0.08);
        border-radius: 2px;
        font-family: PingFang SC;
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;
        color: #ff4747;
      }
      .iconAdvance {
        margin-right: 6px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 16px;
        background: rgba(17, 201, 147, 0.08);
        border-radius: 2px;
        font-family: PingFang SC;
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;
        color: #06b581;
      }
    }
    .mainDate {
      position: absolute;
      right: 2px;
      bottom: 9px;
      font-family: "苹方-简";
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #a5a5a5;
    }
  }
}
