@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  min-height: 100vh;
  width: 375px;
  margin: auto;
  overflow: hidden scroll;
  background: #f1f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: calc(20px + constant(safe-area-inset-bottom));
  padding-bottom: calc(20px + env(safe-area-inset-bottom));

  .headerWrap {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
  }

  .resourceContainer {
    position: relative;
    width: 375px;
    padding-bottom: 16px;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 375px;
    //   height: 100px;
    //   background: linear-gradient(180deg, #ffffff 39%, rgba(255, 255, 255, 0) 83%);
    // }
  }

  >* {
    flex-shrink: 0;
  }

  .modeTabWrap {
    min-height: 47px;
    background-color: #ffffff;
  }

  .cardContainer {
    margin-top: -8px;
    width: 375px;
    padding-bottom: 30px;
  }
}