@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.discovery-wrapper {
  min-height: -webkit-calc(100vh - 130px);
  min-height: -moz-calc(100vh - 130px);
  min-height: calc(100vh - 130px);
  background-color: #fff;
  padding-bottom: 100px;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
  .toEnd {
    animation: appear 0.2s ease-in-out forwards;
    display: flex;
    align-items: center;
  }
  .skeleton-img {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 0 12px;
  }
  .empty-wrapper {
    height: 480px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .empty-img {
      width: 183px;
      height: 183px;
    }
    .empty-text {
      font-size: 14px;
      color: #666;
    }
  }
  .water-fall-box {
    // overflow: hidden;
    // overflow-y: auto;
    z-index: 1;
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    // padding-top: 44px;
    // padding-bottom: 150px;
    box-sizing: border-box;
    // animation: appear 0.5s ease-in-out forwards;
    .waterfall {
      // min-height: -webkit-calc(100vh - 80px);
      // min-height: -moz-calc(100vh - 80px);
      // min-height: calc(100vh - 80px);
      width: 360px;
      display: flex;
      flex-wrap: wrap;
    }
    // .appear {
    //   animation: appear 0.5s ease-in-out forwards;
    // }
    .card-item {
      display: flex;
      flex-direction: column;
      // width: 145px;
      float: left;
      margin-right: 7px;
      margin-bottom: 15px;
    }
    @keyframes appear {
      0% {
        opacity: 0;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .list {
    }
  }
  :global(.adm-infinite-scroll) {
    width: 355px;
    box-sizing: border-box;
  }
}
.infinite {
  margin-bottom: calc(8px + constant(safe-area-inset-bottom));
  margin-bottom: calc(8px + env(safe-area-inset-bottom));
}
.loadingMore {
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
