@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  // display: none;
  z-index: 10;
  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .popupButton {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        width: 140px;
        height: 44px;
        border-radius: 6px;
        font-size: 16px;
        line-height: 22px;
        background-color: #ff4747;
        color: #fff;
      }
    }

    .popupContent {
      background: url(https://akim-oss.aikucun.com/b13d33a52865faaa847dae41e89930b55157dbf5_1699345802587_95.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 289px;
      height: 390px;
      background-repeat: 15px;

      .modalCloseIcon {
        margin-left: 259px;
        width: 18px;
        height: 18px;
        padding: 12px 12px 0 0;
      }

      .popTitle {
        display: flex;
        justify-content: center;
        .popTitleTop {
          width: 245px;
          height: auto;
          padding-top: 4px;
        }
      }
      .popCard {
        background: url(https://akim-oss.aikucun.com/9782297f58f2f7969586e5ee7f8a39e521ec23d8_1709190683376_55.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        height: 233px;
        border-radius: 15px;
        margin-top: 16px;
        .popCardTitle {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #222222;
          padding: 14px 0 12px 0;
        }
        .popCardImg {
          width: 133px;
          height: 133px;
          margin-top: 21px;
          padding: 7px;
        }
      }
    }
    .popupTips {
      font-family: "苹方-简";
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
      text-align: center;
      width: 100%;
      display: inline-block;
      margin-top: 16px;
    }
  }
}
