@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.coupon_item {
  position: relative;
  box-sizing: border-box;

  &.three {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0 6px;
    width: 92px;
    height: 106px;

    &.newcomer {
      background: url("https://akim-oss.aikucun.com/mshop/debb5077d5ccc1becd3007e9f26b76a34fe03261_1708844725193_76.png")
        no-repeat;
      background-size: 100% 100%;

      &::before {
        content: "";
        position: absolute;
        left: 5px;
        bottom: 28px;
        width: 82px;
        border-top: 1px dashed #fff7ef;
        opacity: 0.5;
        transform: scaleY(0.5);
        transform-origin: 0 0;
      }

      .name {
        color: #ffffff;
      }

      .amount {
        color: #ffffff;
      }

      .rmb {
        color: #ffffff;
      }

      .time {
        color: #ffffff;

        .theme {
          color: #ffffff;
        }
      }
    }

    &.venue {
      padding-top: 14px;
      background: url("https://akim-oss.aikucun.com/mshop/09f64d08f61be35f64ea06b1cac99fead83878da_1708844846896_77.png")
        no-repeat;
      background-size: 100% 100%;
      margin-right: 6px;

      &::before {
        content: "";
        position: absolute;
        left: 5px;
        bottom: 28px;
        width: 82px;
        border-top: 1px dashed #c2ac98;
        opacity: 0.5;
        transform: scaleY(0.5);
        transform-origin: 0 0;
      }
    }
  }

  &.two {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0 6px;
    width: 110px;
    height: 106px;

    &.newcomer {
      background: url("https://akim-oss.aikucun.com/mshop/ec41fb9375d470167ce5dd377a399a29bc48721f_1708844962099_13.png")
        no-repeat;
      background-size: 100% 100%;

      &::before {
        content: "";
        position: absolute;
        left: 5px;
        bottom: 28px;
        width: 100px;
        border-top: 1px dashed #fff7ef;
        opacity: 0.5;
        transform: scaleY(0.5);
        transform-origin: 0 0;
      }

      .name {
        color: #ffffff;
      }

      .amount {
        color: #ffffff;
      }

      .rmb {
        color: #ffffff;
      }

      .time {
        color: #ffffff;

        .theme {
          color: #ffffff;
        }
      }
    }

    &.venue {
      padding-top: 14px;
      background: url("https://akim-oss.aikucun.com/mshop/6978790eae782e3eb72ef284ee77a6e135159670_1708845008375_75.png")
        no-repeat;
      background-size: 100% 100%;
      margin-right: 18px;

      &::before {
        content: "";
        position: absolute;
        left: 5px;
        bottom: 28px;
        width: 100px;
        border-top: 1px dashed #c2ac98;
        opacity: 0.5;
        transform: scaleY(0.5);
        transform-origin: 0 0;
      }
    }
  }

  &.one {
    display: flex;
    margin-top: 12px;
    width: 286px;
    height: 74px;

    &.newcomer {
      background: url("https://akim-oss.aikucun.com/mshop/cd449edcaaa32d2486175874ed952515fda184e8_1708849889849_45.png")
        no-repeat;
      background-size: 100% 100%;

      &::before {
        content: "";
        position: absolute;
        left: 76px;
        top: 9px;
        height: 56px;
        border-left: 1px dashed #fff7ef;
        opacity: 0.5;
        transform: scaleX(0.5);
        transform-origin: 0 0;
      }

      .name {
        color: #ffffff;
      }

      .amount {
        color: #ffffff;
      }

      .rmb {
        color: #ffffff;
      }

      .time {
        color: #ffffff;

        .theme {
          color: #ffffff;
        }
      }
    }

    &.venue {
      background: url("https://akim-oss.aikucun.com/mshop/1413748235ed130ee52296d0ff7fb2447eb0eb5b_1706780666856_22.png")
        no-repeat;
      background-size: 100% 100%;

      &::before {
        content: "";
        position: absolute;
        left: 76px;
        top: 9px;
        height: 56px;
        border-left: 1px dashed #c2ac98;
        opacity: 0.5;
        transform: scaleX(0.5);
        transform-origin: 0 0;
      }
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 76px;
    }

    .right {
      flex: 1;
      padding-left: 10px;
      padding-top: 16px;

      .name {
        margin-bottom: 6px;
        padding: 0;
        width: 150px;
        text-align: left;
        font-size: 14px;
      }
    }
  }

  .coupon_num {
    position: absolute;
    top: -8px;
    left: 0px;
    padding: 0px 10px;
    height: 18px;
    line-height: 18px;
    font-size: 10px;
    color: #8c4206;
    border-radius: 6px 1px 6px 0px;
    background: #ffdfc5;
  }

  .main {
    width: 100%;
  }

  .name {
    padding: 0 6px;
    font-family: PingFangSC-Medium;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #222222;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }

  .amount {
    font-family: "DIN-Bold";
    font-weight: bold;
    font-size: 36px;
    line-height: 30px;
    color: #ff4747;
  }

  .rmb {
    margin-left: 2px;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #ff4747;
  }

  .time {
    display: flex;
    align-items: center;
    font-family:
      PingFangSC-Regular,
      PingFang SC;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #666666;

    .theme {
      color: #ff4747;
    }
  }

  .flex_jcc {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-top: 4px;
  }
}
