@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.recProductC {
  width: 100%;
  height: 80vh;
  padding: 12px 0 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #f1f2f5;
  display: flex;
  flex-direction: column;

  .popTitle {
    position: relative;
    margin-bottom: 12px;
    padding: 0 20px;
    font-size: 15px;
    font-weight: bold;
    flex-shrink: 0;

    .close {
      position: absolute;
      top: 2px;
      right: 20px;
      content: "";
      width: 16px;
      height: 16px;
      background: url(https://akim-oss.aikucun.com/88728495c3ab96971d824e94318e64f18a5eacff_1678711263453_43.png) center center no-repeat;
      background-size: 100%;
    }
  }

  .list {
    pointer-events: auto;
    overflow-y: auto;
    flex: 1;
    -webkit-overflow-scrolling: touch;
    position: relative;
    z-index: 1;
    padding-bottom: env(safe-area-inset-bottom);

    .safeBottom {
      width: 100%;
      height: 10px;
    }
  }
}