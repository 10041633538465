@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.container {
  position: relative;
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: inset 0px 0.5px 0px 0px rgba(34, 34, 34, 0.08);
  .tab-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 171px;
    height: 28px;
    font-size: 12px;
    color: #222222;
    border-radius: 6px;
    background: #f1f2f5;
    &.active {
      font-family:
        PingFangSC-Medium,
        PingFang SC;
      font-weight: 500;
      color: #ff4747;
      background: rgba(255, 71, 71, 0.16);
    }
  }
}
