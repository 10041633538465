@themeColor: #ff4747;
@warningColor: #ff6600;
@lightColor: rgba(@themeColor, 0.08);
@bigBorderRadius: 12px;
@smallBorderRadius: 4px;
@bigPadding: 12px;

:root:root {
  --adm-color-primary: @themeColor;
}
.list-wrapper-container {
  padding: 4px 16px 16px 16px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .list-wrapper {
    &-date {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      height: 20px;
      color: #222222;
      margin-bottom: 6px;
    }
    &-content {
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      &-item {
        width: calc((100vw - 32px - 31px) / 3);
        margin-bottom: 20px;
        &:nth-child(3n + 2) {
          margin-left: 16px;
          margin-right: 15px;
        }
        :global(.lbl_item_img) {
          height: 100% !important;
        }
      }
    }
  }
  .infinite {
    margin-bottom: 40px;
  }
  .bottom-tips {
    width: 100%;
    display: flex;
    justify-content: center;
    .desc {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      text-align: center;
      color: #a5a5a5;
      position: relative;
      &::after {
        position: absolute;
        top: 50%;
        content: "";
        height: 1px;
        width: 12px;
        right: -16px;
        background-color: rgba(34, 34, 34, 0.16);
      }
      &::before {
        position: absolute;
        top: 50%;
        content: "";
        height: 1px;
        width: 12px;
        left: -16px;
        background-color: rgba(34, 34, 34, 0.16);
      }
    }
  }
  .loadingMore {
    transform: rotate(360deg);
    -webkit-transition: -webkit-transform 1s linear;
    transition: transform 1s linear;
    -webkit-animation: spin 1s linear 1s 5 alternate;
    animation: spin 1s linear infinite;
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
